import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, message, Radio } from 'antd';
import { withRouter } from 'react-router';

import SelectDropdown from 'components/SelectDropdown';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setToArray, setCreateEditMessage } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getFlowList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;

  const [states, setStates] = useState([]);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await FlowApi.createFlow(
            values.flowId,
            values.flowName,
            values.startStateId,
            values.endStateId,
            values.withTestFit
          );

          history.push({
            pathname: '/cms/master/flow',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Flow Data',
            'Error Inserting Flow Data!'
          );
          getFlowList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  return (
    <Modal
      title="Add New Flow Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Flow Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('flowId', {
            rules: [{ required: true, message: 'Please input flow ID!' }],
          })(<Input placeholder="Flow ID" size="large" autoComplete="false" />)}
        </Form.Item>

        <Form.Item label="Name">
          {getFieldDecorator('flowName', {
            rules: [{ required: true, message: 'Please input flow name!' }],
          })(
            <Input placeholder="Flow Name" size="large" autoComplete="false" />
          )}
        </Form.Item>

        <Form.Item label="Start State ID">
          {getFieldDecorator('startStateId', {
            rules: [
              { required: true, message: 'Please input start state ID!' },
            ],
          })(
            <SelectDropdown
              options={stateOptions}
              placeHolder={'Select Start State'}
            />
          )}
        </Form.Item>
        <Form.Item label="End State ID">
          {getFieldDecorator('endStateId', {
            rules: [{ required: true, message: 'Please input end state ID!' }],
          })(
            <SelectDropdown
              options={stateOptions}
              placeHolder={'Select End State'}
            />
          )}
        </Form.Item>

        <Form.Item label="Test Fit">
          {getFieldDecorator('withTestFit', {
            rules: [{ required: true, message: 'Please choose test fit!' }],
          })(
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
