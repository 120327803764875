import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Row, Col, message } from 'antd';
import { pdf, StyleSheet } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
// import { lowerCase, startCase } from 'lodash';
import { lowerCase, merge } from 'lodash';
import { withRouter } from 'react-router';

import { setCreateEditMessage } from 'utils';
import EkanbanApi from 'api/e-kanban';
import SelectDropdown from 'components/SelectDropdown';

import { PrintLabel } from '../../helper/PrintPdfDocument';
import {
  printTypeOptions,
  roundOptions,
  testFitOptions,
  trimUpperOptions,
  trimLowerOptions,
} from '../../helper';
import { setEkanbanForm } from 'store/action/EkanbanAction';
import { connect } from 'react-redux';

export const FormQR = ({
  values: eKanbanPrintLabelDataValue,
  form,
  eKanbanPrintLabelData,
  eKanbanData,
  currentState,
  history,
}) => {
  const { getFieldDecorator } = form;
  const [qrCanvas, setQrCanvas] = useState('');
  const { TextArea } = Input;
  let QRCode = require('qrcode.react');

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const handleSubmitPrintFormQR = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let theResponse;

          if (
            values.boxNumber === undefined ||
            values.clinic === undefined ||
            values.doctor === undefined ||
            values.lowerData === undefined ||
            values.medicalRecord === undefined ||
            values.notes === undefined ||
            values.printNote === undefined ||
            values.printType === undefined ||
            values.priorityNote === undefined ||
            values.round === undefined ||
            values.testFit === undefined ||
            values.trimLower === undefined ||
            values.trimUpper === undefined ||
            values.upperData === undefined
          ) {
            const currentData = moment(new Date()).format('DD-MM-YYYY');
            const pdfStyles = StyleSheet.create({
              page: {
                minWidth: 446,
                minHeight: 295,
                maxWidth: 446,
                maxHeight: 295,
              },
            });

            const blob = await pdf(
              <PrintLabel
                value={{
                  date: currentData,
                  boxNumber: values.boxNumber,
                  // customerName: startCase(
                  //   eKanbanPrintLabelDataValue.customerName.toLowerCase() ||
                  //     'customer name'
                  // ),
                  customerName:
                    eKanbanPrintLabelDataValue.customerName || 'customer name', //to prevent splitted char between alpha and number
                  orderType: eKanbanPrintLabelDataValue.orderType,
                  orderDesc: eKanbanPrintLabelDataValue.orderDesc,
                  doctor: values.doctor,
                  clinic: values.clinic,
                  lower: values.lower,
                  upper: values.upper,
                  notes: values.notes,
                  round: values.round,
                  printType: values.printType,
                  soNumber: eKanbanPrintLabelDataValue.orderReceiptNumber,
                  medicalRecord: values.medicalRecord,
                  testFit: values.testFit,
                  trimUpper: values.trimUpper,
                  trimLower: values.trimLower,
                  printNote: values.printNote,
                  priorityNote: values.priorityNote,
                  upperData: values.upperData,
                  lowerData: values.lowerData,
                  size: pdfStyles.page,
                }}
                qrCanvas={qrCanvas}
              />
            ).toBlob();

            saveAs(
              blob,
              `print-label-${lowerCase(
                `${eKanbanPrintLabelDataValue.customerName || 'customer name'}`
              ).replace(/ /g, '-')}.pdf`
            );
            // message.error('Please fill all the fields');
            return false;
          } else {
            theResponse = await EkanbanApi.addEkanbanData(
              id,
              values.boxNumber,
              values.medicalRecord,
              values.clinic,
              values.doctor,
              values.printType,
              values.round,
              values.upper,
              values.lower,
              values.notes,
              values.testFit,
              values.trimLower,
              values.trimUpper,
              values.printNote,
              values.priorityNote,
              values.upperData,
              values.lowerData
            );

            if (theResponse.data.status !== 'FAILED') {
              history.goBack();
            }

            setCreateEditMessage(
              theResponse.data,
              'Success Updating Ekanban Data with ID ' + id,
              'Error Updating Ekanban Data!'
            );
          }
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          if (
            values.boxNumber === undefined ||
            values.clinic === undefined ||
            values.doctor === undefined ||
            values.lowerData === undefined ||
            values.medicalRecord === undefined ||
            values.notes === undefined ||
            values.printNote === undefined ||
            values.printType === undefined ||
            values.priorityNote === undefined ||
            values.round === undefined ||
            values.testFit === undefined ||
            values.trimLower === undefined ||
            values.trimUpper === undefined ||
            values.upperData === undefined
          ) {
            return false;
          }
          const currentData = moment(new Date()).format('DD-MM-YYYY');
          const pdfStyles = StyleSheet.create({
            page: {
              minWidth: 446,
              minHeight: 295,
              maxWidth: 446,
              maxHeight: 295,
            },
          });

          const blob = await pdf(
            <PrintLabel
              value={{
                date: currentData,
                boxNumber: values.boxNumber,
                // customerName: startCase(
                //   eKanbanPrintLabelDataValue.customerName.toLowerCase() ||
                //     'customer name'
                // ),
                customerName:
                  eKanbanPrintLabelDataValue.customerName || 'customer name', //to prevent splitted char between alpha and number
                orderType: eKanbanPrintLabelDataValue.orderType,
                orderDesc: eKanbanPrintLabelDataValue.orderDesc,
                doctor: values.doctor,
                clinic: values.clinic,
                lower: values.lower,
                upper: values.upper,
                notes: values.notes,
                round: values.round,
                printType: values.printType,
                soNumber: eKanbanPrintLabelDataValue.orderReceiptNumber,
                medicalRecord: values.medicalRecord,
                testFit: values.testFit,
                trimUpper: values.trimUpper,
                trimLower: values.trimLower,
                printNote: values.printNote,
                priorityNote: values.priorityNote,
                upperData: values.upperData,
                lowerData: values.lowerData,
                size: pdfStyles.page,
              }}
              qrCanvas={qrCanvas}
            />
          ).toBlob();

          saveAs(
            blob,
            `print-label-${lowerCase(
              `${eKanbanPrintLabelDataValue.customerName || 'customer name'}`
            ).replace(/ /g, '-')}.pdf`
          );
          // setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    const qrCodeCanvas = document.querySelectorAll(
      "[data-qr='" + window.location.href + "']"
    )[0];
    const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
    setQrCanvas(qrCodeDataUri);
  }, []);

  return (
    <Form onSubmit={handleSubmitPrintFormQR} className="details__form-qr pb-15">
      <Row gutter={15} className="mt-15">
        <Col span={6}>
          <Form.Item className="text-center">
            <div className="details__qr-item mb-15">
              <QRCode
                data-qr={window.location.href}
                size={257}
                responsive={true}
                value={window.location.href}
                includeMargin={true}
              />
            </div>

            <div>
              {(currentState === '1025' ||
                currentState.includes('1071') ||
                currentState === '1072' ||
                currentState.includes('1091') ||
                currentState === '1092') && (
                <Button className="btn-submit" type="primary" htmlType="submit">
                  Print
                </Button>
              )}
            </div>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Row gutter={15} className="flex-row">
            <Col span={8}>
              <Form.Item label="Box Number">
                {getFieldDecorator('boxNumber', {
                  initialValue:
                    eKanbanPrintLabelDataValue &&
                    eKanbanPrintLabelDataValue.boxNumber,
                })(<Input size="large" placeholder="Box Number" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Test Fit">
                {getFieldDecorator('testFit', {
                  initialValue: eKanbanData && eKanbanData.testFit,
                })(
                  <SelectDropdown
                    options={testFitOptions}
                    placeHolder={'Test Fit'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Printing Type">
                {getFieldDecorator('printType', {
                  initialValue: eKanbanData && eKanbanData.printType,
                })(
                  <SelectDropdown
                    options={printTypeOptions}
                    placeHolder={'Printing Type'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Medical Record">
                {getFieldDecorator('medicalRecord', {
                  initialValue: eKanbanData && eKanbanData.medicalRecord,
                })(<Input size="large" placeholder="Medical Record" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Clinic">
                {getFieldDecorator('clinic', {
                  initialValue: eKanbanData && eKanbanData.clinic,
                })(<Input size="large" placeholder="Clinic" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Doctor">
                {getFieldDecorator('doctor', {
                  initialValue: eKanbanData && eKanbanData.doctor,
                })(<Input size="large" placeholder="Doctor" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Upper">
                {getFieldDecorator('upperData', {
                  initialValue: eKanbanData && eKanbanData.upperData,
                })(<Input size="large" placeholder="Upper" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Trim Upper">
                {getFieldDecorator('trimUpper', {
                  initialValue: eKanbanData && eKanbanData.trimUpper,
                })(
                  <SelectDropdown
                    options={trimUpperOptions}
                    placeHolder={'Trim Upper'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Production Round">
                {getFieldDecorator('round', {
                  initialValue: eKanbanData && eKanbanData.productionRound,
                })(
                  <SelectDropdown
                    options={roundOptions}
                    placeHolder={'Production Round'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Lower">
                {getFieldDecorator('lowerData', {
                  initialValue: eKanbanData && eKanbanData.lowerData,
                })(<Input size="large" placeholder="Lower" />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Trim Lower">
                {getFieldDecorator('trimLower', {
                  initialValue: eKanbanData && eKanbanData.trimLower,
                })(
                  <SelectDropdown
                    options={trimLowerOptions}
                    placeHolder={'Trim Lower'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Notes Tim Cetakan">
                {getFieldDecorator('printNote', {
                  initialValue: eKanbanData && eKanbanData.printNote,
                })(
                  <TextArea
                    size="large"
                    placeholder="Notes Tim Cetakan"
                    rows={4}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Priority Notes">
                {getFieldDecorator('priorityNote', {
                  initialValue: eKanbanData && eKanbanData.priorityNote,
                })(
                  <TextArea
                    size="large"
                    placeholder="Priority Notes"
                    rows={4}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Notes">
                {getFieldDecorator('notes', {
                  initialValue: eKanbanData && eKanbanData.notes,
                })(
                  <TextArea
                    size="large"
                    placeholder="Notes"
                    rows={4}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = ({ eKanbanForm }) => ({
  eKanbanForm,
});

const connectRedux = connect(mapStateToProps, {
  setEkanbanForm,
})(
  Form.create({
    onFieldsChange(props, fields) {
      const data = merge({}, props.eKanbanForm, props.form.getFieldsValue());
      props.setEkanbanForm(data);
    },
  })(FormQR)
);

export default withRouter(connectRedux);
