/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Input, Form, message, Tooltip, Button } from 'antd';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';

import ClinicApi from 'api/clinic';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import { setCreateEditMessage } from 'utils';

export function ClinicEditComponent({ location, form, history }) {
  const [clinicDetail, setClinicDetail] = useState([]);
  const { pathname } = location;
  const clinicId = pathname.split('/').pop();
  const { getFieldDecorator } = form;
  const { TextArea } = Input;

  useEffect(() => {
    async function getClinicListById() {
      const response = await ClinicApi.getClinicById(clinicId);
      setClinicDetail(response.data.data);
    }
    getClinicListById();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let theResponse;

          theResponse = await ClinicApi.updateClinic(
            clinicId,
            values.id,
            values.clinicName,
            values.clinicContactNumber,
            values.clinicAddress
          );

          setCreateEditMessage(
            theResponse.data,
            `Success Updating Clinic Data With Id ${clinicId}`,
            `Error Updating Clinic Data With Id ${clinicId}!`
          );

          if (theResponse.data.status !== 'FAILED') {
            history.goBack();
          }
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          // setLoadingButton(false);
        }
      }
    });
  }

  return (
    <>
      {!isEmpty(clinicDetail) && (
        <DetailLayout
          detailTime={`${clinicDetail.clinicCode} ${
            clinicDetail.clinicName ? `- ${clinicDetail.clinicName}` : ''
          } ${
            clinicDetail.clinicAddress ? `- ${clinicDetail.clinicAddress}` : ''
          }`}
          detailTitle={'Clinic'}
          className={'edit-form'}
          detailComponent={
            <Form onSubmit={(event) => handleSubmit(event)}>
              <Form.Item className="edit-form__submit">
                <Tooltip title="Submit">
                  <Button size="large" htmlType="submit" icon="check-circle" />
                </Tooltip>
              </Form.Item>
              <Form.Item label="Id">
                {getFieldDecorator('id', {
                  initialValue: clinicDetail.id,
                })(
                  <Input
                    placeholder="Id"
                    size="large"
                    autoComplete="false"
                    disabled
                  />
                )}
              </Form.Item>
              <Form.Item label="Name">
                {getFieldDecorator('clinicName', {
                  initialValue: clinicDetail.clinicName,
                })(
                  <TextArea
                    placeholder="Clinic Name"
                    size="large"
                    rows={2}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
              <Form.Item label="Contact Number">
                {getFieldDecorator('clinicContactNumber', {
                  initialValue: clinicDetail.clinicContactNumber,
                })(
                  <TextArea
                    placeholder="Contact Number"
                    size="large"
                    rows={2}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
              <Form.Item label="Address">
                {getFieldDecorator('clinicAddress', {
                  initialValue: clinicDetail.clinicAddress,
                })(
                  <TextArea
                    placeholder="Address"
                    size="large"
                    rows={2}
                    autoComplete="false"
                  />
                )}
              </Form.Item>
            </Form>
          }
        />
      )}
    </>
  );
}

export const ClinicEditComponentForm = Form.create({
  name: 'validate_edit_clinic_form',
})(ClinicEditComponent);

export default withRouter(ClinicEditComponent);
