import React, { useEffect, useState } from 'react';
import { Modal, Input, Col, Row, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import OrderHistoryApi from 'api/order';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setCreateEditMessage, setToArray } from 'utils';
import SelectDropdown from 'components/SelectDropdown';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getOrderHistoryList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [flows, setFlows] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderStatuss, setOrderStatuss] = useState([]);
  const [states, setStates] = useState([]);
  const [orders, setOrders] = useState([]);
  const username = localStorage.getItem('username');

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await OrderHistoryApi.createOrderHistory(
            values.orderHistoryId,
            values.orderId,
            values.prevFlowId,
            values.thisFlowId,
            values.prevOrderType,
            values.thisOrderType,
            values.prevStateId,
            values.thisStateId,
            values.prevOrderStatusId,
            values.thisOrderStatusId,
            username
          );

          history.push({
            pathname: '/cms/transaction/order-history',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Order History Data',
            'Error Inserting Order History Data!'
          );

          getOrderHistoryList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    async function getOrderList() {
      const response = await OrderHistoryApi.listOrderDetail();
      let orderList = setToArray(response.data);

      setOrders(orderList);
    }

    getOrderList();
  }, []);

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  useEffect(() => {
    async function getOrderStatusList() {
      const response = await OrderHistoryApi.listOrderStatus();
      let orderStatusList = setToArray(response.data);

      setOrderStatuss(orderStatusList);
    }

    getOrderStatusList();
  }, []);

  useEffect(() => {
    async function getOrderTypeList() {
      const response = await OrderHistoryApi.listOrderType();
      let orderTypeList = setToArray(response.data);

      setOrderTypes(orderTypeList);
    }

    getOrderTypeList();
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  const orderTypeOptions = orderTypes.map((value) => ({
    label: `${value.orderType} - ${value.orderDesc}`,
    value: value.orderType,
  }));

  const orderStatusOptions = orderStatuss.map((value) => ({
    label: `${value.orderStatusId} - ${value.orderStatusDesc}`,
    value: value.orderStatusId,
  }));

  const orderIdOptions = orders.map((value) => ({
    label: value.orderId,
    value: value.orderId,
  }));

  return (
    <Modal
      title="Add New Order History Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Order History Data.</div>
      <Form>
        <Row gutter={20}>
          <Col span={12}>
            {' '}
            <Form.Item label="Order ID">
              {getFieldDecorator('orderId', {
                rules: [{ required: true, message: 'Please input order!' }],
              })(
                <SelectDropdown
                  options={orderIdOptions}
                  placeHolder={'Select Order ID'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Previous Flow">
              {getFieldDecorator('prevFlowId', {
                rules: [{ required: true, message: 'Please input flow!' }],
              })(
                <SelectDropdown
                  options={flowOptions}
                  placeHolder={'Select Flow'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="This Flow">
              {getFieldDecorator('thisFlowId', {
                rules: [{ required: true, message: 'Please input flow!' }],
              })(
                <SelectDropdown
                  options={flowOptions}
                  placeHolder={'Select Flow'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Previous Order Type">
              {getFieldDecorator('prevOrderType', {
                rules: [
                  { required: true, message: 'Please input order type!' },
                ],
              })(
                <SelectDropdown
                  options={orderTypeOptions}
                  placeHolder={'Select Order Type'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="This Order Type">
              {getFieldDecorator('thisOrderType', {
                rules: [
                  { required: true, message: 'Please input order type!' },
                ],
              })(
                <SelectDropdown
                  options={orderTypeOptions}
                  placeHolder={'Select Order Type'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Previous State">
              {getFieldDecorator('prevStateId', {
                rules: [
                  { required: true, message: 'Please input prev state!' },
                ],
              })(
                <SelectDropdown
                  options={stateOptions}
                  placeHolder={'Select State'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Current State">
              {getFieldDecorator('thisStateId', {
                rules: [
                  { required: true, message: 'Please input current state!' },
                ],
              })(
                <SelectDropdown
                  options={stateOptions}
                  placeHolder={'Select State'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Previous Order Status">
              {getFieldDecorator('prevOrderStatusId', {
                rules: [
                  { required: true, message: 'Please input order status!' },
                ],
              })(
                <SelectDropdown
                  options={orderStatusOptions}
                  placeHolder={'Select Order Status'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="This Order Status">
              {getFieldDecorator('thisOrderStatusId', {
                rules: [
                  { required: true, message: 'Please input order status!' },
                ],
              })(
                <SelectDropdown
                  options={orderStatusOptions}
                  placeHolder={'Select Order Status'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Order History ID">
              {getFieldDecorator('orderHistoryId', {
                rules: [
                  {
                    required: true,
                    message: 'Please input order history ID!',
                  },
                ],
              })(
                <Input
                  placeholder="Order History ID"
                  size="large"
                  autoComplete="false"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
