import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip, Radio } from 'antd';

import SelectDropdown from 'components/SelectDropdown';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setToArray, setCreateEditMessage } from 'utils';

import './style.scss';

export function FlowCreateEdit({ form, history }) {
  const [flowById, setFlowById] = useState({});
  const [states, setStates] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await FlowApi.updateFlow(
            id,
            values.flowName,
            values.startStateId,
            values.endStateId,
            values.withTestFit
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/flow',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Flow Data with Flow ID ' + id,
            'Error Updating Flow Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  useEffect(() => {
    async function getFlowById() {
      const response = await FlowApi.flowById(id);
      let flowByIdData = setToArray(response.data);

      setFlowById(flowByIdData[0]);
    }

    getFlowById();
    // eslint-disable-next-line 
  }, []);

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  return (
    <DetailLayout
      detailTime={`${id} - ${flowById ? flowById.flowName : ''}`}
      detailTitle={'Edit Flow Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="ID">
            {getFieldDecorator('flowId', {
              rules: [{ required: true, message: 'Please input flow ID!' }],
              initialValue: flowById && flowById.flowId,
            })(
              <Input
                placeholder="Flow ID"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('flowName', {
              rules: [{ required: true, message: 'Please input flow name!' }],
              initialValue: flowById && flowById.flowName,
            })(
              <Input
                size="large"
                placeholder="Flow Name"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="Start State ID">
            {getFieldDecorator('startStateId', {
              rules: [
                { required: true, message: 'Please input start state ID!' },
              ],
              initialValue: flowById && flowById.startStateId,
            })(
              <SelectDropdown
                options={stateOptions}
                placeHolder={'Select Start State'}
              />
            )}
          </Form.Item>
          <Form.Item label="End State ID">
            {getFieldDecorator('endStateId', {
              rules: [
                { required: true, message: 'Please input end state ID!' },
              ],
              initialValue: flowById && flowById.endStateId,
            })(
              <SelectDropdown
                options={stateOptions}
                placeHolder={'Select End State'}
              />
            )}
          </Form.Item>

          <Form.Item label="Test Fit">
            {getFieldDecorator('withTestFit', {
              rules: [{ required: true, message: 'Please choose test fit!' }],
              initialValue: flowById && flowById.withTestFit,
            })(
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const FlowForm = Form.create({
  name: 'validate_create_flow',
})(FlowCreateEdit);

export default { FlowCreateEdit };
