import React, { useState, useRef, useEffect } from 'react';
import { Button, Form } from 'antd';
import BoxApi from 'api/box';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';
import { setToArray } from 'utils';

export const ModalReplaceBox = ({
  showModal,
  handleHideModal,
  isReplaceBox,
  setButtonState,
  form,
  values,
  handleShowModalTestFit,
  setProgress1Data,
  progress1Data,
}) => {
  const [boxs, setBoxs] = useState([]);
  const { getFieldDecorator } = form;
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    boxNumber: [],
  });

  const innerRef = useRef(null);

  useEffect(() => {
    async function getBoxList() {
      const response = await BoxApi.listBox();
      let boxList = setToArray(response.data);
      setBoxs(boxList);
    }

    getBoxList();
  }, []);

  const handleOnRowPathname = (record) => {
    setProgress1Data({
      ...progress1Data,
      boxNumber: record.boxNumber,
    });
    if (isReplaceBox) {
      setButtonState(1);
    } else {
      handleShowModalTestFit();
    }
    handleHideModal();
  };

  const boxRow = (row) => {
    return (
      <Form.Item className="mb-0">
        {getFieldDecorator('boxNumber', {
          initialValue: values && values.boxNumber,
        })(
          <Button className="replace-box__btn-submit" htmlType="submit">
            {row.boxNumber}
          </Button>
        )}
      </Form.Item>
    );
  };

  const boxColumns = [
    {
      title: 'Name',
      key: 'boxNumber',
      render: (row) => boxRow(row),
      sorter: (a, b) => a.boxNumber.localeCompare(b.boxNumber),
    },
  ];

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        idContainer={'form-detail-order'}
        modalComponent={
          <TableGlobal
            tableName={'Select Box'}
            inputPlaceholder={'Search Box Here ...'}
            pageSize={8}
            originalData={boxs}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={boxColumns}
            recordKey={(record) => record.boxNumber}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />
    </>
  );
};

export default ModalReplaceBox;
