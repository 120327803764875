import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button, Col, Row } from 'antd';

import { OrderContext } from 'contexts/Order';

import MultiselectDropdown from 'components/MultiselectDropdown';

export function TableOrderFilter({ handleFilterBy, filterBy }) {
  const innerRef = useRef(null);
  const firstRenderRef = useRef(true)

  const {orderTypeList} = useContext(OrderContext);

  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState([]);
  const [overdueFilterValue, setOverdueFilterValue] = useState([])
  const [testFitFilterValue, setTestFitFilterValue] = useState([])

  const onOrderTypeChange = (checkedValues) => {
    setOrderTypeFilterValue(checkedValues)
  };

  const onOverdueChange = (checkedValues) => {
    setOverdueFilterValue(checkedValues)
  }

  const onTestFitChange = (checkedValues) => {
    setTestFitFilterValue(checkedValues)
  }

  const handleReset = () => {
    setOrderTypeFilterValue([])
    setOverdueFilterValue([])
    setTestFitFilterValue([])
  }
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }
    handleFilterBy({
      orderType: orderTypeFilterValue,
      isOverdue: overdueFilterValue,
      isTestFit: testFitFilterValue,
    })
    // eslint-disable-next-line
  }, [orderTypeFilterValue, overdueFilterValue, testFitFilterValue])
  
  let orderTypeOptions = orderTypeList.map((val)=> ({
    value: val.orderType,
    label: `${val.orderType} - ${val.orderDesc}`
  }))

  return ( 
    <div
      style={{
        marginTop: '-15px',
        borderRadius: '8px',
        padding: '30px',
        position: 'absolute',
        zIndex: '10',
        right: '5em',
        background: 'white',
        width: '600px',
        boxShadow: '0 2px 4px -1px rgb(0 0 0 / 20%)',
      }}
      ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={() => handleReset()} type="link">
            Reset
          </Button>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder="Select Order Type"
            onChange={onOrderTypeChange}
            options={orderTypeOptions}
            selectValue={orderTypeFilterValue}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder='Select Overdue'
            onChange={onOverdueChange}
            options={[{value: 'no', label: '-'},{value:'yes', label: 'Overdue'}]}
            selectValue={overdueFilterValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder='Select Test Fit'
            onChange={onTestFitChange}
            options={[{value: 'no', label: 'No Test Fit'},{value:'yes', label: 'Test Fit'}]}
            selectValue={testFitFilterValue}
          />
        </Col>
      </Row>
    </div>
  );
}
