//Clinic
export const GET_LIST_CLINIC = 'GET_LIST_CLINIC';

//BE Error Log
export const GET_LIST_BE_ERROR_LOG = 'GET_LIST_BE_ERROR_LOG';
export const GET_BE_ERROR_LOG_BY_ID = 'GET_BE_ERROR_LOG_BY_ID';

//Order
export const GET_LIST_STATION_ORDER = 'GET_LIST_STATION_ORDER';
export const GET_LIST_SUMMARY_DASHBOARD_ORDER =
  'GET_LIST_SUMMARY_DASHBOARD_ORDER';
export const GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS =
  'GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS';
export const GET_LIST_OVERDUE_ORDER = 'GET_LIST_OVERDUE_ORDER';
export const GET_LIST_DASHBOARD_BY_DATE = 'GET_LIST_DASHBOARD_BY_DATE';
export const GET_REFINEMENT_HISTORY_LIST = 'GET_REFINEMENT_HISTORY_LIST';
export const GET_RESET_HISTORY_LIST = 'GET_RESET_HISTORY_LIST';
export const GET_OVERDUE_ORDER_NUMBER = 'GET_OVERDUE_ORDER_NUMBER';
export const GET_LIST_DASHBOARD_STATION_ORDER =
  'GET_LIST_DASHBOARD_STATION_ORDER';

//Auth
export const GET_LIST_USER = 'GET_LIST_USER';

//E-kanban
export const GET_LIST_STATION_STATE = 'GET_LIST_STATION_STATE';
export const GET_LIST_STATION_HISTORY = 'GET_LIST_STATION_HISTORY';
export const GET_EKANBAN_PRINT_LABEL_DATA = 'GET_EKANBAN_PRINT_LABEL_DATA';
export const GET_EKANBAN_DATA = 'GET_EKANBAN_DATA';
export const SET_EKANBAN_FORM = 'SET_EKANBAN_FORM';

//State
export const GET_LIST_STATE = 'GET_LIST_STATE';
export const GET_LIST_PRINT_GROUP = 'GET_LIST_PRINT_GROUP';

//Refund Request
export const GET_LIST_REFUND_REQUEST = 'GET_LIST_REFUND_REQUEST';

//Component
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

//Payment Detail
export const GET_LIST_PAYMENT_DETAIL_BY_ID = 'GET_LIST_PAYMENT_DETAIL_BY_ID';

//Simulation Design
export const GET_LIST_SIMULATION_DESIGN = 'GET_LIST_SIMULATION_DESIGN';
export const GET_SIMULATION_DESIGN_BY_ID = 'GET_SIMULATION_DESIGN_BY_ID';
export const GET_LIST_PATIENT_NAME_SIMULATION_DESIGN =
  'GET_LIST_PATIENT_NAME_SIMULATION_DESIGN';
export const GET_LIST_STATUS_SIMULATION_DESIGN =
  'GET_LIST_STATUS_SIMULATION_DESIGN';
export const GET_LIST_SO_NUMBER_SIMULATION_DESIGN =
  'GET_LIST_SO_NUMBER_SIMULATION_DESIGN';
export const GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN =
  'GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN';
export const GET_ORDER_TYPE_SIMULATION_DESIGN =
  'GET_ORDER_TYPE_SIMULATION_DESIGN';
export const GET_LIST_DOCTOR_SIMULATION_DESIGN =
  ' GET_LIST_DOCTOR_SIMULATION_DESIGN';
export const GET_LIST_DESIGNER_SIMULATION_DESIGN =
  'GET_LIST_DESIGNER_SIMULATION_DESIGN';
export const GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN =
  'GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN';
export const GET_LIST_VIDEO_REVISION_HISTORY =
  'GET_LIST_VIDEO_REVISION_HISTORY';
export const GET_LIST_ARCHIVED_SIMULATION_HISTORY =
  'GET_LIST_ARCHIVED_SIMULATION_HISTORY ';
export const GET_LIST_IMPRESSION_SIMULATION_HISTORY =
  'GET_LIST_IMPRESSION_SIMULATION_HISTORY';

//Clinic Note
export const GET_LIST_CLINIC_NOTE_BY_ID = 'GET_LIST_CLINIC_NOTE_BY_ID';
export const GET_DETAIL_CLINIC_NOTE_BY_ID = 'GET_DETAIL_CLINIC_NOTE_BY_ID';
export const GET_LIST_CLINIC_NOTE = 'GET_LIST_CLINIC_NOTE';
export const GET_STATUS_CLINIC_NOTE = 'GET_STATUS_CLINIC_NOTE';
export const GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID =
  'GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID';
