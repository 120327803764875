import React from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import OrderApi from 'api/order';
import { setCreateEditMessage } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getOrderStatusList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.createOrderStatus(
            values.orderStatusId,
            values.orderStatusDesc
          );

          history.push({
            pathname: '/cms/master/order-status',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Order Status Data',
            'Error Inserting Order Status Data!'
          );

          getOrderStatusList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  return (
    <Modal
      title="Add New Order Status Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Order Status Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('orderStatusId', {
            rules: [
              {
                required: true,
                message: 'Please input order status ID!',
              },
            ],
          })(<Input size="large" placeholder="Order Status ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('orderStatusDesc', {
            rules: [
              { required: true, message: 'Please input order status name!' },
            ],
          })(<Input size="large" placeholder="Order Status Name*" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
