import React from 'react';
import { Row, Col, Button, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import MultiselectDropdown from 'components/MultiselectDropdown';

export const FilterClinicNotes = ({
  activeFilter,
  resetFilter,
  innerRef,
  onCreatedDateChange,
  onFuTimeStampChange,
  onStateChange,
  onStatusChange,
  onAgentChange,
  statusNoteOptions,
  stateNoteOptions,
  agentOptions,
}) => {
  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <DatePicker
            disabledDate={disabledDate}
            size="large"
            showTime
            placeholder="Reported Date"
            format="DD/MM/YYYY"
            onChange={onCreatedDateChange}
            selectValue={activeFilter.createdAt}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <DatePicker
            disabledDate={disabledDate}
            size="large"
            showTime
            placeholder="FU Date"
            format="DD/MM/YYYY"
            onChange={onFuTimeStampChange}
            selectValue={activeFilter.fuTimestamp}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Insert Status'}
            onChange={onStatusChange}
            options={statusNoteOptions}
            selectValue={activeFilter.noteStatusDesc}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Insert State'}
            onChange={onStateChange}
            options={stateNoteOptions}
            selectValue={activeFilter.stateId}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Insert Agent Name'}
            onChange={onAgentChange}
            options={agentOptions}
            selectValue={activeFilter.createdBy}
          />
        </Col>
      </Row>
    </div>
  );
};

FilterClinicNotes.propTypes = {
  onFlowStatusChange: PropTypes.func,
};

export default FilterClinicNotes;
