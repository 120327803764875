import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import BoxApi from 'api/box';
import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';

import { setToArray } from 'utils';

import { tableBoxColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function BoxList() {
  const [boxs, setBoxs] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });

  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  async function getBoxList() {
    try {
      const response = await BoxApi.listBox();
      let boxList = setToArray(response.data);

      setBoxs(boxList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getBoxList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getBoxId = rowSelected.map((value) => value.boxNumber);
    const getBoxIdToString = getBoxId.join(',');

    confirm({
      title: `Are you sure delete this box data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then box data with id ${getBoxIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingBox() {
          try {
            await BoxApi.deleteMultipleBox(getBoxIdToString);
            setRowSelected([]);
            getBoxList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingBox();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return (
      <></>
      // <ActiveFilter
      //   setShowFilter={setShowFilter}
      //   activeFilterData={activeFilter}
      //   setActiveFilter={setActiveFilter}
      // />
    );
  };

  const filterComponent = () => {
    return (
      <></>
      // <Filter
      //   resetFilter={resetFilter}
      //   activeFilter={activeFilter}
      //   innerRef={innerRef}
      // />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/transaction/box/edit?id=${record.boxNumber}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Box'}
          inputPlaceholder={'Search Box Here...'}
          pageSize={8}
          originalData={boxs}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableBoxColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          isFilter={false}
          recordKey={(record) => record.boxNumber}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getBoxList={getBoxList}
      />
    </Layout>
  );
}

export default { BoxList };
