import { isEmpty } from 'lodash';
export const headerBoxCsv = [{ label: 'Name', key: 'boxNumber' }];

export const tableBoxColumns = [
  {
    title: 'Name',
    key: 'boxNumber',
    dataIndex: 'boxNumber',
    sorter: (a, b) => a.boxNumber.localeCompare(b.boxNumber),
  },
];

export const headerClinicCsv = [
  { label: 'Clinic Code', key: 'clinicCode' },
  { label: 'Clinic Name', key: 'clinicName' },
  { label: 'Clinic Address', key: 'clinicAddress' },
];

export const tableClinicColumns = [
  {
    title: 'Name',
    key: 'clinicName',
    render: (row) =>
      `${row['clinicCode']} ${
        !isEmpty(row['clinicName']) ? `- ${row['clinicName']}` : ''
      } ${!isEmpty(row['clinicAddress']) ? `- ${row['clinicAddress']}` : ''}`,
    sorter: (a, b) => a.clinicCode.localeCompare(b.clinicCode),
  },
];
