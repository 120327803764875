export const orderCheckboxOptions = [
  { label: 'Treat Attachment', value: 'true' },
  { label: 'Treat Slicing', value: 'true' },
  { label: '3D Scan', value: '1' },
  { label: 'Test Fit', value: 'true' },
  { label: 'Is Flagged', value: 'true' },
  { label: 'Removal Requested', value: 'removal' },
];

export const deliveryColums = [
  {
    title: 'Name',
    key: 'shippingAddress1',
    dataIndex: 'shippingAddress1',
  },
];

export const clinicNotesColums = [
  {
    title: 'Reported',
    key: 'createdAt',
    dataIndex: 'createdAt',
  },
  {
    title: 'Agent Name',
    key: 'createdBy',
    dataIndex: 'createdBy',
  },
  {
    title: 'State',
    key: 'stateId',
    render: (row) => `${row['stateId']} - ${row['stateDesc']}`,
  },
  {
    title: 'Status',
    key: 'noteStatusDesc',
    dataIndex: 'noteStatusDesc',
  },
  {
    title: 'FU Date',
    key: 'fuTimestamp',
    dataIndex: 'fuTimestamp',
  },
  {
    title: 'Notes',
    key: 'note',
    dataIndex: 'note',
  },
];

export const treatAttachmentColumns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
];

export const printTypeColumns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
];

export const printTypeList = [
  { name: '3D Scan', value: 1 },
  { name: 'Putty', value: 0 },
];

export const confirmCUList = [
  { name: 'Ya', value: 'yes' },
  { name: 'Tidak', value: 'no' },
];

export const testFitList = [
  {
    name: 'Test Fit No',
    value: false,
  },
  { name: 'Test Fit Yes', value: true },
];

export const treatAttachmentList = [
  { name: 'YES', value: true },
  { name: 'NO', value: false },
];

export const treatSlicingList = [
  { name: 'YES', value: true },
  { name: 'NO', value: false },
];

export const orderLogsColums = [
  {
    title: 'Timestamp',
    render: (row) => `${row['timeString']}`,
    key: 'timeString',
    sorter: (a, b) => a.timeString.localeCompare(b.timeString),
  },
  {
    title: 'User',
    render: (row) => `${row['createdBy']}`,
    key: 'user',
    sorter: (a, b) => a.timeString.localeCompare(b.timeString),
  },
  {
    title: 'Production Flow Step',
    key: 'flowStatus',
    render: (row) => `${row['flowStatus']} ${row['stateStatus']}`,
    sorter: (a, b) => a.flowStatus.localeCompare(b.flowStatus),
  },
  //   {
  //     title: 'Courier',
  //     key: 'shippingCourier',
  //     dataIndex: 'shippingCourier',
  //     sorter: (a, b) => a.shippingCourier.localeCompare(b.shippingCourier),
  //   },
  //   {
  //     title: 'Active Address',
  //     key: 'shippingAddress',
  //     dataIndex: 'shippingAddress',
  //     sorter: (a, b) =>
  //       (a.shippingAddress === null) - (b.shippingAddress === null),
  //   },
];

export const resetHistoryColums = [
  {
    title: 'Date',
    key: 'Date',
    dataIndex: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: 'Action',
    key: 'Action',
    sorter: (a, b) => a.revertType - b.revertType,
    render: (row) => (row['revertType'] ? row['revertType'] : '-'),
  },
  {
    title: 'Order Type',
    key: 'Order Type',
    dataIndex: 'orderLabel',
    sorter: (a, b) => a.orderLabel - b.orderLabel,
  },
  {
    title: 'Total Atas',
    key: 'Total Atas',
    sorter: (a, b) => a.totalUp - b.totalUp,
    render: (row) => (row['totalUp'] ? row['totalUp'] : '-'),
  },
  {
    title: 'Total Bawah',
    key: 'Total Bawah',
    sorter: (a, b) => a.totalDown - b.totalDown,
    render: (row) => (row['totalDown'] ? row['totalDown'] : '-'),
  },
  {
    title: 'AG Atas',
    key: 'AG Atas',
    sorter: (a, b) => a.agUp - b.agUp,
    render: (row) => (row['agUp'] ? row['agUp'] : '-'),
  },
  {
    title: 'AG Bawah',
    key: 'AG Bawah',
    sorter: (a, b) => a.agDown - b.agDown,
    render: (row) => (row['agDown'] ? row['agDown'] : '-'),
  },
  {
    title: 'OC Atas',
    key: 'OC Atas',
    sorter: (a, b) => a.ocUp - b.ocUp,
    render: (row) => (row['ocUp'] ? row['ocUp'] : '-'),
  },
  {
    title: 'OC Bawah',
    key: 'OC Bawah',
    sorter: (a, b) => a.ocDown - b.ocDown,
    render: (row) => (row['ocDown'] ? row['ocDown'] : '-'),
  },
  {
    title: 'Slicing',
    key: 'Slicing',
    sorter: (a, b) => a.slicing - b.slicing,
    render: (row) => (row['slicing'] ? 'Yes' : '-'),
  },
  {
    title: 'Attachment',
    key: 'Attachment',
    sorter: (a, b) => a.attachment - b.attachment,
    render: (row) => (row['attachment'] ? 'Yes' : '-'),
  },
  {
    title: 'Test Fit',
    key: 'Test Fit',
    sorter: (a, b) => a.testFit - b.testFit,
    render: (row) => (row['testFit'] ? 'Yes' : '-'),
  },
];

export const productionFlowColums = [
  {
    title: 'Legend',
    key: 'legend',
    dataIndex: 'legend',
  },
  {
    title: 'Production Flow',
    key: 'flowStatus',
    render: (row) => `${row.flowId} - ${row.actionName}`,
  },
  {
    title: 'Current State',
    key: 'stateDesc',
    dataIndex: 'stateDesc',
  },
  {
    title: 'Next State',
    key: 'nextStateDesc',
    dataIndex: 'nextStateDesc',
  },
];

export const productionFlowOCColums = [
  {
    title: 'OC Options',
    key: 'oc',
    dataIndex: 'name',
  },
];

export const ocList = [
  { name: 'Print with OC', value: true },
  { name: 'Print without OC', value: false },
];

export const reasonList1022to1011 = [
  {
    label: 'Butuh Treatment / Konsultasi / Cetak Ulang',
    value: 'Butuh Treatment / Konsultasi / Cetak Ulang',
  },
  { label: 'Hasil 3D Scan Bermasalah', value: 'Hasil 3D Scan Bermasalah' },
];

export const reasonList1022to1022 = [
  { label: 'Lab Order Bermasalah', value: 'Lab Order Bermasalah' },
];

export const reasonList1031to1011 = [
  { label: 'Hasil 3D Scan Bermasalah', value: 'Hasil 3D Scan Bermasalah' },
  {
    label: 'Hasil Scan Putty Bermasalah',
    value: 'Hasil Scan Putty Bermasalah',
  },
];

export const reasonList1040to1011 = [
  {
    label: 'Butuh Treatment / Konsultasi / Cetak Ulang',
    value: 'Butuh Treatment / Konsultasi / Cetak Ulang',
  },
];

export const reasonList1040to1022 = [
  { label: 'Lab Order Bermasalah', value: 'Lab Order Bermasalah' },
];

export const reasonList1040to1063 = [
  { label: 'Belum OK Test Fit', value: 'Belum OK Test Fit' },
];

export const reasonList1040to1064 = [
  { label: 'Hold Permintaan Pasien', value: 'Hold Permintaan Pasien' },
];

export const reasonList1040to1066 = [
  {
    label: 'Order cancelled by Customer / Pasien',
    value: 'Order cancelled by Customer / Pasien',
  },
];

export const reasonList1044to1011 = [
  {
    label: 'Butuh Treatment / Konsultasi / Cetak Ulang',
    value: 'Butuh Treatment / Konsultasi / Cetak Ulang',
  },
];

export const reasonList1044to1022 = [
  { label: 'Lab Order Bermasalah', value: 'Lab Order Bermasalah' },
];

export const reasonList1044to1064 = [
  { label: 'Hold Permintaan Pasien', value: 'Hold Permintaan Pasien' },
];

export const reasonList1044to1066 = [
  {
    label: 'Order cancelled by Customer / Pasien',
    value: 'Order cancelled by Customer / Pasien',
  },
];

export const reasonList1063to1011 = [
  { label: 'Belum OK Test Fit', value: 'Belum OK Test Fit' },
];
