import moment from 'moment';

export const archivedOrderColumns = [
  {
    title: 'Name',
    key: 'createdAt',
    render: (row) =>
      `${moment(row['createdAt']).format(moment.HTML5_FMT.DATE)} ${moment(
        row['createdAt']
      ).format(moment.HTML5_FMT.TIME_SECONDS)}`,
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: 'Primary Order Number',
    key: 'orderReceiptNumber',
    dataIndex: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
  },
  {
    title: 'Type',
    key: 'orderType',
    render: (row) => `${row['orderType']} - ${row['orderDesc']}`,
    sorter: (a, b) => a.orderType - b.orderType,
  },
  {
    title: 'State',
    key: 'state',
    render: (row) => row['stateId'],
    sorter: (a, b) => a.stateId.localeCompare(b.stateId),
  },
];

export const CompletedOrderColumns = [
  {
    title: 'Name',
    key: 'createdAt',
    render: (row) =>
      `${moment(row['createdAt']).format(moment.HTML5_FMT.DATE)} ${moment(
        row['createdAt']
      ).format(moment.HTML5_FMT.TIME_SECONDS)}`,
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: 'Primary Order Number',
    key: 'orderReceiptNumber',
    dataIndex: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
    render: (row) => {
      //   Format Customer Name => Before `CustomerNumber` After `Customer Number`
      const oldData = row;
      const regex = /(\D+)(\d+)/;
      const result = regex.exec(row);

      if (result !== null) {
        const customerName = result[1];
        const customerNumber = result[2];
        return `${customerName} ${customerNumber}`;
      } else {
        return oldData;
      }
    },
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
  },
  {
    title: 'Type',
    key: 'orderType',
    render: (row) => `${row['orderType']} - ${row['orderDesc']}`,
    sorter: (a, b) => a.orderType - b.orderType,
  },
];

export const flaggedOrderColumns = [
  {
    title: 'ID',
    key: 'id',
    render: (row) =>
      `${moment(row['createdAt']).format(moment.HTML5_FMT.DATE)} ${moment(
        row['createdAt']
      ).format(moment.HTML5_FMT.TIME_SECONDS)}`,
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
  },
  {
    title: 'Primary Order Number',
    key: 'orderReceiptNumber',
    dataIndex: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
  },
  {
    title: 'Test Fit',
    key: 'testFitStatus',
    dataIndex: 'testFitStatus',
    sorter: (a, b) => a.testFitStatus.localeCompare(b.testFitStatus),
  },
  {
    title: 'Flagged Note',
    key: 'flagNote',
    dataIndex: 'flagNote',
    sorter: (a, b) => a.flagNote.localeCompare(b.flagNote),
  },
];

export const activeOrderColumns = [
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => (a.createdAt === null) - (b.createdAt === null),
    width: 200,
  },
  {
    title: 'Refinement Type',
    dataIndex: 'refinementType',
    key: 'refinementType',
    sorter: (a, b) => (a.refinementType === null) - (b.refinementType === null),
    width: 150,
  },
  {
    title: 'Primary Order Number',
    dataIndex: 'orderReceiptNumber',
    key: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
    width: 200,
  },
  {
    title: 'Related Order ID',
    dataIndex: 'relatedOrderId',
    key: 'relatedOrderId',
    sorter: (a, b) => (a.relatedOrderId === null) - (b.relatedOrderId === null),
    width: 200,
  },
  {
    title: 'Patient Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
    width: 150,
  },
  {
    title: 'Order Type',
    render: (row) =>
      `${row['orderType']} - ${row['orderDesc']} ${row['orderFull']}`,
    key: 'orderType',
    sorter: (a, b) => a.orderType - b.orderType,
    width: 150,
  },
  {
    title: 'Current State',
    render: (row) => `${row['stateId']} - ${row['stateName']}`,
    key: 'currentState',
    sorter: (a, b) => a.stateId - b.stateId,
    width: 150,
  },
  {
    title: 'Jenis Cetakan',
    key: 'printTypeString',
    render: (row) =>
      row['printTypeString'] == '0'
        ? 'Putty'
        : row['printTypeString'] === '1'
        ? '3D Scan'
        : '-',
    sorter: (a, b) =>
      a.printTypeString &&
      b.printTypeString &&
      a.printTypeString.localeCompare(b.printTypeString),
  },
  {
    title: 'SLA Deadline',
    key: 'orderDeadline',
    sorter: (a, b) => (a.orderDeadline === null) - (b.orderDeadline === null),
    render: (row) => (row['orderDeadline'] ? row['orderDeadline'] : '-'),
  },
  {
    title: 'Test Fit',
    dataIndex: 'testFitStatus',
    key: 'testFitStatus',
    sorter: (a, b) => a.testFitStatus.localeCompare(b.testFitStatus),
  },
  {
    title: 'Overdue',
    key: 'deadlineStatus',
    render: (row) => (row['deadlineStatus'] === 'Overdue' ? 'Overdue' : '-'),
    sorter: (a, b) => a.deadlineStatus.localeCompare(b.deadlineStatus),
  },
  {
    title: 'Over Correct',
    key: 'ocChecklist',
    render: (row) => (row['ocChecklist'] ? 'Yes' : 'No'),
    sorter: (a, b) => (a.ocChecklist === null) - (b.ocChecklist === null),
  },
];

export const headerFlaggedExportCsv = [
  { label: 'ID', key: 'orderId' },
  { label: 'Name', key: 'orderName' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Test Fit', key: 'testFitStatus' },
  { label: 'Flagged Note', key: 'flagNote' },
];

export const headerActiveExportCsv = [
  { label: 'System ID', key: 'orderId' },
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Order Type ID', key: 'orderType' },
  { label: 'Order Type Name', key: 'orderDesc' },
  { label: 'Current State ID', key: 'stateId' },
  { label: 'Current State Name', key: 'stateName' },
  { label: 'Box Number', key: 'boxNumber' },
  { label: 'SLA Deadline', key: 'orderDeadline' },
  { label: 'Test Fit', key: 'testFitStatus' },
  { label: 'Overdue', key: 'deadlineStatus' },
  { label: 'Flagged', key: 'orderStatusDesc' },
  { label: 'Over Correct', key: 'ocChecklist' },
];

export const headerArchivedExportCsv = [
  { label: 'ID', key: 'orderId' },
  { label: 'Name', key: 'createdAt' }, //?
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'State', key: 'state' },
  { label: 'Type ID', key: 'orderType' },
  { label: 'Type Name', key: 'orderDesc' },
];

export const headerCompletedExportCsv = [
  { label: 'ID', key: 'orderId' },
  { label: 'Name', key: 'createdAt' }, //?
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Type ID', key: 'orderType' },
  { label: 'Type Name', key: 'orderDesc' },
  { label: 'State', key: 'state' },
];

export const overdueOptions = [
  { label: '-', value: 'On Track' },
  { label: 'Overdue', value: 'Overdue' },
];

export const printTypeOptions = [
  { label: 'Putty', value: '0' },
  { label: '3D Scan', value: '1' },
  { label: '-', value: '-' },
];

export const flaggedOptions = [
  { label: '-', value: 'In Progress' },
  { label: 'Flagged', value: 'Flagged' },
];

export const testFitOptions = [
  { label: 'No Test Fit', value: 'No Test Fit' },
  { label: 'Test Fit', value: 'Test Fit' },
];
