import React, { useContext, useState } from 'react';
import { Button, Form, Input, Layout, message } from 'antd';

import { AuthenticationContext } from 'contexts/Authentication';

import './style.scss';

const { Content } = Layout;

export function LoginPage({ form, history }) {
  const [loadingButton, seLoadingButton] = useState(false);

  const { getFieldDecorator } = form;
  const { login } = useContext(AuthenticationContext);

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      await login(payload);

      history.push({
        pathname: '/cms/dashboard',
      });
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleLogin(values);
      }
    });
  }

  return (
    <Content className="login">
      <div className="login__form-wrapper">
        <div className="login__logo-container">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
            alt="Rata Logo"
            className="mb-45 login__logo-img"
          />
        </div>
        <Form onSubmit={(event) => handleSubmit(event)} className="login-form">
          <Form.Item label="Email">
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Please input your E-mail!' }],
            })(<Input placeholder="Input your E-mail..." size="large" />)}
          </Form.Item>
          <Form.Item label=" Password">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(
              <Input
                type="password"
                placeholder="Input your Password..."
                size="large"
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              size="large"
              className="login__submit-btn mt-20">
              <div className="fw-bold text-sm">Log in</div>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
}

export const LoginPageForm = Form.create({ name: 'login' })(LoginPage);

export default { LoginPage };
