import StateApi from 'api/state';
import { setToArray } from 'utils';

import { GET_LIST_STATE, GET_LIST_PRINT_GROUP } from '../../type';

export const getListState = () => async (dispatch) => {
  const response = await StateApi.listState();

  dispatch({
    type: GET_LIST_STATE,
    payload: setToArray(response.data),
  });
};

export const getListPrintGroup = () => async (dispatch) => {
  const response = await StateApi.getPrintGroupList();

  dispatch({
    type: GET_LIST_PRINT_GROUP,
    payload: setToArray(response.data),
  });
};
