import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { message, Button } from 'antd';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';

import TableGlobal from 'components/Table';

import ModalArchivedDetail from './components/ModalArchivedDetail';
import { getListArchivedSimulationHistory } from 'store/action/SimulationDesignAction';

export const ArchivedHistory = ({
  listArchivedSimulationHistoryData,
  getListArchivedSimulationHistory,
  simulationDesignByIdData,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showArchivedDetail, setShowArchivedDetail] = useState(false);
  const [archivedData, setArchivedData] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const simulationDesignId = urlParams.get('id');
  const innerRef = useRef(null);
  //   const { pathname, search } = location;

  const handleOnRowPathname = () => {
    // return `${pathname}${search}`;
  };

  const handleModalArchived = (param, record) => (e) => {
    if (param === 'show') {
      setShowArchivedDetail(true);
      setArchivedData(record);
    } else {
      setShowArchivedDetail(false);
    }
  };

  async function getListArchivedSimulationHistoryData() {
    try {
      if (!isEmpty(simulationDesignByIdData)) {
        const convertSoNumber = simulationDesignByIdData[0].orderReceiptNumber.split(
          '/'
        )[0];
        await getListArchivedSimulationHistory(
          convertSoNumber,
          simulationDesignId
        );
      }
    } catch (err) {
      if (err.response) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      // setLoadingPage(false);
    }
  }

  useEffect(() => {
    getListArchivedSimulationHistoryData();
    // eslint-disable-next-line
  }, []);

  const ArchivedHistoryColumn = [
    {
      title: 'Simulation ID',
      key: 'simulationId',
      dataIndex: 'simulationId',
      sorter: (a, b) => a.simulationId.localeCompare(b.simulationId),
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a, b) =>
        a.createdBy === null || b.createdBy === null
          ? (a.createdBy === null) - (b.createdBy === null)
          : a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: (a, b) =>
        a.status === null || b.status === null
          ? (a.status === null) - (b.status === null)
          : a.status.localeCompare(b.status),
    },
    {
      title: 'Action',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Button
          type="primary"
          icon="bars"
          size={'large'}
          onClick={handleModalArchived('show', record)}
        />
      ),
    },
  ];

  return (
    <>
      {' '}
      <TableGlobal
        tableName={'Archived History'}
        classCustom="order-detail__table panel--table-history"
        inputPlaceholder={'Search Archived History Here...'}
        pageSize={8}
        originalData={listArchivedSimulationHistoryData || []}
        activeFilter={[{}]}
        setActiveFilter={{}}
        tableColumns={ArchivedHistoryColumn}
        exportCsvIsExist={false}
        editableTable={false}
        activeFilterComponent={<></>}
        isFilter={false}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        innerRef={innerRef}
        onRowPathname={handleOnRowPathname}
      />
      <ModalArchivedDetail
        handleModalArchived={handleModalArchived()}
        showModal={showArchivedDetail}
        archivedData={archivedData}
      />
    </>
  );
};

const mapStateToProps = ({ listArchivedSimulationHistoryData }) => ({
  listArchivedSimulationHistoryData,
});

export default withRouter(
  connect(mapStateToProps, {
    getListArchivedSimulationHistory,
  })(ArchivedHistory)
);
