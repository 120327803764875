import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { replaceCharacterWithLine } from 'utils';
const { TextArea } = Input;

function SimulationDesignNotes({ form, orderDetailData }) {
  const { getFieldDecorator } = form;

  return (
    <>
      <div className="color-text-primary text-md fw-bold mb-20">
        Design Notes
      </div>
      {/* <Form.Item label="Flagged Note">
        {getFieldDecorator('flagNote', {
          initialValue:
            orderDetailData &&
            orderDetailData.flagNote &&
            orderDetailData.flagNote.includes('||')
              ? replaceCharacterWithLine(orderDetailData.flagNote, '||')
              : orderDetailData.flagNote,
        })(
          <TextArea
            placeholder="Flagged Note"
            rows={4}
            autoComplete="false"
            className={'d-block'}
            disabled
          />
        )}
      </Form.Item> */}
      <Form.Item label="Notes from Doctor">
        {getFieldDecorator('doctorNote', {
          initialValue: orderDetailData.doctorNote || '-',
        })(
          <TextArea
            placeholder="Notes from doctor"
            size="large"
            rows={4}
            autoComplete="false"
            disabled
          />
        )}
      </Form.Item>
      <Form.Item label="Notes from Designer">
        {getFieldDecorator('approvalDesignNote', {
          initialValue: orderDetailData.approvalDesignNote || '-',
        })(
          <TextArea
            placeholder="Notes from Designer"
            size="large"
            rows={4}
            autoComplete="false"
            disabled
          />
        )}
      </Form.Item>
      <Form.Item label="Notes from Patient">
        {getFieldDecorator('patientNote', {
          initialValue: orderDetailData.patientNote || '-',
        })(
          <TextArea
            placeholder="Notes from Patient"
            size="large"
            rows={4}
            autoComplete="false"
            disabled
          />
        )}
      </Form.Item>
      {/*
        // DONT DELETE THIS
      */}
      {/* <Form.Item label="Video Recap Notes">
        {getFieldDecorator('videoRecapNote', {
          initialValue: orderDetailData.videoRecapNote || '-'
        })(
          <TextArea
            placeholder="Notes"
            size="large"
            rows={12}
            autoComplete="false"
            disabled
          />
        )}
      </Form.Item> */}
      {/*
        // DONT DELETE THIS
      */}
    </>
  );
}

SimulationDesignNotes.propTypes = {
  orderDetailData: PropTypes.object.isRequired,
};

export default Form.create()(SimulationDesignNotes);
