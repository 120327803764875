/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getListStationState } from 'store/action/EkanbanAction';
import { isEqual } from 'lodash';

import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';
import { usePrevious } from 'utils/hooks';
import { headerStationStateCsv, tableStationStateColumns } from '../../helper';
import { Filter } from '../StationState/components/Filter';
import { ActiveFilter } from './components/ActiveFilter';
import { message } from 'antd';
import OrderApi from 'api/order';
import { setToArray } from 'utils';

export function StationState({ listStationState, getListStationState }) {
  const [loadingPage, setLoadingPage] = useState(true);
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState({
    orderType: '-',
    startDate: '-',
    endDate: '-',
  });
  const prevActiveFilter = usePrevious(activeFilter);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [orderType, setOrderType] = useState([]);

  useEffect(() => {
    if (!isEqual(prevActiveFilter, activeFilter)) {
      getListStationState(
        `${activeFilter.orderType}`,
        `${activeFilter.startDate}`,
        `${activeFilter.endDate}`
      );
    }
  }, [activeFilter, prevActiveFilter]);

  const resetFilter = () => {
    setActiveFilter({
      ...activeFilter,
      orderType: '-',
      startDate: '-',
      endDate: '-',
    });
  };

  const handleReload = () => {
    setLoadingPage(true);
  };

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleOnRowPathname = () => {
    return `/cms/dashboard`;
  };

  const onOrderTypeChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      orderType: checkedValues,
    });
  };

  const onStartDateChange = (date, dateString) => {
    setActiveFilter({
      ...activeFilter,
      startDate: dateString,
    });
  };

  const onEndDateChange = (date, dateString) => {
    setActiveFilter({
      ...activeFilter,
      endDate: dateString,
    });
  };

  async function getOrderTypeList() {
    try {
      const response = await OrderApi.listOrderType();
      let orderTypeList = setToArray(response.data);

      setOrderType(orderTypeList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getOrderTypeList();
  }, []);

  async function getListStationStateData() {
    try {
      setLoadingPage(true);

      await getListStationState(
        `${activeFilter.orderType}`,
        `${activeFilter.startDate}`,
        `${activeFilter.endDate}`
      );
    } catch (err) {
      if (err.response) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getListStationStateData();
  }, [activeFilter, prevActiveFilter]);

  useEffect(() => {
    const orderTypeOpts = orderType.map((value) => ({
      label: `${value.orderType} - ${value.orderDesc}`,
      value: `${value.orderType}`,
    }));

    setOrderTypeOptions(orderTypeOpts);
  }, [listStationState]);

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
        setShowFilter={setShowFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        onOrderTypeChange={onOrderTypeChange}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        orderTypeOptions={orderTypeOptions}
      />
    );
  };

  return (
    <>
      {loadingPage ? (
        <PageSpinner className="pd-section--dashboard-table" />
      ) : (
        <TableGlobal
          tableName={'State'}
          inputPlaceholder={'Search State Here ...'}
          pageSize={8}
          headerCsv={headerStationStateCsv}
          originalData={listStationState || []}
          csvName={'station-state-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableStationStateColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          activeFilterComponent={activeFilterComponent()}
          filterComponent={filterComponent()}
          isFilter={true}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          isReload={true}
          classCustom={'panel--table-station-state'}
          onRowPathname={handleOnRowPathname}
          handleReload={handleReload}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ listStationState }) => ({ listStationState });

export default connect(mapStateToProps, { getListStationState })(StationState);
