import React, { useState } from 'react';
// antd ---------
import {
  List,
  Typography,
} from 'antd';
//  ---------
import './style.scss';

import ModalFullScreen from 'components/ModalFullScreen';

import ModalTroubleshoot from './ModalTroubleshoot';
import ModalAdditionalAligner from './ModalAdditionalAligner';

const data = ['Troubleshoot', 'Additional Aligner'];

const ModalRefinementTroubleshoot = ({
  showModal,
  form,
  handleHideModal,
}) => {
  //troubleshoot modal form
  const [modalTroubleshoot, setModalTroubleshoot] = useState(false);

  //refinement modal form
  const [modalRefinement, setModalRefinement] = useState(false);

  const { getFieldValue } = form;

  const handleShowModal = (e) => {
    if (e === 'Troubleshoot') setModalTroubleshoot((show) => !show);
    if (e === 'Additional Aligner') setModalRefinement((show) => !show);
  };
  return (
    <>
      {getFieldValue('stateId') === '2011' ? (
        <ModalFullScreen
          showModal={showModal}
          handleHideModal={handleHideModal}
          modalComponent={
            <>
              <List
                size="large"
                className="refine-header"
                header={
                  <Typography.Text
                    style={{ fontWeight: 'bold', fontSize: '24px' }}>
                    Select Refinement
                  </Typography.Text>
                }
                footer={<div style={{ padding: '1em' }}></div>}
                bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item onClick={() => handleShowModal(item)} value={item}>
                    {item}
                  </List.Item>
                )}
              />
              {/* MODAL TROUBLESHOOT */}
              {modalTroubleshoot && (
                <ModalTroubleshoot
                  showModal={modalTroubleshoot}
                  form={form}
                  handleHideModal={() => handleShowModal('Troubleshoot')}
                  handleHideModalFullScreen={() => handleHideModal()}
                />
              )}

              {/* MODAL ADDITIONAL ALIGNER */}
              {modalRefinement && (
                <ModalAdditionalAligner
                  showModal={modalRefinement}
                  form={form}
                  handleHideModal={() => handleShowModal('Additional Aligner')}
                  handleHideModalFullScreen={() => handleHideModal()}
                />
              )}
            </>
          }
        />
      ) : (
        <ModalTroubleshoot
          showModal={showModal}
          form={form}
          handleHideModal={handleHideModal}
        />
      )}
    </>
  );
};

export default ModalRefinementTroubleshoot;
