import { GET_LIST_REFUND_REQUEST } from '../../type';

export const listRefundRequestData = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_REFUND_REQUEST:
      return action.payload;
    default:
      return state;
  }
};
