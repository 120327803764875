/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  getListStationHistory,
  getEkanbanPrintLabel,
  getEkanbanData,
} from 'store/action/EkanbanAction';

import FormQR from './components/FormQr';
import HistoryStation from './components/HistoryStation';
import './styles/index.scss';

export const Details = ({
  values,
  listStationHistory,
  eKanbanPrintLabelData,
  getEkanbanPrintLabel,
  eKanbanData,
  getEkanbanData,
  getListStationHistory,
  currentState,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  useEffect(() => {
    async function getEkanbanPrintLabelData() {
      await getEkanbanPrintLabel(id);
    }

    getEkanbanPrintLabelData();
  }, []);

  useEffect(() => {
    async function getEkanbanDataFunc() {
      await getEkanbanData(id);
    }

    getEkanbanDataFunc();
  }, []);

  useEffect(() => {
    async function getListStationHistoryData() {
      await getListStationHistory(id);
    }
    getListStationHistoryData();
  }, []);

  return (
    <div>
      <FormQR
        values={values}
        eKanbanData={!isEmpty(eKanbanData) ? eKanbanData[0] : {}}
        eKanbanPrintLabelData={
          eKanbanPrintLabelData ? eKanbanPrintLabelData[0] : {}
        }
        currentState={currentState}
      />

      <HistoryStation listStationHistory={listStationHistory} />
    </div>
  );
};

const mapStateToProps = ({
  listStationHistory,
  eKanbanPrintLabelData,
  eKanbanData,
}) => ({
  listStationHistory,
  eKanbanPrintLabelData,
  eKanbanData,
});

export default connect(mapStateToProps, {
  getListStationHistory,
  getEkanbanPrintLabel,
  getEkanbanData,
})(Details);
