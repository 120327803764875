import OrderApi from 'api/order';

import {
  GET_LIST_OVERDUE_ORDER,
  GET_OVERDUE_ORDER_NUMBER,
} from '../../../type';

export const getListOverdueOrder = (availableState) => async (dispatch) => {
  const response = await OrderApi.getOverdueOrder(availableState);

  dispatch({
    type: GET_LIST_OVERDUE_ORDER,
    payload: response.data,
  });
};

export const getOverdueOrderNumber = (availableState) => async (dispatch) => {
  const { data } = await OrderApi.getOverdueOrderNumber(availableState);

  dispatch({
    type: GET_OVERDUE_ORDER_NUMBER,
    payload: data.data,
  });
};
