import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import ShippingApi from 'api/shipping';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray } from 'utils';
import TableGlobal from 'components/Table';

import { tableShippingColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function ShippingList() {
  const [shippings, setShippings] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });
  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  async function getShippingList() {
    try {
      const response = await ShippingApi.listShipping();
      let shippingList = setToArray(response.data);

      setShippings(shippingList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getShippingList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getShippingId = rowSelected.map((value) => value.shippingId);
    const getShippingIdToString = getShippingId.join(',');

    confirm({
      title: `Are you sure delete this shipping data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then shipping data with id ${getShippingIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingShipping() {
          try {
            await ShippingApi.deleteMultipleShipping(getShippingIdToString);
            setRowSelected([]);
            getShippingList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingShipping();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return (
      <></>
      // <ActiveFilter
      //   setShowFilter={setShowFilter}
      //   activeFilterData={activeFilter}
      //   setActiveFilter={setActiveFilter}
      // />
    );
  };

  const filterComponent = () => {
    return (
      <></>
      // <Filter
      //   resetFilter={resetFilter}
      //   activeFilter={activeFilter}
      //   innerRef={innerRef}
      // />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/master/shipping/edit?id=${record.shippingId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Shipping'}
          inputPlaceholder={'Search Shipping Here...'}
          pageSize={8}
          originalData={shippings}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableShippingColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          recordKey={(record) => record.shippingId}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
          setRowSelected={setRowSelected}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getShippingList={getShippingList}
      />
    </Layout>
  );
}

export default { ShippingList };
