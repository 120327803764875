import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Button, message, Checkbox } from 'antd';
import { withRouter } from 'react-router';

import ClinicNoteApi from 'api/clinic-note';
import StateApi from 'api/state';
import { setCreateEditMessage, setToArray } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getClinicStatusList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [states, setStates] = useState([]);
  const [statesValues, setStateValues] = useState([]);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await ClinicNoteApi.createClinicNoteStatus(
            values.clinicStatusId,
            values.clinicStatusName,
            statesValues.join()
          );

          history.push({
            pathname: '/cms/master/clinic-status',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Clinic Status Data',
            'Error Inserting Clinic Status Data!'
          );
          getClinicStatusList();

          setShowModalAddData(false);
          form.resetFields();
          clearAllCheckedBox();
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  const clearAllCheckedBox = () => {
    setStateValues([]);
  };

  const onStateChange = (checkedValues) => {
    setStateValues(checkedValues);
  };

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  return (
    <Modal
      title="Add New Clinic Status Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Clinic Status Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('clinicStatusId', {
            rules: [
              {
                required: true,
                max: 5,
                message: 'Please input Clinic Status ID!',
              },
            ],
          })(<Input size="large" placeholder="Clinic Status ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('clinicStatusName', {
            rules: [
              { required: true, message: 'Please input Clinic Status name!' },
            ],
          })(<Input size="large" placeholder="Clinic Status Name*" />)}
        </Form.Item>
      </Form>

      <h4>Status</h4>
      <Checkbox.Group
        style={{ display: 'grid' }}
        options={stateOptions.slice(1)}
        value={statesValues}
        onChange={onStateChange}
      />
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
