import React from 'react';
import { Modal, Row, Col } from 'antd';
import { isEmpty } from 'lodash';

import LinkUploadReadOnly from 'components/upload/LinkUploadReadOnly';

export const ModalArchivedDetail = ({
  handleModalArchived,
  showModal,
  archivedData,
}) => {
  return (
    <Modal
      centered
      visible={showModal}
      getContainer={document.body}
      onCancel={() => handleModalArchived('hide')}
      footer={[]}>
      {!isEmpty(archivedData) && (
        <>
          {' '}
          <div className="color-text-primary text-lg fw-bold mb-30">
            Archived Detail
          </div>
          <Row>
            <Col span={12} className="mb-10">
              Simulation ID
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.simulationId}
            </Col>
            <Col span={12} className="mb-10">
              Date
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.createdAt}
            </Col>
            <Col span={12} className="mb-15">
              Status
            </Col>
            <Col span={12} className="mb-30">
              {archivedData.status}
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <Row>
            <Col span={12} className="mb-10">
              Patient Name
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.patientName}
            </Col>
            <Col span={12} className="mb-10">
              SO Number
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.orderReceiptNumber}
            </Col>
            <Col span={12} className="mb-10">
              Order Type
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.orderType}
            </Col>
            <Col span={12} className="mb-10">
              Status
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.simulationStatusName}
            </Col>
            <Col span={12} className="mb-10">
              Assigned Doctor
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.doctorName || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Assigned Designer
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.designerName || '-'}
            </Col>
            <Col span={12} className="mb-30">
              Assigned Admin Designer
            </Col>
            <Col span={12} className="mb-30">
              {archivedData.adminDesignerName || '-'}
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <div className="fw-medium text-base mb-10">Impression</div>
          <Row>
            <Col span={12} className="mb-10 pl-15">
              Jenis Cetakan
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.printType || '-'}
            </Col>
            <Col span={12} className="pl-15 mb-10">
              RA
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.impRaId ? (
                <LinkUploadReadOnly
                  placeholder="Upload RA"
                  purpose=""
                  readOnly={true}
                  uploadedFileId={archivedData.impRaId || ''}
                  uploadedFileName={archivedData.impRaName || ''}
                  uploadedFileUrl={archivedData.impRaUrl || ''}
                  disabled={true}
                  isPreviewIcon={false}
                />
              ) : (
                '-'
              )}
            </Col>
            <Col span={12} className="pl-15 mb-10">
              RB
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.impRbId ? (
                <LinkUploadReadOnly
                  placeholder="Upload RB"
                  purpose=""
                  readOnly={true}
                  uploadedFileId={archivedData.impRbId || ''}
                  uploadedFileName={archivedData.impRbName || ''}
                  uploadedFileUrl={archivedData.impRbUrl || ''}
                  disabled={true}
                />
              ) : (
                '-'
              )}
            </Col>
            <Col span={12} className="pl-15 mb-10">
              Bite Kanan
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.impBkaId ? (
                <ul>
                  <li>
                    <LinkUploadReadOnly
                      placeholder="Upload Bite Kanan"
                      purpose=""
                      readOnly={true}
                      uploadedFileId={archivedData.impBkaId || ''}
                      uploadedFileName={archivedData.impBkaName || ''}
                      uploadedFileUrl={archivedData.impBkaUrl || ''}
                      disabled={true}
                      isPreviewIcon={false}
                    />
                  </li>
                </ul>
              ) : (
                '-'
              )}
            </Col>
            <Col span={12} className="pl-15 mb-15">
              Bite Kiri
            </Col>
            <Col span={12} className="mb-15">
              {archivedData.impBkiId ? (
                <LinkUploadReadOnly
                  placeholder="Upload Bite Kiri"
                  purpose=""
                  readOnly={true}
                  uploadedFileId={archivedData.impBkiId || ''}
                  uploadedFileName={archivedData.impBkiName || ''}
                  uploadedFileUrl={archivedData.impBkiUrl || ''}
                  disabled={true}
                  isPreviewIcon={false}
                />
              ) : (
                '-'
              )}
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <div className="fw-medium text-base mb-10">Video Recording</div>
          <Row>
            <Col span={12} className="mb-10">
              Slicing
            </Col>
            <Col span={12} className="mb-10">
              <ul>
                <li>Slicing.mp4</li>
                <li>Slicing.jpg</li>
              </ul>
            </Col>
            <Col span={12} className="mb-15">
              No Slicing
            </Col>
            <Col span={12} className="mb-15">
              <ul>
                <li>No Slicing.mp4</li>
                <li>No Slicing.jpg</li>
              </ul>
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <Row>
            <Col span={12} className="mb-10">
              Notes from Doctor
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.doctorNote || ''}
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <div className="fw-medium text-base mb-10">Final STL</div>
          <Row>
            <Col span={12} className="mb-10">
              <div className="fw-medium">Version 1</div>
            </Col>
            <Col span={12} className="mb-10">
              08-12-2021 10:00:00
            </Col>
            <Col span={12} className="mb-10">
              RA
            </Col>
            <Col span={12} className="mb-10">
              <ul>
                <li>3D Scan RA 1.stl</li>
                <li>3D Scan RA 2.stl</li>
              </ul>
            </Col>
            <Col span={12} className="mb-15">
              RB
            </Col>
            <Col span={12} className="mb-15">
              <ul>
                <li>3D Scan RB 1.stl</li>
              </ul>
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <Row>
            <Col span={12} className="mb-10">
              RA
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.ra || '-'}
            </Col>
            <Col span={12} className="mb-10">
              RB
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.rb || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Total Aligner
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.totalAligner || '-'}
            </Col>
            <Col span={12} className="mb-10">
              OC Upper
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.ocUp || '-'}
            </Col>
            <Col span={12} className="mb-15">
              OC Lower
            </Col>
            <Col span={12} className="mb-15">
              {archivedData.ocDown || '-'}
            </Col>
          </Row>
          <div className="border-bottom mb-15"></div>
          <Row>
            <Col span={12} className="mb-10">
              <div>Notes From Patient</div>
            </Col>
            <Col span={12} className="mb-10">
              {archivedData.patientNote || ''}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

// ModalFullScreen.defaultProps = {
//   idContainer: '',
// };

export default ModalArchivedDetail;
