import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { isEmpty } from 'lodash';

import { checkValueInObjectArray } from 'utils';

import './styles/index.scss';

export const SelectDropdown = ({
  placeHolder,
  options,
  onChange,
  value,
  disabled,
  isValueNeedToChecked,
}) => {
  const { Option } = Select;

  const checkIfValueAsOptions = checkValueInObjectArray(options, value)
    ? value
    : !isEmpty(options)
    ? options[0].value
    : '';

  return (
    <div className="multiselect-dropdown">
      <Select
        onChange={onChange}
        value={isValueNeedToChecked ? checkIfValueAsOptions : value}
        showSearch
        placeholder={placeHolder}
        disabled={disabled}
        size="large">
        {options.map((value, index) => (
          <Option value={value.value} key={index}>
            {value.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

SelectDropdown.propTypes = {
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
};

SelectDropdown.defaultProps = {
  options: [],
  disabled: false,
  isValueNeedToChecked: false,
};

export default SelectDropdown;
