import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import SelectDropdown from 'components/SelectDropdown';
import StateApi from 'api/state';
import { setCreateEditMessage, setToArray } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getStateList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [stations, setStations] = useState([]);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await StateApi.createState(
            values.stateId,
            values.stateName,
            values.slaDays,
            values.station,
            values.stateRound
          );

          history.push({
            pathname: '/cms/master/state',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting State Data',
            'Error Inserting State Data!'
          );

          getStateList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    async function getStationList() {
      const response = await StateApi.listStation();
      let stationList = setToArray(response.data);

      setStations(stationList);
    }

    getStationList();
  }, []);

  const stationOptions = stations.map((value) => ({
    label: value.station,
    value: value.station,
  }));

  const handleDuplicateStationOptions = stationOptions.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  const stateRoundOptions = [
    {
      label: 0,
      value: 0,
    },
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
  ];

  return (
    <Modal
      title="Add New State Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New State Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('stateId', {
            rules: [{ required: true, message: 'Please input state ID!' }],
          })(
            <Input placeholder="State ID*" autoComplete="false" size="large" />
          )}
        </Form.Item>

        <Form.Item label="Name">
          {getFieldDecorator('stateName', {
            rules: [{ required: true, message: 'Please input state name!' }],
          })(
            <Input
              placeholder="State Name*"
              size="large"
              autoComplete="false"
            />
          )}
        </Form.Item>

        <Form.Item label="SLA Days">
          {getFieldDecorator(
            'slaDays',
            {}
          )(<Input placeholder="SLA Days" size="large" autoComplete="false" />)}
        </Form.Item>

        <Form.Item label="Station">
          {getFieldDecorator(
            'station',
            {}
          )(
            <SelectDropdown
              options={handleDuplicateStationOptions}
              placeHolder={'Select Station'}
            />
          )}
        </Form.Item>

        <Form.Item label="State Round">
          {getFieldDecorator(
            'stateRound',
            {}
          )(
            <SelectDropdown
              options={stateRoundOptions}
              placeHolder={'Select State Round'}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
