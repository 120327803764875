import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import OrderApi from 'api/order';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray } from 'utils';
import TableGlobal from 'components/Table';

import { tableOrderShippingColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function OrderShippingList() {
  const [orderShippings, setOrderShippings] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });

  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  const handleDeleteRow = async () => {
    const getOrderShippingId = rowSelected.map(
      (value) => value.orderShippingId
    );
    const getOsIncId = rowSelected.map((value) => value.osIncId);
    const getOsIncIdToString = getOsIncId.join(',');
    const getOrderShippingIdToString = getOrderShippingId.join(',');

    confirm({
      title: `Are you sure delete this order shipping data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then order shipping data with id ${getOrderShippingIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingOrderShipping() {
          try {
            await OrderApi.deleteMultipleOrderShipping(getOsIncIdToString);
            setRowSelected([]);
            getOrderShippingList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingOrderShipping();
      },
      onCancel() {},
    });
  };

  async function getOrderShippingList() {
    try {
      const response = await OrderApi.listOrderShipping();
      let orderShippingList = setToArray(response.data);

      setOrderShippings(orderShippingList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getOrderShippingList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const activeFilterComponent = () => {
    return (
      <></>
      // <ActiveFilter
      //   setShowFilter={setShowFilter}
      //   activeFilterData={activeFilter}
      //   setActiveFilter={setActiveFilter}
      // />
    );
  };

  const filterComponent = () => {
    return (
      <></>
      // <Filter
      //   resetFilter={resetFilter}
      //   activeFilter={activeFilter}
      //   innerRef={innerRef}
      // />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/transaction/order-shipping/edit?id=${record.orderShippingId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Order Shipping'}
          inputPlaceholder={'Search Order Shipping Here...'}
          pageSize={8}
          originalData={orderShippings}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableOrderShippingColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          recordKey={(record) => record.orderShippingId}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
        />
      </Content>

      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getOrderShippingList={getOrderShippingList}
      />
    </Layout>
  );
}

export default { OrderShippingList };
