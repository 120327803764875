/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';

import { getListPrintGroup } from 'store/action/StateAction';

import { OrderContext } from 'contexts/Order';

export const ModalFullscreenWithoutSubmit = ({
  showModal,
  handleHideModal,
  setFloatingData,
  handleShowOtherModal,
  floatingData,
  stateName,
  tableName,
  getListPrintGroup,
  listPrintGroup,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const { getActiveOrderByIdList } = useContext(OrderContext);

  const innerRef = useRef(null);

  const handleOnRowPathname = async (record) => {
    setFloatingData({
      ...floatingData,
      [stateName]: record.stateId,
    });
    await getActiveOrderByIdList(record.stateId);

    handleHideModal();
    handleShowOtherModal();
  };

  async function getPrintGroupListData() {
    await getListPrintGroup();
  }

  useEffect(() => {
    getPrintGroupListData();
  }, []);

  const statePrintColumns = [
    {
      title: 'Name',
      key: 'stateId',
      render: (row) => `${row['stateId']} - ${row['stateName']}`,
    },
  ];

  const handleOriginalData = () => {
    return !isEmpty(listPrintGroup) && listPrintGroup;
  };

  const handleColumns = () => {
    return statePrintColumns;
  };

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        modalComponent={
          <TableGlobal
            tableName={`Select ${tableName}`}
            inputPlaceholder={`Search ${tableName} Here...`}
            pageSize={8}
            originalData={handleOriginalData()}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={handleColumns()}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />
    </>
  );
};

const mapStateToProps = ({ listPrintGroup }) => ({
  listPrintGroup,
});

export default connect(mapStateToProps, { getListPrintGroup })(
  ModalFullscreenWithoutSubmit
);
