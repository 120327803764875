/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import jsPDF from 'jspdf';

import DetailLayout from 'components/detail-layout/DetailReadOnly';
import '../styles/index.scss';

let QRCode = require('qrcode.react');

export function BoxViewDetail({ location }) {
  const { pathname } = location;
  const boxNumber = pathname.split('/').pop();
  const [qrCanvas, setQrCanvas] = useState('');

  useEffect(() => {
    const qrCodeCanvas = document.querySelectorAll(
      "[data-qr='" + boxNumber + "']"
    )[0];
    const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
    setQrCanvas(qrCodeDataUri);
  }, []);

  const onClickHandler = () => {
    let doc = new jsPDF({
      orientation: 'landscape',
      unit: 'cm',
      format: [10, 5],
    });
    doc.setFontSize(16);
    doc.text(6, 2, boxNumber); //x,y, name
    doc.addImage(qrCanvas, 'JPEG', 2, 1, 3, 3); // x,y, width, height
    doc.save('QrCode-' + boxNumber + '.pdf');
  };

  return (
    <DetailLayout
      detailTime={boxNumber}
      detailTitle={'Box'}
      detailComponent={
        <div className="generate-card">
          <QRCode className="d-none" data-qr={boxNumber} value={boxNumber} />
          <Button onClick={onClickHandler} size="large">
            Generate Card
          </Button>
        </div>
      }
    />
  );
}

export default withRouter(BoxViewDetail);
