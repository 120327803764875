import {
  GET_LIST_CLINIC_NOTE_BY_ID,
  GET_DETAIL_CLINIC_NOTE_BY_ID,
  GET_LIST_CLINIC_NOTE,
  GET_STATUS_CLINIC_NOTE,
  GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID,
} from '../../type';

export const clinicNoteByIdData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_CLINIC_NOTE_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
export const clinicNoteDetailByIdData = (state = [], action) => {
  switch (action.type) {
    case GET_DETAIL_CLINIC_NOTE_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
export const clinicNoteListData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_CLINIC_NOTE:
      return action.payload;
    default:
      return state;
  }
};
export const clinicNoteStatusData = (state = [], action) => {
  switch (action.type) {
    case GET_STATUS_CLINIC_NOTE:
      return action.payload;
    default:
      return state;
  }
};

export const clinicNoteSubStatusByIdData = (state = [], action) => {
  switch (action.type) {
    case GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
