import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

export const PrintLabel = ({ value, qrCanvas }) => {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
        fontWeight: 500,
      },
    ],
  });

  const rowGlobal = {
    margin: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  };

  const colGlobal = {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  };

  const pdfStyles = StyleSheet.create({
    image: {
      width: 50,
      height: 50,
    },
    page: {
      minWidth: 386,
      minHeight: 235,
      maxWidth: 386,
      maxHeight: 235,
    },
    table: {
      display: 'table',
      minWidth: 386,
      minHeight: 235,
      maxWidth: 386,
      maxHeight: 235,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      fontFamily: 'Roboto',
    },
    tableRow: {
      ...rowGlobal,
      borderWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tableRowWidth0: {
      ...rowGlobal,
      borderWidth: 0,
    },
    tableCol: {
      width: '20%',
      ...colGlobal,
    },
    tableColBig: {
      width: '50%',
      ...colGlobal,
    },
    tableColBigWidth0: {
      width: '50%',
      borderWidth: 0,
    },
    tableColBiggest: {
      width: '100%',
      ...colGlobal,
    },
    tableColBiggestWidth0: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 0,
    },
    tableColMedium: {
      width: '33.333%',
      ...colGlobal,
    },
    tableCell: {
      fontSize: 5.5,
      margin: 3,
      minHeight: 7,
      maxHeight: 7,
    },
    tableCellMedium: {
      margin: 3,
      minHeight: 10,
      maxHeight: 10,
    },
    tableCellCustomHeight: {
      fontSize: 6,
      margin: 3,
      minHeight: 21,
      maxHeight: 21,
    },
    fontBold: {
      fontWeight: 'bold',
    },
    fontSizeMedium: {
      fontSize: 11,
    },
    textCenter: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    flexCenter: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
    hideOverFlow: {
      overflow: 'hidden',
    },
  });

  return (
    <Document>
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.table}>
          <View style={{ ...pdfStyles.tableRow, ...pdfStyles.hideOverFlow }}>
            <View style={pdfStyles.tableColBig}>
              <View style={{ ...pdfStyles.tableRow, ...pdfStyles.textCenter }}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <Text
                    style={{
                      ...pdfStyles.tableCell,
                      ...pdfStyles.fontBold,
                    }}>
                    {value.customerName || 'Customer Name'}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    No SO
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.soNumber}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Box Number
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.boxNumber}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Order Type
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>
                    {value.orderType} - {value.orderDesc}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Rekam Medis
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.medicalRecord}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Klinik
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.clinic}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Dokter
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.doctor}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Jenis Cetakan
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.printType}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Test Fit
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.testFit}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <Text
                    style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                    Tanggal
                  </Text>
                </View>
                <View style={pdfStyles.tableColBigWidth0}>
                  <Text style={pdfStyles.tableCell}>{value.date}</Text>
                </View>
              </View>
              <View style={pdfStyles.tableRowWidth0}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <View style={pdfStyles.tableCellCustomHeight}>
                    <Text style={pdfStyles.fontBold}>Note Tim Cetakan</Text>
                    <Text>{value.printNote}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={pdfStyles.tableColBig}>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBig}>
                  <View style={pdfStyles.tableRow}>
                    <View style={pdfStyles.tableColBiggestWidth0}>
                      <View style={pdfStyles.tableCellCustomHeight}>
                        <Text style={pdfStyles.fontBold}>Priority Notes</Text>
                        <Text>{value.priorityNote}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={pdfStyles.tableRowWidth0}>
                    <View style={pdfStyles.tableColBiggestWidth0}>
                      <View style={pdfStyles.tableCellCustomHeight}>
                        <Text style={pdfStyles.fontBold}>Round</Text>
                        <Text>{value.round}</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    ...pdfStyles.tableColBigWidth0,
                    ...pdfStyles.flexCenter,
                  }}>
                  <Image src={qrCanvas} style={pdfStyles.image} alt="images" />
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <View style={pdfStyles.tableCellCustomHeight}>
                    <Text style={pdfStyles.fontBold}>Upper</Text>
                    <Text>{value.upperData}</Text>
                  </View>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <View style={pdfStyles.tableCellCustomHeight}>
                    <Text style={pdfStyles.fontBold}>Trim Upper</Text>
                    <Text>{value.trimUpper}</Text>
                  </View>
                </View>
              </View>
              <View style={pdfStyles.tableRow}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <View style={pdfStyles.tableCellCustomHeight}>
                    <Text style={pdfStyles.fontBold}>Lower</Text>
                    <Text>{value.lowerData}</Text>
                  </View>
                </View>
              </View>
              <View style={pdfStyles.tableRowWidth0}>
                <View style={pdfStyles.tableColBiggestWidth0}>
                  <View style={pdfStyles.tableCellCustomHeight}>
                    <Text style={pdfStyles.fontBold}>Trim Lower</Text>
                    <Text>{value.trimLower}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View style={pdfStyles.tableRow}>
            <View style={pdfStyles.tableColBiggest}>
              <View style={pdfStyles.tableCellCustomHeight}>
                <Text style={pdfStyles.fontBold}>Notes</Text>
                <Text>{value.notes}</Text>
              </View>
            </View>
          </View>

          <View style={pdfStyles.tableRow}>
            <View style={pdfStyles.tableCol}>
              <Text style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                QC Cor
              </Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                QC Print
              </Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                QC Vacuum
              </Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                QC Bur
              </Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                QC Final
              </Text>
            </View>
          </View>

          <View style={pdfStyles.tableRow}>
            <View style={pdfStyles.tableCol}>
              <Text style={pdfStyles.tableCellCustomHeight}></Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={pdfStyles.tableCellCustomHeight}></Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={pdfStyles.tableCellCustomHeight}></Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={pdfStyles.tableCellCustomHeight}></Text>
            </View>
            <View style={pdfStyles.tableCol}>
              <Text style={pdfStyles.tableCellCustomHeight}></Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
