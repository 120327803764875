import api from './index';

export default {
  getSimulationDesignList(createdBy) {
    return api.get(`/simulation/design/designer/${createdBy}/false`);
  },

  getDoctorSimulationList() {
    return api.get(`/simulation/doctor-list`);
  },

  getDesignerSimulationList(stationId) {
    return api.get(`/simulation/user-by-station/${stationId}/null`);
  },

  getSimulationDesignById(simulationId) {
    return api.get(`/simulation/design/${simulationId}/false`);
  },

  getStatusSimulationList() {
    return api.get(`/simulation/status/false`);
  },

  getSimulationListByStatus(simulationStatusById, dateFilter) {
    if (dateFilter.length > 0) {
      let minDate = `minDate=${dateFilter[0]}`;
      let maxDate = `maxDate=${dateFilter[1]}`;
      return api.get(
        `/simulation/design/status/${simulationStatusById}/false?${minDate}&${maxDate}`
      );
    }
    let designerName = localStorage.getItem('username');
    if (simulationStatusById === 'W')
      return api.get(
        `/simulation/design/designer/${designerName}/${simulationStatusById}/false`
      );
    return api.get(`/simulation/design/status/${simulationStatusById}/false`);
  },

  getSimulationListByStatusWithParam(payload) {
    function encodeQueryData(data) {
      const ret = [];
      for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
    }

    let param = {
      offset: payload.offset,
      limit: payload.limit,
      ...(payload.searchBy && { searchBy: payload.searchBy }),
      ...(payload.sortBy && payload.sort && { sortBy: payload.sortBy }),
      ...(payload.sort === 'ascend' && { sort: 'ASC' }),
      ...(payload.sort === 'descend' && { sort: 'DESC' }),
    };
    const QUERY_STRING = encodeQueryData(param);
    let designerName = localStorage.getItem('username');
    return api.get(
      `/simulation/design/status-v2/${payload.simulationStatusId}/${designerName}/false?${QUERY_STRING}`
    );
  },

  getPatientNameSimulationList() {
    return api.get(`/simulation/patient-name/false`);
  },

  getSoNumberSimulationList(patientName) {
    return api.get(`/simulation/so-number/${patientName}/false`);
  },

  getVideoVersionSimulationList() {
    return api.get(`/simulation/video-version/false`);
  },

  getOrderTypeSimulation(soNumber) {
    return api.get(`/simulation/order-type/${soNumber}/false`);
  },

  deleteSimulationDesignBulk(simulationId) {
    return api.delete(`/simulation/design/bulk?simulationId=${simulationId}`);
  },

  uploadSimulationFile(fieldUpload, uploadedBy, data) {
    return api.post(
      `/simulation/files/upload/${fieldUpload}/${uploadedBy}`,
      data
    );
  },

  updateSimulationDesignVideoRecordings(payload) {
    // remap videorecording
    let remappedPayload = payload.map((val) => ({
      ra: val.ra,
      rb: val.rb,
      ocUp: val.ocUp,
      ocDown: val.ocDown,
      videoId: val.videoId,
    }));
    return api.put(`/simulation/design`, { videoRecordings: remappedPayload });
  },

  updateSimulationDesignAlignerDetail(payload) {
    return api.put(`/simulation/design`, payload);
  },

  updateSimulationDesign(
    simulationId,
    patientName,
    orderReceiptNumber,
    orderType,
    videoVersionId,
    simulationStatusId,
    attempt,
    doctorName,
    designerName,
    adminDesignerName,
    videoId,
    puttyId,
    approvalDesignNote,
    doctorNote,
    totalAligner,
    puttyScanFile,
    testFitPatientConfirmation
  ) {
    return api.put(`/simulation/design`, {
      simulationId,
      patientName,
      orderReceiptNumber,
      orderType,
      // videoVersionId,
      simulationStatusId,
      attempt,
      doctorName,
      designerName,
      adminDesignerName,
      // videoId,
      puttyId,
      // approvalDesignNote,
      // doctorNote,
      // totalAligner,
      // puttyScanFile,
      testFitPatientConfirmation,
    });
  },

  addSimulationDesign(
    patientName,
    orderReceiptNumber,
    orderType,
    videoVersionId,
    simulationStatusId,
    attempt,
    doctorName,
    videoId,
    slicingId,
    threeDScanId,
    puttyId,
    doctorNote,
    ra,
    rb,
    ocUp,
    ocDown,
    totalAligner,
    createdBy
  ) {
    return api.post(`/simulation/design`, {
      patientName,
      orderReceiptNumber,
      orderType,
      videoVersionId,
      simulationStatusId,
      attempt,
      doctorName,
      videoId,
      slicingId,
      threeDScanId,
      puttyId,
      doctorNote,
      ra,
      rb,
      ocUp,
      ocDown,
      totalAligner,
      createdBy,
    });
  },

  approvalVideo(payload) {
    let pxApprovalNote;
    if (payload.approvalType === 'revisedBy') {
      pxApprovalNote = payload.simulationData.patientApprovalNote;
    } else {
      pxApprovalNote = '';
    }
    let body = {
      fileId: payload.simulationData.fileId,
      patientApprovalNote: pxApprovalNote,
      ...(payload.approvalType === 'approvedBy' && {
        patientApprovedBy: payload.username,
      }),
      ...(payload.approvalType === 'rejectedBy' && {
        patientRejectedBy: payload.username,
      }),
      ...(payload.approvalType === 'revisedBy' && {
        patientRevisedBy: payload.username,
      }),
    };
    return api.put(`/simulation/video/approval`, body);
  },

  updateSentTimeVideo(fileId, rejectedBy) {
    return api.put(`/simulation/video-sent-time`, {
      fileId,
      rejectedBy,
    });
  },

  addImpressionPutty(
    patientName,
    orderId,
    orderReceiptNumber,
    orderType,
    createdBy,
    printType,
    impRaId,
    impRbId,
    impBpId,
    payload
  ) {
    return api.post(`/simulation/upload/impression`, {
      patientName,
      orderId,
      orderReceiptNumber,
      orderType,
      createdBy,
      printType,
      impRaId,
      impRbId,
      impBpId,
      stateId: payload.stateId,
    });
  },

  addImpression3D(
    patientName,
    orderId,
    orderReceiptNumber,
    orderType,
    createdBy,
    printType,
    impRaId,
    impRbId,
    impBkaId,
    impBkiId,
    payload
  ) {
    return api.post(`/simulation/upload/impression`, {
      patientName,
      orderId,
      orderReceiptNumber,
      orderType,
      createdBy,
      printType,
      impRaId,
      impRbId,
      impBkaId,
      impBkiId,
      stateId: payload.stateId,
    });
  },

  getVideoRevisionHistory(soNumber, simulationId) {
    return api.get(
      `/simulation/video/revision-history/${soNumber}/${simulationId}`
    );
  },

  getArchivedSimulationHistory(soNumber) {
    return api.get(`/simulation/archived/${soNumber}/null`);
  },

  getImpressionSimulationHistory(soNumber) {
    return api.get(`/simulation/impression/history/${soNumber}`);
  },

  reviseSimulationDesign(payload) {
    let body = {
      orderId: payload.orderId,
      updatedBy: payload.updatedBy,
    };
    return api.put(`simulation/revised`, body);
  },

  progressSimulationDesign(
    simulationId,
    videoVersionId,
    doctorName,
    videoId,
    slicingId,
    archformId,
    nextSimulationStatusId,
    finalStlRaId,
    finalStlRbId,
    ra,
    rb,
    raDetails,
    rbDetails,
    agUpper,
    agLower,
    ocUp,
    ocDown,
    patientNote,
    updatedBy,
    designerName,
    videoBiteId,
    imageBiteId,
    payload
  ) {
    if (nextSimulationStatusId === 'SE') {
      let requestPayload = {
        simulationId,
        nextSimulationStatusId,
        finalStlRaId,
        finalStlRbId,
        raDetails,
        rbDetails,
        agUpper,
        agLower,
        patientNote,
        updatedBy,
        designerName,
        alignerRa: payload.alignerRa,
        alignerRb: payload.alignerRb,
        alignerOcUp: payload.alignerOcUp,
        alignerOcDown: payload.alignerOcDown,
        ocUpDetails: payload.ocUpDetails,
        ocDownDetails: payload.ocDownDetails,
        adminDesignerName: payload.adminDesignerName,
        approvalDesignNote: payload.approvalDesignNote,
        doctorName,
      };
      return api.put(`/simulation/progress`, requestPayload);
    } else {
      return api.put(`/simulation/progress`, {
        simulationId,
        videoVersionId,
        doctorName,
        videoId,
        slicingId,
        archformId,
        nextSimulationStatusId,
        finalStlRaId,
        finalStlRbId,
        ...(nextSimulationStatusId === 'W' && { ra: ra }),
        ...(nextSimulationStatusId === 'W' && { rb: rb }),
        raDetails,
        rbDetails,
        agUpper,
        agLower,
        ocUp,
        ocDown,
        patientNote,
        updatedBy,
        designerName,
        videoBiteId,
        imageBiteId,
        approvalDesignNote: payload.approvalDesignNote,
        revisionNotesFromDesigner: payload.revisionNotesFromDesigner,
        adminDesignerName: payload.adminDesignerName,
        videoRecordings: payload.videoRecordings,
      });
    }
  },

  getReportSimdesStatus() {
    return api.get(`simulation/status/false`);
  },

  getReportSimdesHistory(payload) {
    //payload[0] startdate
    //payload[1] enddate
    return api.get(
      `simulation/design/report/history/${payload[0]}/${payload[1]}`
    );
  },

  getReportSimdesHistoryAssigned() {
    return api.get(`simulation/design/report/assigned`);
  },

  getReportSimdes(payload) {
    //payload[0] startdate
    //payload[1] enddate
    return api.get(`simulation/design/report/${payload[0]}/${payload[1]}`);
  },

  downloadFinalStlFile({ simulationId }) {
    return api.get(`simulation/zip/${simulationId}`);
  },

  updateNotesFromDesign(payload) {
    return api.put(`simulation/edit-note-from-designer`, payload);
  },
};
