import ClinicApi from 'api/clinic';
import { setToArray } from 'utils';

import { GET_LIST_CLINIC } from '../../../type';

export const getListClinic = () => async (dispatch) => {
  const response = await ClinicApi.getClinicList();

  dispatch({
    type: GET_LIST_CLINIC,
    payload: setToArray(response.data),
  });
};
