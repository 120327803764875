/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import TableGlobal from 'components/Table';
import { getUserList } from 'store/action/auth-action/GetUserListAction';

import { headerCsv, tableColumns } from './helper';
import ActiveFilter from './components/ActiveFilter';
import Filter from './components/Filter';

const { Content } = Layout;

export function UserRoleList({ listUser, getUserList }) {
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState({
    roleName: [],
    stationName: [],
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);

  async function getUserListData() {
    await getUserList();
  }

  useEffect(() => {
    getUserListData();
  }, []);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const onRoleChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      roleName: checkedValues,
    });
  };

  const onStationChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      stationName: checkedValues,
    });
  };

  const handleDeleteRow = async () => {
    // const getUserId = rowSelected.map((value) => value.auditId);
    // const getUserIdToString = getUserId.join(',');
    // setUserIdDelete(getUserIdToString);
    // await deleteAuditBatch(getAuditIdToString);
  };

  useEffect(() => {
    if (!isEmpty(listUser)) {
      const roleOpts = listUser.map((value) => ({
        label: value.roleName,
        value: value.roleName,
      }));

      const handleDuplicateRoleOpts = roleOpts.filter(
        (value, index, array) =>
          array.findIndex((t) => t.label === value.label) === index
      );

      const stationOpts = listUser.map((value) => ({
        label: value.stationName,
        value: value.stationName,
      }));

      const handleDuplicateStationOpts = stationOpts.filter(
        (value, index, array) =>
          array.findIndex((t) => t.label === value.label) === index
      );

      setRoleOptions(handleDuplicateRoleOpts);
      setStationOptions(handleDuplicateStationOpts);
    }
  }, [listUser]);

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
        onRoleChange={onRoleChange}
        onStationChange={onStationChange}
        stationOptions={stationOptions}
        roleOptions={roleOptions}
      />
    );
  };

  const handleOnRowPathname = (record) => {
    return `/cms/administration/user/${record.userEmail}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'User'}
          inputPlaceholder={'Search User Here...'}
          pageSize={8}
          headerCsv={headerCsv}
          originalData={!isEmpty(listUser) ? listUser : []}
          csvName={'user-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          recordKey={(record) => record.userId}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
        />
      </Content>
    </Layout>
  );
}

const mapStateToProps = ({ listUser }) => ({
  listUser,
});

export default connect(mapStateToProps, { getUserList })(UserRoleList);
