import api from './index';

export default {
  getAuditBatchList() {
    return api.get(`/audit/false`);
  },

  getAuditBatchById(id) {
    return api.get(`/audit/${id}/false`);
  },

  deleteAuditBatchList(id) {
    return api.delete(`/audit/bulk?auditId=${id}`);
  },

  addAuditBatch(auditState) {
    return api.post(`/audit`, {
      auditState: auditState,
    });
  },
};
