import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Input, Form, Row } from 'antd';

import { getPaymentDetailById } from 'store/action/PaymentDetailAction';

export const PaymentDetail = ({ paymentDetailByIdData, form }) => {
  const { getFieldDecorator } = form;

  return (
    <>
      <div className="color-text-primary text-md fw-bold mb-20">
        Payment Detail
      </div>
      <Row gutter={15}>
        <Col span={6}>
          <Form.Item label="SO Number From BE">
            {getFieldDecorator('soNumberBE', {
              initialValue: !isEmpty(paymentDetailByIdData)
                ? paymentDetailByIdData[0].orderReceiptNumber
                : '',
            })(
              <Input
                placeholder="SO Number"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Payment Type">
            {getFieldDecorator('paymentType', {
              initialValue: !isEmpty(paymentDetailByIdData)
                ? paymentDetailByIdData[0].paymentType
                : '',
            })(
              <Input
                placeholder="Payment Type"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Payment Status">
            {getFieldDecorator('paymentStatus', {
              initialValue: !isEmpty(paymentDetailByIdData)
                ? paymentDetailByIdData[0].paymentStatus
                : '',
            })(
              <Input
                placeholder="Payment Status"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Payment Due Date">
            {getFieldDecorator('delivDownRemaining', {
              initialValue: !isEmpty(paymentDetailByIdData)
                ? paymentDetailByIdData[0].paymentDueDate
                : '',
            })(
              <Input
                placeholder="Payment Due Date"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ paymentDetailByIdData }) => ({
  paymentDetailByIdData,
});

export default connect(mapStateToProps, {
  getPaymentDetailById,
})(Form.create()(PaymentDetail));
