import { message } from 'antd';
import { get } from 'lodash';
import qs from 'qs';

/**
 * @param  {object} antFormError
 * @return  {boolean}
 */

export function hasFormErrors(antFormError) {
  return Object.keys(antFormError).some((field) => antFormError[field]);
}

/**
 * @param  {object} error
 * @param  {string} defaultMessage - fallback message when API doesn't give an error message
 */
export function alertErr(
  err,
  defaultMessage = 'connection error, check your connection!'
) {
  const errMessage = get(err, 'response.data.message');
  message.error(errMessage || defaultMessage);
}

// Handle object to array for getting API
export const setToArray = (resp) => {
  let theData;
  const obj = Object.entries(resp);

  obj.forEach(([key, value]) => {
    if (key === `data`) {
      const obj2 = Object.entries(value);
      obj2.forEach(([key, value]) => {
        if (key === `list`) {
          theData = value;
        }
      });
    }
  });
  return theData;
};

// Handle nested filter
export const nestedFilter = (targetArray, filters) => {
  let filterKeys = Object.keys(filters);
  return targetArray.filter(function (eachObj) {
    return filterKeys.every(function (eachKey) {
      if (!filters[eachKey].length) {
        return true;
      }
      return filters[eachKey].includes(eachObj[eachKey]);
    });
  });
};

export function replaceCharacterWithLine(data, character) {
  //convert string to array and remove whitespace
  let dataToArray = data.split(character).map((item) => item.trim());
  //convert array to string replacing character with new line
  return dataToArray.reverse().join('\n');
}

export function setCreateEditMessage(response, successMsg, failedMsg) {
  if (response.status === 'FAILED') {
    if (response.resultCode === 'BR') {
      message.error(failedMsg);
    } else {
      message.error(response.desc);
    }
  } else {
    message.success(successMsg);
  }
}

//Convert array to options style
export const convertOptions = (arrayData, objValue) => {
  const dataOpts = arrayData.map((value) => ({
    label: objValue ? value[objValue] : value,
    value: objValue ? value[objValue] : value,
  }));

  const handleDuplicateDataOpts = dataOpts.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  return handleDuplicateDataOpts;
};

//Convert array to options style V2
export const convertOptionsV2 = (arrayData, objLabel, objValue) => {
  const dataOpts = arrayData.map((value) => ({
    label: objLabel ? value[objLabel] : value,
    value: objValue ? value[objValue] : value,
  }));

  const handleDuplicateDataOpts = dataOpts.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  return handleDuplicateDataOpts;
};

//Check if value exists in object array
export const checkValueInObjectArray = (array, value) => {
  return array.some(function (el) {
    return el.value === value;
  });
};

export function isObjectDate(date, type) {
  let theDate;

  if (typeof date !== 'undefined' || date !== null) {
    date instanceof Date
      ? (theDate =
          date.getFullYear() +
          '-' +
          parseInt(date.getMonth() + 1) +
          '-' +
          date.getDate())
      : type === 'timestamp'
      ? (theDate =
          date.substring(6, 10) +
          '-' +
          date.substring(3, 5) +
          '-' +
          date.substring(0, 2) +
          ' ' +
          date.substring(11, 13) +
          ':' +
          date.substring(14, 16) +
          ':' +
          date.substring(17, 19))
      : (theDate =
          date.substring(6, 10) +
          '-' +
          date.substring(3, 5) +
          '-' +
          date.substring(0, 2));
  } else {
    theDate = null;
  }
  return theDate;
}

// default stringify options
export const defaultStringifyOpts = (options) => {
  const defaultOpts = {
    encode: false,
    addQueryPrefix: true,
    indices: false,
    arrayFormat: 'comma',
    sort: 'alphabeticalSort',
  };

  return { ...defaultOpts, ...options };
};

//Stringify query object into string
export const queryStringify = (queryParams, options = {}) => {
  const opts = defaultStringifyOpts(options);
  return qs.stringify(queryParams, opts);
};

export const removeEmptyAttributes = (objParams) => {
  const dataParams = { ...objParams };
  const entries = Object.entries(dataParams);
  for (const [key, value] of entries) {
    if (!value) delete dataParams[key];
    if (Array.isArray(dataParams[key]) && !value.length) {
      delete dataParams[key];
    }
  }
  return dataParams;
};
