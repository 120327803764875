import React, { useEffect, useState, useRef } from 'react';
import { Layout } from 'antd';

import BoxApi from 'api/box';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray } from 'utils';
import TableGlobal from 'components/Table';

import { headerBoxCsv, tableBoxColumns } from '../helper';
import ActiveFilter from './components/ActiveFilter';
import Filter from './components/Filter';

const { Content } = Layout;

export function BoxListComponent() {
  const [boxs, setBoxs] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);

  const [activeFilter, setActiveFilter] = useState({
    boxNumber: [], //boxNumber
  });

  const onBoxNumberChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      boxNumber: checkedValues,
    });
  };

  const [rowSelected, setRowSelected] = useState([]);

  useEffect(() => {
    async function getBoxList() {
      try {
        const response = await BoxApi.listBox();
        let boxList = setToArray(response.data);

        setBoxs(boxList);
      } finally {
        setLoadingPage(false);
      }
    }

    getBoxList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
        onBoxNumberChange={onBoxNumberChange}
        originalData={boxs}
      />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/system/box/${record.boxNumber}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Box List'}
          inputPlaceholder={'Search Box Here...'}
          pageSize={8}
          headerCsv={headerBoxCsv}
          originalData={boxs}
          csvName={'box-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableBoxColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          recordKey={(record) => record.boxNumber}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
        />
      </Content>
    </Layout>
  );
}

export default { BoxListComponent };
