import LogApi from 'api/log';
import { setToArray } from 'utils';

import { GET_LIST_BE_ERROR_LOG } from '../../../type';

export const getBeErrorLogList = () => async (dispatch) => {
  const response = await LogApi.getBeErrorLog();

  dispatch({
    type: GET_LIST_BE_ERROR_LOG,
    payload: setToArray(response.data),
  });
};
