import React from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';

export const FilterOrderLogs = ({
  onShippingCourierChange,
  onShippingAddressChange,
  onActiveOrderChange,
  onFlowStatusChange,
  activeFilterData,
  orderHistory,
  resetFilter,
  innerRef,
}) => {
  const shippingAddressOpt = orderHistory.map((value) => ({
    label: value.shippingAddress,
    value: value.shippingAddress,
  }));

  const handleDuplicateShippingAddressOpt = shippingAddressOpt.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  // const activeOrderOpt = stationOrderList.map((value) => ({
  //   label: `${value.stateId} - ${value.stateName}`,
  //   value: value.stateId,
  // }));

  // const handleDuplicateCurrentStateOpts = currentStateOpts.filter(
  //   (value, index, array) =>
  //     array.findIndex((t) => t.label === value.label) === index
  // );

  const shippingCourierOpt = orderHistory.map((value) => ({
    label: value.shippingCourier,
    value: value.shippingCourier,
  }));

  const handleDuplicateShippingCourierOpt = shippingCourierOpt.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  const flowStatusOpt = orderHistory.map((value) => ({
    label: value.flowStatus,
    value: value.flowStatus,
  }));

  const handleDuplicateFlowStatusOpt = flowStatusOpt.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Active Order'}
            onChange={onActiveOrderChange}
            // options={orderTypeOptions}
            selectValue={activeFilterData.activeOrder}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Production Flow Step'}
            onChange={onFlowStatusChange}
            options={handleDuplicateFlowStatusOpt}
            selectValue={activeFilterData.flowStatus}
          />
        </Col>
        <Col xs={12}>
          <MultiselectDropdown
            placeHolder={'Courier'}
            onChange={onShippingCourierChange}
            options={handleDuplicateShippingCourierOpt}
            selectValue={activeFilterData.shippingCourier}
          />
        </Col>
        <Col xs={12}>
          <MultiselectDropdown
            placeHolder={'Active Address'}
            onChange={onShippingAddressChange}
            options={handleDuplicateShippingAddressOpt}
            selectValue={activeFilterData.shippingAddress}
          />
        </Col>
      </Row>
    </div>
  );
};

FilterOrderLogs.propTypes = {
  onShippingAddressChange: PropTypes.func,
  onShippingCourierChange: PropTypes.func,
  onActiveOrderChange: PropTypes.func,
  onFlowStatusChange: PropTypes.func,
};

export default FilterOrderLogs;
