export const tableHistoryColumns = [
  {
    title: 'Station',
    key: 'Station',
    dataIndex: 'station',
    sorter: (a, b) => a.station.localeCompare(b.station),
  },
  {
    title: 'In',
    key: 'In',
    dataIndex: 'timeIn',
    sorter: (a, b) => a.timeIn.localeCompare(b.timeIn),
  },
  {
    title: 'Out',
    key: 'Out',
    dataIndex: 'timeOut',
    sorter: (a, b) => a.timeOut.localeCompare(b.timeOut),
  },
  {
    title: 'Duration',
    key: 'Duration',
    dataIndex: 'duration',
    sorter: (a, b) => a.duration.localeCompare(b.duration),
  },
];

export const printTypeOptions = [
  { label: 'Putty', value: 'Putty' },
  { label: '3D Scan', value: '3D Scan' },
];

export const roundOptions = [
  { label: 'Sebagian', value: 'Sebagian' },
  { label: 'Lanjutan', value: 'Lanjutan' },
  { label: 'Refinement', value: 'Refinement' },
  { label: 'Extra Aligner', value: 'Extra Aligner' },
  { label: 'Retainer', value: 'Retainer' },
  { label: 'Sebagian R+', value: 'Sebagian R+' },
  { label: 'Lanjutan R+', value: 'Lanjutan R+' },
  { label: 'Refinement R+', value: 'Refinement R+' },
];

export const testFitOptions = [
  { label: 'Test Fit', value: 'Test Fit' },
  { label: 'No Test Fit', value: 'No Test Fit' },
];

export const trimUpperOptions = [
  { label: 'Cutting Straight', value: 'Cutting Straight' },
  { label: 'Scallopped', value: 'Scallopped' },
];

export const trimLowerOptions = [
  { label: 'Cutting Straight', value: 'Cutting Straight' },
  { label: 'Scallopped', value: 'Scallopped' },
];
