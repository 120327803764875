import React, { useEffect, useState } from 'react';
import { message, Button, Form, Tooltip, Input, Row, Col } from 'antd';

import OrderHistoryApi from 'api/order';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setCreateEditMessage, setToArray } from 'utils';
import SelectDropdown from 'components/SelectDropdown';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function OrderHistoryCreateEdit({ form, history }) {
  const [orders, setOrders] = useState([]);
  const [orderHistoryById, setOrderHistoryById] = useState({});
  const [flows, setFlows] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [orderStatuss, setOrderStatuss] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const username = localStorage.getItem('username');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await OrderHistoryApi.updateOrderHistory(
            id,
            values.orderId,
            values.prevFlowId,
            values.thisFlowId,
            values.prevOrderType,
            values.thisOrderType,
            values.prevStateId,
            values.thisStateId,
            values.prevOrderStatusId,
            values.thisOrderStatusId,
            username
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/transaction/order-history',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Order History Data with ID ' + id,
            'Error Updating Order History Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getOrderList() {
      const response = await OrderHistoryApi.listOrderDetail();
      let orderList = setToArray(response.data);

      setOrders(orderList);
    }

    getOrderList();
  }, []);

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  useEffect(() => {
    async function getOrderStatusList() {
      const response = await OrderHistoryApi.listOrderStatus();
      let orderStatusList = setToArray(response.data);

      setOrderStatuss(orderStatusList);
    }

    getOrderStatusList();
  }, []);

  useEffect(() => {
    async function getOrderTypeList() {
      const response = await OrderHistoryApi.listOrderType();
      let orderTypeList = setToArray(response.data);

      setOrderTypes(orderTypeList);
    }

    getOrderTypeList();
  }, []);

  useEffect(() => {
    async function getOrderHistoryById() {
      const response = await OrderHistoryApi.getOrderHistoryById(id);
      let orderHistoryData = setToArray(response.data);

      setOrderHistoryById(orderHistoryData[0]);
    }

    getOrderHistoryById();
    // eslint-disable-next-line
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  const orderTypeOptions = orderTypes.map((value) => ({
    label: `${value.orderType} - ${value.orderDesc}`,
    value: value.orderType,
  }));

  const orderStatusOptions = orderStatuss.map((value) => ({
    label: `${value.orderStatusId} - ${value.orderStatusDesc}`,
    value: value.orderStatusId,
  }));

  const orderIdOptions = orders.map((value) => ({
    label: value.orderId,
    value: value.orderId,
  }));

  return (
    <DetailLayout
      detailTime={id}
      detailTitle={'Edit Order History Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Order ID">
                {getFieldDecorator('orderId', {
                  rules: [{ required: true, message: 'Please input order!' }],
                  initialValue: orderHistoryById && orderHistoryById.orderId,
                })(
                  <SelectDropdown
                    options={orderIdOptions}
                    placeHolder={'Select Order ID'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Previous Flow">
                {getFieldDecorator('prevFlowId', {
                  rules: [{ required: true, message: 'Please input flow!' }],
                  initialValue: orderHistoryById && orderHistoryById.prevFlowId,
                })(
                  <SelectDropdown
                    options={flowOptions}
                    placeHolder={'Select Flow'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="This Flow">
                {getFieldDecorator('thisFlowId', {
                  rules: [{ required: true, message: 'Please input flow!' }],
                  initialValue: orderHistoryById && orderHistoryById.thisFlowId,
                })(
                  <SelectDropdown
                    options={flowOptions}
                    placeHolder={'Select Flow'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="Previous Order Type">
                {getFieldDecorator('prevOrderType', {
                  rules: [
                    { required: true, message: 'Please input order type!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.prevOrderType,
                })(
                  <SelectDropdown
                    options={orderTypeOptions}
                    placeHolder={'Select Order Type'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="This Order Type">
                {getFieldDecorator('thisOrderType', {
                  rules: [
                    { required: true, message: 'Please input order type!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.thisOrderType,
                })(
                  <SelectDropdown
                    options={orderTypeOptions}
                    placeHolder={'Select Order Type'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="Previous State">
                {getFieldDecorator('prevStateId', {
                  rules: [
                    { required: true, message: 'Please input prev state!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.prevStateId,
                })(
                  <SelectDropdown
                    options={stateOptions}
                    placeHolder={'Select State'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="Current State">
                {getFieldDecorator('thisStateId', {
                  rules: [
                    { required: true, message: 'Please input current state!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.thisStateId,
                })(
                  <SelectDropdown
                    options={stateOptions}
                    placeHolder={'Select State'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="Previous Order Status">
                {getFieldDecorator('prevOrderStatusId', {
                  rules: [
                    { required: true, message: 'Please input order status!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.prevOrderStatusId,
                })(
                  <SelectDropdown
                    options={orderStatusOptions}
                    placeHolder={'Select Order Status'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {' '}
              <Form.Item label="This Order Status">
                {getFieldDecorator('thisOrderStatusId', {
                  rules: [
                    { required: true, message: 'Please input order status!' },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.thisOrderStatusId,
                })(
                  <SelectDropdown
                    options={orderStatusOptions}
                    placeHolder={'Select Order Status'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Order History ID">
                {getFieldDecorator('orderHistoryId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input order history ID!',
                    },
                  ],
                  initialValue:
                    orderHistoryById && orderHistoryById.orderHistoryId,
                })(
                  <Input
                    placeholder="Order History ID"
                    autoComplete="false"
                    disabled
                    size="large"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    />
  );
}

export const OrderHistoryForm = Form.create({
  name: 'validate_create_order-history',
})(OrderHistoryCreateEdit);

export default { OrderHistoryCreateEdit };
