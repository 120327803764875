import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import OrderApi from 'api/order';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function OrderStatusCreateEdit({ form, history }) {
  const [orderStatusById, setOrderStatusById] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.updateOrderStatus(
            id,
            values.orderStatusDesc
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/order-status',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Order Status Data with Order Status ID ' + id,
            'Error Updating Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getOrderStatusById() {
      const response = await OrderApi.getOrderStatusById(id);
      let orderStatusData = setToArray(response.data);

      setOrderStatusById(orderStatusData[0]);
    }

    getOrderStatusById();
    // eslint-disable-next-line
  }, []);

  return (
    <DetailLayout
      detailTime={`${id} - ${
        orderStatusById ? orderStatusById.orderStatusDesc : ''
      }`}
      detailTitle={'Edit Order Status Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="ID">
            {getFieldDecorator('orderStatusId', {
              rules: [
                {
                  required: true,
                  message: 'Please input order status ID!',
                },
              ],
              initialValue: orderStatusById && orderStatusById.orderStatusId,
            })(
              <Input
                placeholder="Order Status ID"
                autoComplete="false"
                disabled
                size="large"
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('orderStatusDesc', {
              rules: [
                { required: true, message: 'Please input order status name!' },
              ],
              initialValue: orderStatusById && orderStatusById.orderStatusDesc,
            })(
              <Input
                placeholder="Order Status Description"
                autoComplete="false"
                size="large"
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const OrderStatusForm = Form.create({
  name: 'validate_create_order-status',
})(OrderStatusCreateEdit);

export default { OrderStatusCreateEdit };
