/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Pagination,
  Button,
  Tooltip,
  Input,
  Icon,
  Modal,
  message,
} from 'antd';
import { isEqual, isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { AuthenticationContext } from 'contexts/Authentication';

import OrderApi from 'api/order';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray, nestedFilter } from 'utils';
import { useOuterClickNotifier, usePrevious } from 'utils/hooks';

import { CompletedOrderColumns, headerCompletedExportCsv } from '../helper';
import Filter from './components/Filter';
import ActiveFilter from './components/ActiveFilter';
import '../styles/index.scss';

const { Content } = Layout;
const { confirm } = Modal;

export function CompletedOrderList({ history }) {
  const [completedOrder, setCompletedOrders] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [filteredCompletedOrderData, setFilteredCompletedOrderData] = useState(
    []
  );
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
    totalSize: 0,
  });
  const [activeFilter, setActiveFilter] = useState({
    orderType: [], //Order type
    orderDesc: [], //Order desc
  });
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState({
    orderOpt: [],
  });
  const [filterShow, setFilterShow] = useState(false);
  const innerRef = useRef(null);
  const prevActiveFilter = usePrevious(activeFilter, setActiveFilter);
  const [rowSelected, setRowSelected] = useState([]);

  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opDeleteArchive, opExportArchive, opEditData } = permissionDataList;
  const available_state = localStorage.getItem('available_state');

  async function getCompletedOrderList() {
    try {
      setLoadingPage(true);
      const response = await OrderApi.getCompletedOrder(
        available_state,
        pagination.limit,
        pagination.offset,
        value
      );
      setLoadingPage(false);
      setPagination({
        ...pagination,
        totalSize: response.data.data.totalPage,
      });
      let CompletedOrderList = setToArray(response.data);

      setCompletedOrders(CompletedOrderList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getCompletedOrderList();
  }, [pagination.limit, pagination.offset]);

  //   const onTableChange = (pagination) => {
  //     setPagination(pagination);
  //   };

  const handleShowSearch = () => {
    setShowInput(true);
    setFilterShow(false);
  };

  const handleHideSearch = () => {
    setShowInput(false);
    setValue('');
    setFilteredCompletedOrderData(completedOrder);
  };

  const handleFilterShow = () => {
    setFilterShow(!filterShow);
    setShowInput(false);
  };

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
    setOrderTypeFilterValue({});
  };

  const onOrderTypeChange = (checkedValues) => {
    const handleOrderType = checkedValues.map((value) => value.split(' ')[0]);
    const handleOrderDesc = checkedValues.map((value) =>
      value.split('-').pop().replace(' ', '')
    );

    setActiveFilter({
      ...activeFilter,
      orderType: handleOrderType,
      orderDesc: handleOrderDesc,
    });

    setOrderTypeFilterValue({
      orderOpt: checkedValues,
    });
  };

  useEffect(() => {
    setFilteredCompletedOrderData(completedOrder);
    const orderTypeOpts = completedOrder.map((value) => ({
      label: `${value.orderType} - ${value.orderDesc}`,
      value: `${value.orderType} - ${value.orderDesc}`,
    }));

    const handleDuplicateOrderTypeOpts = orderTypeOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    setOrderTypeOptions(handleDuplicateOrderTypeOpts);
  }, [completedOrder]);

  // Filter;
  useEffect(() => {
    const allFilterResult = nestedFilter(completedOrder, activeFilter);

    if (!isEqual(prevActiveFilter, activeFilter)) {
      setFilteredCompletedOrderData(allFilterResult);
    }
  }, [activeFilter, completedOrder, prevActiveFilter]);

  //search
  const onInputChange = (e) => {
    const convertValue = e.target.value;
    setValue(convertValue);
    // const filteredData = completedOrder.filter((item) => {
    //   return Object.keys(item).some(
    //     (key) =>
    //       !isEmpty(item[key]) &&
    //       item[key]
    //         .toString()
    //         .toLowerCase()
    //         .search(convertValue.toLowerCase()) !== -1
    //   );
    // });
    // setFilteredCompletedOrderData(filteredData);
  };

  const OnInputKeyPressChange = async (e) => {
    if (e.key === 'Enter') {
      setLoadingPage(true);
      const response = await OrderApi.getCompletedOrder(
        available_state,
        pagination.limit,
        pagination.offset,
        e.target.value
      );
      setPagination({
        ...pagination,
        totalSize: response.data.data.totalPage,
      });
      setLoadingPage(false);
      let CompletedOrderList = setToArray(response.data);
      setCompletedOrders(CompletedOrderList);
    }
  };

  const handleSearch = async () => {
    setLoadingPage(true);
    const response = await OrderApi.getCompletedOrder(
      available_state,
      pagination.limit,
      pagination.offset,
      value
    );
    setPagination({
      ...pagination,
      totalSize: response.data.data.totalPage,
    });
    setLoadingPage(false);
    let CompletedOrderList = setToArray(response.data);
    setCompletedOrders(CompletedOrderList);
  };

  const onChangePagination = (page, pageSize) => {
    setLoadingPage(true);
    setPagination({
      ...pagination,
      offset: (page - 1) * 10,
      limit: pageSize,
    });
    setLoadingPage(false);
  };

  useOuterClickNotifier(handleFilterShow, innerRef);

  //   if (loadingPage) {
  //     return <PageSpinner />;
  //   }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleDeleteRow = async () => {
    const getOrderId = rowSelected.map((value) => value.orderId);
    const getPrimaryOrderNumber = rowSelected.map(
      (value) => value.orderReceiptNumber
    );
    const getOrderIdToString = getOrderId.join(',');

    confirm({
      title: `Are you sure delete this archived order data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then archived order data with primary order number ${getPrimaryOrderNumber} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingCompletedOrder() {
          try {
            await OrderApi.deleteOrderBulk(getOrderIdToString);
            setRowSelected([]);
            getCompletedOrderList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingCompletedOrder();
      },
      onCancel() {},
    });
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <div
          className={`panel ${
            filteredCompletedOrderData.length > 8 ? '' : 'panel--table-global'
          }`}>
          <Row gutter={20} type="flex">
            <Col xs={12}>
              <div className="mb-15">
                {showInput ? (
                  <div className="table-global__search">
                    <div className="table-global__btn_search">
                      <Input
                        onKeyPress={(e) => OnInputKeyPressChange(e)}
                        className="table-global__search-input"
                        placeholder="Search Completed Order Here..."
                        onChange={onInputChange}
                        value={value}
                      />
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </div>
                  </div>
                ) : (
                  <strong className="text-md table-global__title">
                    Completed Orders
                  </strong>
                )}
              </div>
            </Col>
            <Col xs={12} type="flex" align="end">
              {!isEmpty(rowSelected) ? (
                <Tooltip title="Delete">
                  <Button
                    onClick={handleDeleteRow}
                    icon="delete"
                    className="mr-10"
                  />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title={showInput ? 'Close' : 'Search'}>
                    {showInput ? (
                      <Button
                        onClick={handleHideSearch}
                        icon="close"
                        className="mr-10"
                      />
                    ) : (
                      <Button
                        onClick={handleShowSearch}
                        icon="search"
                        className="mr-10"
                      />
                    )}
                  </Tooltip>
                  <Tooltip title="Filter">
                    <Button
                      icon="filter"
                      className="mr-10"
                      onClick={handleFilterShow}
                    />
                  </Tooltip>
                  {filterShow && (
                    <Filter
                      resetFilter={resetFilter}
                      onOrderTypeChange={onOrderTypeChange}
                      activeFilter={activeFilter}
                      innerRef={innerRef}
                      orderTypeOptions={orderTypeOptions}
                      orderTypeFilterValue={orderTypeFilterValue}
                    />
                  )}
                  {(opDeleteArchive || opEditData) && (
                    <Tooltip title="Edit Data">
                      {editTable ? (
                        <Button
                          icon="unlock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      ) : (
                        <Button
                          icon="lock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      )}
                    </Tooltip>
                  )}
                  {opExportArchive && (
                    <Tooltip title="Download CSV">
                      <CSVLink
                        filename={'archived-order-list-export.csv'}
                        headers={headerCompletedExportCsv}
                        data={filteredCompletedOrderData}>
                        <Icon
                          className="mr-10"
                          type="cloud-download"
                          style={{ fontSize: '20px' }}
                        />
                      </CSVLink>
                    </Tooltip>
                  )}
                </>
              )}
            </Col>
          </Row>
          <ActiveFilter
            activeFilterData={activeFilter}
            setActiveFilter={setActiveFilter}
            setFilterShow={setFilterShow}
            setOrderTypeFilterValue={setOrderTypeFilterValue}
            orderTypeFilterValue={orderTypeFilterValue}
          />
          <Table
            dataSource={filteredCompletedOrderData}
            rowSelection={editTable ? rowSelection : ''}
            scroll={{ x: 800, y: 500 }}
            loading={{
              tip: 'Loading...',
              spinning: loadingPage,
            }}
            pagination={false}
            columns={CompletedOrderColumns}
            rowKey={(record) => record.orderId}
            onRow={(record) => ({
              onClick: () =>
                history.push({
                  pathname: `/cms/transaction/order-detail/edit?id=${record.orderId}`,
                  data: { orderDetail: { ...record } },
                  progress: false,
                }),
            })}
            className="table-global__table-item"
          />
          <Pagination
            disabled={loadingPage}
            style={{
              display: 'flex',
              justifyContent: 'end',
              margin: '10px 0px',
            }}
            onChange={(page, pageSize) => onChangePagination(page, pageSize)}
            defaultCurrent={pagination.offset === 0 ? 1 : pagination.offset}
            total={pagination.totalSize === 0 ? 10 : pagination.totalSize}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(CompletedOrderList);
