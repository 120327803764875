import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import SelectDropdown from 'components/SelectDropdown';
import { setCreateEditMessage, setToArray } from 'utils';
import OrderApi from 'api/order';
import FlowApi from 'api/flow';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getShippingList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [flows, setFlows] = useState([]);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.createOrderType(
            values.orderType,
            values.orderDesc,
            values.flowId
          );

          history.push({
            pathname: '/cms/master/order-type',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Order Type Data',
            'Error Inserting Order Type Data!'
          );

          getShippingList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  return (
    <Modal
      title="Add New Order Type Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Order Type Data.</div>
      <Form>
        <Form.Item label="Order Type">
          {getFieldDecorator('orderType', {
            rules: [{ required: true, message: 'Please input order type!' }],
          })(
            <Input placeholder="Order Type" size="large" autoComplete="false" />
          )}
        </Form.Item>

        <Form.Item label="Order Description">
          {getFieldDecorator('orderDesc', {
            rules: [
              { required: true, message: 'Please input order description!' },
            ],
          })(
            <Input
              placeholder="Order Description"
              size="large"
              autoComplete="false"
            />
          )}
        </Form.Item>
        <Form.Item label="Flow ID">
          {getFieldDecorator('flowId', {
            rules: [{ required: true, message: 'Please input flow ID!' }],
          })(
            <SelectDropdown options={flowOptions} placeHolder={'Select Flow'} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
