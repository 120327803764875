export const headerCsv = [
  { label: 'Name', key: 'auditIdLabel' },
  { label: 'Audited State', key: 'auditState' },
  { label: 'Timestamp', key: 'auditTimestamp' },
];

export const tableColumns = [
  {
    title: 'Name',
    key: 'auditIdLabel',
    dataIndex: 'auditIdLabel',
    sorter: (a, b) => a.auditIdLabel.localeCompare(b.auditIdLabel),
  },
  {
    title: 'Audited State',
    key: 'auditState',
    dataIndex: 'auditState',
    sorter: (a, b) => a.auditState.localeCompare(b.auditState),
  },
  {
    title: 'Timestamp',
    key: 'auditTimestamp',
    dataIndex: 'auditTimestamp',
    sorter: (a, b) => a.auditTimestamp.localeCompare(b.auditTimestamp),
  },
];
