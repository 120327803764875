import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';


import '../styles/index.scss';
import TableOrderContainer from './containers/TableOrderContainer';
import { ORDER_RATAPLUS_COLUMNS } from './containers/TableOrderContainer/helper/ORDER_RATAPLUS_COLUMNS';
import { orderActions } from 'store/action/v2/OrderAction/order.actions';

const { Content } = Layout;

function RataPlusListPage() {
  const order = useSelector(state => state.appReducer.order)
  const dispatch = useDispatch()

  const history = useHistory();

  return (
    <Layout className="order-list-page">
      <Content>
        <TableOrderContainer
          reduxState='order'
          tableTitle={`Rata Plus`}
          dataSource={order.orderList.data}
          columns={ORDER_RATAPLUS_COLUMNS}
          total={order.orderList.allData}
          handleRowOnClick={(record) => (
            history.push(`/cms/transaction/order-detail/edit?id=${record.orderId}`)
          )}
          rowKey="orderId"
          rataPlusFlag={false}
          listActionDispatcher={(val)=> dispatch(orderActions.getList(val))}
        />
      </Content>
    </Layout>
  )
}

export default RataPlusListPage