import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import { TableOrderFilter, TableOrderTitle } from './components';

import './styles/tablerow.scss';
import { useSelector } from 'react-redux';

const ORDER_TYPE_RATA_PLUS = ['16071', '16072', '16051', '16052'];

function TableOrderContainer({
  dataSource,
  total,
  handleRowOnClick,
  tableTitle,
  rowKey,
  columns,
  rataPlusFlag,
  listActionDispatcher,
  reduxState
}) {
  // lmit default to 10
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [sort, setSort] = useState('descend');
  // searchBy waiting for API
  const [searchBy, setSearchBy] = useState('');
  // filterBy waiting for API
  const [filterBy, setFilterBy] = useState({
    orderType: [],
    isOverdue: [],
    isTestFit: [],
  });
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = useState(1);
  // const [isLoading, setIsLoading] = useState(false);
  const {isLoading} = useSelector(state => state.appReducer[reduxState]);

  const handleSearch = (val) => {
    setSearchBy(val);
  };
  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setOffset((pagination.current - 1) * 10);
    setSortBy(sorter.columnKey);
    setSort(sorter.order);
  };

  useEffect(() => {
    if (typeof listActionDispatcher == 'function')
      listActionDispatcher({
        limit, offset, filterBy, sortBy, sort, searchBy, page
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filterBy, sortBy, sort, searchBy, page]);

  return (
    <div className={`panel`}>
      <TableOrderTitle
        tableName={tableTitle}
        handleSearch={(val) => handleSearch(val)}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      {showFilter && (
        <TableOrderFilter
          handleFilterBy={(val) => handleFilterBy(val)}
          filterBy={filterBy}
        />
      )}
      <Table
        rowClassName={(record) => rataPlusFlag && ORDER_TYPE_RATA_PLUS.includes(record.orderType) ? 'rataplus-row' : ''}
        currentPage={page}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 1650, y: 300 }}
        className="table-global__table-item"
        onChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter)
        }
        pagination={{ total: total }}
        loading={isLoading}
        onRow={(record, rowIndex) => ({
          onClick: () => handleRowOnClick(record, rowIndex),
        })}
        rowKey={rowKey}
      />
    </div>
  );
}

export default TableOrderContainer;
