/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ClinicNoteApi from 'api/clinic-note';
import SelectDropdown from 'components/SelectDropdown';
import { setToArray, setCreateEditMessage, convertOptions } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import { getListClinicNote } from 'store/action/ClinicNoteAction';

export function ClinicSubStatusCreateEdit({
  form,
  history,
  getListClinicNote,
  clinicNoteListData,
}) {
  const [clinicSubStatusById, setClinicSubStatusById] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  useEffect(() => {
    async function getClinicSubStatusById() {
      const response = await ClinicNoteApi.getClinicSubStatusById(id);
      let ClinicSubStatusData = setToArray(response.data);

      setClinicSubStatusById(ClinicSubStatusData[0]);
    }

    getClinicSubStatusById();
  }, []);

  useEffect(() => {
    async function getClinicNoteList() {
      await getListClinicNote();
    }

    getClinicNoteList();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          const getSpecificStatusClinicNoteData = clinicNoteListData.find(
            (statusValue) =>
              statusValue.noteStatusDesc === values.clinicParentName
          );

          theResponse = await ClinicNoteApi.updateClinicSubStatus(
            clinicSubStatusById.noteSubStatusId,
            values.ClinicSubStatusName,
            getSpecificStatusClinicNoteData.noteStatusId
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/clinic-sub-status',
            });
          }
          setCreateEditMessage(
            theResponse.data,
            'Success Updating Clinic Sub Status Data with ID ' + id,
            'Error Updating Clinic Sub Status Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  return (
    <DetailLayout
      detailTime={`${id}`}
      detailTitle={'Edit Clinic Sub Status Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="ID">
            {getFieldDecorator('ClinicSubStatusId', {
              rules: [
                {
                  required: true,
                  message: 'Please input clinic sub status ID!',
                },
              ],
              initialValue:
                clinicSubStatusById && clinicSubStatusById.noteSubStatusId,
            })(
              <Input
                placeholder="Clinic Sub Status ID"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('ClinicSubStatusName', {
              rules: [
                { required: true, message: 'Please input sub status name!' },
              ],
              initialValue:
                clinicSubStatusById && clinicSubStatusById.noteSubStatusDesc,
            })(
              <Input
                placeholder="Sub Status Name"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="Parent ID">
            {getFieldDecorator('clinicParentName', {
              rules: [
                {
                  required: true,
                  message: 'Please input Parent ID!',
                },
              ],
              initialValue:
                clinicSubStatusById && clinicSubStatusById.noteStatusDesc,
            })(
              <SelectDropdown
                options={
                  !isEmpty(clinicNoteListData)
                    ? convertOptions(clinicNoteListData, 'noteStatusDesc')
                    : []
                }
                placeHolder={'Select Status'}
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

const mapStateToProps = ({ clinicNoteListData }) => ({ clinicNoteListData });

export const ClinicSubStatusCreateEditForm = Form.create({
  name: 'validate_create_clinic_sub_status',
})(ClinicSubStatusCreateEdit);

export default connect(mapStateToProps, { getListClinicNote })(
  ClinicSubStatusCreateEditForm
);
