import OrderApi from 'api/order';
import { setToArray } from 'utils';

import { GET_LIST_REFUND_REQUEST } from '../../type';

export const getListRefundRequest = () => async (dispatch) => {
  const response = await OrderApi.getRefundRequest();

  dispatch({
    type: GET_LIST_REFUND_REQUEST,
    payload: setToArray(response.data),
  });
};
