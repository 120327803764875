import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Input, Form, Row, Checkbox } from 'antd';

import { getPaymentDetailById } from 'store/action/PaymentDetailAction';
import './scroll.scss';
import { AuthenticationContext } from 'contexts/Authentication';

function canBeConsecuetive(arr) {
  if (!arr.length) {
    return false;
  }
  const copy = arr.slice();
  copy.sort((a, b) => parseInt(a) - parseInt(b));
  for (let i = parseInt(copy[0]), j = 0; j < copy.length; i++, j++) {
    if (parseInt(copy[j]) === i) {
      continue;
    }
    return false;
  }
  return true;
}

export const AlignerDetail = ({ form, simulationDesignByIdData }) => {
  const { getFieldDecorator, getFieldValue } = form;
  let valAlignerRa = getFieldValue('alignerRa');
  let valAlignerRb = getFieldValue('alignerRb');
  let valOcUpper = getFieldValue('ocUp');
  let valOcDown = getFieldValue('ocDown');
  const { permissionDataList } = useContext(AuthenticationContext);
  const { opEditSetAlignerDetail } = permissionDataList;

  const onChangeRaDetails = (val) => {
    if (val.includes('0') && valAlignerRa !== val.length - 1)
      form.setFieldsValue({ alignerRa: val.length - 1 });
    if (!val.includes('0') && valAlignerRa !== val.length)
      form.setFieldsValue({ alignerRa: val.length });
    const agUpper = canBeConsecuetive(
      []
        .concat(val, getFieldValue('ocUpDetails'))
        .filter((item, i, arr) => item && arr.indexOf(item) === i)
    );
    if (agUpper) form.setFieldsValue({ agUpper: false });
    if (!isEmpty(val) && agUpper === false)
      form.setFieldsValue({ agUpper: true });
  };

  const onChangeRbDetails = (val) => {
    if (val.includes('0') && valAlignerRb !== val.length - 1)
      form.setFieldsValue({ alignerRb: val.length - 1 });
    if (!val.includes('0') && valAlignerRb !== val.length)
      form.setFieldsValue({ alignerRb: val.length });
    const agLower = canBeConsecuetive(
      []
        .concat(val, getFieldValue('ocDownDetails'))
        .filter((item, i, arr) => item && arr.indexOf(item) === i)
    );
    if (agLower) form.setFieldsValue({ agLower: false });
    if (!isEmpty(val) && agLower === false)
      form.setFieldsValue({ agLower: true });
  };

  const onChangeOCUpperDetails = (val) => {
    if (!val.includes(0) && valOcUpper !== val.length)
      form.setFieldsValue({ ocUp: val.length });
    const agUpper = canBeConsecuetive(
      []
        .concat(val, getFieldValue('raDetails'))
        .filter((item, i, arr) => item && arr.indexOf(item) === i)
    );
    if (agUpper) form.setFieldsValue({ agUpper: false });
    if (!isEmpty(val) && agUpper === false)
      form.setFieldsValue({ agUpper: true });
  };

  const onChangeOCDownDetails = (val) => {
    if (!val.includes(0) && valOcDown !== val.length)
      form.setFieldsValue({ ocDown: val.length });
    const agLower = canBeConsecuetive(
      []
        .concat(val, getFieldValue('rbDetails'))
        .filter((item, i, arr) => item && arr.indexOf(item) === i)
    );
    if (agLower) form.setFieldsValue({ agLower: false });
    if (!isEmpty(val) && agLower === false)
      form.setFieldsValue({ agLower: true });
  };

  const disabledRaDetails = (index) => {
    const ocUpDetails = getFieldValue('ocUpDetails');
    if (ocUpDetails) return ocUpDetails.includes(String(index));
  };
  const disabledRbDetails = (index) => {
    const ocDownDetails = getFieldValue('ocDownDetails');
    if (ocDownDetails) return ocDownDetails.includes(String(index));
  };
  const disabledOCUpper = (index) => {
    const raDetails = getFieldValue('raDetails');
    if (raDetails !== null) return raDetails.includes(String(index));
    return true;
  };
  const disabledOCLower = (index) => {
    const rbDetails = getFieldValue('rbDetails');
    if (rbDetails) return rbDetails.includes(String(index));
    return true;
  };

  return (
    <div className="simulation-design__aligner-detail">
      <div className="color-text-primary text-md fw-bold mb-20">
        Aligner Detail
      </div>
      <Row gutter={15}>
        <Col span={12}>
          {simulationDesignByIdData[0].problemSetRa && (
            <Form.Item label="Set Bermasalah RA">
              {getFieldDecorator('setBermasalahRa', {
                initialValue: !isEmpty(simulationDesignByIdData)
                  ? simulationDesignByIdData[0].problemSetRa
                  : '',
              })(
                <Input
                  placeholder="Set Bermasalah RA"
                  autoComplete="false"
                  size="large"
                  disabled={
                    !(
                      simulationDesignByIdData[0].simulationStatusId === 'AP' &&
                      simulationDesignByIdData[0].stateId === '1045'
                    )
                  }
                />
              )}
            </Form.Item>
          )}
        </Col>
        <Col span={12}>
          {simulationDesignByIdData[0].problemSetRb && (
            <Form.Item label="Set Bermasalah RB">
              {getFieldDecorator('setBermasalahRb', {
                initialValue: !isEmpty(simulationDesignByIdData)
                  ? simulationDesignByIdData[0].problemSetRb
                  : '',
              })(
                <Input
                  placeholder="Set Bermasalah RB"
                  autoComplete="false"
                  size="large"
                  disabled={
                    !(
                      simulationDesignByIdData[0].simulationStatusId === 'AP' &&
                      simulationDesignByIdData[0].stateId === '1045'
                    )
                  }
                />
              )}
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={15}>
        {/* RA / RB Section */}
        <Col span={12}>
          <Form.Item
            label={
              <>
                RA <span className="dot-primary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('alignerRa', {
              initialValue: !isEmpty(simulationDesignByIdData)
                ? simulationDesignByIdData[0].ra
                : '',
              rules: [
                {
                  required:
                    !isEmpty(simulationDesignByIdData) &&
                    simulationDesignByIdData[0].simulationStatusId === 'SE',
                  message: 'Please input RA!',
                },
              ],
            })(
              <Input
                placeholder="Insert RA"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('raDetails', {
              initialValue:
                simulationDesignByIdData[0].raDetails &&
                simulationDesignByIdData[0].raDetails.split(','),
              ...(simulationDesignByIdData[0].simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(simulationDesignByIdData) &&
                      simulationDesignByIdData[0].simulationStatusId === 'SE',
                    message: 'Please input RA!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                onChange={(val) => onChangeRaDetails(val)}
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(101)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i}
                    className={`mr-20 aligner-checkbox-detail`}
                    index={i}
                    disabled={
                      !(
                        (simulationDesignByIdData[0].simulationStatusId ===
                          'AP' &&
                          simulationDesignByIdData[0].stateId === '1045') ||
                        opEditSetAlignerDetail
                      ) || disabledRaDetails(i)
                    }
                    value={String(i)}>
                    {String(i)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('agUpper', {
              initialValue: !isEmpty(simulationDesignByIdData)
                ? simulationDesignByIdData[0].agUpper
                : null,
            })(
              <Checkbox
                size={'large'}
                className="aligner-checkbox-detail"
                checked={getFieldValue('agUpper') === true ? true : false}
                disabled>
                AG Upper
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                RB <span className="dot-primary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('alignerRb', {
              initialValue: !isEmpty(simulationDesignByIdData)
                ? simulationDesignByIdData[0].rb
                : '',
              rules: [
                {
                  required:
                    !isEmpty(simulationDesignByIdData) &&
                    simulationDesignByIdData[0].simulationStatusId === 'SE',
                  message: 'Please input RB!',
                },
              ],
            })(
              <Input
                placeholder="Insert RB"
                autoComplete="false"
                size="large"
                disabled
                // onChange={(val) => onChangeAlignerRb(val)}
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('rbDetails', {
              initialValue:
                simulationDesignByIdData[0].rbDetails &&
                simulationDesignByIdData[0].rbDetails.split(','),
              ...(simulationDesignByIdData[0].simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(simulationDesignByIdData) &&
                      simulationDesignByIdData[0].simulationStatusId === 'SE',
                    message: 'Please input RB!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                onChange={(val) => onChangeRbDetails(val)}
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(101)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i}
                    className="mr-20 aligner-checkbox-detail"
                    index={i}
                    disabled={
                      !(
                        (simulationDesignByIdData[0].simulationStatusId ===
                          'AP' &&
                          simulationDesignByIdData[0].stateId === '1045') ||
                        opEditSetAlignerDetail
                      ) || disabledRbDetails(i)
                    }
                    value={String(i)}>
                    {String(i)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('agLower', {
              initialValue: !isEmpty(simulationDesignByIdData)
                ? simulationDesignByIdData[0].agLower
                : null,
            })(
              <Checkbox
                size={'large'}
                className="aligner-checkbox-detail"
                checked={getFieldValue('agLower') === true ? true : false}
                disabled
                value={true}>
                AG Lower
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item label="Total Aligner">
            {getFieldDecorator('totalAligner', {
              initialValue:
                simulationDesignByIdData[0].totalAligner ||
                parseInt(valAlignerRb) + parseInt(valAlignerRa) ||
                0,
            })(
              <Input
                placeholder="Insert Total Aligner"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        {/* OC Section */}
        <Col span={12}>
          <Form.Item
            label={
              <>
                OC Upper <span className="dot-secondary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('ocUp', {
              initialValue: simulationDesignByIdData[0].ocUp,
            })(
              <Input
                placeholder="Insert OC Upper"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('ocUpDetails', {
              initialValue:
                simulationDesignByIdData[0].ocUpDetails &&
                simulationDesignByIdData[0].ocUpDetails.split(','),
              ...(simulationDesignByIdData[0].simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(simulationDesignByIdData) &&
                      simulationDesignByIdData[0].simulationStatusId === 'SE',
                    message: 'Please input RA!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                onChange={(val) => onChangeOCUpperDetails(val)}
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(100)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i + 1}
                    className={`mr-10 aligner-checkbox-detail-OC ${
                      simulationDesignByIdData[0].simulationStatusId === 'SE'
                        ? ''
                        : 'checkbox-yellow'
                    }`}
                    index={i + 1}
                    disabled={
                      disabledOCUpper(i + 1) ||
                      (simulationDesignByIdData[0].simulationStatusId ===
                        'SE' &&
                        !opEditSetAlignerDetail)
                    }
                    value={String(i + 1)}>
                    {String(i + 1)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                OC Lower <span className="dot-secondary"></span>
              </>
            }
            style={{ marginTop: '6.1em' }}
            className="mb-20">
            {getFieldDecorator('ocDown', {
              initialValue: simulationDesignByIdData[0].ocDown,
            })(
              <Input
                placeholder="Insert OC Lower"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('ocDownDetails', {
              initialValue:
                simulationDesignByIdData[0].ocDownDetails &&
                simulationDesignByIdData[0].ocDownDetails.split(','),
              ...(simulationDesignByIdData[0].simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(simulationDesignByIdData) &&
                      simulationDesignByIdData[0].simulationStatusId === 'SE',
                    message: 'Please input RA!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                onChange={(val) => onChangeOCDownDetails(val)}
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(100)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i + 1}
                    className={`mr-10 aligner-checkbox-detail-OC ${
                      simulationDesignByIdData[0].simulationStatusId === 'SE'
                        ? ''
                        : 'checkbox-yellow'
                    }`}
                    index={i + 1}
                    disabled={
                      disabledOCLower(i + 1) ||
                      (simulationDesignByIdData[0].simulationStatusId ===
                        'SE' &&
                        !opEditSetAlignerDetail)
                    }
                    value={String(i + 1)}>
                    {String(i + 1)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item label="Total OC" style={{ marginTop: '6.1em' }}>
            {getFieldDecorator('totalOC', {
              initialValue:
                parseInt(getFieldValue('ocDown')) +
                  parseInt(getFieldValue('ocUp')) ||
                simulationDesignByIdData[0].ocDown +
                  simulationDesignByIdData[0].ocUp ||
                0,
            })(
              <Input
                placeholder="Insert Total OC"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ paymentDetailByIdData }) => ({
  paymentDetailByIdData,
});

export default connect(mapStateToProps, {
  getPaymentDetailById,
})(Form.create()(AlignerDetail));
