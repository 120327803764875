export const SudahExportTableColumn = [
  {
    title: 'Upload Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
  },
  {
    title: 'Revision',
    key: 'revision',
    dataIndex: 'revision',
    render: (val) => (val ? 'Yes' : 'No'),
  },
  {
    title: 'Designer Name',
    key: 'designerName',
    dataIndex: 'designerName',
    sorter: true
  },
  {
    title: 'Patient Name',
    key: 'patientName',
    dataIndex: 'patientName',
    sorter: true
  },
  {
    title: 'SO Number',
    dataIndex: 'orderReceiptNumber',
    key: 'orderReceiptNumber',
    sorter: true
  },
  {
    title: 'Order Type',
    dataIndex: 'orderDesc',
    key: 'orderDesc',
    sorter: true
  },
  {
    title: 'Video Version',
    key: 'videoVersionName',
    dataIndex: 'videoVersionName',
    // sorter: (a, b) =>
    //   a.videoVersionName === null || b.videoVersionName === null
    //     ? (a.videoVersionName === null) - (b.videoVersionName === null)
    //     : a.videoVersionName.localeCompare(b.videoVersionName),
  },
  {
    title: 'Total Video',
    key: 'videoCount',
    dataIndex: 'videoCount',
    // sorter: (a, b) => (a.videoCount === null) - (b.videoCount === null),
  },
  {
    title: 'RA',
    dataIndex: 'ra',
    key: 'ra',
    // sorter: (a, b) =>
    //   a.ra === null || b.ra === null
    //     ? (a.ra === null) - (b.ra === null)
    //     : a.ra - b.ra,
  },
  {
    title: 'RB',
    dataIndex: 'rb',
    key: 'rb',
    // sorter: (a, b) =>
    //   a.rb === null || b.rb === null
    //     ? (a.rb === null) - (b.rb === null)
    //     : a.rb - b.rb,
  },
  {
    title: 'Assigned Doctor',
    key: 'doctorName',
    dataIndex: 'doctorName',
    sorter: true
  },
];
