/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Tooltip,
  Input,
  Icon,
  Modal,
  message,
} from 'antd';
import { isEqual, isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { AuthenticationContext } from 'contexts/Authentication';

import OrderApi from 'api/order';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray, nestedFilter } from 'utils';
import { useOuterClickNotifier, usePrevious } from 'utils/hooks';

import { flaggedOrderColumns, headerFlaggedExportCsv } from '../helper';
import Filter from './components/Filter';
import ActiveFilter from './components/ActiveFilter';
import '../styles/index.scss';

const { Content } = Layout;
const { confirm } = Modal;

export function FlaggedOrderList({ history }) {
  const [flaggedOrder, setFlaggedOrders] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  });
  const [activeFilter, setActiveFilter] = useState({
    testFitStatus: [],
  });
  const [filterShow, setFilterShow] = useState(false);
  const innerRef = useRef(null);
  const [filteredFlaggedOrderData, setFilteredFlaggedOrderData] = useState([]);
  const prevActiveFilter = usePrevious(activeFilter, setActiveFilter);
  const [editTable, setEditTable] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);

  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opDeleteFlag, opExportFlag, opEditData } = permissionDataList;
  const available_state = localStorage.getItem('available_state');

  async function getFlaggedOrderList() {
    try {
      const response = await OrderApi.getFlaggedOrder(available_state);
      let flaggedOrderList = setToArray(response.data);
      setFlaggedOrders(flaggedOrderList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getFlaggedOrderList();
  }, []);

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleShowSearch = () => {
    setShowInput(true);
    setFilterShow(false);
  };

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideSearch = () => {
    setShowInput(false);
    setValue('');
    setFilteredFlaggedOrderData(flaggedOrder);
  };

  const onTestFitChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      testFitStatus: checkedValues,
    });
  };

  const handleFilterShow = () => {
    setFilterShow(!filterShow);
    setShowInput(false);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  useEffect(() => {
    setFilteredFlaggedOrderData(flaggedOrder);
  }, [flaggedOrder]);

  // Filter;
  useEffect(() => {
    const allFilterResult = nestedFilter(flaggedOrder, activeFilter);

    if (!isEqual(prevActiveFilter, activeFilter)) {
      setFilteredFlaggedOrderData(allFilterResult);
    }
  }, [activeFilter, flaggedOrder, prevActiveFilter]);

  //search
  const onInputChange = (e) => {
    const convertValue = e.target.value;
    setValue(convertValue);

    const filteredData = flaggedOrder.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key]
            .toString()
            .toLowerCase()
            .search(convertValue.toLowerCase()) !== -1
      );
    });

    setFilteredFlaggedOrderData(filteredData);
  };

  useOuterClickNotifier(handleFilterShow, innerRef);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleDeleteRow = async () => {
    const getOrderId = rowSelected.map((value) => value.orderId);
    const getPrimaryOrderNumber = rowSelected.map(
      (value) => value.orderReceiptNumber
    );
    const getOrderIdToString = getOrderId.join(',');

    confirm({
      title: `Are you sure delete this flagged order data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then flagged order data with primary order number ${getPrimaryOrderNumber} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingFlaggedOrder() {
          try {
            await OrderApi.deleteOrderBulk(getOrderIdToString);
            setRowSelected([]);
            getFlaggedOrderList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingFlaggedOrder();
      },
      onCancel() {},
    });
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="panel panel--table-global">
          <Row gutter={20} type="flex">
            <Col xs={12}>
              <div className="mb-15">
                {showInput ? (
                  <div className="table-global__search">
                    <Input
                      className="table-global__search-input"
                      placeholder="Search Flagged Order Here..."
                      onChange={onInputChange}
                      value={value}
                    />
                    <Button
                      onClick={handleHideSearch}
                      icon="close"
                      className="position-absolute table-global__search-close"
                    />
                  </div>
                ) : (
                  <strong className="text-md table-global__title">
                    Flagged Orders
                  </strong>
                )}
              </div>
            </Col>
            <Col xs={12} type="flex" align="end">
              {!isEmpty(rowSelected) ? (
                <Tooltip title="Delete">
                  <Button
                    onClick={handleDeleteRow}
                    icon="delete"
                    className="mr-10"
                  />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Search">
                    <Button
                      onClick={handleShowSearch}
                      icon="search"
                      className="mr-10"
                    />
                  </Tooltip>
                  <Tooltip title="Filter">
                    <Button
                      icon="filter"
                      className="mr-10"
                      onClick={handleFilterShow}
                    />
                  </Tooltip>
                  {filterShow && (
                    <Filter
                      resetFilter={resetFilter}
                      onTestFitChange={onTestFitChange}
                      innerRef={innerRef}
                      activeFilter={activeFilter}
                    />
                  )}
                  {(opDeleteFlag || opEditData) && (
                    <Tooltip title="Edit Data">
                      {editTable ? (
                        <Button
                          icon="unlock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      ) : (
                        <Button
                          icon="lock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      )}
                    </Tooltip>
                  )}
                  {opExportFlag && (
                    <Tooltip title="Download CSV">
                      <CSVLink
                        filename={'flagged-order-list-export.csv'}
                        headers={headerFlaggedExportCsv}
                        data={filteredFlaggedOrderData}>
                        <Icon
                          className="mr-10"
                          type="cloud-download"
                          style={{ fontSize: '20px' }}
                        />
                      </CSVLink>
                    </Tooltip>
                  )}
                </>
              )}
            </Col>
          </Row>
          <ActiveFilter
            activeFilterData={activeFilter}
            setActiveFilter={setActiveFilter}
            setFilterShow={setFilterShow}
          />
          <Table
            dataSource={filteredFlaggedOrderData}
            rowSelection={editTable ? rowSelection : ''}
            scroll={{ x: 1000, y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={flaggedOrderColumns}
            rowKey={(record) => record.orderId}
            onRow={(record) => ({
              onClick: () =>
                history.push({
                  pathname: `/cms/transaction/order-detail/edit?id=${record.orderId}`,
                  data: { orderDetail: { ...record } },
                  progress: false,
                }),
            })}
            className="table-global__table-item"
          />
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(FlaggedOrderList);
