import React from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';

export const FilterOrderLogs = ({
  activeFilter,
  orderShippingById,
  resetFilter,
  innerRef,
  onAddressChange,
}) => {
  const addressOpt = orderShippingById.map((value) => ({
    label: value.shippingAddress1,
    value: value.shippingAddress1,
  }));

  const handleDuplicateAddressOpt = addressOpt.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Delivery Address'}
            onChange={onAddressChange}
            options={handleDuplicateAddressOpt}
            selectValue={activeFilter.shippingAddress1}
          />
        </Col>
      </Row>
    </div>
  );
};

FilterOrderLogs.propTypes = {
  onFlowStatusChange: PropTypes.func,
};

export default FilterOrderLogs;
