import OrderApi from 'api/order';

import {
  GET_LIST_STATION_ORDER,
  GET_LIST_DASHBOARD_STATION_ORDER,
} from '../../../type';

export const getListStationOrder = (availableState) => async (dispatch) => {
  const response = await OrderApi.getStationOrder(availableState);

  dispatch({
    type: GET_LIST_STATION_ORDER,
    payload: response.data,
  });
};

export const getListDashboardStationOrder =
  (availableState) => async (dispatch) => {
    const response = await OrderApi.getDashboardStationOrder(availableState);

    dispatch({
      type: GET_LIST_DASHBOARD_STATION_ORDER,
      payload: response.data,
    });
  };
