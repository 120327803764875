import {
  GET_LIST_STATION_STATE,
  GET_LIST_STATION_HISTORY,
  GET_EKANBAN_PRINT_LABEL_DATA,
  GET_EKANBAN_DATA,
  SET_EKANBAN_FORM,
} from '../../type';

export const listStationState = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_STATION_STATE:
      return action.payload;
    default:
      return state;
  }
};

export const listStationHistory = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_STATION_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const eKanbanPrintLabelData = (state = [], action) => {
  switch (action.type) {
    case GET_EKANBAN_PRINT_LABEL_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const eKanbanData = (state = [], action) => {
  switch (action.type) {
    case GET_EKANBAN_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const eKanbanForm = (state = [], action) => {
  switch (action.type) {
    case SET_EKANBAN_FORM:
      return action.payload;
    default:
      return state;
  }
};
