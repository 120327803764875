export const tableActionColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'actionId',
    sorter: (a, b) => a.actionId.localeCompare(b.actionId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'actionName',
    sorter: (a, b) => a.actionName.localeCompare(b.actionName),
  },
];

export const tableBoxStatusColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'boxStatusId',
    sorter: (a, b) => a.boxStatusId.localeCompare(b.boxStatusId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'boxStatusDesc',
    sorter: (a, b) => a.boxStatusDesc.localeCompare(b.boxStatusDesc),
  },
];

export const tableFlowColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'flowId',
    sorter: (a, b) => a.flowId.localeCompare(b.flowId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'flowName',
    sorter: (a, b) => a.flowName.localeCompare(b.flowName),
  },
];

export const tableOrderStatusColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'orderStatusId',
    sorter: (a, b) => a.orderStatusId.localeCompare(b.orderStatusId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'orderStatusDesc',
    sorter: (a, b) => a.orderStatusDesc.localeCompare(b.orderStatusDesc),
  },
];

export const tableOrderTypeColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'orderType',
    sorter: (a, b) => a.orderType.localeCompare(b.orderType),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'orderDesc',
    sorter: (a, b) => a.orderDesc.localeCompare(b.orderDesc),
  },
];

export const tableShippingColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'shippingId',
    sorter: (a, b) => a.shippingId.localeCompare(b.shippingId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'shippingName',
    sorter: (a, b) => a.shippingName.localeCompare(b.shippingName),
  },
];

export const tableStateColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'stateId',
    sorter: (a, b) => a.stateId.localeCompare(b.stateId),
    width: 150,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'stateName',
    sorter: (a, b) => a.stateName.localeCompare(b.stateName),
  },
];

export const tableClinicStatusColumns = [
  {
    title: 'Clinic Status',
    key: 'clinicStatus',
    dataIndex: 'noteStatusDesc',
    sorter: (a, b) => a.noteStatusDesc.localeCompare(b.noteStatusDesc),
  },
];

export const tableClinicSubStatusColumns = [
  {
    title: 'Clinic Sub Status',
    key: 'clinicSubStatus',
    dataIndex: 'noteSubStatusDesc',
    sorter: (a, b) => a.noteSubStatusDesc.localeCompare(b.noteSubStatusDesc),
  },
];
