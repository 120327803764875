export const headerSimDesHistoryCsv = [
  {
    label: "ID",
    key: "simulationId"
  },
  {
    label: "Patient Name",
    key: "patientName"
  },
  {
    label: "Order Receipt Number",
    key: "orderReceiptNumber"
  },
  {
    label: "Order Type",
    key: "orderType"
  },
  {
    label: "Simulation Design Status",
    key: "simulationStatusName"
  },
  {
    label: "Attempt",
    key: "attempt"
  },
  {
    label: "Doctor Name",
    key: "doctorName"
  },
  {
    label: "Designer Name",
    key: "designerName"
  },
  {
    label: "Admin Designer Name",
    key: "adminDesignerName"
  },
  {
    label: "Video Version ID",
    key: "videoVersionId"
  },
  {
    label: "Video Name",
    key: "videoName"
  },
  {
    label: "Slicing Filename",
    key: "slicingName"
  },
  {
    label: "Video Bite Filename",
    key: "videoBiteName"
  },
  {
    label: "Image Bite Filename",
    key: "imageBiteName"
  },
  {
    label: "Archform Filename",
    key: "archformName"
  },
  {
    label: "Final STL Rahang AtasFilename",
    key: "finalStlRaName"
  },
  {
    label: "Final STL Rahang BawahFilename",
    key: "finalStlRbName"
  },
  {
    label: "Print Type",
    key: "printType"
  },
  {
    label: "Impression Rahang AtasFilename",
    key: "impRaName"
  },
  {
    label: "Impression Rahang BawahFilename",
    key: "impRbName"
  },
  {
    label: "Impression Bite KananFilename",
    key: "impBkaName"
  },
  {
    label: "Impression Bite KiriFilename",
    key: "impBkiName"
  },
  {
    label: "Impression Bite PuttyFilename",
    key: "impBpName"
  },
  {
    label: "Set of RA",
    key: "ra"
  },
  {
    label: "Set of RB",
    key: "rb"
  },
  {
    label: "OC Up",
    key: "ocUp"
  },
  {
    label: "OC Down",
    key: "ocDown"
  },
  {
    label: "Total Aligner",
    key: "totalAligner"
  },
  {
    label: "Doctor Note",
    key: "doctorNote"
  },
  {
    label: "Design Note",
    key: "designerNote"
  },
  {
    label: "Created At",
    key: "createdAt"
  },
  {
    label: "Created By",
    key: "createdBy"
  },
  {
    label: "Updated At",
    key: "updatedAt"
  },
  {
    label: "Updated By",
    key: "updatedBy"
  },
  {
    label: "DoctorApproved At",
    key: "approvedAt"
  },
  {
    label: "DoctorApproved By",
    key: "approvedBy"
  },
  {
    label: "DoctorRevised At",
    key: "revisedAt"
  },
  {
    label: "DoctorRevised By",
    key: "revisedBy"
  },
  {
    label: "DoctorRejected At",
    key: "rejectedAt"
  },
  {
    label: "DoctorRejected By",
    key: "rejectedBy"
  },
  {
    label: "PatientApproved At",
    key: "patientApprovedAt"
  },
  {
    label: "PatientApproved By",
    key: "patientApprovedBy"
  },
  {
    label: "PatientRevised At",
    key: "patientRevisedAt"
  },
  {
    label: "PatientRevised By",
    key: "patientRevisedBy"
  },
  {
    label: "PatientRejected At",
    key: "patientRejectedAt"
  },
  {
    label: "PatientRejected By",
    key: "patientRejectedBy"
  },
  {
    label: "Video Sent Timestamp",
    key: "videoSentTimestamp"
  },
  {
    label: "Problem Set Ra",
    key: "problemSetRa"
  },
  {
    label: "Problem Set Rb",
    key: "problemSetRb"
  },
  {
    label: "Ra Details",
    key: "raDetails"
  },
  {
    label: "Rb Details",
    key: "rbDetails"
  },
  {
    label: "Patient Note",
    key: "patientNote"
  },
  {
    label: "Ag Upper",
    key: "agUpper"
  },
  {
    label: "Ag Lower",
    key: "agLower"
  },
  {
    label: "Ra Rb Remarks",
    key: "raRbRemarks"
  },
  {
    label: "Is Three D Scan File Minimum Format",
    key: "threeDScanFileMinimumFormat"
  },
  {
    label: "Is Three D Scan File Minimum Standard",
    key: "threeDScanFileMinimumStandard"
  },
  {
    label: "Is Putty Scan File",
    key: "puttyScanFile"
  },
  {
    label: "Is Test Fit Patient Confirmation",
    key: "testFitPatientConfirmation"
  },
  {
    label: "Version",
    key: "version"
  },
  {
    label: "Is Active",
    key: "active"
  },
  {
    label: "Is Deleted",
    key: "deleted"
  },
  {
    label: "Total Video Designed by Designer",
    key: "totalVideoUploaded"
  },
  {
    label: "Total Video Rejected by Doctor",
    key: "totalVideoRejectedByDoctor"
  },
  {
    label: "Total Video Request Revise by Doctor",
    key: "totalVideoRevisedByDoctor"
  },
  {
    label: "Total Video Approved by Doctor",
    key: "totalVideoApprovedByDoctor"
  },
  {
    label: "Total Video Rejected by Patient",
    key: "totalVideoRejectedByPatient"
  },
  {
    label: "Total Video Revise by Patient",
    key: "totalVideoRevisedByPatient"
  },
  {
    label: "Total Video Approved by Patient",
    key: "totalVideoApprovedByDoctor"
  },
  {
    label: "Final Video Name_Rejected by Doctor",
    key: "videoRejectedByDoctor"
  },
  {
    label: "Final Video Name_Request Revise by Doctor",
    key: "videoRevisedByDoctor"
  },
  {
    label: "Final Video Name_Approved by Doctor",
    key: "videoApprovedByDoctor"
  },
  {
    label: "Final Video Name_Rejected by Patient",
    key: "videoRejectedByPatient"
  },
  {
    label: "Final Video Name_Request Revise by Patient",
    key: "videoRevisedByPatient"
  },
  {
    label: "Final Video Name_Approved by Patient",
    key: "videoApprovedByPatient"
  },
  {
    label: "Pengecekan Sebelum Design_Timestamp",
    key: "psdTimestamp"
  },
  {
    label: "Rejected - Butuh Treatment_Timestamp",
    key: "rbtTimestamp"
  },
  {
    label: "Sedang Proses Design_Timestamp",
    key: "spdTimestamp"
  },
  {
    label: "Rejected - Design Only_Timestamp",
    key: "rdoTimestamp"
  },
  {
    label: "Sedang Review Dokter_Timestamp",
    key: "srdTimestamp"
  },
  {
    label: "Approved Dokter_Timestamp",
    key: "adTimestamp"
  },
  {
    label: "Sedang Review Pasien_Timestamp",
    key: "srpTimestamp"
  },
  {
    label: "Approved Pasien_Timestamp",
    key: "apTimestamp"
  },
  {
    label: "Sudah Export_Timestamp",
    key: "seTimestamp"
  }
]
