import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import SelectDropdown from 'components/SelectDropdown';
import ActionApi from 'api/action';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setToArray } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getActionList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [flows, setFlows] = useState([]);
  const [states, setStates] = useState([]);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await ActionApi.createAction(
            values.actionId,
            values.actionName,
            values.stateId,
            values.nextStateId,
            values.flowId
          );

          history.push({
            pathname: '/cms/master/action',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Action Data',
            'Error Inserting Action Data!'
          );
          getActionList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  function setCreateEditMessage(response, successMsg, failedMsg) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      message.success(successMsg);
    }
  }

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  return (
    <Modal
      title="Add New Action Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Action Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator(
            'actionId',
            {}
          )(<Input size="large" placeholder="Action ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator(
            'actionName',
            {}
          )(<Input size="large" placeholder="Action Name*" />)}
        </Form.Item>
        <Form.Item label="State">
          {getFieldDecorator(
            'stateId',
            {}
          )(
            <SelectDropdown
              options={stateOptions}
              placeHolder={'Select State'}
            />
          )}
        </Form.Item>
        <Form.Item label="Next State">
          {getFieldDecorator(
            'nextStateId',
            {}
          )(
            <SelectDropdown
              options={stateOptions}
              placeHolder={'Select Next State'}
            />
          )}
        </Form.Item>
        <Form.Item label="Flow">
          {getFieldDecorator(
            'flowId',
            {}
          )(
            <SelectDropdown options={flowOptions} placeHolder={'Select Flow'} />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
