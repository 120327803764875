import React, { useContext } from 'react';
import { Menu, Layout, Typography, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { connect } from 'react-redux';

import iconGigi from '../../assets/img/icon-gigi.svg';
import { AuthenticationContext } from 'contexts/Authentication';
import { toggleSidebar } from 'store/action/component-action/ToggleSidebarAction';

import './style.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

export const Sidebar = ({ location, isSidebarClose }) => {
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const {
    opSupervisorFeature,
    opClinic,
    opViewArchive,
    opViewFlag,
  } = permissionDataList;

  const menuOptions = [
    {
      type: 'Menu',
      name: 'Dashboard',
      link: '/cms/dashboard',
      icon: 'dashboard',
    },

    {
      type: 'Menu',
      name: 'Rata Plus',
      link: '/cms/order/rataplus',
      icon: 'star',
    },
    {
      type: 'Menu',
      name: 'Active',
      link: '/cms/order/active',
      icon: 'carry-out',
    },
    {
      type: 'Menu',
      name: 'Archived',
      link: '/cms/order/archived',
      icon: 'book',
    },
    {
      type: 'Menu',
      name: 'Completed',
      link: '/cms/order/completed',
      icon: 'check-circle',
    },
    {
      type: 'Menu',
      name: 'Flagged',
      link: '/cms/order/flagged',
      icon: 'warning',
    },
    {
      type: 'Menu',
      name: 'BE Error Log',
      link: '/cms/be-error-log',
      icon: 'bug',
    },
    {
      type: 'Menu',
      name: 'Audit Batch',
      link: '/cms/audit-batch',
      icon: 'file-protect',
    },
    {
      type: 'Menu',
      name: 'Refund Request',
      link: '/cms/refund-request',
      icon: 'minus-circle',
    },
    {
      type: 'SubMenu',
      name: 'Simulation Design',
      children: [
        {
          type: 'Menu',
          name: 'Pengecekan Sebelum Design',
          link: '/cms/simulation-design/pengecekan-sebelum-design',
        },
        {
          type: 'Menu',
          name: 'Sedang Proses Design',
          link: '/cms/simulation-design/sedang-proses-design',
        },
        {
          type: 'Menu',
          name: 'Sedang Review Dokter',
          link: '/cms/simulation-design/sedang-review-dokter',
        },
        {
          type: 'Menu',
          name: 'Approved Doctor',
          link: '/cms/simulation-design/approved-doctor',
        },
        {
          type: 'Menu',
          name: 'Reject Butuh Treatment',
          link: '/cms/simulation-design/reject-butuh-treatment',
        },
        {
          type: 'Menu',
          name: 'Reject Design Only',
          link: '/cms/simulation-design/reject-design-only',
        },
        {
          type: 'Menu',
          name: 'Sedang Review Pasien',
          link: '/cms/simulation-design/sedang-review-pasien',
        },
        {
          type: 'Menu',
          name: 'Approved Pasien',
          link: '/cms/simulation-design/approved-pasien',
        },
        {
          type: 'Menu',
          name: 'Sudah Export',
          link: '/cms/simulation-design/sudah-export',
        },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Administration Group',
      icon: 'team',
      children: [
        { type: 'Menu', name: 'User', link: '/cms/administration/user' },
      ],
    },
    {
      type: 'SubMenu',
      name: 'System Group',
      icon: 'control',
      children: [
        { type: 'Menu', name: 'Box', link: '/cms/system/box' },
        { type: 'Menu', name: 'Clinic', link: '/cms/system/clinic' },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Master',
      icon: 'wallet',
      children: [
        { type: 'Menu', name: 'Action', link: '/cms/master/action' },
        { type: 'Menu', name: 'Box Status', link: '/cms/master/box-status' },
        { type: 'Menu', name: 'Flow', link: '/cms/master/flow' },
        {
          type: 'Menu',
          name: 'Order Status',
          link: '/cms/master/order-status',
        },
        { type: 'Menu', name: 'Order Type', link: '/cms/master/order-type' },
        { type: 'Menu', name: 'Shipping', link: '/cms/master/shipping' },
        { type: 'Menu', name: 'State', link: '/cms/master/state' },
        {
          type: 'Menu',
          name: 'Clinic Status',
          link: '/cms/master/clinic-status',
        },
        {
          type: 'Menu',
          name: 'Clinic Sub Status',
          link: '/cms/master/clinic-sub-status',
        },
      ],
    },
    {
      type: 'SubMenu',
      name: 'Transaction',
      icon: 'shopping',
      children: [
        { type: 'Menu', name: 'Box', link: '/cms/transaction/box' },
        {
          type: 'Menu',
          name: 'Order Shipping',
          link: '/cms/transaction/order-shipping',
        },
        {
          type: 'Menu',
          name: 'Order History',
          link: '/cms/transaction/order-history',
        },
      ],
    },
  ];

  const menuNotSupervisorOptions = [
    {
      type: 'Menu',
      name: 'Dashboard',
      link: '/cms/dashboard',
      icon: 'dashboard',
    },
    {
      type: 'SubMenu',
      name: 'Simulation Design',
      children: [
        {
          type: 'Menu',
          name: 'Pengecekan Sebelum Design',
          link: '/cms/simulation-design/pengecekan-sebelum-design',
        },
        {
          type: 'Menu',
          name: 'Sedang Proses Design',
          link: '/cms/simulation-design/sedang-proses-design',
        },
        {
          type: 'Menu',
          name: 'Sedang Review Dokter',
          link: '/cms/simulation-design/sedang-review-dokter',
        },
        {
          type: 'Menu',
          name: 'Approved Doctor',
          link: '/cms/simulation-design/approved-doctor',
        },
        {
          type: 'Menu',
          name: 'Reject Butuh Treatment',
          link: '/cms/simulation-design/reject-butuh-treatment',
        },
        {
          type: 'Menu',
          name: 'Reject Design Only',
          link: '/cms/simulation-design/reject-design-only',
        },
        {
          type: 'Menu',
          name: 'Sedang Review Pasien',
          link: '/cms/simulation-design/sedang-review-pasien',
        },
        {
          type: 'Menu',
          name: 'Approved Pasien',
          link: '/cms/simulation-design/approved-pasien',
        },
        {
          type: 'Menu',
          name: 'Sudah Export',
          link: '/cms/simulation-design/sudah-export',
        },
      ],
    },
  ];

  const menuWithClinicOnlyOptions = [
    {
      type: 'Menu',
      name: 'Dashboard',
      link: '/cms/dashboard',
      icon: 'dashboard',
    },
    {
      type: 'SubMenu',
      name: 'System Group',
      icon: 'control',
      children: [{ type: 'Menu', name: 'Clinic', link: '/cms/system/clinic' }],
    },
    {
      type: 'SubMenu',
      name: 'Simulation Design',
      children: [
        {
          type: 'Menu',
          name: 'Sedang Review Dokter',
          link: '/cms/simulation-design/sedang-review-dokter',
        },
      ],
    },
  ];

  const menuFlagArchived = [
    {
      type: 'Menu',
      name: 'Dashboard',
      link: '/cms/dashboard',
      icon: 'dashboard',
    },
    {
      type: 'Menu',
      name: 'Archived',
      link: '/cms/order/archived',
      icon: 'book',
    },
    {
      type: 'Menu',
      name: 'Flagged',
      link: '/cms/order/flagged',
      icon: 'warning',
    },
  ];

  const renderMenu = (opt) => {
    const {
      name = '',
      link = '',
      type = 'Menu',
      children = [],
      icon = '',
    } = opt;
    if (type === 'Menu') {
      return (
        <Menu.Item
          key={link}
          className={`${isSidebarClose ? '' : 'd-flex align-items-center'} `}>
          {name === 'Simulation Design' ? (
            <Icon
              component={() => (
                <img alt='simdes' className="menu__icon-custom" src={iconGigi} />
              )}
            />
          ) : (
            <Icon type={icon} />
          )}

          <Link to={link}>{name}</Link>
        </Menu.Item>
      );
    } else if (type === 'SubMenu' && name === 'Simulation Design') {
      return (
        <SubMenu
          key={name}
          title={
            <span>
              <Icon
                component={() => (
                  <img alt='simdes' className="menu__icon-custom" src={iconGigi} />
                )}
              />
              <span>{name}</span>
            </span>
          }>
          {children.map((opt) => renderMenu(opt))}
        </SubMenu>
      );
    } else {
      return (
        <SubMenu
          key={name}
          title={
            <span>
              <Icon type={icon} />
              <span>{name}</span>
            </span>
          }>
          {children.map((opt) => renderMenu(opt))}
        </SubMenu>
      );
    }
  };

  const currentPath = get(location, 'pathname');

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={isSidebarClose}
      width="250"
      className="pd-cms-sidebar">
      <div className="wrapper">
        <div className="menu">
          <Title level={3}>
            <Link to={'/'}>
              <div className="logo-container">
                <img
                  src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                  alt="Rata"
                  style={{ width: `${isSidebarClose ? '50%' : '80%'}` }}
                />
              </div>
            </Link>
          </Title>
          <Menu
            mode="inline"
            collapsed={isSidebarClose}
            defaultSelectedKeys={[currentPath]}
            defaultOpenKeys={[
              opSupervisorFeature
                ? get(
                    menuOptions.find(
                      (menu) =>
                        menu.type === 'SubMenu' &&
                        get(menu, 'children', []).find(
                          (child) => child.link === currentPath
                        )
                    ),
                    'name'
                  )
                : opClinic
                ? get(
                    menuWithClinicOnlyOptions.find(
                      (menu) =>
                        menu.type === 'SubMenu' &&
                        get(menu, 'children', []).find(
                          (child) => child.link === currentPath
                        )
                    ),
                    'name'
                  )
                : get(
                    menuNotSupervisorOptions.find(
                      (menu) =>
                        menu.type === 'SubMenu' &&
                        get(menu, 'children', []).find(
                          (child) => child.link === currentPath
                        )
                    ),
                    'name'
                  ),
            ]}>
            {opSupervisorFeature
              ? menuOptions.map((opt) => renderMenu(opt))
              : opClinic
              ? menuWithClinicOnlyOptions.map((opt) => renderMenu(opt))
              : opViewArchive || opViewFlag
              ? menuFlagArchived.map((opt) => renderMenu(opt))
              : menuNotSupervisorOptions.map((opt) => renderMenu(opt))}
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

const mapStateToProps = ({ isSidebarClose }) => ({
  isSidebarClose,
});

export default withRouter(connect(mapStateToProps, { toggleSidebar })(Sidebar));
