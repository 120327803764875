import api from './index';

export default {
  /*
  createState(payload) {
    return api.post(`/state`, { admin: payload });
  },
  */
  /*
  updateState(payload) {
    return api.put(`/state`, { admin: payload });
  },
  */
  listState() {
    return api.get(`/state/false`);
  },

  getPrintGroupList() {
    return api.get(`/state/print-group`);
  },

  listSpecificState(id) {
    return api.get(`/state/${id}/false`);
  },

  listStation() {
    return api.get(`/state/station/false`);
  },

  deleteState(id) {
    return api.delete(`/state/${id}`);
  },

  deleteMultipleState(id) {
    return api.delete(`/state/bulk?stateId=${id}`);
  },

  createState(id, name, slaDays, station, stateRound) {
    return api.post(`/state`, {
      stateId: id,
      stateName: name,
      slaDays: slaDays,
      station: station,
      stateRound: stateRound,
    });
  },

  updateState(id, name, slaDays, station, stateRound) {
    return api.put(`/state`, {
      stateId: id,
      stateName: name,
      slaDays: slaDays,
      station: station,
      stateRound: stateRound,
    });
  },
};
