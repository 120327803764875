import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import ActionApi from 'api/action';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import SelectDropdown from 'components/SelectDropdown';

export function ActionCreateEdit({ form, history }) {
  const [actionById, setActionById] = useState({});
  const [flows, setFlows] = useState([]);
  const [states, setStates] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await ActionApi.updateAction(
            id,
            values.actionName,
            values.stateId,
            values.nextStateId,
            values.flowId
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/action',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Action Data with ID ' + id,
            'Error Updating Action Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  useEffect(() => {
    async function getActionById() {
      const response = await ActionApi.listActionById(id);
      let actionData = setToArray(response.data);

      setActionById(actionData[0]);
    }

    getActionById();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  return (
    <DetailLayout
      detailTime={id}
      detailTitle={'Edit Action Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="ID">
            {getFieldDecorator('actionId', {
              rules: [{ required: true, message: 'Please input action!' }],
              initialValue: actionById && actionById.actionId,
            })(
              <Input
                placeholder="Action ID"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('actionName', {
              rules: [{ required: true, message: 'Please input action name!' }],
              initialValue: actionById && actionById.actionName,
            })(
              <Input
                placeholder="Action Name"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="State">
            {getFieldDecorator('stateId', {
              rules: [{ required: true, message: 'Please input state!' }],
              initialValue: actionById && actionById.stateId,
            })(
              <SelectDropdown
                options={stateOptions}
                placeHolder={'Select State'}
              />
            )}
          </Form.Item>

          <Form.Item label="Next State">
            {getFieldDecorator('nextStateId', {
              rules: [
                { required: true, message: 'Please input next state ID!' },
              ],
              initialValue: actionById && actionById.nextStateId,
            })(
              <SelectDropdown
                options={stateOptions}
                placeHolder={'Select Next State'}
              />
            )}
          </Form.Item>

          <Form.Item label="Flow">
            {getFieldDecorator('flowId', {
              rules: [{ required: true, message: 'Please input flow ID!' }],
              initialValue: actionById && actionById.flowId,
            })(
              <SelectDropdown
                options={flowOptions}
                placeHolder={'Select Flow'}
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const ActionForm = Form.create({
  name: 'validate_create_action',
})(ActionCreateEdit);

export default { ActionCreateEdit };
