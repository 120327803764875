/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Input, Form } from 'antd';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getBeErrorLogById } from 'store/action/be-error-action/GetBeErrorLogByIdAction';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function BeErrorLogDetail({
  form,
  getBeErrorLogById,
  beErrorLogById,
  location,
}) {
  const { getFieldDecorator } = form;
  const { TextArea } = Input;
  const { pathname } = location;
  const logId = pathname.split('/').pop();

  async function getBeErrorLogByIdData() {
    await getBeErrorLogById(logId);
  }

  useEffect(() => {
    getBeErrorLogByIdData();
  }, []);

  return (
    <>
      {!isEmpty(beErrorLogById) && (
        <>
          <DetailLayout
            detailTime={beErrorLogById[0].errorTimestamp}
            detailTitle={'BE Error Log'}
            detailComponent={
              <Form>
                <Form.Item label="Name">
                  {getFieldDecorator('errorTimestamp', {
                    initialValue:
                      beErrorLogById && beErrorLogById[0].errorTimestamp,
                  })(
                    <Input
                      placeholder="Name"
                      size="large"
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="Request Input">
                  {getFieldDecorator('requestInput', {
                    initialValue:
                      beErrorLogById && beErrorLogById[0].requestInput,
                  })(
                    <TextArea
                      placeholder="Request Input"
                      size="large"
                      rows={4}
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="Error Message">
                  {getFieldDecorator('errorDesc', {
                    initialValue: beErrorLogById && beErrorLogById[0].errorDesc,
                  })(
                    <TextArea
                      placeholder="Error Message"
                      size="large"
                      rows={4}
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
              </Form>
            }
          />
        </>
      )}
    </>
  );
}

export const BeErrorLogDetailForm = Form.create({
  name: 'validate_create_log_detail',
})(BeErrorLogDetail);

const mapStateToProps = ({ beErrorLogById }) => ({
  beErrorLogById,
});

export default withRouter(
  connect(mapStateToProps, { getBeErrorLogById })(BeErrorLogDetailForm)
);
