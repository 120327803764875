import React, { useState } from 'react';
import { Modal, Form, Button } from 'antd';

import SelectDropdown from 'components/SelectDropdown';
import InputUpload from 'components/upload/InputWithUpload';

export const ModalImpression = ({
  showModal,
  handleHideModal,
  setButtonState,
  progress1Data,
  setProgress1Data,
  setAnotherButtonState,
  form,
  loadingButton,
  values
}) => {
  const { getFieldDecorator } = form;
  const username = localStorage.getItem('username');
  const [isErrorUpload, setIsErrorUpload] = useState({
    raImpression: false,
    rbImpression: false,
    biteKananImpression: false,
    biteKiriImpression: false,
    biteImpression: false,
  });

  const [isErrorBeforeUpload, setIsErrorBeforeUpload] = useState({
    raImpression: false,
    rbImpression: false,
    biteKananImpression: false,
    biteKiriImpression: false,
    biteImpression: false,
  });

  const handleSubmit = async () => {
    if(values.printType === 0) {
      setProgress1Data({...progress1Data, printType: 0, nextStateId: '1040'});
    }
    if(values.testFit === true && (values.flowId === "Y1" || values.flowId === "Y2")) {
      setProgress1Data({...progress1Data, printType: values.printType, nextStateId: '1061' });
    }
    setAnotherButtonState(1);
    if (progress1Data.raImpression || progress1Data.rbImpression) {
      setButtonState(7);
    }
  };

  const printTypeOptions = [
    { label: '3D Scan', value: 1 },
    { label: 'Putty', value: 0 },
  ];

  return (
    <Modal
      title="Impression"
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmit()}
      onCancel={() => handleHideModal()}
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loadingButton}
          onClick={handleSubmit}>
          Ok
        </Button>,
      ]}>
      <Form>
        <Form.Item label="Jenis Cetakan">
          {getFieldDecorator('printTypeImpression', {
            initialValue: progress1Data.printType === 1 ? 1 : 0,
            // rules: [{ required: true, message: 'Please input Jenis Cetakan!' }],
          })(
            <SelectDropdown
              options={printTypeOptions}
              placeHolder={'Select Jenis Cetakan'}
              disabled={true}
            />
          )}
        </Form.Item>
        <Form.Item label="RA">
          {getFieldDecorator('ra', {
            // rules: [{ required: true, message: 'Please input RA!' }],
          })(
            <InputUpload
              placeholder="Upload RA"
              style={{ width: '80%', marginRight: 8 }}
              setIsErrorUpload={setIsErrorUpload}
              isErrorUpload={isErrorUpload}
              setUploadedIdFile={setProgress1Data}
              setIsErrorBeforeUpload={setIsErrorBeforeUpload}
              isErrorBeforeUpload={isErrorBeforeUpload}
              purpose="raImpression"
              uploadedFileId={progress1Data}
              acceptExtention={'.stl,.zip'}
              withoutInitValue={true}
              actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/impression/${username}`}
            />
          )}
        </Form.Item>
        <Form.Item label="RB">
          {getFieldDecorator('rb', {
            // rules: [{ required: true, message: 'Please input RB!' }],
          })(
            <InputUpload
              placeholder="Upload RB"
              style={{ width: '80%', marginRight: 8 }}
              setIsErrorUpload={setIsErrorUpload}
              isErrorUpload={isErrorUpload}
              setUploadedIdFile={setProgress1Data}
              setIsErrorBeforeUpload={setIsErrorBeforeUpload}
              isErrorBeforeUpload={isErrorBeforeUpload}
              purpose="rbImpression"
              uploadedFileId={progress1Data}
              acceptExtention={'.stl,.zip'}
              withoutInitValue={true}
              actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/impression/${username}`}
            />
          )}
        </Form.Item>
        {progress1Data.printType === 1 ? (
          <>
            {' '}
            <Form.Item label="Bite Kanan">
              {getFieldDecorator('biteKanan', {
                rules: [
                  // { required: true, message: 'Please input Bite Kanan!' },
                ],
              })(
                <InputUpload
                  placeholder="Upload Bite Kanan"
                  style={{ width: '100%', marginRight: 8 }}
                  setIsErrorUpload={setIsErrorUpload}
                  isErrorUpload={isErrorUpload}
                  setUploadedIdFile={setProgress1Data}
                  setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                  isErrorBeforeUpload={isErrorBeforeUpload}
                  purpose="biteKananImpression"
                  uploadedFileId={progress1Data}
                  withoutInitValue={true}
                  acceptExtention={'.stl,.zip'}
                  actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/impression/${username}`}
                />
              )}
            </Form.Item>
            <Form.Item label="Bite Kiri">
              {getFieldDecorator('biteKiri', {
                // rules: [{ required: true, message: 'Please input Bite Kiri!' }],
              })(
                <InputUpload
                  placeholder="Upload Bite Kiri"
                  style={{ width: '100%', marginRight: 8 }}
                  setIsErrorUpload={setIsErrorUpload}
                  isErrorUpload={isErrorUpload}
                  setUploadedIdFile={setProgress1Data}
                  setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                  isErrorBeforeUpload={isErrorBeforeUpload}
                  purpose="biteKiriImpression"
                  uploadedFileId={progress1Data}
                  withoutInitValue={true}
                  acceptExtention={'.stl,.zip'}
                  actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/impression/${username}`}
                />
              )}
            </Form.Item>
          </>
        ) : (
          <Form.Item label="Bite">
            {getFieldDecorator('bite', {
              // rules: [{ required: true, message: 'Please input Bite!' }],
            })(
              <InputUpload
                placeholder="Upload Bite"
                style={{ width: '100%', marginRight: 8 }}
                setIsErrorUpload={setIsErrorUpload}
                isErrorUpload={isErrorUpload}
                setUploadedIdFile={setProgress1Data}
                setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                isErrorBeforeUpload={isErrorBeforeUpload}
                purpose="biteImpression"
                uploadedFileId={progress1Data}
                withoutInitValue={true}
                acceptExtention={'.stl,.zip'}
                actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/impression/${username}`}
              />
            )}
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ModalImpression;
