import LogApi from 'api/log';
import { setToArray } from 'utils';

import { GET_BE_ERROR_LOG_BY_ID } from '../../../type';

export const getBeErrorLogById = (logId) => async (dispatch) => {
  const response = await LogApi.getBeErrorLogById(logId);

  dispatch({
    type: GET_BE_ERROR_LOG_BY_ID,
    payload: setToArray(response.data),
  });
};
