/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { Input, Form, message, Tooltip, Button } from 'antd';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';

import { ShippingContext } from 'contexts/Shipping';
import OrderApi from 'api/order';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function AddressEditComponent({ location, form, history }) {
  const { pathname } = location;
  const shippingId = pathname.split('/').pop();
  const shippingIdOnly = shippingId.split('?')[0];

  const { getFieldDecorator } = form;
  const { TextArea } = Input;
  const urlParams = new URLSearchParams(window.location.search);
  const title = urlParams.get('title');

  const { getOrderShippingById, orderShippingById } =
    useContext(ShippingContext);
  const [orderShippingData] = orderShippingById;

  useEffect(() => {
    async function getShippingDataById() {
      await getOrderShippingById(shippingIdOnly);
    }
    getShippingDataById();
  }, []);

  function setCreateEditMessage(response, successMsg, failedMsg) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      message.success(successMsg);
      history.goBack();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let theResponse;

          theResponse = await OrderApi.updateOrderShipping(
            orderShippingData.osIncId,
            shippingIdOnly,
            '',
            '-',
            values.shippingAddress1,
            '',
            '',
            ''
          );

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Active Address',
            'Error Updating Active Address Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          // setLoadingButton(false);
        }
      }
    });
  }

  return (
    <>
      <DetailLayout
        detailTime={
          !isEmpty(orderShippingData) && orderShippingData.shippingAddress1
        }
        detailTitle={'Active Address'}
        className={'edit-form'}
        detailComponent={
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Form.Item className="edit-form__submit">
              <Tooltip title="Submit">
                <Button size="large" htmlType="submit" icon="check-circle" />
              </Tooltip>
            </Form.Item>
            <Form.Item label="Active Order">
              {getFieldDecorator('id', {
                initialValue: title,
              })(
                <Input
                  placeholder="Active Order"
                  size="large"
                  autoComplete="false"
                  disabled
                />
              )}
            </Form.Item>
            <Form.Item label="Name">
              {getFieldDecorator('shippingAddress1', {
                initialValue:
                  !isEmpty(orderShippingData) &&
                  orderShippingData.shippingAddress1,
              })(
                <TextArea
                  placeholder="Address Name"
                  size="large"
                  rows={4}
                  autoComplete="false"
                />
              )}
            </Form.Item>
          </Form>
        }
      />
    </>
  );
}

export const AddressEditComponentForm = Form.create({
  name: 'validate_edit_address_form',
})(AddressEditComponent);

export default withRouter(AddressEditComponent);
