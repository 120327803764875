import React from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';
import { overdueOptions, printTypeOptions } from '../../../helper';

export const Filter = ({
  resetFilter,
  innerRef,
  onOrderTypeChange,
  onCurrentStateChange,
  onOverdueChange,
  currentStateFilterValue,
  orderTypeFilterValue,
  orderTypeOptions,
  currentStateOptions,
  activeFilter,
  onTestFitChange,
  testFitOptions,
  onPrintTypeChange,
}) => {
  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Order Type'}
            onChange={onOrderTypeChange}
            options={orderTypeOptions}
            selectValue={orderTypeFilterValue.orderOpt}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Current State'}
            onChange={onCurrentStateChange}
            options={currentStateOptions}
            selectValue={currentStateFilterValue.currentOpt}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Overdue'}
            onChange={onOverdueChange}
            options={overdueOptions}
            selectValue={activeFilter.deadlineStatus}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Test Fit'}
            onChange={onTestFitChange}
            options={testFitOptions}
            selectValue={activeFilter.testFitStatus}
          />
        </Col>
        <Col xs={12}>
          <MultiselectDropdown
            placeHolder={'Jenis Cetakan'}
            onChange={onPrintTypeChange}
            options={printTypeOptions}
            selectValue={activeFilter.printTypeString}
          />
        </Col>
      </Row>
    </div>
  );
};

Filter.propTypes = {
  onOrderTypeChange: PropTypes.func,
  onCurrentStateChange: PropTypes.func,
  onOverdueChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
  onPrintTypeChange: PropTypes.func,
};

export default Filter;
