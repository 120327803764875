import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Form, Row, Collapse, Icon, Upload } from 'antd';

const { Dragger } = Upload;

export const FinalSTL = ({ form, orderDetailData }) => {
  const { getFieldDecorator } = form;
  const { Panel } = Collapse;
  const username = localStorage.getItem('username');

  const [fileListRa, setFileListRa] = useState([]);
  const [fileListRb, setFileListRb] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (!isEmpty(orderDetailData) && !isEmpty(orderDetailData.listFinalStlRb)) {
      let fileListDataInit = orderDetailData.listFinalStlRb.map((value) => ({
        uid: value.fileId,
        name: value.fileName,
        status: 'done',
        url: value.fileUrl,
      }));

      form.setFieldsValue({
        finalStlRb: fileListDataInit,
      });
      setFileListRb(fileListDataInit || []);
    }

    if (!isEmpty(orderDetailData) && !isEmpty(orderDetailData.listFinalStlRa)) {
      let fileListDataInit = orderDetailData.listFinalStlRa.map((value) => ({
        uid: value.fileId,
        name: value.fileName,
        status: 'done',
        url: value.fileUrl,
      }));

      form.setFieldsValue({
        finalStlRa: fileListDataInit,
      });

      setFileListRa(fileListDataInit || []);
    }
    // eslint-disable-next-line
  }, [orderDetailData]);

  const handleFinalStlRaChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    form.setFieldsValue({
      finalStlRa: fileList,
    });

    setFileListRa(fileList);
  };

  const handleFinalStlRbChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    form.setFieldsValue({
      finalStlRb: fileList,
    });

    setFileListRb(fileList);
  };

  return (
    <div className="mb-35 simulation-design__final-stl">
      <div className="color-text-primary text-md fw-bold mb-15">Final STL</div>

      <Collapse
        accordion
        className="simulation-design__collapse-form-item mt-20">
        <Panel header="Version 1" key="1">
          {' '}
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="RA">
                {getFieldDecorator('finalStlRa', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                  rules: [
                    {
                      required:
                        orderDetailData.simulationStatusId === 'AP' || false,
                      message: 'Please input Final STL RA!',
                    },
                  ],
                })(
                  <Dragger
                    name="file"
                    accept=".stl"
                    multiple={true}
                    openFileDialogOnClick={
                      orderDetailData.simulationStatusId === 'AP' || false
                    }
                    onChange={handleFinalStlRaChange}
                    className="dnd-multiple-upload"
                    action={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/final-stl-ra/${username}`}>
                    {isEmpty(fileListRa) && (
                      <div className="dnd-multiple-upload__wrap-item">
                        <div>
                          <p className="ant-upload-drag-icon">
                            <Icon type="file-add" />
                          </p>
                          <div className="ant-upload-text text-base">
                            Drop files
                          </div>
                          <div className="ant-upload-text text-base">
                            Final STL RA
                          </div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RB">
                {getFieldDecorator('finalStlRb', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                  rules: [
                    {
                      required:
                        orderDetailData.simulationStatusId === 'AP' || false,
                      message: 'Please input Final STL RB!',
                    },
                  ],
                })(
                  <Dragger
                    name="file"
                    accept=".stl"
                    multiple={true}
                    openFileDialogOnClick={
                      orderDetailData.simulationStatusId === 'AP' || false
                    }
                    onChange={handleFinalStlRbChange}
                    className="dnd-multiple-upload"
                    action={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/final-stl-rb/${username}`}>
                    {isEmpty(fileListRb) && (
                      <div className="dnd-multiple-upload__wrap-item">
                        <div>
                          <p className="ant-upload-drag-icon">
                            <Icon type="file-add" />
                          </p>
                          <div className="ant-upload-text text-base">
                            Drop files
                          </div>
                          <div className="ant-upload-text text-base">
                            Final STL RB
                          </div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};


export default connect()(Form.create()(FinalSTL));
