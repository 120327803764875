import { GET_BE_ERROR_LOG_BY_ID } from '../../../type';

export const beErrorLogById = (state = [], action) => {
  switch (action.type) {
    case GET_BE_ERROR_LOG_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
