import {
  GET_LIST_STATION_ORDER,
  GET_LIST_DASHBOARD_STATION_ORDER,
} from '../../../type';

export const listStationOrder = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_STATION_ORDER:
      return action.payload;
    default:
      return state;
  }
};

export const listDashboardStationOrder = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_DASHBOARD_STATION_ORDER:
      return action.payload;
    default:
      return state;
  }
};
