import React from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

export const ActiveFilter = ({
  activeFilterData,
  setActiveFilter,
  setFilterShow,
}) => {
  const removeEachFilter = (param, value) => (e) => {
    e.preventDefault();

    switch (param) {
      case 'active-order':
        setActiveFilter({
          ...activeFilterData,
          activeOrder: activeFilterData.activeOrder.filter(
            (order) => order !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'flow-status':
        setActiveFilter({
          ...activeFilterData,
          flowStatus: activeFilterData.flowStatus.filter(
            (flow) => flow !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'shipping-courier':
        setActiveFilter({
          ...activeFilterData,
          shippingCourier: activeFilterData.shippingCourier.filter(
            (shippingCourier) => shippingCourier !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'shipping-address':
        setActiveFilter({
          ...activeFilterData,
          shippingAddress: activeFilterData.shippingAddress.filter(
            (shippingAddress) => shippingAddress !== value
          ),
        });
        setFilterShow(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="active-filter d-flex">
      {!isEmpty(activeFilterData.activeOrder) &&
        activeFilterData.activeOrder.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('active-order', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.flowStatus) &&
        activeFilterData.flowStatus.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('flow-status', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.shippingAddress) &&
        activeFilterData.shippingAddress.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('shipping-address', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.shippingCourier) &&
        activeFilterData.shippingCourier.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('shipping-courier', value)}
            />
          </div>
        ))}
    </div>
  );
};

ActiveFilter.propTypes = {
  activeFilterData: PropTypes.object,
  setActiveFilter: PropTypes.func,
  setFilterShow: PropTypes.func,
};

export default ActiveFilter;
