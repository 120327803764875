import React from 'react';
import { Modal, Input, Form, Button } from 'antd';

export const ModalResolve = ({
  showModal,
  handleHideModalResolve,
  setButtonState,
  form,
}) => {
  const { TextArea } = Input;
  const { getFieldDecorator } = form;

  const handleSubmitModalResolve = () => {
    setButtonState(3);
  };

  return (
    <Modal
      title="Resolve Order"
      centered
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmitModalResolve()}
      onCancel={() => handleHideModalResolve()}
      footer={[
        <Button key="back" onClick={handleHideModalResolve}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModalResolve}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">
        Input Resolution Note to resolve flagged order
      </div>
      <Form>
        <Form.Item>
          {getFieldDecorator('userNoteResolveDialog', {
            initialValue: '',
          })(<TextArea size="large" placeholder="Resolution Note" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalResolve;
