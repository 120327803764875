import api from './index';

export default {
  /** order status **/
  createOrderStatus(id, desc) {
    return api.post(`/order/status`, {
      orderStatusId: id,
      orderStatusDesc: desc,
    });
  },

  updateOrderStatus(id, desc) {
    return api.put(`/order/status`, {
      orderStatusId: id,
      orderStatusDesc: desc,
    });
  },

  listOrderStatus() {
    return api.get(`/order/status/false`);
  },

  getOrderStatusById(id) {
    return api.get(`/order/status/${id}/false`);
  },

  deleteOrderStatus(id) {
    return api.delete(`/order/status/${id}`);
  },

  deleteMultipleOrderStatus(id) {
    return api.delete(`/order/status/bulk?orderStatusId=${id}`);
  },

  /** order type **/
  createOrderType(id, desc, flowId) {
    return api.post(`/order/type`, {
      orderType: id,
      orderDesc: desc,
      flowId: flowId,
    });
  },

  updateOrderType(id, desc, flowId) {
    return api.put(`/order/type`, {
      orderType: id,
      orderDesc: desc,
      flowId: flowId,
    });
  },

  listOrderType() {
    return api.get(`/order/type/false`);
  },

  getOrderTypeById(id) {
    return api.get(`/order/type/${id}/false`);
  },

  deleteOrderType(id) {
    return api.delete(`/order/type/${id}`);
  },

  deleteMultipleOrderType(id) {
    return api.delete(`/order/type/bulk?orderType=${id}`);
  },

  /** order shipping **/
  createOrderShipping(
    shippingReceiptNumber1,
    shippingId1,
    shippingAddress1,
    shippingReceiver1,
    shippingAt1
  ) {
    return api.post(`/order/shipping`, {
      shippingReceiptNumber1: shippingReceiptNumber1,
      shippingId1: shippingId1,
      shippingAddress1: shippingAddress1,
      shippingReceiver1: shippingReceiver1,
      shippingAt1: shippingAt1,
    });
  },

  updateOrderShipping(
    osIncId,
    id,
    shippingReceiptNumber1,
    shippingId1,
    shippingAddress1,
    shippingReceiver1,
    shippingAt1
  ) {
    return api.put(`/order/shipping`, {
      osIncId: osIncId,
      orderShippingId: id,
      shippingReceiptNumber1: shippingReceiptNumber1,
      shippingId1: shippingId1,
      shippingAddress1: shippingAddress1,
      shippingReceiver1: shippingReceiver1,
      shippingAt1: shippingAt1,
    });
  },

  addOrderShipping(payload) {
    return api.post(`/order/shipping`, payload);
  },

  listOrderShipping() {
    return api.get(`/order/shipping/false`);
  },

  listOrderShippingById(id) {
    return api.get(`/order/shipping/${id}/false`);
  },

  deleteOrderShipping(id) {
    return api.delete(`/order/shipping/${id}`);
  },

  deleteMultipleOrderShipping(id) {
    return api.delete(`/order/shipping/bulk?osIncId=${id}`);
  },

  /** order detail **/
  createOrderDetail(
    id,
    orderReceiptNumber,
    customerId,
    stateId,
    flowId,
    orderType,
    orderStatusId,
    boxNumber,
    updatedBy,
    orderShippingId,
    clinicId,
    orderDeadline,
    // customerNote,
    systemNote,
    userNote,
    removalNote,
    totalUp,
    totalDown,
    ocUp,
    ocDown,
    agUp,
    agDown,
    printCount,
    delivCount
  ) {
    return api.post(`/order/detail`, {
      orderId: id,
      orderReceiptNumber: orderReceiptNumber,
      customerId: customerId,
      stateId: stateId,
      flowId: flowId,
      orderType: orderType,
      orderStatusId: orderStatusId,
      boxNumber: boxNumber,
      updatedBy: updatedBy,
      orderShippingId: orderShippingId,
      clinicId: clinicId,
      orderDeadline: orderDeadline,
      // customerNote: customerNote,
      systemNote: systemNote,
      userNote: userNote,
      removalNote: removalNote,
      totalUp: totalUp,
      totalDown: totalDown,
      ocUp: ocUp,
      ocDown: ocDown,
      agUp: agUp,
      agDown: agDown,
      printCount: printCount,
      delivCount: delivCount,
    });
  },

  initializeOrder(orderType, customerName, primaryOrderNumber, updatedBy) {
    return api.post(`/order/initializing`, {
      orderType: orderType,
      customerName: customerName,
      orderReceiptNumber: primaryOrderNumber,
      updatedBy: updatedBy,
    });
  },

  updateOrderDetail(
    id,
    orderReceiptNumber,
    customerId,
    customerName,
    stateId,
    flowId,
    orderType,
    orderStatusId,
    boxNumber,
    updatedBy,
    orderShippingId,
    clinicId,
    orderDeadline,
    // customerNote,
    customerPhone,
    systemNote,
    userNote,
    removalNote,
    totalUp,
    totalDown,
    ocUp,
    ocDown,
    agUp,
    agDown,
    printCount,
    delivCount,
    attachment,
    slicing,
    printType,
    printDownRemaining,
    printUpRemaining,
    delivDownRemaining,
    delivUpRemaining,
    round,
    appointmentTime,
    testFit,
    flagged,
    removalRequested,
    stateFromClinic,
    flagNote,
    appointmentSv2Timestamp,
    ocChecklist,
    raRbRemarks
  ) {
    return api.put(`/order/detail`, {
      orderId: id,
      orderReceiptNumber: orderReceiptNumber,
      customerId: customerId,
      customerName: customerName,
      stateId: stateId,
      flowId: flowId,
      orderStatusId: orderStatusId,
      boxNumber: boxNumber,
      updatedBy: updatedBy,
      orderShippingId: orderShippingId,
      clinicId: clinicId,
      orderDeadline: orderDeadline,
      // customerNote: customerNote,
      customerPhone: customerPhone,
      systemNote: systemNote,
      userNote: userNote,
      removalNote: removalNote,
      totalUp: totalUp,
      totalDown: totalDown,
      ocUp: ocUp,
      ocDown: ocDown,
      agUp: agUp,
      agDown: agDown,
      printCount: printCount,
      delivCount: delivCount,
      attachment: attachment,
      slicing: slicing,
      printType: printType,
      printDownRemaining: printDownRemaining,
      printUpRemaining: printUpRemaining,
      delivDownRemaining: delivDownRemaining,
      delivUpRemaining: delivUpRemaining,
      round: round,
      appointmentTime: appointmentTime,
      testFit: testFit,
      flagged: flagged,
      removalRequested: removalRequested,
      stateFromClinic: stateFromClinic,
      flagNote: flagNote,
      appointmentSv2Timestamp: appointmentSv2Timestamp,
      ocChecklist: ocChecklist,
      raRbRemarks: raRbRemarks,
      // is_test_fit: is_test_fit,
    });
  },

  progressOrder(
    id,
    stateId,
    nextStateId,
    flowId,
    orderType,
    updatedBy,
    consultationTime,
    appointmentTime,
    totalUp,
    totalDown,
    ocUp,
    ocDown,
    agUp,
    agDown,
    attachment,
    slicing,
    clinicId,
    shippingAddress,
    shippingId,
    // customerNote,
    customerPhone,
    systemNote,
    diyDeliveryTime,
    testFit,
    boxNumber,
    isStateFromClinic,
    delivUp1,
    delivUp2,
    delivDown1,
    delivDown2,
    printUp1,
    printUp2,
    printDown1,
    printDown2,
    printType,
    appointmentSv2Timestamp,
    ocChecklist,
    videoSentTimestamp,
    raRbRemarks
  ) {
    return api.put(`/order/progress`, {
      orderId: id,
      stateId: stateId,
      nextStateId: nextStateId,
      flowId: flowId,
      orderType: orderType,
      updatedBy: updatedBy,
      consultationTime: consultationTime,
      appointmentTime: appointmentTime,
      totalUp: totalUp,
      totalDown: totalDown,
      ocUp: ocUp,
      ocDown: ocDown,
      agUp: agUp,
      agDown: agDown,
      attachment: attachment,
      slicing: slicing,
      clinicId: clinicId,
      shippingAddress: shippingAddress,
      shippingId: shippingId,
      // customerNote: customerNote,
      customerPhone: customerPhone,
      systemNote: systemNote,
      diyDeliveryTime: diyDeliveryTime,
      testFit: testFit,
      boxNumber: boxNumber,
      stateFromClinic: isStateFromClinic,
      delivUp1: parseInt(delivUp1),
      delivUp2: parseInt(delivUp2),
      delivDown1: parseInt(delivDown1),
      delivDown2: parseInt(delivDown2),
      printUp1: parseInt(printUp1),
      printUp2: parseInt(printUp2),
      printDown1: parseInt(printDown1),
      printDown2: parseInt(printDown2),
      printType: printType,
      appointmentSv2Timestamp: appointmentSv2Timestamp,
      ocChecklist: ocChecklist,
      videoSentTimestamp: videoSentTimestamp,
      raRbRemarks: raRbRemarks,
    });
  },

  getResetHistory(id) {
    return api.get(`/revert/by-id/${id}`);
  },

  progressRevert(id, orderType, updatedBy) {
    return api.post(`/revert/start`, {
      orderId: id,
      orderType: orderType,
      updatedBy: updatedBy,
    });
  },

  updateOrderDetailStatus(id, flagNote) {
    return api.put(`/order/detail/flagging`, {
      orderId: id,
      flagNote: flagNote,
      userNote: null,
      updatedBy: localStorage.getItem('username'),
    });
  },

  updateOrderDetailStatusResolve(id, userNote) {
    return api.put(`/order/detail/resolve`, {
      orderId: id,
      userNote: userNote,
      updatedBy: localStorage.getItem('username'),
    });
  },

  updateOrderDetailStatusArchived(id, userNote) {
    return api.put(`/order/detail/archived`, {
      orderId: id,
      userNote: userNote,
      updatedBy: localStorage.getItem('username'),
    });
  },

  updateOrderDetailStatusReactive(id, userNote) {
    return api.put(`order/detail/reactivate`, {
      orderId: id,
      userNote: userNote,
      updatedBy: localStorage.getItem('username'),
    });
  },

  createRefinement(id, updatedBy) {
    return api.post(`/order/refinement`, {
      orderId: id,
      updatedBy: updatedBy,
    });
  },

  getRefinementHistory(soNumber, refinementGroup) {
    return api.get(
      `order/refinement/history/${soNumber}/${refinementGroup}?limit=1000000000&offset=0`
    );
  },

  listOrderDetail() {
    return api.get(`/order/detail/false`);
  },

  listOrderDetailById(id) {
    return api.get(`/order/detail/${id}/false`);
  },

  deleteOrderDetail(id) {
    return api.delete(`/order/detail/${id}`);
  },

  deleteOrderBulk(orderId) {
    return api.delete(`/order/detail/bulk?orderId=${orderId}`);
  },

  /** order history **/
  createOrderHistory(
    id,
    orderId,
    prevFlowId,
    thisFlowId,
    prevOrderType,
    thisOrderType,
    prevStateId,
    thisStateId,
    prevOrderStatusId,
    thisOrderStatusId,
    createdBy
  ) {
    return api.post(`/order/history`, {
      orderHistoryId: id,
      orderId: orderId,
      prevFlowId: prevFlowId,
      thisFlowId: thisFlowId,
      prevOrderType: prevOrderType,
      thisOrderType: thisOrderType,
      prevStateId: prevStateId,
      thisStateId: thisStateId,
      prevOrderStatusId: prevOrderStatusId,
      thisOrderStatusId: thisOrderStatusId,
      createdBy: createdBy,
    });
  },

  updateOrderHistory(
    id,
    orderId,
    prevFlowId,
    thisFlowId,
    prevOrderType,
    thisOrderType,
    prevStateId,
    thisStateId,
    prevOrderStatusId,
    thisOrderStatusId,
    createdBy
  ) {
    return api.put(`/order/history`, {
      orderHistoryId: id,
      orderId: orderId,
      prevFlowId: prevFlowId,
      thisFlowId: thisFlowId,
      prevOrderType: prevOrderType,
      thisOrderType: thisOrderType,
      prevStateId: prevStateId,
      thisStateId: thisStateId,
      prevOrderStatusId: prevOrderStatusId,
      thisOrderStatusId: thisOrderStatusId,
      createdBy: createdBy,
    });
  },

  listOrderHistory() {
    return api.get(`/order/history/false`);
  },

  getOrderHistoryById(id) {
    return api.get(`/order/history/${id}/false`);
  },

  listOrderHistoryById(id) {
    return api.get(`/order/history/logs/${id}/false`);
  },

  deleteOrderHistory(id) {
    return api.delete(`/order/history/${id}`);
  },

  deleteMultipleOrderHistory(id) {
    return api.delete(`/order/history/bulk?orderHistoryId=${id}`);
  },

  /** order all **/

  getArchivedOrder(availableState, designState) {
    return api.get(
      `/order/dashboard/archived-order?designState=${designState}&availableState=${availableState}`
    );
  },
  getCompletedOrder(availableState, limit, offset, keyword) {
    return api.get(
      `/order/dashboard/completed-order?limit=${limit}&offset=${offset}&keyword=${keyword}&availableState=${availableState}`
    );
  },

  getActiveOrder(availableState) {
    return api.get(`/order/dashboard/P?availableState=${availableState}`);
  },

  getFlaggedOrder(availableState) {
    return api.get(`/order/dashboard/F?availableState=${availableState}`);
  },

  getSummaryDashboard(availableState) {
    return api.get(`/order/summary-station?availableState=${availableState}`);
  },

  getSummaryDashboardRataPlus(availableState) {
    return api.get(
      `/order/summary-station-plus?availableState=${availableState}`
    );
  },

  getSummaryDashboardByDate(date, availableState) {
    return api.get(
      `/order/summary-station/${date}?availableState=${availableState}`
    );
  },

  getStationOrder(availableState) {
    return api.get(`/order/station-order?availableState=${availableState}`);
  },

  getDashboardStationOrder(availableState) {
    return api.get(
      `/order/dashboard-station-order?limit=0&offset=0&availableState=${availableState}`
    );
  },

  getOverdueOrder(availableState) {
    return api.get(`/order/overdue?availableState=${availableState}`);
  },

  getOverdueOrderNumber(availableState) {
    return api.get(`/order/overdue-count?availableState=${availableState}`);
  },

  getOverdueOrderById(id) {
    return api.get(`/order/overdue/${id}`);
  },

  getCustomerName(id) {
    return api.get(`/order/overdue/${id}`);
  },

  getReportByOrderStatusId(id, availableState) {
    return api.get(`order/report/${id}?availableState=${availableState}`);
  },

  getRefundRequest() {
    return api.get(`order/refund-request`);
  },

  getActiveOrderListById(id) {
    return api.get(`/order/active/${id}`);
  },

  postOrderTroubleshootTrigger(payload) {
    return api.post(`order/refinement/trigger`, payload);
  },

  confirmCetakUlang(payload) {
    let body = {
      orderId: payload.orderId,
      updatedBy: payload.updatedBy,
    };
    return api.put(`order/confirm-cetak-ulang`, body);
  },

  upgradeDpAction(orderId) {
    let body = {
      orderId: orderId,
      updatedBy: localStorage.getItem('username'),
    };
    return api.put(`order/upgrade-dp-rata`, body);
  },
};
