import React, { useState, useRef } from 'react';

import TableGlobal from 'components/Table';
import { withRouter } from 'react-router';

import { tableHistoryColumns } from '../../helper/';

export const HistoryStation = ({ listStationHistory, location }) => {
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const { pathname, search } = location;

  const handleOnRowPathname = () => {
    return `${pathname}${search}`;
  };

  return (
    <TableGlobal
      tableName={'History Station'}
      classCustom="order-detail__table panel--table-history"
      inputPlaceholder={'Search History Station Here...'}
      pageSize={8}
      originalData={listStationHistory || []}
      activeFilter={[{}]}
      setActiveFilter={{}}
      tableColumns={tableHistoryColumns}
      exportCsvIsExist={false}
      editableTable={false}
      activeFilterComponent={<></>}
      isFilter={false}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      innerRef={innerRef}
      onRowPathname={handleOnRowPathname}
    />
  );
};

export default withRouter(HistoryStation);
