import React from 'react';
import { Col, Form, Input, Row } from 'antd';

function NonCetakToDesignDetail({
  getFieldDecorator,
  values,
  opFieldAgDown,
  opFieldAgUp,
  opFieldOcDown,
  opFieldOcUp,
  opFieldTotalDown,
  opFieldTotalUp,
}) {
  return (
    <Row gutter={15}>
      <Col span={6}>
        <Form.Item label="Total Atas (UP)">
          {getFieldDecorator('totalUp', {
            initialValue: values && values.totalUp,
          })(
            <Input
              placeholder="Total Atas (UP)"
              autoComplete="false"
              size="large"
              disabled={opFieldTotalUp ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Total Bawah (DOWN)">
          {getFieldDecorator('totalDown', {
            initialValue: values && values.totalDown,
          })(
            <Input
              placeholder="Total Bawah (DOWN)"
              autoComplete="false"
              size="large"
              disabled={opFieldTotalDown ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="AG Atas (UP)">
          {getFieldDecorator('agUp', {
            initialValue: values && values.agUp,
          })(
            <Input
              placeholder="AG Atas (UP)"
              autoComplete="false"
              size="large"
              disabled={opFieldAgUp ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="AG Bawah (DOWN)">
          {getFieldDecorator('agDown', {
            initialValue: values && values.agDown,
          })(
            <Input
              placeholder="AG Bawah (DOWN)"
              autoComplete="false"
              size="large"
              disabled={opFieldAgDown ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="OC Atas (UP)">
          {getFieldDecorator('ocUp', {
            initialValue: values && values.ocUp,
          })(
            <Input
              placeholder="OC Atas (UP)"
              autoComplete="false"
              size="large"
              disabled={opFieldOcUp ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="OC Bawah (DOWN)">
          {getFieldDecorator('ocDown', {
            initialValue: values && values.ocDown,
          })(
            <Input
              placeholder="OC Bawah (DOWN)"
              autoComplete="false"
              size="large"
              disabled={opFieldOcDown ? false : true}
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Print Atas Remaining">
          {getFieldDecorator('printUpRemaining', {
            initialValue: values && values.printUpRemaining,
          })(
            <Input
              placeholder="Print Atas Remaining"
              autoComplete="false"
              size="large"
              disabled
            />
          )}
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Print Bawah Remaining">
          {getFieldDecorator('printDownRemaining', {
            initialValue: values && values.printDownRemaining,
          })(
            <Input
              placeholder="Print Bawah Remaining"
              autoComplete="false"
              size="large"
              disabled
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  );
}

export default NonCetakToDesignDetail;
