import {
  GET_REFINEMENT_HISTORY_LIST,
  GET_RESET_HISTORY_LIST,
} from '../../type';

export const refinementHistoryList = (state = [], action) => {
  switch (action.type) {
    case GET_REFINEMENT_HISTORY_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const resetHistoryList = (state = [], action) => {
  switch (action.type) {
    case GET_RESET_HISTORY_LIST:
      return action.payload;
    default:
      return state;
  }
};