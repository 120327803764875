import React, { useContext } from 'react';
import { Input, Form } from 'antd';
import { isEmpty } from 'lodash';

import DetailLayout from 'components/detail-layout/DetailReadOnly';
import { AuditBatchContext } from 'contexts/AuditBatch';

export function AuditBatchDetail({ form }) {
  const { auditByIdData } = useContext(AuditBatchContext || {});
  const { getFieldDecorator } = form;

  return (
    <>
      {!isEmpty(auditByIdData) && (
        <>
          <DetailLayout
            detailTime={auditByIdData[0].auditIdLabel}
            detailTitle={'Audit Batch'}
            detailComponent={
              <Form>
                <Form.Item label="Name">
                  {getFieldDecorator('auditIdLabel', {
                    initialValue:
                      auditByIdData && auditByIdData[0].auditIdLabel,
                  })(
                    <Input
                      placeholder="Name"
                      size="large"
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="Audited State">
                  {getFieldDecorator('auditState', {
                    initialValue: auditByIdData && auditByIdData[0].auditState,
                  })(
                    <Input
                      placeholder="Audited State"
                      size="large"
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="Timestamp">
                  {getFieldDecorator('auditTimestamp', {
                    initialValue:
                      auditByIdData && auditByIdData[0].auditTimestamp,
                  })(
                    <Input
                      placeholder="Timestamp"
                      size="large"
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
              </Form>
            }
          />
        </>
      )}
    </>
  );
}

export const AuditBatchDetailForm = Form.create({
  name: 'validate_create_audit_batch_detail',
})(AuditBatchDetail);

export default AuditBatchDetail;
