import ClinicNoteApi from 'api/clinic-note';
import { setToArray } from 'utils';

import {
  GET_LIST_CLINIC_NOTE_BY_ID,
  GET_DETAIL_CLINIC_NOTE_BY_ID,
  GET_LIST_CLINIC_NOTE,
  GET_STATUS_CLINIC_NOTE,
  GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID,
} from '../../type';

export const getClinicNoteById = (id) => async (dispatch) => {
  const response = await ClinicNoteApi.getClinicNoteById(id);

  dispatch({
    type: GET_LIST_CLINIC_NOTE_BY_ID,
    payload: setToArray(response.data),
  });
};

export const getClinicNoteDetailById = (id) => async (dispatch) => {
  const response = await ClinicNoteApi.getClinicNoteDetailById(id);

  dispatch({
    type: GET_DETAIL_CLINIC_NOTE_BY_ID,
    payload: setToArray(response.data),
  });
};

export const getListClinicNote = () => async (dispatch) => {
  const response = await ClinicNoteApi.getClinicNoteList();

  dispatch({
    type: GET_LIST_CLINIC_NOTE,
    payload: setToArray(response.data),
  });
};

export const getStatusClinicNote = (id) => async (dispatch) => {
  const response = await ClinicNoteApi.getClinicNoteStatus(id);

  dispatch({
    type: GET_STATUS_CLINIC_NOTE,
    payload: setToArray(response.data),
  });
};

export const getClinicNoteSubStatusById = (id) => async (dispatch) => {
  const response = await ClinicNoteApi.getClinicNoteSubStatusById(id);

  dispatch({
    type: GET_STATUS_CLINIC_NOTE_SUB_STATUS_BY_ID,
    payload: setToArray(response.data),
  });
};
