import React from 'react';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';
import './styles/index.scss';
import {
  flaggedOptions,
  overdueOptions,
  printTypeOptions,
} from '../../helper/index';

export const FilterDashboard = ({
  onOrderTypeChange,
  onCurrentStateChange,
  onOverdueChange,
  onFlaggedChange,
  onPrintTypeChange,
  orderTypeOptions,
  testFitOptions,
  onTestFitChange,
  currentStateOptions,
  resetFilter,
  orderTypeSelectValue,
  currentStateSelectValue,
  overdueSelectValue,
  flaggedSelectValue,
  testFitSelectValue,
  printTypeSelectValue,
  innerRef,
}) => {
  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Order Type'}
            onChange={onOrderTypeChange}
            options={orderTypeOptions}
            selectValue={orderTypeSelectValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Current State'}
            onChange={onCurrentStateChange}
            options={currentStateOptions}
            selectValue={currentStateSelectValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Overdue'}
            onChange={onOverdueChange}
            options={overdueOptions}
            selectValue={overdueSelectValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Flagged'}
            onChange={onFlaggedChange}
            options={flaggedOptions}
            selectValue={flaggedSelectValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Test Fit'}
            onChange={onTestFitChange}
            options={testFitOptions}
            selectValue={testFitSelectValue}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Jenis Cetakan'}
            onChange={onPrintTypeChange}
            options={printTypeOptions}
            selectValue={printTypeSelectValue}
          />
        </Col>
      </Row>
    </div>
  );
};

FilterDashboard.propTypes = {
  onOrderTypeChange: PropTypes.func,
  onCurrentStateChange: PropTypes.func,
  onOverdueChange: PropTypes.func,
  onFlaggedChange: PropTypes.func,
  onPrintTypeChange: PropTypes.func,
};

export default FilterDashboard;
