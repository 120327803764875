import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import { setToArray } from 'utils';
import OrderApi from 'api/order';
import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';

import { tableOrderHistoryColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function OrderHistoryList() {
  const [orderHistories, setOrderHistories] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });

  const [rowSelected, setRowSelected] = useState([]);
  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  async function getOrderHistoryList() {
    try {
      const response = await OrderApi.listOrderHistory();
      let orderHistoryList = setToArray(response.data);

      setOrderHistories(orderHistoryList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getOrderHistoryList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getOrderHistoryId = rowSelected.map((value) => value.orderHistoryId);
    const getOrderHistoryIdToString = getOrderHistoryId.join(',');

    confirm({
      title: `Are you sure delete this order history data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then order history data with id ${getOrderHistoryIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingOrderHistory() {
          try {
            await OrderApi.deleteMultipleOrderHistory(
              getOrderHistoryIdToString
            );
            setRowSelected([]);
            getOrderHistoryList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingOrderHistory();
      },
      onCancel() {},
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/transaction/order-history/edit?id=${record.orderHistoryId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Order History'}
          inputPlaceholder={'Search Order History Here...'}
          pageSize={8}
          originalData={orderHistories}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableOrderHistoryColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          recordKey={(record) => record.orderHistoryId}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getOrderHistoryList={getOrderHistoryList}
      />
    </Layout>
  );
}

export default { OrderHistoryList };
