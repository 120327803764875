import React, { useState, useEffect, useRef, useContext } from 'react';
import { Layout, Modal, message } from 'antd';

import { AuditBatchContext } from 'contexts/AuditBatch';
import { AuthenticationContext } from 'contexts/Authentication';

import TableGlobal from 'components/Table';

import { headerCsv, tableColumns } from './helper';
import ActiveFilter from './components/ActiveFilter';
import Filter from './components/Filter';

const { Content } = Layout;
const { confirm } = Modal;

export function AuditBatch() {
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState({
    auditState: [], //auditState
  });

  const { auditBatchList, deleteAuditBatch, getAuditBatchList } = useContext(
    AuditBatchContext || {}
  );
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opEditData } = permissionDataList;
  const [rowSelected, setRowSelected] = useState([]);

  useEffect(() => {
    getAuditBatchList();
    // eslint-disable-next-line
  }, []);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleDeleteRow = async () => {
    const getAuditId = rowSelected.map((value) => value.auditId);
    const getAuditIdToString = getAuditId.join(',');

    confirm({
      title: `Are you sure delete this audit batch data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then audit batch data with id ${getAuditIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingAuditBatch() {
          try {
            await deleteAuditBatch(getAuditIdToString);
            setRowSelected([]);
            getAuditBatchList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingAuditBatch();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
      />
    );
  };

  const handleOnRowPathname = (record) => {
    return `/cms/audit-batch/${record.auditId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Audit Batch'}
          inputPlaceholder={'Search Audit Batch Here...'}
          pageSize={8}
          headerCsv={headerCsv}
          originalData={auditBatchList}
          csvName={'audit-batch-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={opEditData ? true : false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          recordKey={(record) => record.auditId}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
        />
      </Content>
    </Layout>
  );
}

export default AuditBatch;
