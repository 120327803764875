import React from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

export const ActiveFilterClinicNotes = ({
  activeFilterData,
  setActiveFilter,
  setFilterShow,
}) => {
  const removeEachFilter = (param, value) => (e) => {
    e.preventDefault();

    switch (param) {
      case 'created-note':
        setActiveFilter({
          ...activeFilterData,
          createdAt: '',
        });
        setFilterShow(false);
        break;
      case 'fu-note':
        setActiveFilter({
          ...activeFilterData,
          fuTimestamp: '',
        });
        setFilterShow(false);
        break;
      case 'status-note':
        setActiveFilter({
          ...activeFilterData,
          noteStatusDesc: activeFilterData.noteStatusDesc.filter(
            (statusNote) => statusNote !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'state-note':
        setActiveFilter({
          ...activeFilterData,
          stateId: activeFilterData.stateId.filter(
            (stateNote) => stateNote !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'agent-note':
        setActiveFilter({
          ...activeFilterData,
          createdBy: activeFilterData.createdBy.filter(
            (agentNote) => agentNote !== value
          ),
        });
        setFilterShow(false);
        break;

      default:
        break;
    }
  };

  return (
    <div className="active-filter d-flex">
      {!isEmpty(activeFilterData.fuTimestamp) && (
        <div className="d-flex active-filter__item mr-10">
          <div className="mr-10 text-sm">
            {activeFilterData.fuTimestamp}
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('fu-note')}
            />
          </div>
        </div>
      )}
      {!isEmpty(activeFilterData.createdAt) && (
        <div className="d-flex active-filter__item mr-10">
          <div className="mr-10 text-sm">
            {activeFilterData.createdAt}
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('created-note')}
            />
          </div>
        </div>
      )}
      {!isEmpty(activeFilterData.noteStatusDesc) &&
        activeFilterData.noteStatusDesc.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('status-note', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.stateId) &&
        activeFilterData.stateId.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('state-note', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.createdBy) &&
        activeFilterData.createdBy.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('agent-note', value)}
            />
          </div>
        ))}
    </div>
  );
};

ActiveFilterClinicNotes.propTypes = {
  activeFilterData: PropTypes.object,
  setActiveFilter: PropTypes.func,
  setFilterShow: PropTypes.func,
};

export default ActiveFilterClinicNotes;
