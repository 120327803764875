import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Layout, Button, Row, Col } from 'antd';
import { startCase } from 'lodash';
import { connect } from 'react-redux';

import { useOuterClickNotifier } from 'utils/hooks';
import { AuthenticationContext } from 'contexts/Authentication';
import { toggleSidebar } from 'store/action/component-action/ToggleSidebarAction';

const { Header } = Layout;

export function HeaderComponent({ isSidebarClose, toggleSidebar }) {
  const [userShow, setUserShow] = useState(false);
  const innerRef = useRef(null);
  const handleUserShow = () => {
    setUserShow(!userShow);
  };

  const toggleCollapsed = () => {
    toggleSidebar(!isSidebarClose);
  };

  const { permissionDataList } = useContext(AuthenticationContext || {});

  const { opSupervisorFeature } = permissionDataList;

  const user_email = localStorage.getItem('user_email');

  async function handleLogout() {
    const cms_token = localStorage.getItem('cms_token');
    const role_id = localStorage.getItem('role_id');
    const username = localStorage.getItem('username');
    const available_state = localStorage.getItem('available_state');

    if (cms_token || role_id || username || available_state || user_email) {
      localStorage.removeItem('cms_token');
      localStorage.removeItem('role_id');
      localStorage.removeItem('username');
      localStorage.removeItem('available_state');
      localStorage.removeItem('user_email');
    }
  }

  useOuterClickNotifier(handleUserShow, innerRef);

  return (
    <Header className="header">
      {/* add custom css, for mozilla browser */}
      <Row className="d-flex">
        <Col span={12}>
          <Button onClick={toggleCollapsed}>
            <Icon type={isSidebarClose ? 'menu-unfold' : 'menu-fold'} />
          </Button>
        </Col>
        <Col
          span={12}
          type="flex"
          align="end"
          className="d-flex justify-content-flex-end align-items-center">
          <Button onClick={handleUserShow}>
            <div className="d-flex align-items-center">
              <Icon type="user" className="mr-5" />
              <div className="fw-medium mr-5">
                {localStorage.getItem('username') === null
                  ? 'Username'
                  : startCase(localStorage.getItem('username'))}
              </div>
              <Icon type="down" className="header__icon header__icon--small" />
            </div>
          </Button>
        </Col>
      </Row>
      {userShow && (
        <div className="header__user-content" ref={innerRef}>
          <ul className="mb-0">
            {opSupervisorFeature && (
              <li className="mb-10">
                <Link to={`/cms/administration/user/${user_email}`}>
                  <Icon type="setting" className="mr-10" />
                  Setting
                </Link>
              </li>
            )}

            <li>
              <Link onClick={handleLogout} to={'/'}>
                <Icon type="logout" className="mr-10" />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      )}
    </Header>
  );
}

const mapStateToProps = ({ isSidebarClose }) => ({
  isSidebarClose,
});

export default connect(mapStateToProps, { toggleSidebar })(HeaderComponent);
