import React, { useState, useEffect } from 'react';
import {
  Modal,
  Input,
  Form,
  Button,
  message,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { withRouter } from 'react-router';

import OrderApi from 'api/order';
import ShippingApi from 'api/shipping';
import SelectDropdown from 'components/SelectDropdown';
import { setCreateEditMessage, setToArray } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getOrderShippingList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;
  const [shippings, setShippings] = useState([]);

  function isObjectDate(date) {
    let theDate;
    if (typeof date !== 'undefined' && date !== null) {
      date instanceof Date
        ? (theDate =
            date.getFullYear() +
            '-' +
            parseInt(date.getMonth() + 1) +
            '-' +
            date.getDate())
        : (theDate =
            date.substring(6, 10) +
            '-' +
            date.substring(3, 5) +
            '-' +
            date.substring(0, 2));
    } else {
      theDate = undefined;
    }
    return theDate;
  }

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.createOrderShipping(
            values.shippingReceiptNumber1,
            values.shippingId1,
            values.shippingAddress1,
            values.shippingReceiver1,
            values.shippingAt1
              ? isObjectDate(values['shippingAt1'].format('DD-MM-YYYY'))
              : null
          );

          history.push({
            pathname: '/cms/transaction/order-shipping',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Order Shipping Data',
            'Error Inserting Order Shipping  Data!'
          );

          getOrderShippingList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    async function getShippingList() {
      const response = await ShippingApi.listShipping();
      let shippingList = setToArray(response.data);

      setShippings(shippingList);
    }

    getShippingList();
  }, []);

  const shippingOptions = shippings.map((value) => ({
    label: value.shippingId,
    value: value.shippingName,
  }));

  return (
    <Modal
      title="Add New Order Shipping Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input Order Shipping Data.</div>
      <Form>
        <Row gutter={20}>
          <Col span={12}>
            {' '}
            <Form.Item label="Receipt Number 1">
              {getFieldDecorator(
                'shippingReceiptNumber1',
                {}
              )(
                <Input
                  placeholder="Receipt Number 1"
                  size="large"
                  autoComplete="false"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Address 1">
              {getFieldDecorator(
                'shippingAddress1',
                {}
              )(
                <Input
                  placeholder="Address 1"
                  size="large"
                  autoComplete="false"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Receiver 1">
              {getFieldDecorator(
                'shippingReceiver1',
                {}
              )(
                <Input
                  placeholder="Receiver 1"
                  size="large"
                  autoComplete="false"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Shipping At 1">
              {getFieldDecorator(
                'shippingAt1',
                {}
              )(
                <DatePicker
                  size="large"
                  showTime
                  placeholder="Click to select a date"
                  format="DD-MM-YYYY"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item label="Shipping Courier 1">
              {getFieldDecorator('shippingId1', {
                rules: [
                  {
                    required: true,
                    message: 'Please input shipping courier 1!',
                  },
                ],
              })(
                <SelectDropdown
                  options={shippingOptions}
                  placeHolder={'Select Shipping Courier'}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
