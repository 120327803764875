import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty, isNull } from 'lodash';
import { Col, Form, Row, Collapse, message } from 'antd';

import SelectDropdown from 'components/SelectDropdown';
import InputUploadReadOnly from 'components/upload/InputWithUploadReadOnly';
import { getListImpressionSimulationHistory } from 'store/action/SimulationDesignAction';

//Impression & Impression History

export const Impression = ({
  form,
  values,
  listImpressionHistoryData,
  getListImpressionSimulationHistory,
}) => {
  const { getFieldDecorator } = form;
  const { Panel } = Collapse;

  const printTypeOptions = [
    { label: '3D Scan', value: 1 },
    { label: 'Putty', value: 0 },
  ];

  async function getListImpressionHistoryData() {
    const convertSoNumber = values.orderReceiptNumber
      ? values.orderReceiptNumber.split('/')[0]
      : '';
    try {
      await getListImpressionSimulationHistory(convertSoNumber);
    } catch (err) {
      if (err.response) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      // setLoadingPage(false);
    }
  }

  useEffect(() => {
    getListImpressionHistoryData();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="color-text-primary text-md fw-bold mb-20">Impression</div>
      <Row gutter={12}>
        <Col span={24}>
          <Form.Item
            label="Jenis Cetakan"
            className="simulation-design__form-item--mb-20">
            {getFieldDecorator('printType', {
              initialValue: !isNull(values.printType) ? values.printType : null,
            })(
              <SelectDropdown
                disabled={true}
                options={printTypeOptions}
                placeHolder={'Select Jenis Cetakan'}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="RA">
            {getFieldDecorator('ra', {
              initialValue: !isEmpty(values.impRaId) ? values.impRaId : '',
            })(
              <InputUploadReadOnly
                placeholder="Upload RA"
                style={{ width: '80%', marginRight: 8 }}
                purpose=""
                uploadedFileId={values.impRaId || ''}
                uploadedFileName={values.impRaName || ''}
                uploadedFileUrl={values.impRaUrl || ''}
                acceptExtention=".stl"
                disabled={true}
                isDownload={true}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="RB">
            {getFieldDecorator(
              'rb',{
                  initialValue: !isEmpty(values.impRbId) ? values.impRbId : '',
                }
            )(
              <InputUploadReadOnly
                placeholder="Upload RB"
                style={{ width: '80%', marginRight: 8 }}
                purpose=""
                uploadedFileId={values.impRbId || ''}
                uploadedFileName={values.impRbName || ''}
                uploadedFileUrl={values.impRbUrl || ''}
                acceptExtention=".stl"
                isDownload={true}
                disabled={true}
              />
            )}
          </Form.Item>
        </Col>
        {values.printType === 1 ? (
          <>
            <Col span={6}>
              <Form.Item label="Bite Kanan">
                {getFieldDecorator(
                  'biteKanan',{
                      initialValue: !isEmpty(values.impBkaId) ? values.impBkaId : '',
                    }
                )(
                  <InputUploadReadOnly
                    placeholder="Upload Bite Kanan"
                    style={{ width: '100%', marginRight: 8 }}
                    purpose=""
                    uploadedFileId={values.impBkaId || ''}
                    uploadedFileName={values.impBkaName || ''}
                    uploadedFileUrl={values.impBkaUrl || ''}
                    disabled={true}
                    acceptExtention=".stl"
                    isDownload={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Bite Kiri">
                {getFieldDecorator(
                  'biteKiri',{
                      initialValue: !isEmpty(values.impBkiId) ? values.impBkiId : '',
                    }
                )(
                  <InputUploadReadOnly
                    placeholder="Upload Bite Kiri"
                    style={{ width: '100%', marginRight: 8 }}
                    purpose=""
                    uploadedFileId={values.impBkiId || ''}
                    uploadedFileName={values.impBkiName || ''}
                    uploadedFileUrl={values.impBkiUrl || ''}
                    disabled={true}
                    acceptExtention=".stl"
                    isDownload={true}
                  />
                )}
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={6}>
            <Form.Item label="Bite">
              {getFieldDecorator(
                'bite', {
                    initialValue: !isEmpty(values.impBpId) ? values.impBpId : '',
                  }
              )(
                <InputUploadReadOnly
                  placeholder="Upload Bite"
                  style={{ width: '100%', marginRight: 8 }}
                  purpose=""
                  uploadedFileId={values.impBpId || ''}
                  uploadedFileName={values.impBpId || ''}
                  uploadedFileUrl={values.impBpId || ''}
                  disabled={true}
                  acceptExtention=".stl"
                  isDownload={true}
                />
              )}
            </Form.Item>
          </Col>
        )}
      </Row>

      <Collapse accordion className="order-detail_delivery mb-35">
        <Panel header="Impression History" key="1">
          {listImpressionHistoryData.map((value, index) => (
            <div key={index}>
              <div className="color-text-primary text-base fw-medium mb-15">
                {`${index + 1}. ${value.printType} - ${value.createdAt}`}
              </div>
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item label="RA">
                    {getFieldDecorator(
                      'ra',{
                          initialValue: !isEmpty(values.impRaId) ? values.impRaId : '',
                        }
                    )(
                      <InputUploadReadOnly
                        placeholder="Upload RA"
                        style={{ width: '80%', marginRight: 8 }}
                        purpose=""
                        uploadedFileId={value.impRaId || ''}
                        uploadedFileName={value.impRaName || ''}
                        uploadedFileUrl={value.impRaUrl || ''}
                        isDownload={true}
                        disabled={true}
                        acceptExtention=".stl"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="RB">
                    {getFieldDecorator(
                      'rb',{
                          initialValue: !isEmpty(values.impRbId) ? values.impRbId : '',
                        }
                    )(
                      <InputUploadReadOnly
                        placeholder="Upload RB"
                        style={{ width: '80%', marginRight: 8 }}
                        purpose=""
                        uploadedFileId={value.impRbId || ''}
                        uploadedFileName={value.impRbName || ''}
                        uploadedFileUrl={value.impRbUrl || ''}
                        isDownload={true}
                        disabled={true}
                        acceptExtention=".stl"
                      />
                    )}
                  </Form.Item>
                </Col>
                {value.printType === 1 || value.printType === "3D Scan"  ? (
                  <>
                    <Col span={6}>
                      <Form.Item label="Bite Kanan">
                        {getFieldDecorator(
                          'biteKanan',{
                              initialValue: !isEmpty(values.impBkaId) ? values.impBkaId : '',
                            }
                        )(
                          <InputUploadReadOnly
                            placeholder="Upload Bite Kanan"
                            style={{ width: '100%', marginRight: 8 }}
                            purpose=""
                            uploadedFileId={value.impBkaId || ''}
                            uploadedFileName={value.impBkaName || ''}
                            uploadedFileUrl={value.impBkaUrl || ''}
                            isDownload={true}
                            disabled={true}
                            acceptExtention=".stl"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Bite Kiri">
                        {getFieldDecorator(
                          'biteKiri',{
                              initialValue: !isEmpty(values.impBkiId) ? values.impBkiId : '',
                            }
                        )(
                          <InputUploadReadOnly
                            placeholder="Upload Bite Kiri"
                            style={{ width: '100%', marginRight: 8 }}
                            purpose=""
                            uploadedFileId={value.impBkiId || ''}
                            uploadedFileName={value.impBkiName || ''}
                            uploadedFileUrl={value.impBkiUrl || ''}
                            isDownload={true}
                            disabled={true}
                            acceptExtention=".stl"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <Col span={6}>
                    <Form.Item label="Bite">
                      {getFieldDecorator(
                        'bite',{
                            initialValue: !isEmpty(values.impBpId) ? values.impBpId : '',
                          }
                      )(
                        <InputUploadReadOnly
                          placeholder="Upload Bite"
                          style={{ width: '100%', marginRight: 8 }}
                          purpose=""
                          uploadedFileId={value.impBpId || ''}
                          uploadedFileName={value.impBpName || ''}
                          uploadedFileUrl={value.impBpUrl || ''}
                          isDownload={true}
                          disabled={true}
                          acceptExtention=".stl"
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>
          ))}
        </Panel>
      </Collapse>
    </>
  );
};

const mapStateToProps = ({ listImpressionHistoryData }) => ({
  listImpressionHistoryData,
});

export default connect(mapStateToProps, {
  getListImpressionSimulationHistory,
})(Form.create()(Impression));
