import React from 'react';
// import { Button } from 'antd';
// import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

export const ActiveFilter = ({
  activeFilterData,
  setActiveFilter,
  setFilterShow,
}) => {
  // const removeEachFilter = (param, value) => (e) => {
  //   e.preventDefault();

  //   switch (param) {
  //     case 'order-type':
  //       setActiveFilter({
  //         ...activeFilterData,
  //         orderType: activeFilterData.orderType.filter(
  //           (orderType) => orderType !== value
  //         ),
  //       });
  //       setFilterShow(false);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  return (
    <div className="active-filter d-flex">
      {/* {!isEmpty(activeFilterData.orderType) &&
        activeFilterData.orderType.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('order-type', value)}
            />
          </div>
        ))} */}
    </div>
  );
};

ActiveFilter.propTypes = {
  activeFilterData: PropTypes.object,
  setActiveFilter: PropTypes.func,
  setFilterShow: PropTypes.func,
};

export default ActiveFilter;
