import { Form, Modal, Input } from 'antd';
import React from 'react';

function ModalPatientRevision({
  isModalPatientOpen,
  handleOnCancel,
  handleOnOk,
  valRevisionPatient,
  form,
}) {
  const { getFieldDecorator } = form;
  return (
    <Modal
      destroyOnClose
      visible={isModalPatientOpen}
      onCancel={() => handleOnCancel((val) => !val)}
      onOk={(val) => handleOnOk(val)}
      title="Revision Reason">
      {valRevisionPatient}
      <br></br>
      <Form.Item label="Revision Notes from Patient">
        {getFieldDecorator('patientApprovalNoteVal', {
          initialValue: valRevisionPatient || '',
        })(
          <Input.TextArea
            placeholder="Revision Notes from Patient"
            size="large"
            rows={4}
            autoComplete="false"
          />
        )}
      </Form.Item>
    </Modal>
  );
}

export default ModalPatientRevision;
