import api from './index';

export default {
  /*
  createFlow(payload) {
    return api.post(`/flow`, { admin: payload });
  },
  updateFlow(payload) {
    return api.put(`/flow`, { admin: payload });
  },
  */
  listFlow() {
    return api.get(`/flow/false`);
  },

  flowById(id) {
    return api.get(`/flow/${id}/false`);
  },

  createFlow(id, name, startStateId, endStateId, withTestFit) {
    return api.post(`/flow`, {
      flowId: id,
      flowName: name,
      startStateId: startStateId,
      endStateId: endStateId,
      withTestFit: withTestFit,
    });
  },

  updateFlow(id, name, startStateId, endStateId, withTestFit) {
    return api.put(`/flow`, {
      flowId: id,
      flowName: name,
      startStateId: startStateId,
      endStateId: endStateId,
      withTestFit: withTestFit,
    });
  },

  deleteFlow(id) {
    return api.delete(`/flow/${id}`);
  },

  deleteMultipleFlow(id) {
    return api.delete(`/flow/bulk?flowId=${id}`);
  },
};
