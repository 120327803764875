import React from 'react';
import { Modal, Form, Button } from 'antd';
import SelectDropdown from 'components/SelectDropdown';
// import { isEmpty } from 'lodash';

export const ModalSingleForm = ({
  showModal,
  handleHideModal,
  handleSubmitModal,
  form,
  idContainer,
  modalTitle,
  modalDesc,
  fieldDecoratorName,
  inputComponent,
  options,
  initValue,
  values,
  buttonProgressClicked,
  maxNumberValid2,
  minNumberValid2,
  progress1Data,
}) => {
  const { getFieldDecorator } = form;
  // const checkFlowType = values.flowType === '3' ? 3 : 7;

  // console.log(progress1Data);

  const checkModalUpName =
      fieldDecoratorName.includes('printUp');
    // const checkModalDownName =
    //   fieldDecoratorName.includes('printDown');

    // const checkModalPrintType = fieldDecoratorName.includes('print');
    const checkModalDelivName = fieldDecoratorName.includes('deliv');

  const checkMinValueValidation =
    // deliv --> always refer to print
    checkModalDelivName
    ?
      fieldDecoratorName.includes('1')
      ?
        checkModalUpName
        ?
          // 1074 deliv up (both type)
          values.printUp1
        :
          // 1074 deliv down (both type)
          values.printDown1
      :
        checkModalUpName
        ?
          // 1094 deliv up (both type)
          values.printUp2
        :
          // 1094 deliv down (both type)
          values.printDown2
    :
      // print
      fieldDecoratorName.includes('2')
      ?
        // 1092
        checkModalUpName
        ?
          // 1092 print up (both type)
          values.printUpRemaining
        :
          // 1092 print down (both type)
          values.printDownRemaining
      :
        // 1072
        checkModalUpName
        ?
          // 1072 UP
          (values.flowId.includes('G') || values.flowId.includes('I') || values.flowId.includes('Y'))
          ?
            // 1072 UP - TYPE RATA
            0
          :
            // 1072 UP - TYPE PAYG
            minNumberValid2
        :
          // 1072 DOWN
          (values.flowId.includes('G') || values.flowId.includes('I') || values.flowId.includes('Y'))
          ?
            // 1072 DOWN - TYPE RATA
            10 - (progress1Data && progress1Data.printUp1)
          :
            // 1072 DOWN - TYPE PAYG
            minNumberValid2
    ;

  const checkMaxValueValidation =
    // deliv --> always refer to print
    checkModalDelivName
    ?
      fieldDecoratorName.includes('1')
      ?
        checkModalUpName
        ?
          // 1074 deliv up (both type)
          values.printUp1
        :
          // 1074 deliv down (both type)
          values.printDown1
      :
        checkModalUpName
        ?
          // 1094 deliv up (both type)
          values.printUp2
        :
          // 1094 deliv down (both type)
          values.printDown2
    :
      // print
      fieldDecoratorName.includes('2')
      ?
        // 1092
        checkModalUpName
        ?
          // 1092 print up (both type)
          values.printUpRemaining
        :
          // 1092 print down (both type)
          values.printDownRemaining
      :
        // 1072
        checkModalUpName
        ?
          // 1072 UP
          (values.flowId.includes('G') || values.flowId.includes('I') || values.flowId.includes('Y'))
          ?
            // 1072 UP - TYPE RATA
            10
          :
            // 1072 UP - TYPE PAYG
            maxNumberValid2
        :
          // 1072 DOWN
          (values.flowId.includes('G') || values.flowId.includes('I') || values.flowId.includes('Y'))
          ?
            // 1072 DOWN - TYPE RATA
            10 - (progress1Data && progress1Data.printUp1)
          :
            // 1072 DOWN - TYPE PAYG
            maxNumberValid2
    ;

  return (
    <Modal
      title={modalTitle}
      centered
      visible={showModal}
      getContainer={() => document.getElementById(idContainer)}
      onOk={() => handleSubmitModal()}
      onCancel={() => handleHideModal()}
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModal}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">{modalDesc}</div>
      <Form>
        {fieldDecoratorName.includes('systemNoteRedoDialog') && (
          <Form.Item label="Reason">
            {getFieldDecorator('reason', {
              initialValue: '',
            })(
              <SelectDropdown options={options} placeHolder={'Select Reason'} />
            )}
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator(fieldDecoratorName, {
            ...(fieldDecoratorName.includes('print')
              ? {
                  getValueFromEvent: (e) => {
                    const convertedValue = Number(e.currentTarget.value);
                    if (isNaN(convertedValue)) {
                      return Number(form.getFieldValue(fieldDecoratorName));
                    } else {
                      return convertedValue;
                    }
                  },
                }
              : ''),
            rules: fieldDecoratorName.includes('print')
              ? [
                  {
                    required: buttonProgressClicked ? true : false,
                    type: 'number',
                    // max: checkMaxValueValidation,
                    // min: checkMinValueValidation,
                    message: `Jumlah ${`Print ${
                      fieldDecoratorName.includes('Down') ? 'Bawah' : 'Atas'
                    }`} ${
                      (values.stateId === '1092' || values.stateId === '1094i' || values.stateId === '1094o') ||
                      (values.flowId === 'B1' || values.flowId === 'B2' || values.flowId === 'D1' || values.flowId === 'D2') ||
                      ((values.flowId === 'G1' || values.flowId === 'G2' || values.flowId === 'I1' || values.flowId === 'I2' || values.flowId === 'Y1' || values.flowId === 'Y2') && values.stateId === '1072' && fieldDecoratorName.includes('printDown')) ||
                      ((values.flowId === 'G1' || values.flowId === 'G2' || values.flowId === 'I1' || values.flowId === 'I2' || values.flowId === 'Y1' || values.flowId === 'Y2') && (values.stateId === '1074i' && values.stateId === '1074o'))
                        ? ' must be '
                        : ' cannot be greater than '
                    }${checkMaxValueValidation}!`,
                  },
                ]
              : [],
            initialValue: initValue,
          })(inputComponent)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalSingleForm;
