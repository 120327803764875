import React, { useState } from 'react';
import { Col, Row, Button, Tooltip, Input } from 'antd';

function TableDataTitle({ tableName, handleSearch }) {
  const [showInput, setShowInput] = useState(false);

  const handleToggleSearch = (val) => {
    setShowInput(!val)
    handleSearch('')
  };

  return (
    <Row gutter={20} type="flex" className="table-global__action-item">
      <Col xs={12}>
        <div className="mb-15">
          {showInput ? (
            <div className="table-global__search">
              <Input.Search
                className="table-global__search-input"
                placeholder="Phone/Email/Name"
                onSearch={(val) => handleSearch(val)}
                autoFocus
              />
              <Button
                onClick={() => handleToggleSearch(showInput)}
                icon="close"
                className="position-absolute table-global__search-close"
              />
            </div>
          ) : (
            <strong className="text-md table-global__title">{tableName}</strong>
          )}
        </div>
      </Col>
      <Col xs={12} type="flex" align="end">
        <Tooltip title="Search">
          <Button
            onClick={() => handleToggleSearch(showInput)}
            icon="search"
            className="mr-10"
          />
        </Tooltip>
      </Col>
    </Row>
  );
}

export default TableDataTitle;
