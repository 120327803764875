import api from './index';

export default {
  /*
  createBoxStatus(payload) {
    return api.post(`/box/status`, { admin: payload });
  },
  updateBoxStatus(payload) {
    return api.put(`/box/status`, { admin: payload });
  },
  */
  createBoxStatus(id, desc) {
    return api.post(`/box/status`, {
      boxStatusId: id,
      boxStatusDesc: desc,
    });
  },

  updateBoxStatus(id, desc) {
    return api.put(`/box/status`, {
      boxStatusId: id,
      boxStatusDesc: desc,
    });
  },

  listBoxStatus() {
    return api.get(`/box/status/false`);
  },

  boxStatusById(id) {
    return api.get(`/box/status/${id}/false`);
  },

  deleteBoxStatus(id) {
    return api.delete(`/box/status/${id}`);
  },

  deleteMultipleBoxStatus(id) {
    return api.delete(`/box/status/bulk?boxStatusId=${id}`);
  },

  /*
  createBox(payload) {
    return api.post(`/box`, { admin: payload });
  },
  updateBox(payload) {
    return api.put(`/box`, { admin: payload });
  },
  */
  createBox(id, desc, updated_by) {
    return api.post(`/box/data`, {
      boxNumber: id,
      boxStatusId: desc,
      updatedBy: updated_by,
    });
  },

  updateBox(id, desc, updated_by) {
    return api.put(`/box/data`, {
      boxNumber: id,
      boxStatusId: desc,
      updatedBy: updated_by,
    });
  },

  listBox() {
    return api.get(`/box/data/false`);
  },

  getBoxById(boxNumber) {
    return api.get(`/box/data/${boxNumber}/false`);
  },

  deleteBox(id) {
    return api.delete(`/box/data/${id}`);
  },

  deleteMultipleBox(id) {
    return api.delete(`/box/data/bulk?boxNumber=${id}`);
  },
};
