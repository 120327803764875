import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import SelectDropdown from 'components/SelectDropdown';
import BoxApi from 'api/box';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function BoxCreateEdit({ form, history }) {
  const [boxById, setBoxById] = useState({});
  const [boxStatus, setBoxStatus] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const username = localStorage.getItem('username');
  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await BoxApi.updateBox(
            id,
            values.boxStatusId,
            username
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/transaction/box',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Box Data with ID ' + id,
            'Error Updating Box Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getBoxStatusList() {
      const response = await BoxApi.listBoxStatus();
      let boxStatusList = setToArray(response.data);

      setBoxStatus(boxStatusList);
    }

    getBoxStatusList();
  }, []);

  useEffect(() => {
    async function getBoxById() {
      const response = await BoxApi.getBoxById(id);
      let boxData = setToArray(response.data);

      setBoxById(boxData[0]);
    }

    getBoxById();
    // eslint-disable-next-line
  }, []);

  const boxStatusOptions = boxStatus.map((value) => ({
    label: `${value.boxStatusId} - ${value.boxStatusDesc}`,
    value: value.boxStatusId,
  }));

  return (
    <DetailLayout
      detailTime={`${id}`}
      detailTitle={'Edit Box Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="Box Number">
            {getFieldDecorator('boxNumber', {
              rules: [{ required: true, message: 'Please input Box Number!' }],
              initialValue: boxById && boxById.boxNumber,
            })(
              <Input
                placeholder="Box Number"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Box Status ID">
            {getFieldDecorator('boxStatusId', {
              rules: [
                { required: true, message: 'Please input box status ID!' },
              ],
              initialValue: boxById && boxById.boxStatusId,
            })(
              <SelectDropdown
                options={boxStatusOptions}
                placeHolder={'Select Box Status'}
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const BoxForm = Form.create({
  name: 'validate_create_box',
})(BoxCreateEdit);

export default { BoxCreateEdit };
