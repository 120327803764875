export const headerSimDesHistoryAssignedCsv = [
  {
    label: "ID",
    key: "simulationId"
  },
  {
    label: "Doctor Name",
    key: "doctorName"
  },
  {
    label: "Video Name",
    key: "videoName"
  },
  {
    label: "Designer Name",
    key: "designerName"
  },
  {
    label: "Admin Designer Name",
    key: "adminDesignerName"
  },
  {
    label: "Updated Designer Timestamp",
    key: "doctorTimestamp"
  },
  {
    label: "Updated Designer Timestamp",
    key: "designerTimestamp"
  },
  {
    label: "Updated Designer Timestamp",
    key: "adminDesignerTimestamp"
  },
]
