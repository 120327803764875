import api from './index';

export default {
  getClinicNoteById(id) {
    return api.get(`/clinic-note/order/by-order/${id}/false`);
  },

  getClinicNoteDetailById(id) {
    return api.get(`/clinic-note/order/${id}/false`);
  },

  getClinicNoteStatus(id) {
    return api.get(`clinic-note/master/by-state/${id}/false`);
  },

  getClinicNoteSubStatus() {
    return api.get(`clinic-note/sub-master/sub/false`);
  },

  getClinicNoteSubStatusById(id) {
    return api.get(`clinic-note/sub-master/by-id/${id}/false`);
  },

  createClinicNote(
    orderId,
    stateId,
    noteStatusId,
    noteSubStatusId,
    note,
    fuTimestamp,
    createdBy
  ) {
    return api.post(`/clinic-note/order`, {
      orderId: orderId,
      stateId: stateId,
      noteStatusId: noteStatusId,
      noteSubStatusId: noteSubStatusId,
      note: note,
      fuTimestamp: fuTimestamp,
      createdBy: createdBy,
    });
  },

  updateClinicNote(
    noteId,
    orderId,
    stateId,
    noteStatusId,
    noteSubStatusId,
    note,
    fuTimestamp,
    updatedBy
  ) {
    return api.put(`/clinic-note/order`, {
      noteId: noteId,
      orderId: orderId,
      stateId: stateId,
      noteStatusId: noteStatusId,
      noteSubStatusId: noteSubStatusId,
      note: note,
      fuTimestamp: fuTimestamp,
      updatedBy: updatedBy,
    });
  },

  deleteClinicNote(id, deletedBy) {
    return api.delete(`/clinic-note/order/${id}/${deletedBy}`);
  },

  deleteMultipleClinicNote(id, deletedBy) {
    return api.delete(`/clinic-note/order/bulk/${deletedBy}?noteId=${id}`);
  },

  filterClinicNote(reportedDate, fuDate, status, state) {
    return api.get(
      `/clinic-note/order/filter/${reportedDate}/${fuDate}/${status}/${state}`
    );
  },

  getClinicNoteList() {
    return api.get(`/clinic-note/master/false`);
  },

  getClinicNoteOrder() {
    return api.get(`/clinic-note/order/false?limit=1000000`);
  },

  deleteClinicNoteStatus(noteStatusId) {
    return api.delete(`/clinic-note/master/${noteStatusId}`);
  },

  deleteBulkClinicNoteStatus(id) {
    return api.delete(`/clinic-note/master/bulk?noteStatusId=${id}`);
  },

  updateClinicNoteStatus(noteStatusId, noteStatusDesc, stateId) {
    return api.put(`/clinic-note/master`, {
      noteStatusId: noteStatusId,
      noteStatusDesc: noteStatusDesc,
      stateId: stateId,
    });
  },

  createClinicNoteStatus(noteStatusId, noteStatusDesc, stateId) {
    return api.post(`/clinic-note/master`, {
      noteStatusId: noteStatusId,
      noteStatusDesc: noteStatusDesc,
      stateId: stateId,
    });
  },

  getClinicStatusById(noteStatusId) {
    return api.get(`/clinic-note/master/${noteStatusId}/false`);
  },

  getClinicSubStatusList() {
    return api.get(`/clinic-note/sub-master/sub/false`);
  },

  updateClinicSubStatus(noteSubStatusId, noteSubStatusDesc, parentID) {
    return api.put(`/clinic-note/sub-master`, {
      noteSubStatusId: noteSubStatusId,
      noteSubStatusDesc: noteSubStatusDesc,
      noteStatusId: parentID,
    });
  },

  createClinicSubStatus(noteSubStatusId, noteSubStatusDesc, parentID) {
    return api.post(`/clinic-note/sub-master`, {
      noteSubStatusId: noteSubStatusId,
      noteSubStatusDesc: noteSubStatusDesc,
      noteStatusId: parentID,
    });
  },

  getClinicSubStatusById(noteSubStatusId) {
    return api.get(`/clinic-note/sub-master/${noteSubStatusId}/false`);
  },

  deleteBulkClinicSubStatus(id) {
    return api.delete(`/clinic-note/sub-master/bulk?noteSubStatusId=${id}`);
  },
};
