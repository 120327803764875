import React, { useContext } from 'react';
import { Modal, Input, Form, Button } from 'antd';

import { ShippingContext } from 'contexts/Shipping';

const { TextArea } = Input;

export const ModalAddAddress = ({
  form,
  shippingId,
  showAddAddress,
  handleHideAddAddress,
  orderShippings,
  setShowAddAddress,
}) => {
  const { getFieldDecorator } = form;
  const { addOrderShipping, getOrderShippingById } =
    useContext(ShippingContext);

  const getSpecificShippingData = orderShippings.find(
    (value) => value.orderShippingId === shippingId
  );

  const handleSubmitAddAddress = () => {
    form.validateFields(async (err, values) => {
      await addOrderShipping({
        orderShippingId: shippingId,
        shippingReceiptNumber1:
          getSpecificShippingData.shippingReceiptNumber1 || '',
        shippingId1: '-',
        shippingAddress1: values.shippingAddress1,
        shippingReceiver1: getSpecificShippingData.shippingReceiver1 || '',
        shippingAt1: '',
      });
      await getOrderShippingById(shippingId);
    });

    setShowAddAddress(false);
  };

  return (
    <Modal
      title="New Address"
      centered
      visible={showAddAddress}
      onOk={() => handleSubmitAddAddress()}
      onCancel={() => handleHideAddAddress()}
      footer={[
        <Button key="back" onClick={handleHideAddAddress}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddAddress}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">
        Input New Delivery Address to create new address.
      </div>
      <Form>
        <Form.Item>
          {getFieldDecorator(
            'shippingAddress1',
            {}
          )(
            <TextArea
              size="large"
              placeholder="New Delivery Address*"
              row={4}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(ModalAddAddress);
