import React from 'react';
import { Layout } from 'antd';

import '../styles/index.scss';

const { Content } = Layout;

export function DetailReadOnlyGlobal({
  detailTime,
  detailComponent,
  detailTitle,
  className,
}) {
  return (
    <Layout className={`detail-read-only ${className}`}>
      <Content>
        <div className="mb-30 text-md detail-read-only__title">
          <strong>{detailTime}</strong>
        </div>
        <div className="panel panel--table-detail-read-only">
          <div className="detail-read-only__item">
            <div className="text-center fw-bold text-lg">{detailTitle}</div>
            {detailComponent}
          </div>
        </div>
      </Content>
    </Layout>
  );
}

DetailReadOnlyGlobal.defaultProps = {
  className: '',
};

export default DetailReadOnlyGlobal;
