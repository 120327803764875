import OrderApi from 'api/order';
import { setToArray } from 'utils';

import {
  GET_REFINEMENT_HISTORY_LIST,
  GET_RESET_HISTORY_LIST,
} from '../../type';

export const getRefinementHistoryList = (soNumber, refinementGroup) => async (
  dispatch
) => {
  const { data } = await OrderApi.getRefinementHistory(
    soNumber,
    refinementGroup
  );

  dispatch({
    type: GET_REFINEMENT_HISTORY_LIST,
    payload: data.data,
  });
};

export const getResetHistoryList = (id) => async (dispatch) => {
  const { data } = await OrderApi.getResetHistory(id);

  dispatch({
    type: GET_RESET_HISTORY_LIST,
    payload: data.data !== null ? setToArray(data) : null,
  });
};
