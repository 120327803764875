/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Input,
  Form,
  message,
  Tooltip,
  Button,
  Row,
  Col,
  Icon,
  Checkbox,
} from 'antd';
import { withRouter } from 'react-router';
import { isEmpty, startCase, kebabCase } from 'lodash';
import { connect } from 'react-redux';

import DetailLayout from 'components/detail-layout/DetailReadOnly';
import SelectDropdown from 'components/SelectDropdown';
import { toggleSidebar } from 'store/action/component-action/ToggleSidebarAction';
import {
  getSimulationDesignById,
  getListStatusSimulation,
  getListVideoVersionSimulation,
  getListDoctorSimulation,
  getListDesignerSimulation,
  getListAdminDesignerSimulation,
} from 'store/action/SimulationDesignAction';
import SimulationDesignApi from 'api/simulation-design';
import { setCreateEditMessage, convertOptions } from 'utils';
import { PageSpinner } from 'components/PageSpinner';

import { attemptOptions } from './helper';
import './styles/index.scss';
import Impression from './components/edit-detail/Impression';
// import PrintType from './components/edit-detail/PrintType';
import FinalSTL from './components/edit-detail/FinalSTL';
import RevisionVideoHistory from './components/edit-detail/RevisionVideoHistory';
import ArchivedHistory from './components/edit-detail/ArchivedHistory';
import AlignerDetail from './components/edit-detail/AlignerDetail';
import DoctorAttacmentList from './components/edit-detail/DoctorAttacmentList';
import { VideoRecordingContainer } from './components/edit-detail/VideoRecordingV2';

import { AuthenticationContext } from 'contexts/Authentication';

// let videoFileId = 0;
// let threeDScanId = 0;

// const ButtonGroup = Button.Group;

export function SimulationDesignEditComponent({
  form,
  history,
  getSimulationDesignById,
  simulationDesignByIdData,
  getListStatusSimulation,
  getListSoNumberSimulation,
  getListVideoVersionSimulation,
  location,
  listStatusSimulationData,
  isSidebarClose,
  getListDoctorSimulation,
  listDoctorSimulationData,
  getListDesignerSimulation,
  getListAdminDesignerSimulation,
  listDesignerSimulationData,
  listAdminDesignerSimulationData,
}) {
  const {
    permissionDataList: {
      opSimdesFieldAssignedAdminDesigner,
      opSimdesFieldAssignedDesigner,
      opSimdesFieldAssignedDoctor,
      opEditSetAlignerDetail,
      opEditNotesDesigner,
    },
  } = useContext(AuthenticationContext || {});

  const uploadedBy = localStorage.getItem('username');
  const [loadingPage, setLoadingPage] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const simulationDesignId = urlParams.get('id');
  const [videoRecordStatusList, setVideoRecordStatusList] = useState([]);
  const [buttonState, setButtonState] = useState(0);
  const { pathname } = location;

  const [showProgressButton, setShowProgressButton] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(false);
  // for opEditSetVideo
  const [isEditVideoEnabled, setIsEditVideoEnabled] = useState(undefined);
  //   const [isEditNotesFromDesign, setIsEditNotesFromDesign] = useState(true);
  const [isPermissionEditNotesFromDesigner, setIsPermissionNotesFromDesigner] =
    useState(opEditNotesDesigner);
  const [
    statusPermissionEditNotesFromDesigner,
    setStatusPermissionEditNotesFromDesigner,
  ] = useState('');

  const [uploadedFileName, setUploadedFileName] = useState({
    video: [],
    videoBite: [],
    imageBite: [],
    archform: [],
    slicing: [],
    finalStlRa: [],
    finalStlRb: [],
  });

  const [uploadedFileId, setUploadedFileId] = useState({
    video: [],
    videoBite: ['NON'],
    imageBite: ['NON'],
    archform: ['NON'],
    slicing: ['NON'],
    finalStlRa: [],
    finalStlRb: [],
  });

  const [isErrorUpload, setIsErrorUpload] = useState({
    video: false,
    videoBite: false,
    imageBite: false,
    finalStlRa: false,
    finalStlRb: false,
    archform: false,
    slicing: false,
  });

  const [isErrorBeforeUpload, setIsErrorBeforeUpload] = useState({
    video: false,
    videoBite: false,
    imageBite: false,
    archform: false,
    slicing: false,
    finalStlRa: false,
    finalStlRb: false,
  });

  const { getFieldDecorator } = form;

  const { TextArea } = Input;

  // const username = localStorage.getItem('username');

  /**
   * LEGACY CODE
   * Function get SimDes by Id Data
   */
  async function getSimulationDesignByIdData() {
    try {
      await getSimulationDesignById(simulationDesignId);
    } finally {
      setLoadingPage(false);
    }
  }

  /**
   * LEGACY CODE
   * call function get SimDes by Id Data
   */
  useEffect(() => {
    getSimulationDesignByIdData();
  }, []);

  /**
   * call list of doctor for Assigned Doctor
   */
  useEffect(() => {
    async function getListDoctorData() {
      await getListDoctorSimulation();
    }

    getListDoctorData();
  }, []);

  useEffect(() => {
    setIsPermissionNotesFromDesigner(opEditNotesDesigner);
  }, [opEditNotesDesigner]);

  /**
   * call list of designer for Assigned Doctor
   */
  useEffect(() => {
    async function getListDesignerData() {
      await getListDesignerSimulation('141');
    }

    async function getListAdminDesignerData() {
      await getListAdminDesignerSimulation('142');
    }

    getListDesignerData();
    getListAdminDesignerData();
  }, []);

  const handleSubmitButton = () => {
    setButtonState(1);
  };

  const handleProgressButton = () => {
    setButtonState(2);
  };

  const handleVideoEditButton = (val) => {
    if (val) {
      setIsEditVideoEnabled(true);
    } else {
      form.resetFields();
      setIsEditVideoEnabled(false);
    }
  };

  const handleReviseButton = async () => {
    let resp = await SimulationDesignApi.reviseSimulationDesign({
      orderId: simulationDesignByIdData[0].orderId,
      updatedBy: uploadedBy,
    });
    if (resp.data.status === 'FAILED') {
      message.error(resp.data.desc);
    } else {
      message.success('REVISE SUCCESS');
      history.push(
        `/cms/transaction/order-detail/edit?id=${simulationDesignByIdData[0].orderId}`
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(simulationDesignByIdData)) {
      setUploadedFileId({
        video: simulationDesignByIdData[0].listVideoId
          ? simulationDesignByIdData[0].listVideoId.map((value) => value)
          : ['NON'],
        slicing: simulationDesignByIdData[0].listSlicingId
          ? simulationDesignByIdData[0].listSlicingId.map((value) =>
              value ? value : 'NON'
            )
          : ['NON'],
        archform: simulationDesignByIdData[0].listArchformId
          ? simulationDesignByIdData[0].listArchformId.map((value) =>
              value ? value : 'NON'
            )
          : ['NON'],
        threeDScan: simulationDesignByIdData[0].list3DScanId
          ? simulationDesignByIdData[0].list3DScanId.map((value) => value)
          : [],
        putty: simulationDesignByIdData[0].puttyId,
        videoVersion: simulationDesignByIdData[0].listVideoVersionId
          ? simulationDesignByIdData[0].listVideoVersionId.map((value) => value)
          : [],
        videoBite: simulationDesignByIdData[0].listVideoBiteId
          ? simulationDesignByIdData[0].listVideoBiteId.map((value) => value)
          : ['NON'],
        imageBite: simulationDesignByIdData[0].listImageBiteId
          ? simulationDesignByIdData[0].listImageBiteId.map((value) => value)
          : ['NON'],
      });

      setUploadedFileName({
        video: simulationDesignByIdData[0].listVideoName
          ? simulationDesignByIdData[0].listVideoName.map((value) => value)
          : [],
        slicing: simulationDesignByIdData[0].listSlicingName
          ? simulationDesignByIdData[0].listSlicingName.map((value) =>
              value ? value : 'NON'
            )
          : [],
        archform: simulationDesignByIdData[0].listArchformName
          ? simulationDesignByIdData[0].listArchformName.map((value) =>
              value ? value : 'NON'
            )
          : [],
        threeDScan: simulationDesignByIdData[0].list3DScanName
          ? simulationDesignByIdData[0].list3DScanName.map((value) => value)
          : [],
        putty: simulationDesignByIdData[0].puttyName,
        videoVersion: simulationDesignByIdData[0].listVideoVersionName
          ? simulationDesignByIdData[0].listVideoVersionName.map(
              (value) => value
            )
          : [],
        videoBite: simulationDesignByIdData[0].listVideoBiteName
          ? simulationDesignByIdData[0].listVideoBiteName.map((value) => value)
          : [],
        imageBite: simulationDesignByIdData[0].listVideoBiteName
          ? simulationDesignByIdData[0].listVideoBiteName.map((value) => value)
          : [],
      });

      /**
       * setShowProgressButton
       */
      if (simulationDesignByIdData[0].stateId === '1042n')
        setShowProgressButton(false);
      if (simulationDesignByIdData[0].stateId === '1050')
        setShowProgressButton(false);
      if (simulationDesignByIdData[0].simulationStatusId === 'SE')
        setShowProgressButton(false);

      return () => {
        setShowProgressButton(true);
      };
    }
  }, [simulationDesignByIdData]);

  /**
   * call list of Status Simulation
   */
  useEffect(() => {
    async function getListStatusSimulationData() {
      await getListStatusSimulation();
    }

    getListStatusSimulationData();
  }, []);

  useEffect(() => {
    async function getListVideoVersionSimulationData() {
      await getListVideoVersionSimulation();
    }

    getListVideoVersionSimulationData();
  }, []);

  useEffect(() => {
    if (!isEmpty(simulationDesignByIdData)) {
      const videoSimulationStatusList = !isEmpty(
        simulationDesignByIdData[0].listVideo
      )
        ? simulationDesignByIdData[0].listVideo.map((value) =>
            value.latestApprovalStatus.toLowerCase()
          )
        : [];

      setVideoRecordStatusList(videoSimulationStatusList);
    }
  }, [simulationDesignByIdData]);

  const checkVideoRecordStatusList = !isEmpty(videoRecordStatusList);
  const nextSimulationStatusId = pathname.includes('pengecekan-sebelum-design')
    ? 'SPD'
    : pathname.includes('sedang-proses-design')
    ? 'W'
    : pathname.includes('sedang-review-dokter') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('revised')
    ? 'A'
    : pathname.includes('sedang-review-dokter') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('approved')
    ? 'RDO'
    : pathname.includes('sedang-review-dokter') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('rejected')
    ? 'RBT'
    : pathname.includes('approved-doctor')
    ? 'WP'
    : pathname.includes('sedang-review-pasien') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('approved')
    ? 'AP'
    : pathname.includes('sedang-review-pasien') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('revised')
    ? 'RDO'
    : pathname.includes('reject-design-only') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('revised')
    ? 'W'
    : pathname.includes('sedang-review-pasien') &&
      checkVideoRecordStatusList &&
      videoRecordStatusList.includes('rejected')
    ? 'RBT'
    : pathname.includes('approved-pasien')
    ? 'SE'
    : null;

  const simulationStatus =
    nextSimulationStatusId === 'MD'
      ? 'pengecekan-sebelum-design'
      : nextSimulationStatusId === 'SPD'
      ? 'sedang-proses-design'
      : nextSimulationStatusId === 'W'
      ? 'sedang-review-dokter'
      : nextSimulationStatusId === 'A'
      ? 'approved-doctor'
      : nextSimulationStatusId === 'RDO'
      ? 'reject-design-only'
      : nextSimulationStatusId === 'RBT'
      ? 'reject-butuh-treatment'
      : nextSimulationStatusId === 'WP'
      ? 'sedang-review-pasien'
      : nextSimulationStatusId === 'AP'
      ? 'approved-pasien'
      : nextSimulationStatusId === 'SE'
      ? 'sudah-export'
      : {};

  const convertDnDData = (dndData) => {
    if (!isEmpty(dndData)) {
      const getId = dndData.map((value) =>
        !isEmpty(value.response.data) ? value.response.data[0].fileId : {}
      );
      if (!isEmpty(getId)) {
        return getId.join('-');
      } else {
        return null;
      }
    }
  };

  const convertArrayToSring = (arrayData) => {
    return arrayData.join(',');
  };

  function handleSubmit(e) {
    e.preventDefault();
    form.setFieldsValue({
      videoRecording: uploadedFileId.video,
      videoBiteId: uploadedFileId.videoBite,
      imageBiteId: uploadedFileId.imageBite,
    });
    form.validateFields(async (err, values) => {
      const { finalStlRa, finalStlRb, raDetails, rbDetails } = values;

      const videoVersionValue = uploadedFileId.videoVersion;
      let filteredVideoVersionName = !isEmpty(values.videoVersionName)
        ? values.videoVersionName.filter(function (el) {
            return el != null;
          })
        : '';
      if (!isEmpty(values.videoVersionName)) {
        videoVersionValue.push(filteredVideoVersionName);
      }
      if (!err) {
        setLoadingProgress(true);
        try {
          const getSpecificStatusSimulationData = listStatusSimulationData.find(
            (value) =>
              value.simulationStatusName === values.simulationStatusName
          );
          let theResponse;
          if (buttonState === 1) {
            /**
             * update aligner detail
             */
            if (
              simulationDesignByIdData[0].simulationStatusId === 'SE' &&
              opEditSetAlignerDetail
            ) {
              function convertfinalSTL(finalSTL) {
                if (!isEmpty(finalSTL)) {
                  const getId = finalSTL.map((value) => value.uid);
                  if (!isEmpty(getId)) return getId.join('-');
                  return null;
                }
              }

              setLoadingPage(true);
              theResponse =
                await SimulationDesignApi.updateSimulationDesignAlignerDetail({
                  simulationId: simulationDesignId,
                  finalStlRaId:
                    values.finalStlRa && convertfinalSTL(values.finalStlRa),
                  finalStlRbId:
                    values.finalStlRa && convertfinalSTL(values.finalStlRb),
                  raDetails: values.raDetails
                    ? convertArrayToSring(values.raDetails)
                    : '',
                  rbDetails: values.rbDetails
                    ? convertArrayToSring(values.rbDetails)
                    : '',
                  updatedBy: uploadedBy,
                  alignerRa: values.alignerRa,
                  alignerRb: values.alignerRb,
                  alignerOcUp: values.ocUp,
                  alignerOcDown: values.ocDown,
                  ocUpDetails: values.ocUpDetails
                    ? convertArrayToSring(values.ocUpDetails)
                    : '',
                  ocDownDetails: values.ocDownDetails
                    ? convertArrayToSring(values.ocDownDetails)
                    : '',
                });
              setCreateEditMessage(
                theResponse.data,
                `Success Updating Simulation Design Data With Simulation Design Id ${simulationDesignId}`,
                `Error Updating Simulation Design Data With Simulation Design Id ${simulationDesignId}`
              );
              if (theResponse.data.status !== 'FAILED') {
                getSimulationDesignByIdData();
              }
              setLoadingPage(false);
              return;
            }

            /**
             * update only video recording value
             */
            if (isEditVideoEnabled !== undefined) {
              setLoadingPage(true);
              theResponse =
                await SimulationDesignApi.updateSimulationDesignVideoRecordings(
                  values.videoRecordings
                );
              setIsEditVideoEnabled(false);
            } else {
              theResponse = await SimulationDesignApi.updateSimulationDesign(
                simulationDesignId,
                values.patientName,
                values.orderReceiptNumber,
                values.orderType ? values.orderType.split(' ')[0] : '',
                uploadedFileId.videoVersion ||
                  uploadedFileId.videoVersion === null
                  ? uploadedFileId.videoVersion.join().replace(/,/g, '-')
                  : values.initVideoVersion.join().replace(/,/g, '-'),
                !isEmpty(getSpecificStatusSimulationData)
                  ? getSpecificStatusSimulationData.simulationStatusId
                  : null,
                values.attempt,
                values.doctorName,
                values.designerName,
                values.adminDesignerName,
                uploadedFileId.video || uploadedFileId.video === null
                  ? uploadedFileId.video.join().replace(/,/g, '-')
                  : values.initVideoId.join().replace(/,/g, '-'),
                uploadedFileId.putty,
                values.designNote,
                values.doctorNote,
                Number(values.ra) + Number(values.rb),
                values.puttyScanFile,
                values.testFitPatientConfirmation
              );
            }
            setCreateEditMessage(
              theResponse.data,
              `Success Updating Simulation Design Data With Simulation Design Id ${simulationDesignId}`,
              `Error Updating Simulation Design Data With Simulation Design Id ${simulationDesignId}`
            );
            if (theResponse.data.status !== 'FAILED') {
              getSimulationDesignByIdData();
            }
          } else {
            /**
             * please add to payload to payload for additonal request
             */
            let payload = {
              approvalDesignNote: values.approvalDesignNote, // for simdes designer note
              ...(nextSimulationStatusId === 'SE' && {
                alignerOcUp: parseInt(values.ocUp),
                alignerOcDown: parseInt(values.ocDown),
                alignerRa: parseInt(values.alignerRa),
                alignerRb: parseInt(values.alignerRb),
                ocUpDetails: values.ocUpDetails
                  ? convertArrayToSring(values.ocUpDetails)
                  : '',
                ocDownDetails: values.ocDownDetails
                  ? convertArrayToSring(values.ocDownDetails)
                  : '',
              }),
              revisionNotesFromDesigner: values.revisionNotesFromDesigner, // for video recording
              adminDesignerName: values.adminDesignerName,
              videoRecordings: values.videoRecordings,
            };
            theResponse = await SimulationDesignApi.progressSimulationDesign(
              simulationDesignId,
              !isEmpty(values.videoVersion) &&
                Array.isArray(values.videoVersion)
                ? values.videoVersion.join().replace(/,/g, '-')
                : null,
              values.doctorName || null,
              !isEmpty(uploadedFileId.video) &&
                Array.isArray(uploadedFileId.video)
                ? uploadedFileId.video.join().replace(/,/g, '-')
                : null,
              !isEmpty(uploadedFileId.slicing) &&
                Array.isArray(uploadedFileId.slicing)
                ? uploadedFileId.slicing.join().replace(/,/g, '-')
                : null,
              !isEmpty(uploadedFileId.archform) &&
                Array.isArray(uploadedFileId.archform)
                ? uploadedFileId.archform.join().replace(/,/g, '-')
                : null,
              nextSimulationStatusId,
              values.finalStlRa ? convertDnDData(finalStlRa) : null,
              values.finalStlRb ? convertDnDData(finalStlRb) : null,
              !isEmpty(values.ra) && Array.isArray(values.ra)
                ? values.ra.join().replace(/,/g, '-')
                : !isEmpty(values.ra)
                ? values.ra
                : null,
              !isEmpty(values.rb) && Array.isArray(values.rb)
                ? values.rb.join().replace(/,/g, '-')
                : !isEmpty(values.rb)
                ? values.rb
                : null,
              raDetails ? convertArrayToSring(raDetails) : null,
              rbDetails ? convertArrayToSring(rbDetails) : null,
              values.agUpper || null,
              values.agLower || null,
              !isEmpty(values.OCUpper) && Array.isArray(values.OCUpper)
                ? values.OCUpper.map((val) =>
                    val === undefined || val === '' ? 0 : val
                  ).join('-')
                : null,
              !isEmpty(values.OCLower) && Array.isArray(values.OCLower)
                ? values.OCLower.map((val) =>
                    val === undefined || val === '' ? 0 : val
                  ).join('-')
                : null,
              values.patientNote || null,
              uploadedBy || null,
              values.designerName || null,
              !isEmpty(uploadedFileId.videoBite) &&
                Array.isArray(uploadedFileId.videoBite)
                ? uploadedFileId.videoBite.join().replace(/,/g, '-')
                : null,
              !isEmpty(uploadedFileId.imageBite) &&
                Array.isArray(uploadedFileId.imageBite)
                ? uploadedFileId.imageBite.join().replace(/,/g, '-')
                : null,
              payload
            );
            setCreateEditMessage(
              theResponse.data,
              `Success Progressing Simulation Design Data With Simulation Design Id ${simulationDesignId}!`,
              `Error Progressing Simulation Design Data With Simulation Design Id ${simulationDesignId}!`
            );
            if (theResponse.data.status !== 'FAILED') {
              getSimulationDesignByIdData();
              history.push({
                pathname: `/cms/simulation-design/${kebabCase(
                  simulationStatus
                )}/edit?id=${simulationDesignId}`,
              });
              window.location.reload();
            }
          }
        } catch (err) {
          if (err && err.response && err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingProgress(false);
        }
      }
    });
  }

  async function handleSubmitNotesFromDesign() {
    try {
      form.validateFields(async (_, values) => {
        const payload = {
          simulationId: simulationDesignId,
          approvalDesignNote: values.approvalDesignNote,
        };
        const response = await SimulationDesignApi.updateNotesFromDesign(
          payload
        );
        setCreateEditMessage(
          response.data,
          `Success Updating Notes From Designer Data With Simulation Design Id ${simulationDesignId}`,
          `Error Updating Notes From Designer Data With Simulation Design Id ${simulationDesignId}`
        );
        if (response.data.status !== 'FAILED') {
          getSimulationDesignByIdData();
        }
        setLoadingPage(false);
        setIsPermissionNotesFromDesigner(true);
        setStatusPermissionEditNotesFromDesigner('');
      });
    } catch (err) {
      if (err && err.response && err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      setLoadingProgress(false);
      setIsPermissionNotesFromDesigner(false);
    }
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <>
      {!isEmpty(simulationDesignByIdData) && (
        <DetailLayout
          detailTime={`${startCase(
            simulationDesignByIdData[0].patientName
          )} - ${simulationDesignByIdData[0].orderReceiptNumber}`}
          detailTitle={''}
          className={`edit-form edit-form--simulation-design ${
            isSidebarClose
              ? 'simulation-design--sidebar-close'
              : 'simulation-design--sidebar-open'
          }`}
          detailComponent={
            <>
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Form.Item className="edit-form__submit">
                  <Tooltip title="Submit">
                    <Button
                      size="large"
                      htmlType="submit"
                      icon="check-circle"
                      onClick={handleSubmitButton}
                    />
                  </Tooltip>
                </Form.Item>
                <div className="edit-form--simulation-design-item mb-35">
                  <Row gutter={15}>
                    <Col span={6}>
                      <Form.Item label="Patient Name">
                        {getFieldDecorator('patientName', {
                          initialValue: simulationDesignByIdData[0].patientName,
                          rules: [
                            {
                              required: true,
                              message: 'Please input patient name!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Order Type"
                            size="large"
                            autoComplete="false"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="SO Number">
                        {getFieldDecorator('orderReceiptNumber', {
                          initialValue:
                            simulationDesignByIdData[0].orderReceiptNumber,
                        })(
                          <Input
                            placeholder="Order Type"
                            size="large"
                            autoComplete="false"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Order Type">
                        {getFieldDecorator('orderType', {
                          initialValue: simulationDesignByIdData[0].orderDesc,
                        })(
                          <Input
                            placeholder="Order Type"
                            size="large"
                            autoComplete="false"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Refinement Type">
                        {getFieldDecorator('refinementType', {
                          initialValue:
                            simulationDesignByIdData[0].refinementType || '',
                        })(
                          <Input
                            placeholder="-"
                            size="large"
                            autoComplete="false"
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Attempt/Day">
                        {getFieldDecorator('attempt', {
                          ...(!isEmpty(simulationDesignByIdData[0].attempt)
                            ? {
                                initialValue:
                                  simulationDesignByIdData[0].attempt,
                              }
                            : ''),
                        })(
                          <SelectDropdown
                            options={attemptOptions}
                            placeHolder={'Select Attempt/Day'}
                            disabled={
                              parseInt(simulationDesignByIdData[0].stateId) >
                                1060 || false
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Assigned Doctor">
                        {getFieldDecorator('doctorName', {
                          ...(!isEmpty(
                            simulationDesignByIdData[0].doctorName
                          ) && {
                            initialValue:
                              simulationDesignByIdData[0].doctorName,
                          }),
                          ...(simulationDesignByIdData[0].simulationStatusId ===
                            'SPD' &&
                            buttonState !== 1 && {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input Doctor!',
                                },
                              ],
                            }),
                        })(
                          <SelectDropdown
                            options={
                              !isEmpty(listDoctorSimulationData)
                                ? convertOptions(
                                    listDoctorSimulationData,
                                    'name'
                                  )
                                : []
                            }
                            placeHolder={'Select Assigned Doctor'}
                            disabled={
                              opSimdesFieldAssignedDoctor ||
                              simulationDesignByIdData[0].simulationStatusId ===
                                'SPD'
                                ? false
                                : true
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Assigned Designer"
                        style={{ marginBottom: 0 }}>
                        {getFieldDecorator('designerName', {
                          ...(!isEmpty(simulationDesignByIdData[0].designerName)
                            ? {
                                initialValue:
                                  simulationDesignByIdData[0].designerName,
                              }
                            : ''),
                          rules: [
                            {
                              required: true,
                              message: 'Please select Designer!',
                            },
                          ],
                        })(
                          <SelectDropdown
                            options={
                              !isEmpty(listDesignerSimulationData)
                                ? convertOptions(
                                    listDesignerSimulationData,
                                    'userName'
                                  )
                                : []
                            }
                            placeHolder={'Select Assigned Designer'}
                            disabled={
                              opSimdesFieldAssignedDesigner &&
                              (simulationDesignByIdData[0]
                                .simulationStatusId === 'MD' ||
                                simulationDesignByIdData[0]
                                  .simulationStatusId === 'SPD' ||
                                simulationDesignByIdData[0]
                                  .simulationStatusId === 'A' ||
                                simulationDesignByIdData[0]
                                  .simulationStatusId === 'RDO')
                                ? false
                                : true
                            }
                          />
                        )}
                        <Icon type="team" /> Last Designer:{' '}
                        {simulationDesignByIdData[0].lastDesignerName}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Assigned Admin Designer"
                        style={{ marginBottom: 0 }}>
                        {getFieldDecorator('adminDesignerName', {
                          initialValue:
                            simulationDesignByIdData[0].adminDesignerName,
                          ...(simulationDesignByIdData[0].simulationStatusId ===
                            'A' && {
                            rules: [
                              {
                                required: true,
                                message: 'Please input Admin Designer!',
                              },
                            ],
                          }),
                        })(
                          <SelectDropdown
                            placeholder="Assigned Admin Designer"
                            options={
                              !isEmpty(listAdminDesignerSimulationData)
                                ? convertOptions(
                                    listAdminDesignerSimulationData,
                                    'userName'
                                  )
                                : []
                            }
                            disabled={
                              opSimdesFieldAssignedAdminDesigner ||
                              simulationDesignByIdData[0].simulationStatusId ===
                                'A'
                                ? false
                                : true
                            }
                          />
                        )}
                        <Icon type="team" /> Last Admin Designer:{' '}
                        {simulationDesignByIdData[0].lastAdminDesignerName}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <Form.Item label="Status">
                        {getFieldDecorator('simulationStatusName', {
                          ...(!isEmpty(
                            simulationDesignByIdData[0].simulationStatusName
                          )
                            ? {
                                initialValue:
                                  simulationDesignByIdData[0]
                                    .simulationStatusName,
                              }
                            : ''),
                          rules: [
                            {
                              required: true,
                              message: 'Please select Status!',
                            },
                          ],
                        })(
                          <SelectDropdown
                            options={
                              !isEmpty(listStatusSimulationData)
                                ? convertOptions(
                                    listStatusSimulationData,
                                    'simulationStatusName'
                                  )
                                : []
                            }
                            placeHolder={'Select Status'}
                            disabled
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Impression
                    values={
                      !isEmpty(simulationDesignByIdData)
                        ? simulationDesignByIdData[0]
                        : {}
                    }
                  />

                  <Row>
                    {/* show if print Type 3D print */}
                    {simulationDesignByIdData[0].printType === 1 && (
                      <>
                        <Col span={8}>
                          <Form.Item className="simulation-design__form-item--mb-20">
                            {getFieldDecorator('threeDScanFileMinimumFormat', {
                              initialValue:
                                simulationDesignByIdData[0] &&
                                simulationDesignByIdData[0]
                                  .threeDScanFileMinimumFormat,
                              valuePropName: 'checked',
                            })(
                              <Checkbox
                                size={'large'}
                                value={true}
                                disabled={
                                  simulationDesignByIdData[0]
                                    .simulationStatusId === 'MD'
                                    ? false
                                    : true
                                }>
                                3D Scan file meets minimum format requirements
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={9}>
                          <Form.Item className="simulation-design__form-item--mb-20">
                            {getFieldDecorator(
                              'threeDScanFileMinimumStandard',
                              {
                                initialValue:
                                  simulationDesignByIdData[0] &&
                                  simulationDesignByIdData[0]
                                    .threeDScanFileMinimumStandard,
                                valuePropName: 'checked',
                              }
                            )(
                              <Checkbox
                                size={'large'}
                                value={true}
                                disabled={
                                  simulationDesignByIdData[0]
                                    .simulationStatusId === 'MD'
                                    ? false
                                    : true
                                }>
                                3D scan file meets minimum standard requirements
                              </Checkbox>
                            )}
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {/* show if print Type Putty Scan */}
                    {simulationDesignByIdData[0].printType === 0 && (
                      <Col span={7}>
                        <Form.Item className="simulation-design__form-item--mb-20">
                          {getFieldDecorator('puttyScanFile', {
                            initialValue:
                              simulationDesignByIdData[0] &&
                              simulationDesignByIdData[0].puttyScanFile,
                            valuePropName: 'checked',
                          })(
                            <Checkbox size={'large'} value={true} disabled>
                              Putty scan file
                            </Checkbox>
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  {simulationDesignByIdData[0].simulationStatusId !== 'MD' && (
                    <VideoRecordingContainer
                      simulationDesignByIdData={
                        !isEmpty(simulationDesignByIdData) &&
                        simulationDesignByIdData[0]
                      }
                      listVideo={
                        !isEmpty(simulationDesignByIdData) &&
                        simulationDesignByIdData[0].listVideo
                      }
                      getSimulationDesignByIdData={getSimulationDesignByIdData}
                      form={form}
                      isErrorBeforeUpload={isErrorBeforeUpload}
                      setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                      isErrorUpload={isErrorUpload}
                      setIsErrorUpload={setIsErrorUpload}
                      buttonState={buttonState}
                      setIsEditEnabled={handleVideoEditButton}
                      isEditEnabled={isEditVideoEnabled}
                    />
                  )}

                  {(simulationDesignByIdData[0].simulationStatusId === 'RBT' ||
                    simulationDesignByIdData[0].simulationStatusId === 'RDO' ||
                    simulationDesignByIdData[0].simulationStatusId === 'A' ||
                    simulationDesignByIdData[0].simulationStatusId === 'WP' ||
                    simulationDesignByIdData[0].simulationStatusId === 'AP' ||
                    simulationDesignByIdData[0].simulationStatusId ===
                      'SE') && (
                    <Form.Item label="Notes from Doctor">
                      {getFieldDecorator('doctorNote', {
                        initialValue: simulationDesignByIdData[0].doctorNote,
                      })(
                        <TextArea
                          placeholder="Notes from doctor"
                          size="large"
                          rows={4}
                          autoComplete="false"
                          disabled
                        />
                      )}
                    </Form.Item>
                  )}

                  {simulationDesignByIdData[0].attachmentList && (
                    <DoctorAttacmentList
                      simulationDesignByIdData={simulationDesignByIdData}
                    />
                  )}

                  {simulationDesignByIdData[0].simulationStatusId !== 'MD' && (
                    <div style={{ margin: '30px 0px' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                          marginBottom: '10px',
                        }}>
                        <label htmlFor="notesFromDesigner">
                          Notes from Designer
                        </label>

                        <div>
                          {isPermissionEditNotesFromDesigner &&
                          parseInt(simulationDesignByIdData[0].stateId) >
                            1060 ? (
                            <Button
                              icon="edit"
                              type="link"
                              onClick={() => {
                                setIsPermissionNotesFromDesigner(
                                  !isPermissionEditNotesFromDesigner
                                );
                                setStatusPermissionEditNotesFromDesigner(
                                  'Cancel'
                                );
                              }}>
                              Edit
                            </Button>
                          ) : null}
                          {statusPermissionEditNotesFromDesigner === 'Cancel' &&
                          !isPermissionEditNotesFromDesigner &&
                          parseInt(simulationDesignByIdData[0].stateId) >
                            1060 ? (
                            <>
                              <Button
                                style={{ margin: '0px 10px' }}
                                type="link"
                                onClick={() => {
                                  setIsPermissionNotesFromDesigner(true);
                                  setStatusPermissionEditNotesFromDesigner('');
                                }}>
                                Cancel
                              </Button>
                              <Button
                                style={{ color: 'black', fontWeight: 600 }}
                                type="link"
                                onClick={handleSubmitNotesFromDesign}>
                                Submit
                              </Button>
                            </>
                          ) : null}
                        </div>
                      </div>
                      {getFieldDecorator('approvalDesignNote', {
                        initialValue:
                          simulationDesignByIdData[0].approvalDesignNote,
                      })(
                        <TextArea
                          id="notesFromDesigner"
                          placeholder="Notes from Designer"
                          size="large"
                          rows={4}
                          autoComplete="false"
                          disabled={
                            statusPermissionEditNotesFromDesigner === '' &&
                            parseInt(simulationDesignByIdData[0].stateId) >
                              1060 &&
                            true
                          }
                        />
                      )}
                    </div>
                  )}

                  {(simulationDesignByIdData[0].simulationStatusId === 'AP' ||
                    simulationDesignByIdData[0].simulationStatusId ===
                      'SE') && (
                    <>
                      <FinalSTL
                        uploadedFileName={uploadedFileName}
                        setUploadedFileName={setUploadedFileName}
                        uploadedFileId={uploadedFileId}
                        setUploadedFileId={setUploadedFileId}
                        isErrorBeforeUpload={isErrorBeforeUpload}
                        setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                        isErrorUpload={isErrorUpload}
                        form={form}
                        setIsErrorUpload={setIsErrorUpload}
                        simulationDesignByIdData={simulationDesignByIdData}
                      />
                      <AlignerDetail
                        simulationDesignByIdData={
                          simulationDesignByIdData || []
                        }
                        form={form}
                      />
                    </>
                  )}

                  {(simulationDesignByIdData[0].simulationStatusId === 'WP' ||
                    simulationDesignByIdData[0].simulationStatusId === 'AP' ||
                    simulationDesignByIdData[0].simulationStatusId ===
                      'SE') && (
                    <Form.Item label="Notes From Patient">
                      {getFieldDecorator('patientNote', {
                        initialValue: simulationDesignByIdData[0].patientNote,
                        ...(simulationDesignByIdData[0].stateId === '1044' && {
                          rules: [
                            {
                              required: true,
                              message: 'Please input Notes!',
                            },
                          ],
                        }),
                      })(
                        <TextArea
                          placeholder="Notes From Patient"
                          size="large"
                          rows={4}
                          autoComplete="false"
                          disabled={
                            simulationDesignByIdData[0].stateId === '1045'
                              ? true
                              : false
                          }
                        />
                      )}
                    </Form.Item>
                  )}
                  <Row gutter={15} type="flex" justify="start" align="top">
                    {showProgressButton && (
                      <Col>
                        <Button
                          disabled={isEditVideoEnabled}
                          loading={loadingProgress}
                          htmlType="submit"
                          type="primary"
                          onClick={handleProgressButton}
                          className="simulation-design--btn-progress"
                          size="large">
                          Progress
                        </Button>
                      </Col>
                    )}
                    {simulationDesignByIdData[0].stateId === '1040' &&
                      simulationDesignByIdData[0].simulationStatusId !==
                        'RBT' && (
                        <Col>
                          <Button
                            onClick={() => handleReviseButton()}
                            className="simulation-design--btn-revise"
                            size="large">
                            Revise
                          </Button>
                        </Col>
                      )}
                  </Row>
                </div>
              </Form>

              <RevisionVideoHistory
                simulationDesignByIdData={simulationDesignByIdData}
              />
              <ArchivedHistory
                simulationDesignByIdData={simulationDesignByIdData}
              />
            </>
          }
        />
      )}
    </>
  );
}

export const SimulationDesignEditComponentForm = Form.create({
  name: 'validate_edit_simulation_design_form',
})(SimulationDesignEditComponent);

const mapStateToProps = ({
  isSidebarClose,
  simulationDesignByIdData,
  listVideoVersionSimulationData,
  listStatusSimulationData,
  listDoctorSimulationData,
  listDesignerSimulationData,
  listAdminDesignerSimulationData,
}) => ({
  isSidebarClose,
  simulationDesignByIdData,
  listVideoVersionSimulationData,
  listStatusSimulationData,
  listDoctorSimulationData,
  listDesignerSimulationData,
  listAdminDesignerSimulationData,
});

export default withRouter(
  connect(mapStateToProps, {
    toggleSidebar,
    getSimulationDesignById,
    getListStatusSimulation,
    getListVideoVersionSimulation,
    getListDoctorSimulation,
    getListDesignerSimulation,
    getListAdminDesignerSimulation,
  })(SimulationDesignEditComponentForm)
);
