import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import StateApi from 'api/state';
import SelectDropdown from 'components/SelectDropdown';

export function StateCreateEdit({ form, history }) {
  const [stateById, setStateById] = useState({});
  const [stations, setStations] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await StateApi.updateState(
            id,
            values.stateName,
            values.slaDays,
            values.station,
            values.stateRound
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/state',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating State Data with State ID ' + id,
            'Error Updating State Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getStationList() {
      const response = await StateApi.listStation();
      let stationList = setToArray(response.data);

      setStations(stationList);
    }

    getStationList();
  }, []);

  useEffect(() => {
    async function getStateById() {
      const response = await StateApi.listSpecificState(id);
      let stateData = setToArray(response.data);

      setStateById(stateData[0]);
    }

    getStateById();
    // eslint-disable-next-line 
  }, []);

  const stationOptions = stations.map((value) => ({
    label: value.station,
    value: value.station,
  }));

  const handleDuplicateStationOptions = stationOptions.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  const stateRoundOptions = [
    {
      label: 0,
      value: 0,
    },
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
  ];

  return (
    <DetailLayout
      detailTime={`${id} - ${stateById ? stateById.stateName : ''}`}
      detailTitle={'Edit State Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="ID">
            {getFieldDecorator('stateId', {
              rules: [{ required: true, message: 'Please input state ID!' }],
              initialValue: stateById && stateById.stateId,
            })(
              <Input
                placeholder="State ID"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('stateName', {
              rules: [{ required: true, message: 'Please input state name!' }],
              initialValue: stateById && stateById.stateName,
            })(
              <Input
                placeholder="State Name"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="SLA Days">
            {getFieldDecorator('slaDays', {
              initialValue: stateById && stateById.slaDays,
            })(
              <Input placeholder="SLA Days" size="large" autoComplete="false" />
            )}
          </Form.Item>

          <Form.Item label="Station">
            {getFieldDecorator('station', {
              initialValue: stateById && stateById.station,
            })(
              <SelectDropdown
                options={handleDuplicateStationOptions}
                placeHolder={'Select Station'}
              />
            )}
          </Form.Item>

          <Form.Item label="State Round">
            {getFieldDecorator('stateRound', {
              initialValue: stateById && stateById.stateRound,
            })(
              <SelectDropdown
                options={stateRoundOptions}
                placeHolder={'Select State Round'}
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const StateForm = Form.create({
  name: 'validate_create_state',
})(StateCreateEdit);

export default { StateCreateEdit };
