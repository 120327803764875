import api from './index';

export default {
  getBeErrorLog() {
    return api.get(`/logs`);
  },

  getBeErrorLogById(id) {
    return api.get(`/logs/${id}`);
  },
};
