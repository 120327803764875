import React, { useState, useRef } from 'react';
import { Button, Form } from 'antd';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';
import { testFitList } from '../../../../helper';

export const ModalTestFit = ({
  showModal,
  handleHideModal,
  form,
  showOtherModal,
  setProgress1Data,
  progress1Data,
  idContainer,
  values,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const { getFieldDecorator } = form;
  const innerRef = useRef(null);

  const handleOnRowPathname = (record) => {
    setProgress1Data({
      ...progress1Data,
      testFit: record.value,
      printType: values.printType,
    });

    handleHideModal();

    showOtherModal();
  };

  const testFitRow = (row) => {
    return (
      <Form.Item className="mb-0">
        {getFieldDecorator(
          'testFit',
          {}
        )(
          <Button className="replace-box__btn-submit" htmlType="submit">
            {row.name}
          </Button>
        )}
      </Form.Item>
    );
  };

  const testFitColumns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => testFitRow(record),
    },
  ];

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        idContainer={idContainer}
        modalComponent={
          <TableGlobal
            tableName={'Select Test Fit'}
            inputPlaceholder={'Search Test Fit Here ...'}
            pageSize={8}
            originalData={testFitList}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={testFitColumns}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />
    </>
  );
};

export default ModalTestFit;
