import AuthenticationApi from 'api/authentication';
import { setToArray } from 'utils';

import { GET_LIST_USER } from '../../../type';

export const getUserList = () => async (dispatch) => {
  const response = await AuthenticationApi.userList();

  dispatch({
    type: GET_LIST_USER,
    payload: setToArray(response.data),
  });
};
