import api from './index';

export default {
  /*
  createShipping(payload) {
    return api.post(`/shipping`, { shipping: payload });
  },
  */

  /*
  updateShipping(payload) {
    return api.put(`/shipping`, { shipping: payload });
  },
  */

  listShipping() {
    return api.get(`/shipping/false`);
  },

  getShippingById(id) {
    return api.get(`/shipping/${id}/false`);
  },

  createShipping(id, name) {
    return api.post(`/shipping`, {
      shippingId: id,
      shippingName: name,
    });
  },

  updateShipping(id, name) {
    return api.put(`/shipping`, {
      shippingId: id,
      shippingName: name,
    });
  },

  deleteShipping(id) {
    return api.delete(`/shipping/${id}`);
  },

  deleteMultipleShipping(id) {
    return api.delete(`/shipping/bulk?shippingId=${id}`);
  },
};
