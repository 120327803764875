import React, { useState, useRef } from 'react';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';

import { productionFlowColums } from '../../../../helper';

export const ModalProductionFlow = ({
  showModal,
  handleHideModal,
  handleShowModalConsultation,
  setProgress1Data,
  progress1Data,
  handleShowModalProgressAppointment,
  handleShowModalDIYDate,
  handleShowModalAttachment,
  handleShowModalRedo,
  handleShowModalAppointmentEnd,
  handleShowModalClinicNote,
  prodFlowList,
  setStateIdFlow,
  handleShowModalDeliveryAddress,
  handleShowModalRedoAppointmentStart,
  handleSubmitFlowAuto,
  handleShowModalDelivUp,
  handleShowModalOC,
  handleShowModalPrintUp,
  handleShowModalPrintType,
  redoStateList,
  nextStateList,
  values,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });

  const innerRef = useRef(null);

  const handleOnRowPathname = (record, e) => {
    setStateIdFlow(record.nextStateId);
    setProgress1Data({
      ...progress1Data,
      nextStateId: record.nextStateId,
      flowId: record.flowId,
    });

    if (
      record.stateId !== '1023' &&
      record.nextStateId === '1022' &&
      record.progressType !== 'Reject'
    ) {
      //appointment_time, clinic
      handleShowModalProgressAppointment();
    } else if (
      (record.stateId === '1023' && record.nextStateId === '1021') ||
      (record.stateId === '1022' &&
        record.nextStateId === '1011' &&
        record.progressType !== 'Reject') ||
      (record.stateId === '1031' &&
        record.nextStateId === '1011' &&
        record.progressType !== 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1011' &&
        record.progressType !== 'Reject')
    ) {
      handleShowModalRedoAppointmentStart();
    } else if (record.nextStateId === '1021') {
      handleShowModalDIYDate();
    } else if (record.nextStateId === '1041o' && record.stateId === '1063') {
      handleSubmitFlowAuto(e, record.nextStateId, record);
    } else if (
      (record.stateId === '1042o' && record.nextStateId === '1040') ||
      (record.stateId === '1042o' && record.nextStateId === '1041o') ||
      // (record.nextStateId === '1041' && record.stateId !== '1025') ||
      (record.stateId === '1022' &&
        record.nextStateId === '1022' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1063' && record.nextStateId === '1061') ||
      // (record.stateId === '1040' && record.nextStateId === '1022') ||
      // (record.stateId === '1040' && record.nextStateId === '1064') ||
      (record.stateId === '1044' && record.nextStateId === '1040') ||
      // (record.stateId === '1044' && record.nextStateId === '1064') ||
      // (record.stateId === '1044' && record.nextStateId === '1066') ||
      (record.stateId === '1066' && record.nextStateId === '1040') ||
      (record.stateId === '1066' && record.nextStateId === '1044') ||
      (record.stateId === '1022' &&
        record.nextStateId === '1011' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1031' &&
        record.nextStateId === '1011' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1011' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1022' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1063' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1064' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1040' &&
        record.nextStateId === '1066' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1044' &&
        record.nextStateId === '1011' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1044' &&
        record.nextStateId === '1022' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1044' &&
        record.nextStateId === '1064' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1044' &&
        record.nextStateId === '1066' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1025' &&
        record.nextStateId === '1022' &&
        record.progressType === 'Reject') ||
      (record.stateId === '1063' && record.nextStateId === '1011')
    ) {
      handleShowModalRedo();
      // } else if (record.nextStateId === '1042') {
      //   handleShowModalAttachment();
    } else if (record.nextStateId === '1012') {
      handleShowModalConsultation();
    } else if (record.nextStateId === '1023') {
      //appointment_time
      handleShowModalAppointmentEnd();
    } else if (record.nextStateId === '1063') {
      handleShowModalDeliveryAddress();
    } else if (record.stateId === '1023' && record.nextStateId === '1022') {
      handleShowModalClinicNote();
    } else if (
      record.nextStateId === '2011' ||
      record.nextStateId === '1081' ||
      record.nextStateId === '1090' ||
      record.nextStateId === '1083'
    ) {
      handleShowModalDelivUp();
    } else if (record.nextStateId === '1073' || record.nextStateId === '1093') {
      handleShowModalPrintUp();
    } else if (record.nextStateId.includes('1091')) {
      handleShowModalOC();
    } else if (
      record.stateId === '1022' &&
      (record.nextStateId === '1025' || record.nextStateId === '1026') &&
      values.ctdFlag === 'yes'
    ) {
      handleShowModalPrintType();
    } else if (
      //This condition is for auto progress when select one of next state in modal "Select production flow"
      record.nextStateId === '1032' ||
      record.nextStateId === '1051' ||
      record.nextStateId === '1065' ||
      record.nextStateId === '1061' ||
      record.nextStateId === '1071i' ||
      record.nextStateId === '1071o' ||
      record.nextStateId === '1025' ||
      record.nextStateId === '1031' ||
      record.nextStateId === '1026' ||
      (record.nextStateId === '1041n' && record.stateId === '1040') ||
      (record.nextStateId === '1041o' && record.stateId === '1063') ||
      record.nextStateId === '1043' ||
      record.nextStateId === '1045' ||
      (record.stateId === '1025' && record.nextStateId === '1031') ||
      (record.stateId === '1025' && record.nextStateId === '1040') ||
      (record.stateId === '1025' && record.nextStateId === '1041o') ||
      // (record.stateId === '1042' && record.nextStateId === '1050') ||
      (record.stateId === '1044' && record.nextStateId === '1050') ||
      (record.stateId === '1031' && record.nextStateId === '1040') ||
      (record.stateId === '1063' && record.nextStateId === '1040') ||
      (record.stateId === '1064' && record.nextStateId === '1040') ||
      (record.stateId === '1064' && record.nextStateId === '1044') ||
      (record.stateId === '1066' && record.nextStateId === '2021')
    ) {
      handleSubmitFlowAuto(e, record.nextStateId, record);
    }

    handleHideModal();
  };

  //Don't delete this console.log
  console.log(prodFlowList, 'Cek Percabangan Progress');

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        idContainer={'form-detail-order'}
        modalComponentNext={
          <TableGlobal
            tableName={'Select Production Flow Next Step'}
            inputPlaceholder={'Search Production Flow Here ...'}
            pageSize={8}
            originalData={nextStateList}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={productionFlowColums}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
            handleSubmitFlowAuto={handleSubmitFlowAuto}
          />
        }
        modalComponentRedo={
          <TableGlobal
            tableName={'Select Production Flow Redo Step'}
            inputPlaceholder={'Search Production Flow Here ...'}
            pageSize={3}
            originalData={redoStateList}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={productionFlowColums}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
            handleSubmitFlowAuto={handleSubmitFlowAuto}
          />
        }
      />
    </>
  );
};

export default ModalProductionFlow;
