/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext } from 'react';
import { Icon, Button, Row, Col, Form, Input, message, Modal, DatePicker } from 'antd';
import { lowerCase } from 'lodash';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';
import OrderApi from 'api/order';
import AuditApi from 'api/audit';
import EkanbanApi from 'api/e-kanban';
import ClinicNotesApi from 'api/clinic-note';
import { setToArray } from 'utils';
import { AuthenticationContext } from 'contexts/Authentication';
import { getListRefundRequest } from 'store/action/RefundRequestAction';

import {
  downloadCSVColumns,
  headerExportCsv,
  headerRefundFloatingExportCsv,
  headerFlaggedFloatingExportCsv,
  headerExportCsvEkanban,
  headerClinicNotesCsv,
  headerSimDesStatusCsv,
  headerSimDesHistoryCsv,
  headerSimDesHistoryAssignedCsv,
} from '../../helper';
import ModalSingleWithoutSubmit from '../modal/ModalSingleWithoutSubmit';
import ModalFullscreenWithSubmit from '../modal/ModalFullscreenWithSubmit';
import ModalFullscreenWithoutSubmit from '../modal/ModalFullscreenWithoutSubmit';

import SimulationDesignApi from 'api/simulation-design';

import './styles/index.scss';

const { RangePicker } = DatePicker

export const FloatingButton = ({
  form,
  history,
  listRefundRequestData,
  getListRefundRequest,
}) => {
  const [showCustomerNameModal, setShowCustomerNameModal] = useState(false);
  const [showPrimaryOrderModal, setShowPrimaryOrderModal] = useState(false);
  const [showOrderTypeModal, setShowOrderTypeModal] = useState(false);
  const [showModalStatePrint, setShowModalStatePrint] = useState(false);
  const [showModalActiveOrderPrint, setShowModalActiveOrderPrint] =
    useState(false);
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalStateAudit, setShowModalStateAudit] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const [buttonState, setButtonState] = useState(0);
  const [floatingData, setFloatingData] = useState({
    customerName: '',
    primaryOrderNumber: '',
    orderType: '',
    stateAudit: '',
  });
  const [csvName, setCsvName] = useState('');

  const [simdesReport, setSimdesReport] = useState('')
  const [simdesFilterModal, setSimdesFilterModal] = useState(false)
  const [simdesFilterValue, setSimdesFilterValue] = useState()
  const [simdesLoading, setsimdesLoading] = useState(false)

  const { TextArea } = Input;
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const {
    opFloatingDashboard,
    opFloatingPrintLabel,
    opFloatingCreateAudit,
    opFloatingCreateOrder,
    opFloatingDownloadCsv,
  } = permissionDataList;

  const innerRef = useRef(null);
  const [orderListByStatusOrder, setOrderListByStatusOrder] = useState([]);
  const currentData = moment(new Date()).format('DD-MM-YYYY');
  const available_state = localStorage.getItem('available_state');
  const csvLinkRef = useRef();
  const username = localStorage.getItem('username');

  const dummyCSVList = [
    {
      stateId: '1101',
      stateName: 'Active Order',
      orderStatusId: 'P',
    },
    {
      stateId: '1102',
      stateName: 'Flagged Order',
      orderStatusId: 'F',
    },
    {
      stateId: '1103',
      stateName: 'Archived Order',
      orderStatusId: 'A',
    },
    {
      stateId: '1103',
      stateName: 'Refund Request',
      orderStatusId: 'RQ',
    },
    {
      stateId: '1101',
      stateName: 'Delivery - DIY Pack',
      orderStatusId: 'DD',
    },
    {
      stateId: '1101',
      stateName: 'Delivery - General',
      orderStatusId: 'DG',
    },
    {
      stateId: '1101',
      stateName: 'Production Output',
      orderStatusId: 'PO',
    },
    {
      stateId: '1101',
      stateName: 'Audit Log',
      orderStatusId: 'AL',
    },
    {
      stateId: '1101',
      stateName: 'E-Kanban Timestamp',
      orderStatusId: 'ET',
    },
    {
      stateId: '1101',
      stateName: 'Clinic Notes',
      orderStatusId: 'CN',
    },
    {
      stateId: '1040',
      stateName: 'Simulation Design Status',
      orderStatusId: 'SD',
    },
    {
      stateId: '1040',
      stateName: 'Simulation Design History',
      orderStatusId: 'SDH',
    },
    {
      stateId: '1040',
      stateName: 'Assigned Simulation Design History ',
      orderStatusId: 'ASDH',
    },
    {
      stateId: '1040',
      stateName: 'Simulation Design Report',
      orderStatusId: 'SDR',
    },
  ];

  const handleShowCustomerNameModal = () => {
    setShowCustomerNameModal(true);
  };

  const handleHideCustomerNameModal = () => {
    setShowCustomerNameModal(false);
  };

  const handleHidePrimaryOrderModal = () => {
    setShowPrimaryOrderModal(false);
  };

  const handleShowPrimaryOrderModal = () => {
    setShowPrimaryOrderModal(true);
  };

  const handleHideOrderTypeModal = () => {
    setShowOrderTypeModal(false);
  };

  const handleShowOrderTypeModal = () => {
    setShowOrderTypeModal(true);
  };

  const handleShowModalCSV = () => {
    setShowModalCSV(true);
  };

  const handleHideModalCSV = () => {
    setShowModalCSV(false);
  };

  const handleShowModalStateAudit = () => {
    setShowModalStateAudit(true);
  };

  const handleHideModalStateAudit = () => {
    setShowModalStateAudit(false);
  };

  const handleSubmitModalCSV = () => {
    setShowModalCSV(false);
  };

  const handleShowModalStatePrint = () => {
    setShowModalStatePrint(true);
  };
  const handleHideModalStatePrint = () => {
    setShowModalStatePrint(false);
  };

  const handleShowActiveOrderPrint = () => {
    setShowModalActiveOrderPrint(true);
  };

  const handleHideActiveOrderPrint = () => {
    setShowModalActiveOrderPrint(false);
  };

  const handleOnRowPathname = async (record) => {
    var response = '';
    switch (record.orderStatusId) {
      case 'ET':
        response = await EkanbanApi.getEkanbanReport();
        break;
      case 'CN':
        response = await ClinicNotesApi.getClinicNoteOrder();
        break;
      case 'SD':
        response = await SimulationDesignApi.getReportSimdesStatus()
        break;
      case 'SDH':
        setSimdesReport('SDH')
        setSimdesFilterModal(!simdesFilterModal)
        return
      case 'ASDH':
        response = await SimulationDesignApi.getReportSimdesHistoryAssigned()
        break;
      case 'SDR':
        setSimdesReport('SDR')
        setSimdesFilterModal(!simdesFilterModal);
        return
      default:
        response = await OrderApi.getReportByOrderStatusId(
          record.orderStatusId,
          available_state
        );
        break;
    }

    let orderList = setToArray(response.data);

    setOrderListByStatusOrder(
      record.orderStatusId === 'RQ' ? listRefundRequestData : orderList
    );

    setCsvName(record);

    if (record) {
      csvLinkRef.current.link.click();
    }

    setShowModalCSV(false);
  };

  function setCreateEditMessage(response, successMsg, failedMsg, submitName) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      if (submitName === 'create-audit-batch') {
        history.push({
          pathname: `/cms/audit-batch/${response.data.auditId}`,
        });
      } else {
        history.push({
          pathname: `/cms/transaction/order-detail/edit?id=${response.data.orderId}`,
        });
      }

      message.success(successMsg);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (buttonState === 1) {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            // setLoadingButton(true);
            let theResponse;
            theResponse = await OrderApi.initializeOrder(
              floatingData.orderType,
              floatingData.customerName,
              floatingData.primaryOrderNumber,
              username
            );

            setCreateEditMessage(
              theResponse.data,
              'Success Create New Order',
              'Error Create New Order Data!',
              'create-new-order'
            );
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            // setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 2) {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            // setLoadingButton(true);
            let theResponse;
            theResponse = await AuditApi.addAuditBatch(floatingData.stateAudit);

            setCreateEditMessage(
              theResponse.data,
              'Success Create Audit Batch Data',
              'Error Create Audit Batch Data!',
              'create-audit-batch'
            );
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            // setLoadingButton(false);
          }
        }
      });
    }
  }

  return (
    <>
      {opFloatingDashboard && (
        <div className="floating-button">
          <Button className="floating-button__main">
            <Icon
              type="plus"
              className="floating-button__icon"
              style={{ fontSize: '25px' }}
            />
          </Button>
          <ul className="floating-button__wrap-item">
            {opFloatingCreateAudit && (
              <li className="floating-button__item">
                <Row type="flex" className="align-items-center" gutter={10}>
                  <Col xs={19}>
                    <div className="floating-button__label">
                      Create Audit Batch
                    </div>
                  </Col>
                  <Col xs={5}>
                    <Button onClick={handleShowModalStateAudit}>
                      <Icon
                        type="audit"
                        className="floating-button__icon"
                        style={{ fontSize: '25px' }}
                      />
                    </Button>
                  </Col>
                </Row>
              </li>
            )}
            {opFloatingPrintLabel && (
              <li className="floating-button__item">
                <Row type="flex" className="align-items-center" gutter={10}>
                  <Col xs={19}>
                    <div className="floating-button__label">Print Label</div>
                  </Col>
                  <Col xs={5}>
                    <Button onClick={handleShowModalStatePrint}>
                      <Icon
                        type="printer"
                        className="floating-button__icon"
                        style={{ fontSize: '25px' }}
                      />
                    </Button>
                  </Col>
                </Row>
              </li>
            )}
            {opFloatingDownloadCsv && (
              <li className="floating-button__item ">
                <Row type="flex" className="align-items-center" gutter={10}>
                  <Col xs={19}>
                    <div className="floating-button__label">
                      Download CSV Report
                    </div>
                  </Col>
                  <Col xs={5}>
                    <Button onClick={handleShowModalCSV}>
                      <Icon
                        type="cloud-download"
                        className="floating-button__icon"
                        style={{ fontSize: '25px' }}
                      />
                    </Button>
                  </Col>
                </Row>
              </li>
            )}
            {opFloatingCreateOrder && (
              <li className="floating-button__item">
                <Row type="flex" className="align-items-center" gutter={10}>
                  <Col xs={19}>
                    <div className="floating-button__label">
                      Create New Order
                    </div>
                  </Col>
                  <Col xs={5}>
                    <Button onClick={handleShowCustomerNameModal}>
                      <Icon
                        type="file-add"
                        className="floating-button__icon"
                        style={{ fontSize: '25px' }}
                      />
                    </Button>
                  </Col>
                </Row>
              </li>
            )}
          </ul>
        </div>
      )}

      {/* Download CSV Simdes History, REFACTOR LATER */}
      <Modal 
        visible={simdesReport === 'SDH' && simdesFilterModal}
        title='Select Export Date'
        onOk={async ()=> {
          setsimdesLoading(true)
          let response = await SimulationDesignApi.getReportSimdesHistory(simdesFilterValue)
          const link = document.createElement("a");
          link.href = response.data.data[0].url;
          document.body.appendChild(link);
          link.click();
          setSimdesFilterModal(false)
          setsimdesLoading(false)
          setShowModalCSV(false);
        }}
        onCancel={(val)=> setSimdesFilterModal(!val)}
        okButtonProps={{ disabled: simdesFilterValue && moment(simdesFilterValue[1]).diff(moment(simdesFilterValue[0]),'days') < 30 ? false : true }}
        confirmLoading={simdesLoading}
      >
        <RangePicker 
          size="large"
          placeholder="Export Date Filter"
          format="YYYY-MM-DD"
          onChange={(_, dateString) => setSimdesFilterValue(dateString)}
        />
        <span>Maximum range : 30 Days</span>
      </Modal>
      {/* end of download csv  simdes history */}

      {/* download CSV Simdes Report, REFACTOR LATER */}
      <Modal 
        visible={simdesReport === 'SDR' && simdesFilterModal}
        title='Select Export Date'
        onOk={async ()=> {
          setsimdesLoading(true)
          let response = await SimulationDesignApi.getReportSimdes(simdesFilterValue)
          const link = document.createElement("a");
          link.href = response.data.data[0].url;
          document.body.appendChild(link);
          link.click();
          setSimdesFilterModal(false)
          setsimdesLoading(false)
          setShowModalCSV(false);
        }}
        onCancel={(val)=> setSimdesFilterModal(!val)}
        okButtonProps={{ disabled: simdesFilterValue && moment(simdesFilterValue[1]).diff(moment(simdesFilterValue[0]),'days') < 30 ? false : true }}
        confirmLoading={simdesLoading}
      >
        <RangePicker 
          size="large"
          placeholder="Export Date Filter"
          format="YYYY-MM-DD"
          onChange={(_, dateString) => setSimdesFilterValue(dateString)}
        />
        <span>Maximum range : 30 Days</span>
      </Modal>
      {/* end of download csv  simdes history */}

      <ModalFullScreen
        showModal={showModalCSV}
        handleHideModal={handleHideModalCSV}
        handleSubmitModal={handleSubmitModalCSV}
        modalComponent={
          <TableGlobal
            tableName={'Select CSV Report'}
            inputPlaceholder={'Search CSV Report Here...'}
            pageSize={8}
            originalData={dummyCSVList}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            tableColumns={downloadCSVColumns}
            editableTable={false}
            exportCsvIsExist={false}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />

      <CSVLink
        filename={`rata-${lowerCase(
          csvName.stateName && csvName.stateName
        ).replace(/ /g, '-')}-export-${currentData}.csv`}
        ref={csvLinkRef}
        headers={
          csvName.orderStatusId
            ? csvName.orderStatusId === 'F'
              ? headerFlaggedFloatingExportCsv
              : csvName.orderStatusId === 'RQ'
              ? headerRefundFloatingExportCsv
              : csvName.orderStatusId === 'ET'
              ? headerExportCsvEkanban
              : csvName.orderStatusId === 'CN'
              ? headerClinicNotesCsv
              : csvName.orderStatusId === 'SD'
              ? headerSimDesStatusCsv
              : csvName.orderStatusId === 'SDH'
              ? headerSimDesHistoryCsv
              : csvName.orderStatusId === 'ASDH'
              ? headerSimDesHistoryAssignedCsv
              : headerExportCsv
            : headerExportCsv
        }
        data={orderListByStatusOrder}
      />

      <Form
        onSubmit={(event) => handleSubmit(event)}
        className="order-detail__form"
        id="form-detail-order">
        <ModalSingleWithoutSubmit
          showModal={showCustomerNameModal}
          modalName="customer-name-modal"
          handleHideModal={handleHideCustomerNameModal}
          setFloatingData={setFloatingData}
          modalTitle="Create New Order"
          modalDesc={'Input Customer Name to proceed creating new order'}
          floatingData={floatingData}
          fieldDecoratorName={'customerName'}
          inputComponent={
            <TextArea placeholder="Input Customer Name..." row={4} />
          }
          showOtherModal={handleShowPrimaryOrderModal}
        />

        <ModalSingleWithoutSubmit
          showModal={showPrimaryOrderModal}
          modalName="primary-order-modal"
          handleHideModal={handleHidePrimaryOrderModal}
          setFloatingData={setFloatingData}
          modalTitle="Create New Order"
          floatingData={floatingData}
          modalDesc={'Input Primary Order Number to proceed creating new order'}
          fieldDecoratorName={'primaryOrderNumber'}
          inputComponent={
            <TextArea placeholder="Input Primary Order Number..." row={4} />
          }
          showOtherModal={handleShowOrderTypeModal}
        />

        <ModalFullscreenWithSubmit
          handleHideModal={handleHideOrderTypeModal}
          showModal={showOrderTypeModal}
          setFloatingData={setFloatingData}
          setButtonState={setButtonState}
          floatingData={floatingData}
          form={form}
          stateName={'orderType'}
          tableName={'Order Type'}
          showQR={true}
        />

        {/* Select Active Order in Label */}
        <ModalFullscreenWithSubmit
          handleHideModal={handleHideActiveOrderPrint}
          showModal={showModalActiveOrderPrint}
          setFloatingData={setFloatingData}
          setButtonState={setButtonState}
          floatingData={floatingData}
          form={form}
          stateName={'activeOrderPrint'}
          tableName={'Active Order'}
        />

        {/* Select state in print label */}
        <ModalFullscreenWithoutSubmit
          handleHideModal={handleHideModalStatePrint}
          showModal={showModalStatePrint}
          handleShowOtherModal={handleShowActiveOrderPrint}
          setFloatingData={setFloatingData}
          setButtonState={setButtonState}
          floatingData={floatingData}
          stateName={'statePrint'}
          tableName={'State'}
        />

        {/* Select state in create audit batch */}
        <ModalFullscreenWithSubmit
          handleHideModal={handleHideModalStateAudit}
          showModal={showModalStateAudit}
          setFloatingData={setFloatingData}
          setButtonState={setButtonState}
          floatingData={floatingData}
          form={form}
          stateName={'stateAudit'}
          tableName={'State'}
        />
      </Form>
    </>
  );
};
const mapStateToProps = ({ listRefundRequestData }) => ({
  listRefundRequestData,
});

export default withRouter(
  connect(mapStateToProps, { getListRefundRequest })(
    Form.create()(FloatingButton)
  )
);
