import axios from 'axios';

const baseURL = process.env.REACT_APP_RATA_CLINIC_API_URL;

const createAPIRataClinic = () => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return axiosInstance;
};

const apiClinic = createAPIRataClinic();

export default {
  getClinicList() {
    return apiClinic.get(`/api/clinic?page=1&limit=30000`);
  },

  getClinicById(id) {
    return apiClinic.get(`/api/clinic/${id}`);
  },

  updateClinic(code, id, clinicName, clinicContactNumber, clinicAddress) {
    return apiClinic.put(`/api/clinic/${code}`, {
      id,
      clinicCode: code,
      clinicName,
      clinicContactNumber,
      clinicAddress,
    });
  },

  addClinic(clinicCode, clinicName, clinicAddress, clinicContactNumber) {
    return apiClinic.post(`/api/clinic`, {
      clinicCode,
      clinicName,
      clinicAddress,
      clinicContactNumber,
    });
  },
};
