/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, Form, message } from 'antd';
import { connect } from 'react-redux';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
  Image,
  Font,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';

import { OrderContext } from 'contexts/Order';

import { getListState } from 'store/action/StateAction';
import EkanbanApi from 'api/e-kanban';

import { activeOrderColumns } from '../../../helper';

import '../styles/index.scss';
import { PrintLabel } from '../../../../order/EditOrderDetail/components/Details/helper/PrintPdfDocument';

export const ModalFullscreenWithSubmit = ({
  showModal,
  handleHideModal,
  setButtonState,
  form,
  setFloatingData,
  floatingData,
  stateName,
  tableName,
  getListState,
  listState,
}) => {
  const [rowSelected, setRowSelected] = useState([]);
  const [editPrintLabelTable, setEditPrintLabelTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const { getFieldDecorator } = form;
  const innerRef = useRef(null);
  const { orderTypeList, activeOrderByIdList } = useContext(OrderContext);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);

      const getBulkOrderId = selectedRowKeys.map((value) => value);
      const getBulkOrderIdToString = getBulkOrderId.join('&orderId=');

      getBulkPrintLabelData(getBulkOrderIdToString);
    },
  };
  const [orderId, setOrderId] = useState('');
  const [qrCanvas, setQrCanvas] = useState('');
  const [bulkPrintLabelList, setBulkPrintLabelList] = useState([]);
  let QRCode = require('qrcode.react');

  async function getStateListData() {
    await getListState();
  }

  useEffect(() => {
    getStateListData();
  }, []);

  useEffect(() => {
    const qrCodeCanvas = document.querySelectorAll(
      "[data-qr='" +
        `http://manufacturing-stag.rata.id/cms/transaction/order-detail/edit?id=${orderId}` +
        "']"
    )[0];
    const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);

    setQrCanvas(qrCodeDataUri);
  }, []);

  const handleOnRowPathname = async (record) => {
    setOrderId(record.orderId);

    const response = await EkanbanApi.getEkanbanPrintLabel(record.orderId);
    let printLabelData = response.data.data[0];

    handleHideModal();

    if (stateName === 'activeOrderPrint') {
      const blob = await pdf(
        <SinglePrintLabel value={printLabelData} />
      ).toBlob();
      saveAs(blob, `Label-${record.orderId}.pdf`);
    } else {
      setFloatingData({
        ...floatingData,
        [stateName]:
          stateName === 'orderType'
            ? record.orderType
            : stateName === 'stateAudit'
            ? record.stateId
            : record.value,
      });
      if (stateName === 'orderType') {
        setButtonState(1);
      } else {
        setButtonState(2);
      }
    }
  };

  const handleRow = (row) => {
    return (
      <Form.Item className="mb-0">
        {getFieldDecorator(
          stateName,
          {}
        )(
          <Button className="replace-box__btn-submit" htmlType="submit">
            {stateName === 'orderType'
              ? `${row.orderType} ${row.orderDesc}`
              : stateName === 'stateAudit'
              ? `${row.stateId} - ${row.stateName}`
              : row.name}
          </Button>
        )}
      </Form.Item>
    );
  };

  const orderTypeColumns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => handleRow(record),
    },
  ];

  const stateAuditColumns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => handleRow(record),
    },
  ];

  const handleOriginalData = () => {
    if (stateName === 'activeOrderPrint') {
      return activeOrderByIdList;
    } else if (stateName === 'orderType') {
      return orderTypeList;
    } else if (stateName === 'stateAudit') {
      return !isEmpty(listState) && listState;
    }

    handleHideModal();
  };

  const handleSelectRowAction = () => {
    handleHideModal();
  };

  const SinglePrintLabel = ({ value }) => (
    <PrintLabel
      value={{
        date: value.printDate,
        boxNumber: value.boxNumber,
        customerName: value.customerName,
        orderType: value.orderType,
        orderDesc: value.orderDesc,
        doctor: value.doctor,
        clinic: value.clinic,
        lower: value.totalDown,
        upper: value.totalUp,
        notes: value.notes,
        round: value.productionRound,
        printType: value.printType,
        soNumber: value.soNumber,
        medicalRecord: value.medicalRecord,
        testFit: value.testFit,
        trimUpper: value.trimUpper,
        trimLower: value.trimLower,
        printNote: value.printNote,
        priorityNote: value.priorityNote,
        upperData: value.upperData,
        lowerData: value.lowerData,
      }}
      qrCanvas={qrCanvas}
    />
  );

  async function getBulkPrintLabelData(getBulkOrderIdToString) {
    try {
      const response = await EkanbanApi.getEkanbanBulkPrintLabel(
        getBulkOrderIdToString
      );
      let bulkList = response.data.data;

      setBulkPrintLabelList(bulkList);
    } catch (err) {
      if (err.response) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    }
  }

  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
        fontWeight: 500,
      },
    ],
  });

  const rowGlobal = {
    margin: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  };

  const colGlobal = {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
  };

  const pdfStyles = StyleSheet.create({
    image: {
      width: 50,
      height: 50,
    },
    page: {
      minWidth: 446,
      minHeight: 295,
      maxWidth: 446,
      maxHeight: 295,
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: 700,
    },
    table: {
      display: 'table',
      minWidth: 386,
      minHeight: 235,
      maxWidth: 386,
      maxHeight: 235,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      fontFamily: 'Roboto',
      transform: 'rotate(90deg)',
      marginBottom: 75,
      marginTop: 100,
      marginRight: -110,
      marginLeft: -20,
    },
    tableRow: {
      ...rowGlobal,
      borderWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    tableRowWidth0: {
      ...rowGlobal,
      borderWidth: 0,
    },
    tableCol: {
      width: '20%',
      ...colGlobal,
    },
    tableColBig: {
      width: '50%',
      ...colGlobal,
    },
    tableColBigWidth0: {
      width: '50%',
      borderWidth: 0,
    },
    tableColBiggest: {
      width: '100%',
      ...colGlobal,
    },
    tableColBiggestWidth0: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 0,
    },
    tableColMedium: {
      width: '33.333%',
      ...colGlobal,
    },
    tableCell: {
      fontSize: 5.5,
      margin: 3,
      minHeight: 7,
      maxHeight: 7,
    },
    tableCellMedium: {
      margin: 3,
      minHeight: 10,
      maxHeight: 10,
    },
    tableCellCustomHeight: {
      fontSize: 6,
      margin: 3,
      minHeight: 21,
      maxHeight: 21,
    },
    fontBold: {
      fontWeight: 'bold',
    },
    fontSizeMedium: {
      fontSize: 11,
    },
    textCenter: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    flexCenter: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
    hideOverFlow: {
      overflow: 'hidden',
    },
  });

  const MyDocument = () => (
    <Document>
      <Page>
        <View style={pdfStyles.flex}>
          {!isEmpty(bulkPrintLabelList) &&
            bulkPrintLabelList.map((value, index) => (
              <View key={index} style={pdfStyles.table}>
                <View
                  style={{ ...pdfStyles.tableRow, ...pdfStyles.hideOverFlow }}>
                  <View style={pdfStyles.tableColBig}>
                    <View
                      style={{
                        ...pdfStyles.tableRow,
                        ...pdfStyles.textCenter,
                      }}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          {value.customerName || 'Customer Name'}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          No SO
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.soNumber}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Box Number
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.boxNumber}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Order Type
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.orderType} - {value.orderDesc}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Rekam Medis
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.medicalRecord}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Klinik
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>{value.clinic}</Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Dokter
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>{value.doctor}</Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Jenis Cetakan
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.printType}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Test Fit
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>{value.testFit}</Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <Text
                          style={{
                            ...pdfStyles.tableCell,
                            ...pdfStyles.fontBold,
                          }}>
                          Tanggal
                        </Text>
                      </View>
                      <View style={pdfStyles.tableColBigWidth0}>
                        <Text style={pdfStyles.tableCell}>
                          {value.printDate}
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRowWidth0}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <View style={pdfStyles.tableCellCustomHeight}>
                          <Text style={pdfStyles.fontBold}>
                            Note Tim Cetakan
                          </Text>
                          <Text>{value.printNote}</Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={pdfStyles.tableColBig}>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBig}>
                        <View style={pdfStyles.tableRow}>
                          <View style={pdfStyles.tableColBiggestWidth0}>
                            <View style={pdfStyles.tableCellCustomHeight}>
                              <Text style={pdfStyles.fontBold}>
                                Priority Notes
                              </Text>
                              <Text>{value.priorityNote}</Text>
                            </View>
                          </View>
                        </View>
                        <View style={pdfStyles.tableRowWidth0}>
                          <View style={pdfStyles.tableColBiggestWidth0}>
                            <View style={pdfStyles.tableCellCustomHeight}>
                              <Text style={pdfStyles.fontBold}>Round</Text>
                              <Text>{value.productionRound}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          ...pdfStyles.tableColBigWidth0,
                          ...pdfStyles.flexCenter,
                        }}>
                        <Image
                          src={qrCanvas}
                          style={pdfStyles.image}
                          alt="images"
                        />
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <View style={pdfStyles.tableCellCustomHeight}>
                          <Text style={pdfStyles.fontBold}>Upper</Text>
                          <Text>{value.upperData}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <View style={pdfStyles.tableCellCustomHeight}>
                          <Text style={pdfStyles.fontBold}>Trim Upper</Text>
                          <Text>{value.trimUpper}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <View style={pdfStyles.tableCellCustomHeight}>
                          <Text style={pdfStyles.fontBold}>Lower</Text>
                          <Text>{value.lowerData}</Text>
                        </View>
                      </View>
                    </View>
                    <View style={pdfStyles.tableRowWidth0}>
                      <View style={pdfStyles.tableColBiggestWidth0}>
                        <View style={pdfStyles.tableCellCustomHeight}>
                          <Text style={pdfStyles.fontBold}>Trim Lower</Text>
                          <Text>{value.trimLower}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={pdfStyles.tableRow}>
                  <View style={pdfStyles.tableColBiggest}>
                    <View style={pdfStyles.tableCellCustomHeight}>
                      <Text style={pdfStyles.fontBold}>Notes</Text>
                      <Text>{value.notes}</Text>
                    </View>
                  </View>
                </View>

                <View style={pdfStyles.tableRow}>
                  <View style={pdfStyles.tableCol}>
                    <Text
                      style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                      QC Cor
                    </Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text
                      style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                      QC Print
                    </Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text
                      style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                      QC Vacuum
                    </Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text
                      style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                      QC Bur
                    </Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text
                      style={{ ...pdfStyles.tableCell, ...pdfStyles.fontBold }}>
                      QC Final
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.tableRow}>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCellCustomHeight}></Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCellCustomHeight}></Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCellCustomHeight}></Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCellCustomHeight}></Text>
                  </View>
                  <View style={pdfStyles.tableCol}>
                    <Text style={pdfStyles.tableCellCustomHeight}></Text>
                  </View>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );

  const handleEditPrintLabelTable = () => {
    setEditPrintLabelTable(!editPrintLabelTable);
  };

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        idContainer={'form-detail-order'}
        modalComponent={
          <TableGlobal
            tableName={`Select ${tableName}`}
            inputPlaceholder={`Search ${tableName} Here...`}
            pageSize={8}
            originalData={handleOriginalData()}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={stateName === 'activeOrderPrint' ? true : false}
            editTable={
              stateName === 'activeOrderPrint' ? editPrintLabelTable : false
            }
            exportCsvIsExist={false}
            MyDocument={MyDocument}
            handleEditTable={
              stateName === 'activeOrderPrint' ? handleEditPrintLabelTable : ''
            }
            rowSelected={rowSelected}
            handleSelectRowAction={handleSelectRowAction}
            tableColumns={
              stateName === 'activeOrderPrint'
                ? activeOrderColumns
                : stateName === 'stateAudit'
                ? stateAuditColumns
                : orderTypeColumns
            }
            rowSelection={rowSelection}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
            recordKey={(record) => record.orderId}
          />
        }
      />
      <div className="details__qr-item mb-15" style={{ display: 'none' }}>
        <QRCode
          data-qr={`http://manufacturing-stag.rata.id/cms/transaction/order-detail/edit?id=${orderId}`}
          size={257}
          responsive={true}
          value={`http://manufacturing-stag.rata.id/cms/transaction/order-detail/edit?id=${orderId}`}
          includeMargin={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ listState }) => ({
  listState,
});

export default connect(mapStateToProps, { getListState })(
  ModalFullscreenWithSubmit
);
