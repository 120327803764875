export const downloadCSVColumns = [
  {
    title: 'Name',
    render: (row) => `${row['stateId']} - ${row['stateName']}`,
    key: 'stateId',
  },
];

export const tableColumns = [
  {
    title: 'Primary Order Number',
    dataIndex: 'orderReceiptNumber',
    key: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
    width: 200,
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
    render: (row) => (row['customerName'] ? row['customerName'] : '-'),
  },
  {
    title: 'Order Type',
    render: (row) =>
      `${row['orderType']} - ${row['orderDesc']} ${row['orderFull']}`,
    key: 'orderType',
    sorter: (a, b) => a.orderType - b.orderType,
  },
  {
    title: 'Current State',
    render: (row) => `${row['stateId']} - ${row['stateName']}`,
    key: 'currentState',
    sorter: (a, b) => a.stateId - b.stateId,
  },
  {
    title: 'Jenis Cetakan',
    key: 'printTypeString',
    render: (row) =>
      row['printTypeString'] == '0'
        ? 'Putty'
        : row['printTypeString'] === '1'
        ? '3D Scan'
        : '-',
    sorter: (a, b) =>
      a?.printTypeString &&
      b?.printTypeString &&
      a?.printTypeString.localeCompare(b?.printTypeString),
  },
  {
    title: 'SLA Deadline',
    key: 'orderDeadline',
    sorter: (a, b) => (a.orderDeadline === null) - (b.orderDeadline === null),
    render: (row) => (row['orderDeadline'] ? row['orderDeadline'] : '-'),
  },
  {
    title: 'Test Fit',
    dataIndex: 'testFitStatus',
    key: 'testFitStatus',
    sorter: (a, b) => a.testFitStatus.localeCompare(b.testFitStatus),
  },
  {
    title: 'Overdue',
    key: 'deadlineStatus',
    render: (row) => (row['deadlineStatus'] === 'Overdue' ? 'Overdue' : '-'),
    sorter: (a, b) => a.deadlineStatus.localeCompare(b.deadlineStatus),
  },
  {
    title: 'Flagged',
    key: 'orderStatusDesc',
    render: (row) => (row['orderStatusDesc'] === 'Flagged' ? 'Flagged' : '-'),
    sorter: (a, b) => a.orderStatusDesc.localeCompare(b.orderStatusDesc),
  },
  {
    title: 'Over Correct',
    key: 'ocChecklist',
    render: (row) => (row['ocChecklist'] ? 'Yes' : 'No'),
    sorter: (a, b) => (a.ocChecklist === null) - (b.ocChecklist === null),
  },
];

export const headerExportCsv = [
  { label: 'ID', key: 'orderId' },
  { label: 'Modified At', key: 'updatedAt' },
  { label: 'Modified By', key: 'updatedBy' },
  { label: 'Name', key: 'orderName' },
  { label: 'Type', key: 'orderFull' },
  { label: 'State', key: 'stateId' },
  { label: 'Refinement Type', key: 'refinementType' },
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Round', key: 'stateRound' },
  { label: 'System Note', key: 'systemNote' },
  { label: 'Total Atas', key: 'totalUp' },
  { label: 'Total Bawah', key: 'totalDown' },
  { label: 'Delivery Remaining Atas', key: 'delivRemainUp' },
  { label: 'Delivery Remaining Bawah', key: 'delivRemainDown' },
  { label: 'OC Atas', key: 'ocUp' },
  { label: 'OC Bawah', key: 'ocDown' },
  { label: 'AG Atas', key: 'agUp' },
  { label: 'AG Bawah', key: 'agDown' },
  { label: 'Treat Attachment', key: 'treatAttachment' },
  { label: 'Treat Slicing', key: 'treatSlicing' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Creation Time', key: 'createdAt' },
  { label: 'Appointment Timestamp', key: 'appointmentTime' },
  { label: 'Clinic', key: 'clinic' },
  { label: 'Box Timestamp', key: 'boxCreatedAt' },
  { label: 'Box Number', key: 'boxNumber' },
  { label: 'SLA Deadline', key: 'orderDeadline' },
  { label: 'User Note', key: 'userNote' },
  { label: 'Clinic Required', key: 'clinicRequired' },
  { label: 'Consultation Timestamp', key: 'consultationTime' },
  { label: 'DIY Delivery Timestamp', key: 'diyDeliveryTime' },
  { label: 'Contact Number', key: 'customerPhone' },
  { label: '3D Scan', key: 'printType' },
  { label: 'Handover', key: 'handover' },
  { label: 'On Station', key: 'onStationTime' },
  { label: 'Removal Request', key: 'removalRequested' },
  { label: 'Outsource Round 1', key: 'outsourceRound1' },
  { label: 'Outsource Round 2', key: 'outsourceRound2' },
  { label: 'Test Fit', key: 'testFitStatus' },
  { label: 'Overdue', key: 'overdue' },
  { label: 'Is Flagged', key: 'isFlagged' },
  { label: 'Over Correct', key: 'ocChecklist' },
  { label: 'Appointment SV 2 Timestamp', key: 'appointmentSv2Timestamp' },
  { label: 'Doctor Name', key: 'doctorName' },
];

export const headerFlaggedFloatingExportCsv = [
  ...headerExportCsv,
  { label: 'Flag Note', key: 'flagNote' },
];

export const headerRefundFloatingExportCsv = [
  ...headerExportCsv,
  { label: 'Removal Request Note', key: 'removalNote' },
];

export const headerActiveExportCsv = [
  { label: 'System ID', key: 'orderId' },
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Order Type ID', key: 'orderType' },
  { label: 'Order Type Name', key: 'orderDesc' },
  { label: 'Current State ID', key: 'stateId' },
  { label: 'Current State Name', key: 'stateName' },
  { label: 'Box Number', key: 'boxNumber' },
  { label: 'SLA Deadline', key: 'orderDeadline' },
  { label: 'Test Fit', key: 'testFitStatus' },
  { label: 'Overdue', key: 'deadlineStatus' },
  { label: 'Flagged', key: 'orderStatusDesc' },
  { label: 'Over Correct', key: 'ocChecklist' },
];

export const overdueOptions = [
  { label: '-', value: 'On Track' },
  { label: 'Overdue', value: 'Overdue' },
];

export const printTypeOptions = [
  { label: 'Putty', value: '0' },
  { label: '3D Scan', value: '1' },
  { label: '-', value: '-' },
];

export const flaggedOptions = [
  { label: '-', value: 'In Progress' },
  { label: 'Flagged', value: 'Flagged' },
];

export const activeOrderColumns = [
  {
    title: 'ID',
    key: 'orderId',
    dataIndex: 'orderId',
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
  },
  {
    title: 'Primary Order Number',
    key: 'orderReceiptNumber',
    dataIndex: 'orderReceiptNumber',
  },
  {
    title: 'Test Fit',
    key: 'testFit',
    dataIndex: 'testFit',
  },
];

export const tableStationStateColumns = [
  {
    title: 'State',
    key: 'State',
    dataIndex: 'stateName',
    sorter: (a, b) => a.stateName.localeCompare(b.stateName),
  },
  {
    title: 'In',
    key: 'In',
    render: (row) => (row['in'] ? row['in'] : '-'),
    sorter: (a, b) => (a.in === null) - (b.in === null),
  },
  {
    title: 'Out',
    key: 'Out',
    render: (row) => (row['out'] ? row['out'] : '-'),
    sorter: (a, b) => (a.out === null) - (b.out === null),
  },
  {
    title: 'Average Duration',
    key: 'Average Duration',
    render: (row) => (row['avgDuration'] ? row['avgDuration'] : '-'),
    sorter: (a, b) => (a.avgDuration === null) - (b.avgDuration === null),
  },
];

export const headerStationStateCsv = [
  { label: 'State', key: 'stateName' },
  { label: 'In', key: 'in' },
  { label: 'Out', key: 'out' },
  { label: 'Average Duration', key: 'avgDuration' },
];

export const headerExportCsvEkanban = [
  { label: 'ID', key: 'orderId' },
  { label: 'Primary Order', key: 'orderReceiptNumber' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Type', key: 'orderType' },
  { label: 'State', key: 'stateName' },
  { label: '3D Scan', key: 'printType' },
  { label: 'Test Fit', key: 'testFitLabel' },
  { label: 'Creation Time', key: 'createdAt' },
  { label: '1011 - Menunggu Pembuatan Janji Konsultasi', key: 'time1011' },
  { label: '1012 - Menunggu Konsultasi Online', key: 'time1012' },
  { label: '1021 - Menunggu Pembuatan Janji Cetak Online', key: 'time1021' },
  { label: '1022 - Menunggu Cetak Klinik', key: 'time1022' },
  { label: '1023 - Menunggu Cetak Online', key: 'time1023' },
  { label: '1025 - Menunggu Penempatan Kotak', key: 'time1025' },
  { label: '1026 - Rata 10 On Hold', key: 'time1026' },
  { label: '1027 - Rata 10 Ready', key: 'time1027' },
  { label: '1031 - Menunggu QC Cetakan', key: 'time1031' },
  { label: '1032 - Menunggu Scanning Cetakan', key: 'time1032' },
  { label: '1041 - Menunggu Design', key: 'time1041' },
  { label: '1042 - Menunggu Persetujuan Design', key: 'time1042' },
  { label: '1051 - PAYG 2 On Hold', key: 'time1051' },
  { label: '1052 - PAYG 2 Ready', key: 'time1052' },
  { label: '1061 - Menunggu QC Test Fit', key: 'time1061' },
  { label: '1062 - Menunggu Pengiriman Test Fit', key: 'time1062' },
  { label: '1065 - Menunggu Keputusan Outsource 1', key: 'time1065' },
  {
    label: '1071-I - Menunggu Meshmixer Produksi 1 (In-House)',
    key: 'time1071i',
  },
  {
    label: '1071-O - Menunggu Meshmixer Produksi 1 (Outsource)',
    key: 'time1071o',
  },
  { label: '1072 - Menunggu Print Produksi 1', key: 'time1072' },
  { label: '1073 - Menunggu QC Produksi 1', key: 'time1073' },
  {
    label: '1074-I - Menunggu Pengiriman Produksi 1 (In-House)',
    key: 'time1074i',
  },
  {
    label: '1074-O - Menunggu Pengiriman Produksi 1 (Outsource)',
    key: 'time1074o',
  },
  { label: '1081 - PAYG 3 On Hold', key: 'time1081' },
  { label: '1082 - PAYG 3 Ready', key: 'time1082' },
  { label: '1083 - Rata Full On Hold', key: 'time1083' },
  { label: '1084 - Rata Full Ready', key: 'time1084' },
  { label: '1085 - Waiting SV 2', key: 'time1085' },
  { label: '1086 - Scheduling SV 2', key: 'time1086' },
  { label: '1087 - Menunggu SV 2', key: 'time1087' },
  { label: '1088', key: 'time1088' },
  { label: '1089 - Menunggu Keputusan Outsource 2', key: 'time1089' },
  { label: '1090 - Menunggu FSV', key: 'time1090' },
  {
    label: '1091-I - Menunggu Meshmixer Produksi 2 (In-House)',
    key: 'time1091i',
  },
  {
    label: '1091-O - Menunggu Meshmixer Produksi 2 (Outsource)',
    key: 'time1091o',
  },
  { label: '1092 - Menunggu Print Produksi 2', key: 'time1092' },
  { label: '1093 - Menunggu QC Produksi 2', key: 'time1093' },
  {
    label: '1094-I - Menunggu Pengiriman Produksi 2 (In-House)',
    key: 'time1094i',
  },
  {
    label: '1094-O - Menunggu Pengiriman Produksi 2 (Outsource)',
    key: 'time1094o',
  },
];

export const headerClinicNotesCsv = [
  { label: 'Reported Date', key: 'createdDate' },
  { label: 'Reported Time', key: 'createdTime' },
  { label: 'Name', key: 'customerName' },
  { label: 'State', key: 'stateLabel' },
  { label: 'Status', key: 'noteStatusDesc' },
  { label: 'Sub Status', key: 'noteSubStatusDesc' },
  { label: 'FU Date', key: 'fuTimestamp' },
  { label: 'Clinic Notes', key: 'note' },
];

export { headerSimDesStatusCsv } from './headerSimDesStatusCsv';

export { headerSimDesHistoryCsv } from './headerSimDesHistoryCsv';

export { headerSimDesHistoryAssignedCsv } from './headerSimDesHistoryAssignedCsv';
