/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Checkbox,
  Form,
  Collapse,
  Input,
  Modal,
  message,
  Icon,
} from 'antd';
import { isEmpty } from 'lodash';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SelectDropdown from 'components/SelectDropdown';
import { AuthenticationContext } from 'contexts/Authentication';
import TableGlobal from 'components/Table';
import { replaceCharacterWithLine } from 'utils';
import ClinicNoteApi from 'api/clinic-note';
import { getClinicNoteById } from 'store/action/ClinicNoteAction';

import { deliveryColums, clinicNotesColums } from '../../helper';
import Filter from '../filter/FilterDelivery';
import FilterClinicNotes from '../filter/FilterClinicNotes';
import ActiveFilterClinicNotes from '../active-filter/ActiveFilterClinicNote';
import ActiveFilter from '../active-filter/ActiveFilterDelivery';
import PaymentDetail from './components/PaymentDetail';
import ModalAddClinicNote from '../modal/ModalAddClinicNotes';
import AlignerDetail from './components/AlignerDetail';
import Impression from './components/Impression';
import SimulationDetail from './components/SimulationDetail';
import RefinementHistory from './components/RefinementHistory';
import FinalSTL from './components/FinalSTL';
import NonCetakToDesignDetail from './components/NonCetakToDesignDetail/NonCetakToDesignDetail';
import SimulationDetailNotes from './components/SimulationDetailNotes';
import order from 'api/order';

export const OrderDetailActive = ({
  orderTypeOptions,
  orderShippingById,
  states,
  values,
  getFieldDecorator,
  form,
  loadingButton,
  handleShowAddAddress,
  handleShowModalCetakUlang,
  handleShowModalTroubleshoot,
  handleShowModalFlag,
  handleShowModalResolve,
  whenResolve,
  handleShowSlaDeadline,
  handleShowModalRefinement,
  handleModalRefinementTroubleshoot,
  whenFlag,
  handleShowModalArchived,
  handleButtonReactive,
  handleShowModalAppointment,
  handleShowModalProductionFlow,
  handleShowModalReplaceBox,
  prodFlowList,
  setButtonState,
  setProgress1Data,
  progress1Data,
  handleShowModalPrintUp,
  handleShowModalDelivUp,
  handleShowModalPrintType,
  handleShowModalConfirmCU,
  handleShowModalImpression,
  // handleShowModalAttachment,
  handleShowModalProgressAppointmentSV2,
  handleShowModalVideoSentDate,
  handleShowModalAppointmentSV2,
  handleShowModalProgressBox,
  handleShowModalDeliveryAddress,
  handleShowModalRescheduleAppointmentWithClinic,
  handleModalConfirmCetakUlang,
  detailTitle,
  getClinicNoteById,
  clinicNoteByIdData,
}) => {
  const { TextArea } = Input;
  const { Panel } = Collapse;
  const { confirm } = Modal;
  const { permissionDataList, userByEmail } = useContext(
    AuthenticationContext || {}
  );

  const statesOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  const {
    opAddAddress,
    opBasic,
    opCreateRefinement,
    opEditAddress,
    opReplaceBox,
    opArchive,
    opFlag,
    opProgress,
    opResolve,
    opViewAddress,
    opFieldCustomerName,
    opFieldSONumber,
    opFieldClinicId,
    opFieldBoxNumber,
    opFieldState,
    opFieldTotalUp,
    opFieldTotalDown,
    opFieldAgUp,
    opFieldAgDown,
    opFieldOcUp,
    opFieldOcDown,
    opFieldContactNumber,
    opFieldHandover,
    opFieldOutsourceRound1,
    opFieldOutsourceRound2,
    opFieldTreatAttachment,
    opFieldTreatSlicing,
    opField3dScan,
    opFieldTestFit,
    opFieldRemovalRequested,
    // opFieldOrderStatus,
    opFieldUserNote,
    opUpgradeDp,
  } = permissionDataList;

  const handleSubmitProgressAuto = () => {
    setProgress1Data({
      ...progress1Data,
      nextStateId: !isEmpty(prodFlowList) && prodFlowList[0].nextStateId,
    });
    setButtonState(7);
  };

  // eslint-disable-next-line
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    noteStatusDesc: [],
    stateId: [],
    createdBy: [],
    createdAt: '',
    fuTimestamp: '',
  });

  const [statusNoteOptions, setStatusNoteOptions] = useState([]);
  const [stateNoteOptions, setStateNoteOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);

  const latestUpdatedAt = !isEmpty(clinicNoteByIdData)
    ? clinicNoteByIdData[0].latestUpdatedAt
    : '';
  const latestUpdatedBy = !isEmpty(clinicNoteByIdData)
    ? clinicNoteByIdData[0].latestUpdatedBy
    : '';

  async function getClinicNoteListData() {
    try {
      await getClinicNoteById(values.orderId);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getClinicNoteListData();
  }, []);

  const resetFilter = () => {
    setActiveFilter({});
  };

  const onAddressChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      shippingAddress1: checkedValues,
    });
  };

  const onCreatedDateChange = (date, dateString) => {
    setActiveFilter({
      ...activeFilter,
      createdAt: dateString,
    });
  };

  const onFuTimeStampChange = (date, dateString) => {
    setActiveFilter({
      ...activeFilter,
      fuTimestamp: dateString,
    });
  };

  const onStateChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      stateId: checkedValues,
    });
  };

  const onStatusChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      noteStatusDesc: checkedValues,
    });
  };

  const onAgentChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      createdBy: checkedValues,
    });
  };

  useEffect(() => {
    if (!isEmpty(clinicNoteByIdData)) {
      const statusNoteOpts = clinicNoteByIdData.map((value) => ({
        label: value.noteStatusDesc,
        value: value.noteStatusDesc,
      }));

      const handleDuplicateStatusNoteOpts = statusNoteOpts.filter(
        (value, index, array) =>
          array.findIndex((t) => t.label === value.label) === index
      );

      const stateNoteOpts = clinicNoteByIdData.map((value) => ({
        label: value.stateDesc,
        value: value.stateId,
      }));

      const handleDuplicateStateNoteOpts = stateNoteOpts.filter(
        (value, index, array) =>
          array.findIndex((t) => t.label === value.label) === index
      );

      const agentNoteOpts = clinicNoteByIdData.map((value) => ({
        label: value.createdBy,
        value: value.createdBy,
      }));

      const handleDuplicateAgentNoteOpts = agentNoteOpts.filter(
        (value, index, array) =>
          array.findIndex((t) => t.label === value.label) === index
      );

      setStatusNoteOptions(handleDuplicateStatusNoteOpts);
      setStateNoteOptions(handleDuplicateStateNoteOpts);
      setAgentOptions(handleDuplicateAgentNoteOpts);
    }
  }, [clinicNoteByIdData]);

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
        onAddressChange={onAddressChange}
        orderShippingById={orderShippingById}
      />
    );
  };

  const handleOnRowPathname = (record) => {
    return opEditAddress
      ? `/cms/transaction/order-detail/edit-address/${record.orderShippingId}?title=${detailTitle}`
      : window.location.pathname + window.location.search;
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleOnRowClinicNotes = (record) => {
    return `/cms/transaction/order-detail/edit-clinic-notes/${values.orderId}?id=${record.noteId}`;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const activeFilterClinicNotesComponent = () => {
    return (
      <ActiveFilterClinicNotes
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
        setFilterShow={setShowFilter}
      />
    );
  };

  const filterClinicNotesComponent = () => {
    return (
      <FilterClinicNotes
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        onCreatedDateChange={onCreatedDateChange}
        onFuTimeStampChange={onFuTimeStampChange}
        onStateChange={onStateChange}
        onStatusChange={onStatusChange}
        onAgentChange={onAgentChange}
        statusNoteOptions={statusNoteOptions}
        stateNoteOptions={stateNoteOptions}
        agentOptions={agentOptions}
      />
    );
  };

  const handleDeleteRow = async () => {
    const getClinicNoteId = rowSelected.map((value) => value.noteId);
    const getClinicNoteIdString = getClinicNoteId.join(',');

    confirm({
      title: `Are you sure delete this clinic note data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then clinic note data with id ${getClinicNoteIdString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingClinicNote() {
          try {
            await ClinicNoteApi.deleteMultipleClinicNote(
              getClinicNoteIdString,
              localStorage.getItem('user_name')
            );
            setRowSelected([]);
            getClinicNoteById(values.orderId);
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingClinicNote();
      },
      onCancel() {},
    });
  };

  function on3DScanChange(e) {
    return e.target.checked;
  }

  const convertStateId = parseInt(values.stateId);

  const confirmUpgradeDP = () => {
    confirm({
      title: 'Waiting Next Payment',
      content: 'Please double check. Once you click, you cant undo the action.',
      onOk: async () => {
        try {
          const res = await order.upgradeDpAction(values.orderId);
          if (res.data.status === 'FAILED') {
            message.error(res.data.desc);
          } else {
            message.success('Set Waiting Next Payment Success');
            window.location.reload();
          }
        } catch (error) {
          if (error.response.data) {
            const errMessage = error.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server');
          }
        }
      },
    });
  };

  //   Format Customer Name => Before `CustomerNumber` After `Customer Number`
  const formatName = (name) => {
    const oldData = name;
    const regex = /(\D+)(\d+)/;
    const result = regex.exec(name);

    if (result !== null) {
      const customerName = result[1];
      const customerNumber = result[2];
      return `${customerName} ${customerNumber}`;
    } else {
      return oldData;
    }
  };
  return (
    <>
      <div className="order-detail-active">
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item label="Order ID">
              {getFieldDecorator('orderId', {
                rules: [{ required: true, message: 'Please input order ID!' }],
                initialValue: values && values.orderId,
              })(
                <Input
                  placeholder="Order ID"
                  autoComplete="false"
                  disabled
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Customer Name">
              {getFieldDecorator('customerName', {
                initialValue: values && values.customerName,
              })(
                <Input
                  placeholder="Customer Name"
                  autoComplete="false"
                  size="large"
                  disabled={opFieldCustomerName ? false : true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Primary Order Number">
              {getFieldDecorator('orderReceiptNumber', {
                initialValue: values && values.orderReceiptNumber,
              })(
                <Input
                  placeholder="Primary Order Number"
                  autoComplete="false"
                  size="large"
                  disabled={opFieldSONumber ? false : true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Waiting Confirmation Order Number">
              {getFieldDecorator('waitingConfirmSO', {
                initialValue: values && values.waitingConfirmSO,
              })(
                <Input
                  placeholder="Waiting Confirmation Order Number"
                  autoComplete="false"
                  size="large"
                  className="red-7"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Clinic ID">
              {getFieldDecorator('clinicId', {
                initialValue: values && values.clinicId,
              })(
                <Input
                  placeholder="Clinic ID"
                  autoComplete="false"
                  size="large"
                  disabled={opFieldClinicId ? false : true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Box Number">
              {getFieldDecorator('boxNumber', {
                initialValue: values && values.boxNumber,
              })(
                <Input
                  placeholder="Box Number"
                  autoComplete="false"
                  size="large"
                  disabled={opFieldBoxNumber ? false : true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Order Type">
              {getFieldDecorator('orderType', {
                initialValue: values && values.orderFull,
              })(<Input disabled={true} size="large" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="State">
              {getFieldDecorator('stateId', {
                rules: [{ required: true, message: 'Please input state!' }],
                initialValue: values && values.stateId,
              })(
                <SelectDropdown
                  options={statesOptions}
                  placeHolder={'Select State'}
                  disabled={opFieldState ? false : true}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {values.ctdFlag !== 'yes' && (
          <NonCetakToDesignDetail
            getFieldDecorator={getFieldDecorator}
            values={values}
            opFieldTotalUp={opFieldTotalUp}
            opFieldTotalDown={opFieldTotalDown}
            opFieldAgUp={opFieldAgUp}
            opFieldAgDown={opFieldAgDown}
            opFieldOcUp={opFieldOcUp}
            opFieldOcDown={opFieldOcDown}
          />
        )}
        <Row gutter={15}>
          <Col span={6}>
            <Form.Item label="Delivery Atas Remaining">
              {getFieldDecorator('delivUpRemaining', {
                initialValue: values && values.delivUpRemaining,
              })(
                <Input
                  placeholder="Delivery Atas Remaining"
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Delivery Bawah Remaining">
              {getFieldDecorator('delivDownRemaining', {
                initialValue: values && values.delivDownRemaining,
              })(
                <Input
                  placeholder="Delivery Bawah Remaining"
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Refinement Type">
              {getFieldDecorator('refinementType', {
                initialValue: values && values.refinementType,
              })(
                <Input
                  placeholder="Refinement Type"
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
          {/* UNUSED CODE PLS DELETE AFTER THIS COMMIT
          <Col span={6}>
            <Form.Item label="Total Atas (UP)">
              {getFieldDecorator('totalUp', {
                initialValue: values && values.totalUp,
                ...(values.stateId === '1041'
                  ? {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Total Atas (UP)!',
                        },
                      ],
                    }
                  : ''),
              })(
                <Input
                  placeholder="Total Atas (UP)"
                  autoComplete="false"
                  size="large"
                  disabled={
                    opFieldTotalUp
                      ? values.orderType === '15131' ||
                        values.orderType === '16131'
                      : true
                  }
                />
              )}
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Total Bawah (DOWN)">
              {getFieldDecorator('totalDown', {
                initialValue: values && values.totalDown,
                ...(values.stateId === '1041'
                  ? {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Total Bawah (DOWN)!',
                        },
                      ],
                    }
                  : ''),
              })(
                <Input
                  placeholder="Total Bawah (DOWN)"
                  autoComplete="false"
                  size="large"
                  disabled={
                    opFieldTotalDown
                      ? values.orderType === '15121' ||
                        values.orderType === '16121'
                      : true
                  }
                />
              )}
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item label="Contact Number">
              {getFieldDecorator('customerPhone', {
                initialValue: values && values.customerPhone,
                // rules: [
                //   {
                //     // type: 'number',
                //     required: values.stateId === '1022' ? true : false,
                //     message: 'Please input contact number!',
                //   },
                // ],
              })(
                <Input
                  placeholder="Contact Number"
                  autoComplete="false"
                  size="large"
                  disabled={opFieldContactNumber ? false : true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Handover">
              <Input
                placeholder="Handover"
                autoComplete="false"
                size="large"
                disabled={opFieldHandover ? false : true}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Outsource Round 1">
              <Input
                placeholder="Outsource Round 1"
                autoComplete="false"
                size="large"
                disabled={opFieldOutsourceRound1 ? false : true}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Outsource Round 2">
              <Input
                placeholder="Outsource Round 2"
                autoComplete="false"
                size="large"
                disabled={opFieldOutsourceRound2 ? false : true}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Designer">
              {getFieldDecorator('designerName', {
                initialValue: values && values.designerName,
              })(
                <Input
                  placeholder={'Designer'}
                  autoComplete="false"
                  size="large"
                  disabled={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Admin Designer">
              {getFieldDecorator('adminDesignerName', {
                initialValue: values.adminDesignerName,
              })(
                <Input
                  placeholder={'Admin Designer'}
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Keterangan Order RA/RB">
              {getFieldDecorator('raRbRemarks', {
                initialValue: values && values.raRbRemarks,
              })(
                <Input
                  placeholder="Keterangan Order RA/RB"
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* <Col span={6}>
            <Form.Item label="Nama Dokter">
              {getFieldDecorator('doctorName', {
                initialValue: values && values.doctorName,
              })(
                <Input
                  placeholder="Nama Dokter"
                  autoComplete="false"
                  size="large"
                  disabled
                />
              )}
            </Form.Item>
          </Col> */}

        <div className="order-detail__checkbox">
          <Form.Item>
            {getFieldDecorator('attachment', {
              initialValue: values && values.attachment,
              valuePropName: 'checked',
            })(
              <Checkbox
                size={'large'}
                disabled={opFieldTreatAttachment ? false : true}
                value={true}>
                Treat Attachment
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('slicing', {
              initialValue: values && values.slicing,
              valuePropName: 'checked',
            })(
              <Checkbox
                size={'large'}
                disabled={opFieldTreatSlicing ? false : true}
                value={true}>
                Treat Slicing
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('printType', {
              initialValue: values && values.printType === 1 ? true : false,
              valuePropName: 'checked',
            })(
              <Checkbox
                size={'large'}
                value={true}
                onChange={on3DScanChange}
                disabled={opField3dScan ? false : true}>
                3D Scan
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('testFit', {
              initialValue: values && values.testFit,
              valuePropName: 'checked',
            })(
              <Checkbox
                size={'large'}
                disabled={opFieldTestFit ? false : true}
                value={'Test Fit'}>
                Test Fit
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('flagged', {
              initialValue: values && values.flagged,
              valuePropName: 'checked',
            })(
              <Checkbox size={'large'} disabled value={'Test Flagged'}>
                Is Flagged
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('ocChecklist', {
              initialValue: values && values.ocChecklist,
              valuePropName: 'checked',
            })(
              <Checkbox size={'large'} disabled value={true}>
                Over Correct
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('removal', {
              initialValue: values && values.removalRequested,
              valuePropName: 'checked',
            })(
              <Checkbox
                size={'large'}
                disabled={opFieldRemovalRequested ? false : true}
                value={true}>
                Removal Requested
              </Checkbox>
            )}
          </Form.Item>
        </div>

        <PaymentDetail />
        {convertStateId >= 1061 && values.ctdFlag === 'yes' && (
          <>
            <FinalSTL orderDetailData={values} />
            <AlignerDetail orderDetailData={values} />

            <Row gutter={15}>
              <Col span={6}>
                <Form.Item label="Print Atas Remaining">
                  {getFieldDecorator('printUpRemaining', {
                    initialValue: values && values.printUpRemaining,
                  })(
                    <Input
                      placeholder="Print Atas Remaining"
                      autoComplete="false"
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Print Bawah Remaining">
                  {getFieldDecorator('printDownRemaining', {
                    initialValue: values && values.printDownRemaining,
                  })(
                    <Input
                      placeholder="Print Bawah Remaining"
                      autoComplete="false"
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Delivery Atas Remaining">
                  {getFieldDecorator('delivUpRemaining', {
                    initialValue: values && values.delivUpRemaining,
                  })(
                    <Input
                      placeholder="Delivery Atas Remaining"
                      autoComplete="false"
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Delivery Bawah Remaining">
                  {getFieldDecorator('delivDownRemaining', {
                    initialValue: values && values.delivDownRemaining,
                  })(
                    <Input
                      placeholder="Delivery Bawah Remaining"
                      autoComplete="false"
                      size="large"
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {convertStateId >= 1025 &&
          convertStateId <= 1065 &&
          values.ctdFlag === 'yes' && <Impression orderDetailData={values} />}
        {convertStateId >= 1040 &&
          convertStateId <= 1065 &&
          values.ctdFlag === 'yes' &&
          values.flagged === false && (
            <SimulationDetail orderDetailData={values} />
          )}
        {convertStateId >= 1040 && values.ctdFlag === 'yes' && (
          <SimulationDetailNotes orderDetailData={values} />
        )}

        {values.orderStatusId === 'F' ||
          (values.orderStatusId === 'A' && ( //Edit
            <Form.Item label="Flagged Note">
              {getFieldDecorator('flagNote', {
                initialValue:
                  values && values.flagNote && values.flagNote.includes('||')
                    ? replaceCharacterWithLine(values.flagNote, '||')
                    : values.flagNote,
              })(
                <TextArea
                  placeholder="Flagged Note"
                  rows={4}
                  autoComplete="false"
                  className={whenResolve ? 'd-none' : 'd-block'}
                  disabled
                />
              )}
            </Form.Item>
          ))}

        {/* <Form.Item label="Approval Design by Patient">
        {getFieldDecorator('customerNote', {
          initialValue:
            values &&
            values.approvalDesignNote &&
            values.approvalDesignNote.includes('||')
              ? replaceCharacterWithLine(values.approvalDesignNote, '||')
              : values.approvalDesignNote,
          rules: [
            {
              required: false,
              message: 'Please fill this text area!',
            },
          ],
        })(
          <TextArea
            placeholder="Approval Design by Patient"
            rows={4}
            autoComplete="false"
            disabled
          />
        )}
      </Form.Item> */}

        <Form.Item label="User Note">
          {getFieldDecorator('userNote', {
            initialValue:
              values && values.userNote && values.userNote.includes('||')
                ? replaceCharacterWithLine(values.userNote, '||')
                : values.userNote,
          })(
            <TextArea
              placeholder="User Note"
              disabled={opFieldUserNote ? false : true}
              rows={4}
              autoComplete="false"
            />
          )}
        </Form.Item>

        <Form.Item label="System Note">
          {getFieldDecorator('systemNote', {
            initialValue:
              values && values.systemNote && values.systemNote.includes('||')
                ? replaceCharacterWithLine(values.systemNote, '||')
                : values.systemNote,
          })(
            <TextArea
              placeholder="System Note"
              rows={4}
              autoComplete="false"
              disabled
            />
          )}
        </Form.Item>

        {!opBasic && (
          <>
            <Row gutter={15} className="order-detail__btn-select">
              {opCreateRefinement &&
                values.orderStatusId !== 'F' &&
                values.orderStatusId !== 'P' && (
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        onClick={handleShowModalRefinement}>
                        Create Refinement
                      </Button>
                    </Form.Item>
                  </Col>
                )}

              {(values.orderStatusId === 'A' ||
                values.orderStatusId === 'C') && (
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      onClick={handleButtonReactive}>
                      Re-Active Order
                    </Button>
                  </Form.Item>
                </Col>
              )}

              {values.orderStatusId !== 'A' && (
                <>
                  {!values.flagged && opAddAddress && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleShowAddAddress}>
                          Add Address
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {opFlag && values.orderStatusId !== 'F' && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          disabled={values.orderStatusId === 'F' ? true : false}
                          onClick={
                            values.orderStatusId === 'F'
                              ? {}
                              : handleShowModalFlag
                          }>
                          Flag
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                  {opArchive && values.orderStatusId !== 'P' && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleShowModalArchived}>
                          Archive
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {values.deadlineStatus !== 'Finished' &&
                    (convertStateId < 1040 ||
                      convertStateId > 1050 ||
                      values.ctdFlag !== 'yes') && (
                      <>
                        {(opProgress ||
                          (!isEmpty(userByEmail) &&
                            userByEmail[0].stationId === '121' &&
                            values.stateId === '1063')) &&
                          !isEmpty(prodFlowList) &&
                          values.orderStatusId !== 'F' && (
                            <Col span={6}>
                              <Form.Item>
                                {/* There's two kind of flows in button progress, click button then auto progress or click button then show other modal:
                            - If htmlType empty string, it means it will show other modal
                            - If htmlType submit, then it will auto progress to next state.
                            - Default code: if "percabangan ke next state hanya satu", then if u click, without any setting this button will progress to next state.
                            - If you want to make specifict setting if "percabangan hanya satu, dan memunculkan modal", u can set if else like below.
                            */}

                                <Button
                                  loading={loadingButton}
                                  size="large"
                                  type="primary"
                                  htmlType={
                                    // prodFlowList[0].nextStateId === '1042' ||
                                    prodFlowList[0].nextStateId === '1073' ||
                                    prodFlowList[0].nextStateId === '1081' ||
                                    prodFlowList[0].nextStateId === '1093' ||
                                    prodFlowList[0].nextStateId === '2011' ||
                                    prodFlowList[0].nextStateId === '1031' ||
                                    prodFlowList[0].nextStateId === '1063' ||
                                    ((values.stateId === '1074i' ||
                                      values.stateId === '1074o') &&
                                      prodFlowList[0].nextStateId === '1090') ||
                                    prodFlowList[0].nextStateId === '1083' ||
                                    prodFlowList[0].nextStateId === '1087' ||
                                    prodFlowList[0].nextStateId === '1025' ||
                                    prodFlowList[0].nextStateId === '1040' ||
                                    prodFlowList.length >= 2
                                      ? ''
                                      : 'submit'
                                  }
                                  onClick={
                                    values.stateId === '1025'
                                      ? handleShowModalProgressBox
                                      : values.confirmClinic === true &&
                                        values.stateId === '1022'
                                      ? handleModalConfirmCetakUlang
                                      : prodFlowList.length >= 2
                                      ? handleShowModalProductionFlow
                                      : prodFlowList[0].stateId === '1022' &&
                                        (prodFlowList[0].nextStateId ===
                                          '1025' ||
                                          prodFlowList[0].nextStateId ===
                                            '1026') &&
                                        values.ctdFlag === 'yes'
                                      ? handleShowModalPrintType
                                      : prodFlowList[0].nextStateId === '1073'
                                      ? handleShowModalPrintUp
                                      : prodFlowList[0].nextStateId === '1093'
                                      ? handleShowModalPrintUp
                                      : prodFlowList[0].nextStateId ===
                                          '2011' ||
                                        ((values.stateId === '1074i' ||
                                          values.stateId === '1074o') &&
                                          prodFlowList[0].nextStateId ===
                                            '1090') ||
                                        prodFlowList[0].nextStateId ===
                                          '1083' ||
                                        prodFlowList[0].nextStateId === '1081'
                                      ? handleShowModalDelivUp
                                      : prodFlowList[0].nextStateId === '1031'
                                      ? handleShowModalProgressBox
                                      : prodFlowList[0].nextStateId === '1063'
                                      ? handleShowModalDeliveryAddress
                                      : prodFlowList[0].nextStateId === '1087'
                                      ? handleShowModalProgressAppointmentSV2
                                      : prodFlowList[0].nextStateId === '1044'
                                      ? handleShowModalVideoSentDate
                                      : prodFlowList[0].nextStateId ===
                                          '1040' ||
                                        prodFlowList[0].nextStateId === '1061'
                                      ? handleShowModalImpression
                                      : handleSubmitProgressAuto
                                  }>
                                  Progress
                                </Button>
                              </Form.Item>
                            </Col>
                          )}
                      </>
                    )}

                  {!values.flagged && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={
                            values.stateId === '1022'
                              ? handleShowModalRescheduleAppointmentWithClinic
                              : values.stateId === '1087'
                              ? handleShowModalAppointmentSV2
                              : handleShowModalAppointment
                          }
                          disabled={values.appointmentTime ? false : true}>
                          Reschedule Appointment
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {!values.flagged && opReplaceBox && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleShowModalReplaceBox}>
                          Replace Box
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {!values.flagged && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleShowSlaDeadline}>
                          Adjust SLA Deadline
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {!values.flagged &&
                    (values.stateId === '1090' ||
                      values.stateId === '1040') && (
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            size="large"
                            type="primary"
                            onClick={
                              values.stateId === '1040'
                                ? handleShowModalConfirmCU
                                : handleShowModalCetakUlang
                            }>
                            Cetak Ulang
                          </Button>
                        </Form.Item>
                      </Col>
                    )}

                  {!values.flagged && values.stateId === '1087' && (
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          onClick={handleShowModalTroubleshoot}>
                          Troubleshoot
                        </Button>
                      </Form.Item>
                    </Col>
                  )}

                  {!values.flagged &&
                    (values.stateId === '2011' ||
                      values.stateId === '1090') && (
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            size="large"
                            type="primary"
                            onClick={handleModalRefinementTroubleshoot}>
                            {(values.stateId === '2011' && 'Refinement') ||
                              (values.stateId === '1090' && 'Troubleshoot')}
                          </Button>
                        </Form.Item>
                      </Col>
                    )}

                  {(values.orderStatusId === 'F' ||
                    (whenFlag && !whenResolve)) &&
                    opResolve && (
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            size="large"
                            type="primary"
                            onClick={handleShowModalResolve}>
                            Resolved
                          </Button>
                        </Form.Item>
                      </Col>
                    )}
                </>
              )}

              {values.orderStatusId === 'P' &&
                (values.stateId === '1042o' || values.stateId === '1044') && (
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        disabled={
                          !['H1A', 'H2A'].includes(values.flowId) ||
                          !opUpgradeDp
                        }
                        onClick={confirmUpgradeDP}>
                        Waiting Next Payment
                      </Button>
                    </Form.Item>
                  </Col>
                )}
            </Row>
            {opViewAddress && (
              <Collapse accordion className="order-detail_delivery">
                <Panel header="Delivery" key="1">
                  <TableGlobal
                    tableName={'Addresses'}
                    inputPlaceholder={'Search Address Here ...'}
                    pageSize={8}
                    classCustom={'panel--table-delivery'}
                    originalData={orderShippingById}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    tableColumns={deliveryColums}
                    editableTable={false}
                    exportCsvIsExist={false}
                    activeFilterComponent={activeFilterComponent()}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    innerRef={innerRef}
                    filterComponent={filterComponent()}
                    onRowPathname={handleOnRowPathname}
                  />
                </Panel>
              </Collapse>
            )}
            {
              <Collapse accordion className="order-detail_delivery mt-20 mb-35">
                <Panel header="Clinic Note" key="1">
                  <TableGlobal
                    tableName={'Clinic Note'}
                    inputPlaceholder={'Search'}
                    pageSize={8}
                    classCustom={'panel--table-delivery'}
                    originalData={clinicNoteByIdData}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                    tableColumns={clinicNotesColums}
                    addDataTable={true}
                    editTable={editTable}
                    handleEditTable={handleEditTable}
                    exportCsvIsExist={false}
                    editableTable={true}
                    activeFilterComponent={activeFilterClinicNotesComponent()}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    innerRef={innerRef}
                    filterComponent={filterClinicNotesComponent()}
                    handleShowAddData={handleShowModalAddData}
                    onRowPathname={handleOnRowClinicNotes}
                    recordKey={(record) => record.clinicNoteId}
                    rowSelection={rowSelection}
                    rowSelected={rowSelected}
                    setRowSelected={setRowSelected}
                    handleDeleteRow={handleDeleteRow}
                  />
                  <p style={{ marginTop: 'auto' }}>
                    <Icon type="sync" /> Last update at {latestUpdatedAt} by{' '}
                    {latestUpdatedBy}
                  </p>
                </Panel>
                <ModalAddClinicNote
                  showModalAddData={showModalAddData}
                  handleHideModalAddData={handleHideModalAddData}
                  setShowModalAddData={setShowModalAddData}
                  states={states}
                  values={values}
                  getClinicNoteListData={getClinicNoteListData}
                />
              </Collapse>
            }
          </>
        )}
      </div>
      <RefinementHistory orderDetailData={values} />
    </>
  );
};

// OrderDetailActive.propTypes = {
//   onOrderTypeChange: PropTypes.func,
//   onCurrentStateChange: PropTypes.func,
//   onOverdueChange: PropTypes.func,
//   onFlaggedChange: PropTypes.func,
// };

const mapStateToProps = ({ clinicNoteByIdData }) => ({
  clinicNoteByIdData,
});

export default withRouter(
  connect(mapStateToProps, { getClinicNoteById })(OrderDetailActive)
);
