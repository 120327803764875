import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

export const ActiveFilter = ({
  activeFilterData,
  setActiveFilter,
  setShowFilter,
}) => {
  const removeEachFilter = (param) => (e) => {
    e.preventDefault();

    switch (param) {
      case 'order-type':
        setActiveFilter({
          ...activeFilterData,
          orderType: '-',
        });
        setShowFilter(false);
        break;
      case 'start-date':
        setActiveFilter({
          ...activeFilterData,
          startDate: '-',
        });
        setShowFilter(false);
        break;
      case 'end-date':
        setActiveFilter({
          ...activeFilterData,
          endDate: '-',
        });
        setShowFilter(false);
        break;

      default:
        break;
    }
  };

  return (
    <div className="active-filter d-flex">
      {activeFilterData.orderType !== '-' && (
        <div className="d-flex active-filter__item mr-10">
          <div className="mr-10 text-sm">{activeFilterData.orderType}</div>
          <Button
            className="active-filter__btn-close"
            icon="close"
            size="small"
            onClick={removeEachFilter('order-type')}
          />
        </div>
      )}
      {activeFilterData.startDate !== '-' && (
        <div className="d-flex active-filter__item mr-10">
          <div className="mr-10 text-sm">{activeFilterData.startDate}</div>
          <Button
            className="active-filter__btn-close"
            icon="close"
            size="small"
            onClick={removeEachFilter('start-date')}
          />
        </div>
      )}
      {activeFilterData.endDate !== '-' && (
        <div className="d-flex active-filter__item mr-10">
          <div className="mr-10 text-sm">{activeFilterData.endDate}</div>
          <Button
            className="active-filter__btn-close"
            icon="close"
            size="small"
            onClick={removeEachFilter('end-date')}
          />
        </div>
      )}
    </div>
  );
};

ActiveFilter.propTypes = {
  activeFilterData: PropTypes.object,
  setActiveFilter: PropTypes.func,
  setShowFilter: PropTypes.func,
};

export default ActiveFilter;
