import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import './styles/index.scss';

export const MultiselectDropdown = ({
  onChange,
  placeHolder,
  options,
  selectValue,
}) => {
  const { Option } = Select;

  return (
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        onChange={onChange}
        placeholder={placeHolder}
        size="large"
        value={selectValue}
        tokenSeparators={[',']}>
        {options.map((value, index) => (
          <Option value={value.value} key={index}>
            {value.label}
          </Option>
        ))}
      </Select>
  );
};

MultiselectDropdown.propTypes = {
  onChange: PropTypes.func,
  placeHolder: PropTypes.string,
  options: PropTypes.array,
  selectValue: PropTypes.array,
};

MultiselectDropdown.defaultProps = {
  options: [],
};

export default MultiselectDropdown;
