import React from 'react';
import { Modal, Form, Button } from 'antd';

export const ModalSingleWithoutSubmit = ({
  showModal,
  handleHideModal,
  modalName,
  showOtherModal,
  form,
  setFloatingData,
  floatingData,
  idContainer,
  modalTitle,
  modalDesc,
  fieldDecoratorName,
  inputComponent,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitModal = () => {
    form.validateFields(async (err, values) => {
      if (modalName === 'customer-name-modal') {
        setFloatingData({
          ...floatingData,
          customerName: values.customerName,
        });
      } else {
        setFloatingData({
          ...floatingData,
          primaryOrderNumber: values.primaryOrderNumber,
        });
      }
    });

    handleHideModal();
    showOtherModal();
  };

  return (
    <Modal
      title={modalTitle}
      centered
      visible={showModal}
      getContainer={() =>
        idContainer ? document.getElementById(idContainer) : document.body
      }
      onOk={() => handleSubmitModal()}
      onCancel={() => handleHideModal()}
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModal}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">{modalDesc}</div>
      <Form>
        <Form.Item>
          {getFieldDecorator(fieldDecoratorName, {})(inputComponent)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(ModalSingleWithoutSubmit);
