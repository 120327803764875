import { combineReducers } from 'redux';

import { listClinic } from './clinic-reducer/GetClinicReducer';
import { listBeErrorLog } from './be-error-reducer/GetBeErrorListReducer';
import { beErrorLogById } from './be-error-reducer/GetBeErrorByIdReducer';
import {
  listStationOrder,
  listDashboardStationOrder,
} from './OrderReducer/GetStationOrderReducer';
import { listSummaryDashboardOrder, listSummaryDashboardOrderRataPlus } from './OrderReducer/GetSummaryDashboardReducer';
import {
  listOverdueOrder,
  overdueNumber,
} from './OrderReducer/GetOverdueOrderReducer';
import { listDashboardByDate } from './OrderReducer/GetDashboardListByDateReducer';
import { listUser } from './auth-reducer/GetUserListReducer';
import {
  listStationState,
  listStationHistory,
  eKanbanPrintLabelData,
  eKanbanData,
  eKanbanForm,
} from './EkanbanReducer/';
import { listState, listPrintGroup } from './StateReducer';
import { refinementHistoryList, resetHistoryList } from './OrderReducer';
import { listRefundRequestData } from './RefundRequestReducer';
import { isSidebarClose } from './component-reducer/ToggleSidebarReducer';
import {
  listSimulationDesignData,
  simulationDesignByIdData,
  listSoNumberSimulationData,
  listVideoVersionSimulationData,
  listStatusSimulationData,
  listPatientNameSimulationData,
  orderTypeSimulationData,
  listDoctorSimulationData,
  listDesignerSimulationData,
  listAdminDesignerSimulationData,
  listVideoRevisionHistoryData,
  listArchivedSimulationHistoryData,
  listImpressionHistoryData,
} from './SimulationDesignReducer';
import { paymentDetailByIdData } from './PaymentDetailReducer';
import {
  clinicNoteByIdData,
  clinicNoteDetailByIdData,
  clinicNoteListData,
  clinicNoteStatusData,
  clinicNoteSubStatusByIdData,
} from './ClinicNoteReducer';
import appReducer from './v2/appReducer';

const RootReducer = combineReducers({
  appReducer,
  listClinic,
  listBeErrorLog,
  beErrorLogById,
  listStationOrder,
  listSummaryDashboardOrder,
  listSummaryDashboardOrderRataPlus,
  listOverdueOrder,
  listDashboardByDate,
  listUser,
  listStationState,
  listStationHistory,
  eKanbanPrintLabelData,
  listState,
  listPrintGroup,
  refinementHistoryList,
  resetHistoryList,
  eKanbanData,
  eKanbanForm,
  listDashboardStationOrder,
  overdueNumber,
  listRefundRequestData,
  isSidebarClose,
  listSimulationDesignData,
  simulationDesignByIdData,
  listSoNumberSimulationData,
  listVideoVersionSimulationData,
  listStatusSimulationData,
  listPatientNameSimulationData,
  orderTypeSimulationData,
  listDoctorSimulationData,
  listDesignerSimulationData,
  listAdminDesignerSimulationData,
  paymentDetailByIdData,
  clinicNoteByIdData,
  clinicNoteDetailByIdData,
  clinicNoteListData,
  clinicNoteStatusData,
  clinicNoteSubStatusByIdData,
  listVideoRevisionHistoryData,
  listArchivedSimulationHistoryData,
  listImpressionHistoryData,
});

export default RootReducer;
