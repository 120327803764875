import OrderApi from 'api/order';
import { setToArray } from 'utils';

import { GET_LIST_SUMMARY_DASHBOARD_ORDER, GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS } from '../../../type';

export const getListSummaryDashboardOrder =
  (availableState) => async (dispatch) => {
    const response = await OrderApi.getSummaryDashboard(availableState);

    dispatch({
      type: GET_LIST_SUMMARY_DASHBOARD_ORDER,
      payload: setToArray(response.data),
    });
  };

export const getListSummaryDashboardOrderRataPlus =
  (availableState) => async (dispatch) => {
    const response = await OrderApi.getSummaryDashboardRataPlus(availableState);

    dispatch({
      type: GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS,
      payload: setToArray(response.data),
    });
  };