/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import ShippingApi from 'api/shipping';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';

export function ShippingCreateEdit({ form, history }) {
  const [shippingById, setShippingById] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await ShippingApi.updateShipping(
            id,
            values.shippingName
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/shipping',
            });
          }
          setCreateEditMessage(
            theResponse.data,
            'Success Updating Shipping Data with ID ' + id,
            'Error Updating Shipping Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getShippingById() {
      const response = await ShippingApi.getShippingById(id);
      let shippingData = setToArray(response.data);

      setShippingById(shippingData[0]);
    }

    getShippingById();
  }, []);

  return (
    <DetailLayout
      detailTime={`${id}`}
      detailTitle={'Edit Shipping Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="ID">
            {getFieldDecorator('shippingId', {
              rules: [
                {
                  required: true,
                  message: 'Please input shipping ID!',
                },
              ],
              initialValue: shippingById && shippingById.shippingId,
            })(
              <Input
                placeholder="Shipping ID"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('shippingName', {
              rules: [
                { required: true, message: 'Please input shipping name!' },
              ],
              initialValue: shippingById && shippingById.shippingName,
            })(
              <Input
                placeholder="Shipping Name"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const ShippingForm = Form.create({
  name: 'validate_create_shipping',
})(ShippingCreateEdit);

export default { ShippingCreateEdit };
