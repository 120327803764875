import React, { useState, useRef, useContext } from 'react';
// import { isEmpty } from 'lodash';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';
import {
  treatAttachmentColumns,
  treatAttachmentList,
  printTypeColumns,
  printTypeList,
} from '../../../../helper';
import { ShippingContext } from 'contexts/Shipping';

//This modal is used if you want to set fullscreen modal in middle/ start of progress flow, not the end of progress flow
export const ModalFullscreenWithoutSubmit = ({
  showModal,
  handleHideModal,
  setProgress1Data,
  handleShowOtherModal,
  progress1Data,
  stateName,
  tableName,
  handleSubmitFlowAuto,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const { orderShippingById } = useContext(ShippingContext);

  const innerRef = useRef(null);

  const handleOnRowPathname = (record, e) => {
    setProgress1Data({
      ...progress1Data,
      [stateName]:
        stateName === 'attachment' || stateName === 'printType'
          ? record.value
          : record.shippingAddress1,
    });

    handleHideModal();

    if (record.name === 'Putty') {
      if (progress1Data.flowId === "H1" || progress1Data.flowId === "H2") {
        handleSubmitFlowAuto(e, '1026', record);
      } else {
        handleSubmitFlowAuto(e, '1025', record);
      }
    } 
    if (record.name === '3D Scan') {
      if (progress1Data.flowId === "Y1" || progress1Data.flowId === "Y2") {
        handleSubmitFlowAuto(e,'1025', record);
      } 
    }
    handleShowOtherModal();
  };

  const deliveryColumns = [
    {
      title: 'Name',
      key: 'shippingAddress1',
      dataIndex: 'shippingAddress1',
    },
  ];

  const handleOriginalData = () => {
    if (stateName === 'attachment') {
      return treatAttachmentList;
    } else if (stateName === 'printType') {
      return printTypeList;
    } else {
      return orderShippingById;
    }
  };

  const handleColumns = () => {
    if (stateName === 'attachment') {
      return treatAttachmentColumns;
    } else if (stateName === 'printType') {
      return printTypeColumns;
    } else {
      return deliveryColumns;
    }
  };

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        modalComponent={
          <TableGlobal
            tableName={`Select ${tableName}`}
            inputPlaceholder={`Search ${tableName} Here ...`}
            pageSize={8}
            originalData={handleOriginalData()}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={handleColumns()}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />
    </>
  );
};

export default ModalFullscreenWithoutSubmit;
