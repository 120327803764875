/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Tooltip,
  Input,
  Icon,
  Modal,
  message,
} from 'antd';
import { isEqual, isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { AuthenticationContext } from 'contexts/Authentication';

import OrderApi from 'api/order';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray, nestedFilter } from 'utils';
import { useOuterClickNotifier, usePrevious } from 'utils/hooks';

import { archivedOrderColumns, headerArchivedExportCsv } from '../helper';
import Filter from './components/Filter';
import ActiveFilter from './components/ActiveFilter';
import '../styles/index.scss';

const { Content } = Layout;
const { confirm } = Modal;

export function ArchivedOrderList({ history }) {
  const [archivedOrder, setArchivedOrders] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [designStateStatus, setDesignStateStatus] = useState(true);
  const [filteredArchivedOrderData, setFilteredArchivedOrderData] = useState(
    []
  );
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  });
  const [activeFilter, setActiveFilter] = useState({
    orderType: [], //Order type
    orderDesc: [], //Order desc
  });
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState({
    orderOpt: [],
  });
  const [filterShow, setFilterShow] = useState(false);
  const innerRef = useRef(null);
  const prevActiveFilter = usePrevious(activeFilter, setActiveFilter);
  const [rowSelected, setRowSelected] = useState([]);

  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opDeleteArchive, opExportArchive, opEditData } = permissionDataList;
  const available_state = localStorage.getItem('available_state');

  async function getArchivedOrderList() {
    try {
      const response = await OrderApi.getArchivedOrder(available_state, designStateStatus);

      let archivedOrderList = setToArray(response.data);

      setArchivedOrders(archivedOrderList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getArchivedOrderList();
  }, [designStateStatus]);

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleShowSearch = () => {
    setShowInput(true);
    setFilterShow(false);
  };

  const handleHideSearch = () => {
    setShowInput(false);
    setValue('');
    setFilteredArchivedOrderData(archivedOrder);
  };

  const handleFilterShow = () => {
    setFilterShow(!filterShow);
    setShowInput(false);
  };

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
    setOrderTypeFilterValue({});
  };

  const onOrderTypeChange = (checkedValues) => {
    const handleOrderType = checkedValues.map((value) => value.split(' ')[0]);
    const handleOrderDesc = checkedValues.map((value) =>
      value.split('-').pop().replace(' ', '')
    );

    setActiveFilter({
      ...activeFilter,
      orderType: handleOrderType,
      orderDesc: handleOrderDesc,
    });

    setOrderTypeFilterValue({
      orderOpt: checkedValues,
    });
  };

  useEffect(() => {
    setFilteredArchivedOrderData(archivedOrder);

    const orderTypeOpts = archivedOrder.map((value) => ({
      label: `${value.orderType} - ${value.orderDesc}`,
      value: `${value.orderType} - ${value.orderDesc}`,
    }));

    const handleDuplicateOrderTypeOpts = orderTypeOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    setOrderTypeOptions(handleDuplicateOrderTypeOpts);
  }, [archivedOrder]);

  // Filter;
  useEffect(() => {
    const allFilterResult = nestedFilter(archivedOrder, activeFilter);

    if (!isEqual(prevActiveFilter, activeFilter)) {
      setFilteredArchivedOrderData(allFilterResult);
    }
  }, [activeFilter, archivedOrder, prevActiveFilter]);

  //search
  const onInputChange = (e) => {
    const convertValue = e.target.value;
    setValue(convertValue);

    const filteredData = archivedOrder.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key]
            .toString()
            .toLowerCase()
            .search(convertValue.toLowerCase()) !== -1
      );
    });

    setFilteredArchivedOrderData(filteredData);
  };

  useOuterClickNotifier(handleFilterShow, innerRef);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleDeleteRow = async () => {
    const getOrderId = rowSelected.map((value) => value.orderId);
    const getPrimaryOrderNumber = rowSelected.map(
      (value) => value.orderReceiptNumber
    );
    const getOrderIdToString = getOrderId.join(',');

    confirm({
      title: `Are you sure delete this archived order data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then archived order data with primary order number ${getPrimaryOrderNumber} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingArchivedOrder() {
          try {
            await OrderApi.deleteOrderBulk(getOrderIdToString);
            setRowSelected([]);
            getArchivedOrderList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingArchivedOrder();
      },
      onCancel() {},
    });
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <div
          className={`panel ${
            filteredArchivedOrderData.length > 8 ? '' : 'panel--table-global'
          }`}>
          <Row gutter={20} type="flex">
            <Col xs={12}>
              <div className="mb-15">
                {showInput ? (
                  <div className="table-global__search">
                    <Input
                      className="table-global__search-input"
                      placeholder="Search Archived Order Here..."
                      onChange={onInputChange}
                      value={value}
                    />
                    <Button
                      onClick={handleHideSearch}
                      icon="close"
                      className="position-absolute table-global__search-close"
                    />
                  </div>
                ) : (
                  <strong className="text-md table-global__title">
                    Archived Orders
                  </strong>
                )}
              </div>
            </Col>
            <Col xs={12} type="flex" align="end">
              {!isEmpty(rowSelected) ? (
                <Tooltip title="Delete">
                  <Button
                    onClick={handleDeleteRow}
                    icon="delete"
                    className="mr-10"
                  />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Search">
                    <Button
                      onClick={handleShowSearch}
                      icon="search"
                      className="mr-10"
                    />
                  </Tooltip>
                  <Tooltip title="Filter">
                    <Button
                      icon="filter"
                      className="mr-10"
                      onClick={handleFilterShow}
                    />
                  </Tooltip>
                  {filterShow && (
                    <Filter
                      setDesignStateStatus={setDesignStateStatus}
                      designStateStatus={designStateStatus}
                      resetFilter={resetFilter}
                      onOrderTypeChange={onOrderTypeChange}
                      activeFilter={activeFilter}
                      innerRef={innerRef}
                      orderTypeOptions={orderTypeOptions}
                      orderTypeFilterValue={orderTypeFilterValue}
                    />
                  )}
                  {(opDeleteArchive || opEditData) && (
                    <Tooltip title="Edit Data">
                      {editTable ? (
                        <Button
                          icon="unlock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      ) : (
                        <Button
                          icon="lock"
                          className="mr-10"
                          onClick={handleEditTable}
                        />
                      )}
                    </Tooltip>
                  )}
                  {opExportArchive && (
                    <Tooltip title="Download CSV">
                      <CSVLink
                        filename={'archived-order-list-export.csv'}
                        headers={headerArchivedExportCsv}
                        data={filteredArchivedOrderData}>
                        <Icon
                          className="mr-10"
                          type="cloud-download"
                          style={{ fontSize: '20px' }}
                        />
                      </CSVLink>
                    </Tooltip>
                  )}
                </>
              )}
            </Col>
          </Row>
          <ActiveFilter
            activeFilterData={activeFilter}
            setActiveFilter={setActiveFilter}
            setFilterShow={setFilterShow}
            setOrderTypeFilterValue={setOrderTypeFilterValue}
            orderTypeFilterValue={orderTypeFilterValue}
          />
          <Table
            dataSource={filteredArchivedOrderData}
            rowSelection={editTable ? rowSelection : ''}
            scroll={{ x: 800, y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={archivedOrderColumns}
            rowKey={(record) => record.orderId}
            onRow={(record) => ({
              onClick: () =>
                history.push({
                  pathname: `/cms/transaction/order-detail/edit?id=${record.orderId}`,
                  data: { orderDetail: { ...record } },
                  progress: false,
                }),
            })}
            className="table-global__table-item"
          />
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(ArchivedOrderList);
