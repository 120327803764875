import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Button, Tooltip, Input, Icon } from 'antd';
import { isEqual, isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import PropTypes from 'prop-types';

import { nestedFilter } from 'utils';
import { usePrevious, useOuterClickNotifier } from 'utils/hooks';

import './styles/index.scss';

export function TableGlobal({
  rowSelected,
  editTable,
  inputPlaceholder,
  clickNewHistory,
  onRowPathname,
  filterComponent,
  isFilter,
  activeFilterComponent,
  innerRef,
  rowSelection,
  editableTable,
  handleEditTable,
  headerCsv,
  originalData,
  setActiveFilter,
  tableColumns,
  tableName,
  handleSelectRowAction,
  MyDocument,
  activeFilter,
  pageSize,
  recordKey,
  csvName,
  showFilter,
  setShowFilter,
  handleDeleteRow,
  history,
  exportCsvIsExist,
  classCustom,
  scrollX,
  addDataTable,
  handleShowAddData,
  isReload,
  handleReload,
  // setRowSelected,
  rowClassName
}) {
  const onTableChange = (pagination) => {
    setPagination(pagination);
  };
  const prevActiveFilter = usePrevious(activeFilter, setActiveFilter);
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
  });
  const [valueInput, setValueInput] = useState('');
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setFilteredData(originalData);
  }, [originalData]);

  const handleShowSearch = () => {
    setShowInput(true);
    setShowFilter(false);
  };

  const handleHideSearch = () => {
    setShowInput(false);
    setValueInput('');
    setFilteredData(originalData);
  };

  const handleFilterShow = () => {
    setShowFilter(!showFilter);
    setShowInput(false);
  };

  // Filter;
  useEffect(() => {
    const allFilterResult = nestedFilter(originalData, activeFilter);

    if (!isEqual(prevActiveFilter, activeFilter)) {
      setFilteredData(allFilterResult);
    }
  }, [activeFilter, originalData, prevActiveFilter]);

  //   search
  const onInputChange = (e) => {
    const convertValue = e.target.value;
    setValueInput(convertValue);

    const filteredData = originalData.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key]
            .toString()
            .toLowerCase()
            .search(convertValue.toLowerCase()) !== -1
      );
    });

    setFilteredData(filteredData);
  };

  useOuterClickNotifier(handleFilterShow, innerRef);

  return (
    <div
      className={`panel ${classCustom ? classCustom : ''} ${
        filteredData.length > 8 || tableName.includes('Production Flow')
          ? ''
          : 'panel--table-global'
      }`}>
      <Row gutter={20} type="flex" className="table-global__action-item">
        <Col xs={12}>
          <div className="mb-15">
            {showInput ? (
              <div className="table-global__search">
                <Input
                  className="table-global__search-input"
                  placeholder={inputPlaceholder}
                  onChange={onInputChange}
                  value={valueInput}
                  autoFocus
                />
                <Button
                  onClick={handleHideSearch}
                  icon="close"
                  className="position-absolute table-global__search-close"
                />
              </div>
            ) : (
              <strong className="text-md table-global__title">
                {tableName}
              </strong>
            )}
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          {!isEmpty(rowSelected) ? (
            handleSelectRowAction ? (
              <Tooltip title="Select">
                <PDFDownloadLink
                  document={<MyDocument />}
                  fileName={'Print-Multiple-Label'}>
                  <Button
                    onClick={handleSelectRowAction}
                    icon="check-circle"
                    className="mr-10"
                  />
                </PDFDownloadLink>
              </Tooltip>
            ) : (
              <Tooltip title="Delete">
                <Button
                  onClick={handleDeleteRow}
                  icon="delete"
                  className="mr-10"
                />
              </Tooltip>
            )
          ) : (
            <>
              <Tooltip title="Search">
                <Button
                  onClick={handleShowSearch}
                  icon="search"
                  className="mr-10"
                />
              </Tooltip>

              {isFilter && (
                <Tooltip title="Filter">
                  <Button
                    icon="filter"
                    className="mr-10"
                    onClick={handleFilterShow}
                  />
                </Tooltip>
              )}

              {addDataTable && (
                <Tooltip title="Add Data">
                  <Button
                    icon="plus"
                    className="mr-10"
                    onClick={handleShowAddData}
                  />
                </Tooltip>
              )}

              {showFilter && filterComponent}

              {editableTable && (
                <Tooltip title="Edit Data">
                  {editTable ? (
                    <Button
                      icon="unlock"
                      className="mr-10"
                      onClick={handleEditTable}
                    />
                  ) : (
                    <Button
                      icon="lock"
                      className="mr-10"
                      onClick={handleEditTable}
                    />
                  )}
                </Tooltip>
              )}

              {exportCsvIsExist && (
                <Tooltip title="Download CSV">
                  <CSVLink
                    filename={csvName}
                    headers={headerCsv}
                    data={filteredData}>
                    <Icon
                      className="mr-10"
                      type="cloud-download"
                      style={{ fontSize: '20px' }}
                    />
                  </CSVLink>
                </Tooltip>
              )}

              {isReload && (
                <Tooltip title="Reload">
                  <Button icon="reload" onClick={handleReload} />
                </Tooltip>
              )}
            </>
          )}
        </Col>
      </Row>

      {activeFilterComponent}

      <Table
        dataSource={filteredData}
        rowSelection={editTable ? rowSelection : ''}
        scroll={{ x: scrollX, y: 500 }}
        onChange={onTableChange}
        pagination={pagination}
        // rowKey={tableName}
        rowKey={recordKey}
        columns={tableColumns}
        onRow={
          clickNewHistory
            ? (record) => ({
                onClick: () =>
                  history.push({
                    pathname: onRowPathname(record),
                    data: record,
                  }),
              })
            : (record) => ({
                onClick: (e) => {
                  onRowPathname(record, e);
                },
              })
        }
        className="table-global__table-item"
        rowClassName={rowClassName}
      />
    </div>
  );
}

TableGlobal.defaultProps = {
  exportCsvIsExist: true,
  clickNewHistory: true,
  scrollX: 800,
  isFilter: true,
  isReload: false,
};

export default withRouter(TableGlobal);
