import { ORDER_CONSTANTS } from '../../../type/v2/OrderConstants';

const initialState = {
  isLoading: false,
  isError: false,
  errors: null,
  orderList: {
    allData: 0,
    page: 1,
    data: [],
    totalData: 0,
    totalPage: 0,
    limit: 10,
    filters: {},
    search: null,
    offset: 0,
    sortBy: "created_at",
    sort: "descend"
  },
};

/**
 * @description Redux Reducer for order data
 */
export default function order(state = initialState, action) {
  let returnData = state;

  Object.values(ORDER_CONSTANTS).map((ctx) => {
    if (ctx === action.type) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
