/**
 * constant format
 * modules_submodules_action
 */

export const ORDER_CONSTANTS = {
  LIST_REQUEST: 'ORDER_LIST_REQUEST',
  LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
  LIST_FAILED: 'ORDER_LIST_FAILED',

  LIST_FILTERED_REQUEST: 'ORDER_LIST_FILTERED_REQUEST',
  LIST_FILTERED_SUCCESS: 'ORDER_LIST_FILTERED_SUCCESS',
  LIST_FILTERED_FAILED: 'ORDER_LIST_FILTERED_FAILED',

  FILTER_STATUS_REQUEST: 'ORDER_FILTER_STATUS_REQUEST',
  FILTER_STATUS_SUCCESS: 'ORDER_FILTER_STATUS_SUCCESS',
  FILTER_STATUS_FAILED: 'ORDER_FILTER_STATUS_FAILED',
  
  FILTER_AVAIL_STATE_REQUEST: 'ORDER_FILTER_AVAIL_STATE_REQUEST',
  FILTER_AVAIL_STATE_SUCCESS: 'ORDER_FILTER_AVAIL_STATE_SUCCESS',
  FILTER_AVAIL_STATE_FAILED: 'ORDER_FILTER_AVAIL_STATE_FAILED',

  FILTER_STATE_ID_REQUEST: 'ORDER_FILTER_STATE_ID_REQUEST',
  FILTER_STATE_ID_SUCCESS: 'ORDER_FILTER_STATE_ID_SUCCESS',
  FILTER_STATE_ID_FAILED: 'ORDER_FILTER_STATE_ID_FAILED',

  FILTER_TYPE_REQUEST: 'ORDER_FILTER_TYPE_REQUEST',
  FILTER_TYPE_SUCCESS: 'ORDER_FILTER_TYPE_SUCCESS',
  FILTER_TYPE_FAILED: 'ORDER_FILTER_TYPE_FAILED',

  FILTER_STATION_REQUEST: 'ORDER_FILTER_STATION_REQUEST',
  FILTER_STATION_SUCCESS: 'ORDER_FILTER_STATION_SUCCESS',
  FILTER_STATION_FAILED: 'ORDER_FILTER_STATION_FAILED',



  // DETAIL_REQUEST: "ORDER_DETAIL_REQUEST",
  // DETAIL_SUCCESS: "ORDER_DETAIL_SUCCESS",
  // DETAIL_FAILED: "ORDER_DETAIL_FAILED",
};
