import {
  GET_LIST_SIMULATION_DESIGN,
  GET_SIMULATION_DESIGN_BY_ID,
  GET_LIST_PATIENT_NAME_SIMULATION_DESIGN,
  GET_LIST_STATUS_SIMULATION_DESIGN,
  GET_LIST_SO_NUMBER_SIMULATION_DESIGN,
  GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN,
  GET_ORDER_TYPE_SIMULATION_DESIGN,
  GET_LIST_DOCTOR_SIMULATION_DESIGN,
  GET_LIST_DESIGNER_SIMULATION_DESIGN,
  GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN,
  GET_LIST_VIDEO_REVISION_HISTORY,
  GET_LIST_ARCHIVED_SIMULATION_HISTORY,
  GET_LIST_IMPRESSION_SIMULATION_HISTORY,
} from '../../type';

export const listSimulationDesignData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const simulationDesignByIdData = (state = [], action) => {
  switch (action.type) {
    case GET_SIMULATION_DESIGN_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const listPatientNameSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_PATIENT_NAME_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listStatusSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_STATUS_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listVideoVersionSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listSoNumberSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_SO_NUMBER_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const orderTypeSimulationData = (state = '', action) => {
  switch (action.type) {
    case GET_ORDER_TYPE_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listDoctorSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_DOCTOR_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listDesignerSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_DESIGNER_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listAdminDesignerSimulationData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN:
      return action.payload;
    default:
      return state;
  }
};

export const listVideoRevisionHistoryData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_VIDEO_REVISION_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const listArchivedSimulationHistoryData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_ARCHIVED_SIMULATION_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const listImpressionHistoryData = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_IMPRESSION_SIMULATION_HISTORY:
      return action.payload;
    default:
      return state;
  }
};
