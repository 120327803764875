import { GET_LIST_BE_ERROR_LOG } from '../../../type';

export const listBeErrorLog = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_BE_ERROR_LOG:
      return action.payload;
    default:
      return state;
  }
};
