import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'antd';
import OrderApi from 'api/order';
import { isEmpty } from '@antv/util';

//This modal is used if you want to set single modal in middle/ start of progress flow, not the end of progress flow
export const ModalSingleWithoutSubmit = ({
  showModal,
  handleHideModal,
  modalName,
  showOtherModal,
  form,
  setProgress1Data,
  progress1Data,
  idContainer,
  modalTitle,
  modalDesc,
  fieldDecoratorName,
  detailValue,
  inputComponent,
  maxNumberValid2,
  minNumberValid2,
  initValue,
  setIsRedo,
}) => {
  const { getFieldDecorator } = form;
  const [values, setValues] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const checkFlowType = values.flowType === '3' ? 3 : 10;

  // Calling API for getting data order detail by id
  async function getOrderDetailList(id) {
    const response = await OrderApi.listOrderDetailById(id);
    let orderDetailList = response.data.data.list[0];
    setValues(orderDetailList);
  }

  useEffect(() => {
    if (id) {
      getOrderDetailList(id);
    }
  }, [id]);

  const handleSubmitModal = () => {
    form.validateFields(async (err, values) => {
      if (modalName === 'diy-modal') {
        setProgress1Data({
          ...progress1Data,
          diyDeliveryTime: values['diyDeliveryTime'].format(
            'DD-MM-YYYY HH:mm:ss'
          ),
        });
        handleHideModal();
        showOtherModal();
      } else if (
        modalName === 'clinic-note-modal' ||
        modalName === 'redo-appointment-modal'
      ) {
        setProgress1Data({
          ...progress1Data,
          systemNote: values.systemNoteRedoDialog,
        });
        if (modalName === 'redo-appointment-modal') {
          setIsRedo(true);
        }
        handleHideModal();
        showOtherModal();
      } else if (modalName === 'deliv-up-1-modal') {
        setProgress1Data({
          ...progress1Data,
          delivUp1: values.delivUp1,
        });
      } else if (modalName === 'deliv-up-2-modal') {
        setProgress1Data({
          ...progress1Data,
          delivUp2: values.delivUp2,
        });
      } else if (modalName === 'deliv-down-1-modal') {
        setProgress1Data({
          ...progress1Data,
          delivDown1: values.delivDown1,
        });
      } else if (modalName === 'deliv-down-2-modal') {
        setProgress1Data({
          ...progress1Data,
          delivDown2: values.delivDown2,
        });
      } else if (modalName === 'print-up-1-modal') {
        setProgress1Data({
          ...progress1Data,
          printUp1: values.printUp1,
        });
      } else if (modalName === 'print-up-2-modal') {
        setProgress1Data({
          ...progress1Data,
          printUp2: values.printUp2,
        });
      } else {
        setProgress1Data({
          ...progress1Data,
          appointmentTime: values['appointmentTime'].format(
            'DD-MM-YYYY HH:mm:ss'
          ),
        });
        handleHideModal();
        showOtherModal();
      }

      const checkTotalUp =
        // 1072 PAYG PRINT UP 1   = 3 or totalUp (if totalUp < 3)
        // 1092 PAYG PRINT UP 2   = totalUp - printUp1
        // 1074 PAYG DELIV UP 1   = printUp1
        // 1094 PAYG DELIV UP 2   = delivUp1
        // 1072 RATA10 PRINT UP 1 = printUp1 <= 10
        // 1092 RATA10 PRINT UP 2 = totalUp - printUp1
        // 1074 RATA10 DELIV UP 1 = printUp1
        // 1094 RATA10 DELIV UP 2 = delivUp1

        // 1072 PAYG PRINT UP 1
        (detailValue.stateId === '1072' && (detailValue.flowId === 'B1' || detailValue.flowId === 'B2' || detailValue.flowId === 'D1' || detailValue.flowId === 'D2'))
            ?
            detailValue.totalUp >= 3
                ?
                3
                :
                detailValue.totalUp
            :
            // 1072 RATA10 PRINT UP 1
            (detailValue.stateId === '1072' && (detailValue.flowId === 'G1' || detailValue.flowId === 'G2' || detailValue.flowId === 'I1' || detailValue.flowId === 'I2'))
                ?
                10
                :
                // 1074 PAYG DELIV UP 1 & 1074 RATA10 DELIV UP 1 = printUp1
                (detailValue.stateId === '1074i' && detailValue.stateId === '1074o')
                    ?
                    detailValue.printUp1
                    :
                    // 1092 PAYG PRINT UP 2 & 1092 RATA10 PRINT UP 2
                    (detailValue.stateId === '1092')
                        ?
                        detailValue.totalUp - detailValue.printUp1
                        :
                        // 1094 PAYG DELIV UP 2 & 1094 RATA10 DELIV UP 2
                        (detailValue.stateId === '1094i' && detailValue.stateId === '1094o')
                            ?
                            detailValue.delivUp1
                            :
                            100;

        const checkTotalDown =
        // 1072 PAYG PRINT DOWN 1   = 3 or totalDown (if totalDown < 3)
        // 1092 PAYG PRINT DOWN 2   = totalDown - printDown1
        // 1074 PAYG DELIV DOWN 1   = printDown1
        // 1094 PAYG DELIV DOWN 2   = delivDown1
        // 1072 RATA10 PRINT DOWN 1 = 0 <= printDown1 <= 10
        // 1092 RATA10 PRINT DOWN 2 = totalDown - printDown1
        // 1074 RATA10 DELIV DOWN 1 = printDown1
        // 1094 RATA10 DELIV DOWN 2 = delivDown1

        // 1072 PAYG PRINT DOWN 1
        (detailValue.stateId === '1072' && (detailValue.flowId === 'B1' || detailValue.flowId === 'B2' || detailValue.flowId === 'D1' || detailValue.flowId === 'D2'))
            ?
            detailValue.totalDown >= 3
                ?
                3
                :
                detailValue.totalDown
            :
            // 1072 RATA10 PRINT DOWN 1
            (detailValue.stateId === '1072' && (detailValue.flowId === 'G1' || detailValue.flowId === 'G2' || detailValue.flowId === 'I1' || detailValue.flowId === 'I2'))
                ?
                10
                :
                // 1074 PAYG DELIV DOWN 1 & 1074 RATA10 DELIV DOWN 1
                (detailValue.stateId === '1074i' && detailValue.stateId === '1074o')
                    ?
                    detailValue.printDown1
                    :
                    // 1092 PAYG PRINT DOWN 2 & 1092 RATA10 PRINT DOWN 2
                    (detailValue.stateId === '1092')
                        ?
                        detailValue.totalDown - detailValue.printDown1
                        :
                        // 1094 PAYG DELIV DOWN 2 & 1094 RATA10 DELIV DOWN 2
                        (detailValue.stateId === '1094i' && detailValue.stateId === '1094o')
                            ?
                            detailValue.delivDown1
                            :
                            100;

      if (
        values.printUp1 <= checkTotalUp ||
        // values.printDown1 <= checkTotalDown ||
        values.delivUp1 <= checkTotalUp ||
        values.delivDown1 <= checkTotalDown ||

        values.printUp2 === maxNumberValid2 ||
        values.printDown2 === maxNumberValid2 ||
        values.delivUp2 === maxNumberValid2 ||
        values.delivDown2 === maxNumberValid2 ||

        values.printUp2 === minNumberValid2 ||
        values.printDown2 === minNumberValid2 ||
        values.delivUp2 === minNumberValid2 ||
        values.delivDown2 === minNumberValid2
      ) {
        handleHideModal();
        showOtherModal();
      }
    });
  };

  const checkModalUpName =
    modalName.includes('deliv-up') || modalName.includes('print-up');
  const checkModalDownName =
    modalName.includes('deliv-down') || modalName.includes('print-down');

  const checkModalPrintType = modalName.includes('print');
  const checkModalDelivName = modalName.includes('deliv');

  const checkMinValueValidation =
      // deliv --> always refer to print
      checkModalDelivName
      ?
        modalName.includes('1')
        ?
          checkModalUpName
          ?
            // 1074 deliv up (both type)
            detailValue.printUp1
          :
            // 1074 deliv down (both type)
            detailValue.printDown1
        :
          checkModalUpName
          ?
            // 1094 deliv up (both type)
            detailValue.printUp2
          :
            // 1094 deliv down (both type)
            detailValue.printDown2
      :
        // print
        modalName.includes('2')
        ?
          // 1092
          checkModalUpName
          ?
            // 1092 print up (both type)
            detailValue.printUpRemaining
          :
            // 1092 print down (both type)
            detailValue.printDownRemaining
        :
          // 1072
          checkModalUpName
          ?
            // 1072 UP
            (detailValue.flowId.includes('G') || detailValue.flowId.includes('I') || detailValue.flowId.includes('Y'))
            ?
              // 1072 UP - TYPE RATA
              0
            :
              // 1072 UP - TYPE PAYG
              minNumberValid2
          :
            // 1072 DOWN
            (detailValue.flowId.includes('G') || detailValue.flowId.includes('I') || detailValue.flowId.includes('Y'))
            ?
              // 1072 DOWN - TYPE RATA
              10 - progress1Data.printUp1
            :
              // 1072 DOWN - TYPE PAYG
              minNumberValid2
      ;

    const checkMaxValueValidation =
      // deliv --> always refer to print
      checkModalDelivName
      ?
        modalName.includes('1')
        ?
          checkModalUpName
          ?
            // 1074 deliv up (both type)
            detailValue.printUp1
          :
            // 1074 deliv down (both type)
            detailValue.printDown1
        :
          checkModalUpName
          ?
            // 1094 deliv up (both type)
            detailValue.printUp2
          :
            // 1094 deliv down (both type)
            detailValue.printDown2
      :
        // print
        modalName.includes('2')
        ?
          // 1092
          checkModalUpName
          ?
            // 1092 print up (both type)
            detailValue.printUpRemaining
          :
            // 1092 print down (both type)
            detailValue.printDownRemaining
        :
          // 1072
          checkModalUpName
          ?
            // 1072 UP
            (detailValue.flowId.includes('G') || detailValue.flowId.includes('I') || detailValue.flowId.includes('Y'))
            ?
              // 1072 UP - TYPE RATA
              10
            :
              // 1072 UP - TYPE PAYG
              maxNumberValid2
          :
            // 1072 DOWN
            (detailValue.flowId.includes('G') || detailValue.flowId.includes('I') || detailValue.flowId.includes('Y'))
            ?
              // 1072 DOWN - TYPE RATA
              10 - progress1Data.printUp1
            :
              // 1072 DOWN - TYPE PAYG
              maxNumberValid2
      ;

  return (
    <Modal
      title={modalTitle}
      centered
      visible={showModal}
      getContainer={() =>
        idContainer ? document.getElementById(idContainer) : document.body
      }
      onOk={() => handleSubmitModal()}
      onCancel={() => handleHideModal()}
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModal}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">{modalDesc}</div>
      <Form>
        <Form.Item>
          {getFieldDecorator(fieldDecoratorName, {
            ...(modalName.includes('deliv') || modalName.includes('print')
              ? {
                  getValueFromEvent: (e) => {
                    const convertedValue = Number(e.currentTarget.value);
                    if (isNaN(convertedValue)) {
                      return Number(form.getFieldValue(fieldDecoratorName));
                    } else {
                      return convertedValue;
                    }
                  },
                }
              : ''),
            rules:
              modalName.includes('deliv') || modalName.includes('print')
                ? [
                    {
                      required: true,
                      type: 'number',
                      // max: checkMaxValueValidation,
                      // min: checkMinValueValidation,
                      message: `Jumlah ${
                        modalName.includes('print')
                          ? `Print ${
                              modalName.includes('down') ? 'Bawah' : 'Atas'
                            }`
                          : `Delivery ${
                              modalName.includes('down') ? 'Bawah' : 'Atas'
                            }`
                      } ${
                        (values.stateId === '1092' || values.stateId === '1094i' || values.stateId === '1094o') ||
                        (values.flowId === 'B1' || values.flowId === 'B2' || values.flowId === 'D1' || values.flowId === 'D2') ||
                        ((values.flowId === 'G1' || values.flowId === 'G2' || values.flowId === 'I1' || values.flowId === 'I2' || values.flowId === 'Y1' || values.flowId === 'Y2') && values.stateId === '1072' && modalName.includes('print-down')) ||
                        ((values.flowId === 'G1' || values.flowId === 'G2' || values.flowId === 'I1' || values.flowId === 'I2' || values.flowId === 'Y1' || values.flowId === 'Y2') && (values.stateId === '1074i' && values.stateId === '1074o'))
                          ? ' must be '
                          : ' cannot be greater than '
                      } ${checkMaxValueValidation}!`,
                    },
                  ]
                : [],
            initialValue: initValue,
          })(inputComponent)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(ModalSingleWithoutSubmit);
