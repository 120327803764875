import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// antd ---------
import { Modal, Form, Checkbox, Col, Button, message } from 'antd';
//  ---------
import './style.scss';

import OrderApi from 'api/order';

const ModalAdditionalAligner = ({ showModal, form, handleHideModal }) => {
  const [loading, setLoading] = useState(false);
  //add aligner modal form
  const [inputRahangAtas, setInputRahangAtas] = useState(false);
  const [inputRahangBawah, setInputRahangBawah] = useState(false);

  const { getFieldDecorator } = form;
  const history = useHistory();

  const handleSubmitModalAddAligner = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        let archType;
        //RA RB validation
        //RA RB validation
        if (!inputRahangAtas && !inputRahangBawah) {
          message.error('Set bermasalah perlu diisi');
          return;
        }
        if (
          (inputRahangAtas &&
          !values.problemSetRa) &&
          (inputRahangBawah &&
          !values.problemSetRb)
        ) {
          message.error('Set bermasalah perlu diisi');
          return;
        } else if (inputRahangAtas && !values.problemSetRa) {
          message.error('RA Belum diisi');
          return;
        } else if (inputRahangBawah && !values.problemSetRb) {
          message.error('RB Belum diisi');
          return;
        }
        //RA RB validation end

        if (
          values.problemSetRa &&
          inputRahangAtas &&
          values.problemSetRb &&
          inputRahangBawah
        ) {
          archType = 'Both';
        } else if (values.problemSetRa && inputRahangAtas) {
          archType = 'Upper';
        } else if (values.problemSetRb && inputRahangBawah) {
          archType = 'Lower';
        }

        // build request payload
        let payload = {
          orderReceiptNumber: values.orderReceiptNumber,
          createdBy: localStorage.getItem('username'),
          problemSetRa: values.problemSetRa && inputRahangAtas ? 1 : 0,
          problemSetRb: values.problemSetRb && inputRahangBawah ? 1 : 0,
          refinementType: 'Additional Aligner',
          archType: archType,
          refinementGroup: 2,
        };

        let response;
        try {
          setLoading(true);
          response = await OrderApi.postOrderTroubleshootTrigger(payload);
        } catch (err) {
          if (err.response.data.message) {
            message.error(err.response.data.message);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoading(false);
          if (response) {
            if (response.data.status === 'FAILED') {
              message.error(response.data.desc);
            } else {
              //navigate to active order
              history.push('/cms/order/active');
            }
          }
        }
      }
    });
  };

  return (
    <Modal
      closable={false}
      title="Additional Aligner"
      visible={showModal}
      okText="Save"
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => handleSubmitModalAddAligner()}>
          Save
        </Button>,
      ]}>
      <Form>
        <Form.Item label="Set Bermasalah" required>
          <Col span={6}>
            {getFieldDecorator('problemSetRa')(
              <Checkbox onChange={() => setInputRahangAtas((val) => !val)}>
                RA
              </Checkbox>
            )}
          </Col>
          <Col span={6}>
            {getFieldDecorator('problemSetRb')(
              <Checkbox onChange={() => setInputRahangBawah((val) => !val)}>
                RB
              </Checkbox>
            )}
          </Col>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAdditionalAligner;
