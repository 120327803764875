import PaymentDetailApi from 'api/payment-detail';
import { setToArray } from 'utils';

import { GET_LIST_PAYMENT_DETAIL_BY_ID } from '../../type';

export const getPaymentDetailById = (id) => async (dispatch) => {
  const response = await PaymentDetailApi.getPaymentDetailById(id);

  dispatch({
    type: GET_LIST_PAYMENT_DETAIL_BY_ID,
    payload: setToArray(response.data),
  });
};
