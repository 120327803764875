/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, Input, Form, Button, message, DatePicker } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import SelectDropdown from 'components/SelectDropdown';
import { convertOptions } from 'utils';
import ClinicNoteApi from 'api/clinic-note';
import {
  getStatusClinicNote,
  getClinicNoteById,
  getClinicNoteSubStatusById,
} from 'store/action/ClinicNoteAction';

import moment from 'moment';

const { TextArea } = Input;

export const ModalAddClinicNote = ({
  states,
  getClinicNoteById,
  showModalAddData,
  handleHideModalAddData,
  setShowModalAddData,
  values,
  form,
  getStatusClinicNote,
  clinicNoteStatusData,
  clinicNoteSubStatusByIdData,
  getClinicNoteSubStatusById,
}) => {
  const { getFieldDecorator } = form;
  // const [clinicStatus, setClinicStatusStates] = useState([]);

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  const handleSubmitAddData = () => {
    form.validateFields(async (err, value) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          const getSpecificStatusClinicNoteData = clinicNoteStatusData.find(
            (statusValue) =>
              statusValue.noteStatusDesc === value.clinicNoteStatusName
          );

          const getSpecificSubStatusClinicNoteData = clinicNoteSubStatusByIdData.find(
            (subStatusValue) =>
              subStatusValue.noteSubStatusDesc === value.clinicNoteSubStatusName
          );

          theResponse = await ClinicNoteApi.createClinicNote(
            values.orderId,
            value.stateId,
            !isEmpty(getSpecificStatusClinicNoteData)
              ? getSpecificStatusClinicNoteData.noteStatusId
              : '',
            !isEmpty(getSpecificSubStatusClinicNoteData)
              ? getSpecificSubStatusClinicNoteData.noteSubStatusId
              : '',
            value.note,
            moment(value.fuTimestamp).format('YYYY-MM-DD HH:mm:ss'),
            localStorage.getItem('username')
          );

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Action Data',
            'Error Inserting Action Data!'
          );

          setShowModalAddData(false);
          getClinicNoteById(values.orderId);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  function setCreateEditMessage(response, successMsg, failedMsg) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      message.success(successMsg);
    }
  }

  useEffect(() => {
    async function getStatusClinicNoteData(id) {
      await getStatusClinicNote(id);
    }

    getStatusClinicNoteData(values.stateId);
  }, []);

  // useEffect(() => {
  //   async function getStateList(id) {
  //     const response = await ClinicNoteApi.getClinicNoteStatus(id);
  //     let ClinicNoteStatus = setToArray(response.data);

  //     setClinicStatusStates(ClinicNoteStatus);
  //   }

  //   getStateList(values.stateId);
  // }, [values.stateId]);

  async function getClinicNoteSubStatusByIdData(id) {
    await getClinicNoteSubStatusById(id);
  }

  const handleStatusOnChange = (value) => {
    if (!isEmpty(clinicNoteStatusData)) {
      const getSpecificStatusClinicNoteData = clinicNoteStatusData.find(
        (statusValue) => statusValue.noteStatusDesc === value
      );

      getClinicNoteSubStatusByIdData(
        getSpecificStatusClinicNoteData.noteStatusId
      );
    }

    form.setFieldsValue({
      clinicNoteSubStatusName: undefined,
    });
  };

  return (
    <Modal
      title="Add New Clinic Note"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <Form>
        <Form.Item label="State">
          {getFieldDecorator('stateId', {
            rules: [{ required: true, message: 'Please select state!' }],
            initialValue: values && values.stateId,
          })(
            <SelectDropdown
              options={stateOptions}
              placeHolder={'Select State'}
              disabled={true}
            />
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('clinicNoteStatusName', {
            rules: [{ required: true, message: 'Please select status!' }],
          })(
            <SelectDropdown
              options={
                !isEmpty(clinicNoteStatusData)
                  ? convertOptions(clinicNoteStatusData, 'noteStatusDesc')
                  : []
              }
              onChange={handleStatusOnChange}
              placeHolder={'Select Status'}
            />
          )}
        </Form.Item>
        <Form.Item label="Sub Status">
          {getFieldDecorator('clinicNoteSubStatusName', {
            rules: [{ required: true, message: 'Please select sub status!' }],
          })(
            <SelectDropdown
              options={
                !isEmpty(clinicNoteSubStatusByIdData)
                  ? convertOptions(
                      clinicNoteSubStatusByIdData,
                      'noteSubStatusDesc'
                    )
                  : []
              }
              placeHolder={'Select Sub Status'}
            />
          )}
        </Form.Item>
        <Form.Item label="Notes">
          {getFieldDecorator('note', {
            rules: [{ required: true, message: 'Please input clinic note!' }],
          })(
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder="Insert Notes"
            />
          )}
        </Form.Item>
        <Form.Item label="FU Date">
          {getFieldDecorator('fuTimestamp', {
            rules: [{ required: true, message: 'Please select FU Date!' }],
          })(
            <DatePicker
              size="large"
              showTime
              placeholder="Select Updated Date"
              format="YYYY-MM-DD HH:mm"
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  clinicNoteStatusData,
  clinicNoteByIdData,
  clinicNoteSubStatusByIdData,
}) => ({
  clinicNoteStatusData,
  clinicNoteByIdData,
  clinicNoteSubStatusByIdData,
});

export default withRouter(
  connect(mapStateToProps, {
    getStatusClinicNote,
    getClinicNoteById,
    getClinicNoteSubStatusById,
  })(Form.create()(ModalAddClinicNote))
);
