import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// antd ---------
import {
  Modal,
  Form,
  Radio,
  Checkbox,
  InputNumber,
  Col,
  Button,
  message,
} from 'antd';
//  ---------
import './style.scss';

import OrderApi from 'api/order';

const ModalTroubleshoot = ({
  showModal,
  form,
  handleHideModal,
}) => {
  const [loading, setLoading] = useState(false);
  //troubleshoot modal form
  const [inputRahangAtas, setInputRahangAtas] = useState(false);
  const [inputRahangBawah, setInputRahangBawah] = useState(false);

  const { getFieldDecorator } = form;
  const history = useHistory();

  const handleSubmitModalTroubleshoot = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        let archType;
        //RA RB validation
        if (!values.refinementSolution) {
          message.error('Jenis Troubleshoot belum dipilih');
          return;
        }
        if (!inputRahangAtas && !inputRahangBawah) {
          message.error('Set bermasalah perlu diisi');
          return;
        }
        if (
          (inputRahangAtas &&
          !values.problemSetRa) &&
          (inputRahangBawah &&
          !values.problemSetRb)
        ) {
          message.error('Set bermasalah perlu diisi');
          return;
        } else if (inputRahangAtas && !values.problemSetRa) {
          message.error('RA Belum diisi');
          return;
        } else if (inputRahangBawah && !values.problemSetRb) {
          message.error('RB Belum diisi');
          return;
        }
        //RA RB validation end

        if (
          values.problemSetRa &&
          inputRahangAtas &&
          values.problemSetRb &&
          inputRahangBawah
        ) {
          archType = 'Both';
        } else if (values.problemSetRa && inputRahangAtas) {
          archType = 'Upper';
        } else if (values.problemSetRb && inputRahangBawah) {
          archType = 'Lower';
        }

        // build request payload
        let payload = {
          orderReceiptNumber: values.orderReceiptNumber,
          createdBy: localStorage.getItem('username'),
          problemSetRa:
            values.problemSetRa && inputRahangAtas
              ? values.problemSetRa
              : undefined,
          problemSetRb:
            values.problemSetRb && inputRahangBawah
              ? values.problemSetRb
              : undefined,
          refinementType: 'Troubleshoot',
          archType: archType,
          refinementGroup: 1,
          refinementSolution: values.refinementSolution,
        };

        let response;
        try {
          setLoading(true);
          response = await OrderApi.postOrderTroubleshootTrigger(payload);
        } catch (err) {
          if (err.response.data.message) {
            message.error(err.response.data.message);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoading(false);
          if (response) {
            if (response.data.status === 'FAILED') {
              message.error(response.data.desc);
            } else {
              //navigate to active order
              history.push('/cms/order/active');
            }
          }
        }
      }
    });
  };

  return (
    <Modal
      closable={false}
      title="Troubleshoot"
      visible={showModal}
      okText="Save"
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => handleSubmitModalTroubleshoot()}>
          Save
        </Button>,
      ]}>
      <Form>
        <Form.Item label="Jenis Troubleshoot">
          {getFieldDecorator('refinementSolution', {
            initialValues: '',
          })(
            <Radio.Group>
              <Radio value={`Cetak Ulang`}>Cetak Ulang</Radio>
              <Radio value={`Print Ulang`}>Print Ulang</Radio>
              <Radio value={`Vacuum Ulang`}>Vacuum Ulang</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label="Set Bermasalah" required>
          <Col span={12}>
            <Checkbox onChange={() => setInputRahangAtas((val) => !val)}>
              RA
            </Checkbox>
            {getFieldDecorator('problemSetRa')(
              <InputNumber min={1} disabled={!inputRahangAtas} />
            )}
          </Col>
          <Col span={12}>
            <Checkbox onChange={() => setInputRahangBawah((val) => !val)}>
              RB
            </Checkbox>
            {getFieldDecorator('problemSetRb')(
              <InputNumber min={1} disabled={!inputRahangBawah} />
            )}
          </Col>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalTroubleshoot;
