/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Input, Upload, Button } from 'antd';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';

import '../styles/index.scss';

export const InputWithUploadReadOnly = ({
  placeholder,
  customClass,
  isDownload,
  purpose,
  uploadedFileName,
  uploadedFileUrl,
  uploadedFileId,
  withoutInitValue,
  disabled,
  acceptExtention,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(
    () => {
      if (withoutInitValue) {
        setFileList([]);
      } else {
        let fileListDataInit;
        if (purpose) {
          fileListDataInit = uploadedFileName[purpose]
            ? [
                {
                  uid: uploadedFileId[purpose] || `${purpose}-0`,
                  name: uploadedFileName[purpose],
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        } else {
          fileListDataInit = uploadedFileName
            ? [
                {
                  uid: uploadedFileId,
                  name: uploadedFileName,
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        }

        setFileList(fileListDataInit);
      }
    },
    withoutInitValue ? [] : [uploadedFileName[purpose]]
  );

  const props = {
    name: 'file',

    onDownload: (file) => {
      if (!isEmpty(file.response)) {
        saveAs(file.response.data[0].fileUrl, file.response.data[0].fileName);
      } else {
        saveAs(uploadedFileUrl, uploadedFileName[purpose]);
      }
    },

    openFileDialogOnClick: true,

    showUploadList: {
      showDownloadIcon: isDownload,
      showRemoveIcon: false,
    },

    accept: acceptExtention,

    ...(withoutInitValue ? '' : { fileList: fileList }),
  };

  return (
    <Upload {...props} className={`input-with-upload ${customClass}`} disabled={disabled}>
      <div className="d-flex input-with-upload__item align-items-center position-relative">
        <Input
          className="input-with-upload__input"
          placeholder={placeholder}
          size="large"
          disabled={disabled}
        />

        <Button
          style={{ right: '20px' }}
          className="input-with-upload__btn"
          icon="paper-clip"
        />
      </div>
    </Upload>
  );
};

InputWithUploadReadOnly.defaultProps = {
  isDownload: false,
  customClass: '',
  withoutInitValue: false,
  disabled: false,
  acceptExtention: 'video/*,application/pdf,image/*,.zip',
};

export default React.memo(InputWithUploadReadOnly);
