import React, { createContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { isEqual } from 'lodash';
// import PropTypes from 'prop-types';

import { setToArray } from 'utils';
import { usePrevious } from 'utils/hooks';
import AuditApi from 'api/audit';

export const AuditBatchContext = createContext();

export function AuditBatch({ children, location }) {
  const [auditByIdData, setAuditByIdData] = useState({});
  const [auditBatchList, setAuditBatchList] = useState([]);
  const { pathname } = location;
  const auditId = pathname.split('/').pop();
  const prevLocation = usePrevious(pathname);

  async function deleteAuditBatch(id) {
    await AuditApi.deleteAuditBatchList(id);
  }

  async function getAuditById() {
    if (pathname.includes('/cms/audit-batch/')) {
      const response = await AuditApi.getAuditBatchById(auditId);
      setAuditByIdData(setToArray(response.data));
    }
  }

  async function getAuditBatchList() {
    if (pathname.includes('audit-batch')) {
      const response = await AuditApi.getAuditBatchList();
      setAuditBatchList(setToArray(response.data));
    }
  }

  useEffect(() => {
    if (!isEqual(prevLocation, pathname)) {
      getAuditById();
      getAuditBatchList();
    }
    // eslint-disable-next-line
  }, [prevLocation, pathname]);

  useEffect(() => {
    getAuditBatchList();
    // eslint-disable-next-line
  }, []);

  return (
    <AuditBatchContext.Provider
      value={{
        auditBatchList,
        deleteAuditBatch,
        auditByIdData,
        getAuditBatchList,
      }}>
      {children}
    </AuditBatchContext.Provider>
  );
}

export default withRouter(AuditBatch);
