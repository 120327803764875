import { GET_LIST_PAYMENT_DETAIL_BY_ID } from '../../type';

export const paymentDetailByIdData = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_PAYMENT_DETAIL_BY_ID:
      return action.payload;
    default:
      return state;
  }
};
