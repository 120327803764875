import React from 'react';
import { Modal } from 'antd';
import './styles/index.scss';

export const ModalFullScreen = ({
  showModal,
  handleHideModal,
  modalComponent,
  modalComponentNext,
  modalComponentRedo,
  idContainer,
}) => {
  return (
    <Modal
      className="modal-full-screen"
      centered
      visible={showModal}
      getContainer={() =>
        idContainer ? document.getElementById(idContainer) : document.body
      }
      onCancel={() => handleHideModal()}
      footer={[]}>
      {modalComponent}
      {modalComponentNext}
      {modalComponentRedo}
    </Modal>
  );
};

ModalFullScreen.defaultProps = {
  idContainer: '',
};

export default ModalFullScreen;
