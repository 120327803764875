import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import ClinicNoteApi from 'api/clinic-note';
import { PageSpinner } from 'components/PageSpinner';
import { setToArray } from 'utils';
import TableGlobal from 'components/Table';

import { tableClinicSubStatusColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function ClinicSubStatusList() {
  const [clinicSubStatus, setClinicSubStatus] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });
  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  async function getClinicSubStatusList() {
    try {
      const response = await ClinicNoteApi.getClinicSubStatusList();
      let clinicSubStatusList = setToArray(response.data);

      setClinicSubStatus(clinicSubStatusList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getClinicSubStatusList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getClinicSubStatusId = rowSelected.map(
      (value) => value.noteSubStatusId
    );
    const getClinicSubStatusIdToString = getClinicSubStatusId.join(',');

    confirm({
      title: `Are you sure delete this clinic sub status data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then clinic sub status data with id ${getClinicSubStatusIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingClinicSubStatus() {
          try {
            await ClinicNoteApi.deleteBulkClinicSubStatus(
              getClinicSubStatusIdToString
            );
            setRowSelected([]);
            getClinicSubStatusList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingClinicSubStatus();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return <></>;
  };

  const filterComponent = () => {
    return <></>;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/master/clinic-sub-status/edit?id=${record.noteSubStatusId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Clinic Sub Status'}
          inputPlaceholder={'Search Sub Clinic Status Here...'}
          pageSize={8}
          originalData={clinicSubStatus}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableClinicSubStatusColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          recordKey={(record) => record.noteSubStatusId}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
          setRowSelected={setRowSelected}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getClinicSubStatusList={getClinicSubStatusList}
      />
    </Layout>
  );
}

export default { ClinicSubStatusList };
