import React from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import ShippingApi from 'api/shipping';
import { setCreateEditMessage } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getShippingList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await ShippingApi.createShipping(
            values.shippingId,
            values.shippingName
          );

          history.push({
            pathname: '/cms/master/shipping',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Shipping Data',
            'Error Inserting Shipping Data!'
          );
          getShippingList();

          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  return (
    <Modal
      title="Add New Shipping Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Shipping Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('shippingId', {
            rules: [{ required: true, message: 'Please input shipping ID!' }],
          })(<Input size="large" placeholder="Shipping ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('shippingName', {
            rules: [{ required: true, message: 'Please input shipping name!' }],
          })(<Input size="large" placeholder="Shipping Name*" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
