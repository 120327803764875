/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Row,
  Layout,
  Typography,
  Table,
  Icon,
  Button,
  Tooltip,
  Input,
} from 'antd';
import { Bar } from '@ant-design/charts';
import { CSVLink } from 'react-csv';
import { isEqual, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { PageSpinner } from 'components/PageSpinner';
import { useOuterClickNotifier, usePrevious } from 'utils/hooks';
import { nestedFilter, setToArray } from 'utils';
import { getListDashboardStationOrder } from 'store/action/OrderAction/GetStationOrderAction';
import {
  getListSummaryDashboardOrder,
  getListSummaryDashboardOrderRataPlus,
} from 'store/action/OrderAction/GetSummaryDashboardAction';
import {
  getListOverdueOrder,
  getOverdueOrderNumber,
} from 'store/action/OrderAction/GetOverdueOrderAction';
import { getListDashboardByDate } from 'store/action/OrderAction/GetDashboardListByDateAction';

import { tableColumns, headerActiveExportCsv } from './helper';
import FilterDashboard from './components/Filter';
import FloatingButton from './components/FloatingButton';
import ActiveFilter from './components/ActiveFilter';
import StationState from './components/StationState';
import './style.scss';

const { Content } = Layout;
const { Text } = Typography;

const ORDER_TYPE_RATA_PLUS = [
  '16071',
  '16072',
  '16051',
  '16052',
  '16041',
  '16052',
];

export function DashboardList({
  history,
  listDashboardStationOrder,
  getListSummaryDashboardOrder,
  getListSummaryDashboardOrderRataPlus,
  listSummaryDashboardOrder,
  listSummaryDashboardOrderRataPlus,
  getListDashboardStationOrder,
  getOverdueOrderNumber,
  overdueNumber,
  getListDashboardByDate,
  listDashboardByDate,
}) {
  const [showInput, setShowInput] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });
  const [activeFilter, setActiveFilter] = useState({
    orderType: [], //Order type
    orderDesc: [], //Order desc
    stateId: [], //Current State
    deadlineStatus: [], //Overdue
    orderStatusDesc: [], //Flagged
    printTypeString: [], //Print Type
    stateName: [],
    testFitStatus: [],
  });
  const [staticStateIsLoading, setStaticStateIsLoading] = useState(true);
  const [activeOrderIsLoading, setActiveOrderIsLoading] = useState(true);
  const [overdueOrderIsLoading, setOverdueOrderIsLoading] = useState(true);
  const [staticOrderIsLoading, setStaticOrderIsLoading] = useState(true);
  const [todayStateIsLoading, setTodayStateIsLoading] = useState(true);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [stationOrderList, setStationOrderList] = useState([]);
  const [stationOrderNumber, setStationOrderNumber] = useState(0);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState({
    orderOpt: [],
  });
  const [currentStateFilterValue, setCurrentStateFilterValue] = useState({
    currentStateOpt: [],
  });
  const [concatListByDateWithType, setConcatListByDateWithType] = useState([]);
  const [concatStationStateWithType, setConcatStationStateWithType] = useState(
    []
  );
  const [concatRplusStateWithType, setConcatRplusStateWithType] = useState([]);
  const [testFitOptions, setTestFitOptions] = useState([]);
  const [currentStateOptions, setCurrentStateOptions] = useState([]);
  const [value, setValue] = useState('');
  const [activeOrderData, setActiveOrderData] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const innerRef = useRef(null);
  const prevActiveFilter = usePrevious(activeFilter, setActiveFilter);
  const available_state = localStorage.getItem('available_state');
  const prevStaticOrderLoading = usePrevious(staticOrderIsLoading);
  const prevActiveOrderIsLoading = usePrevious(activeOrderIsLoading);
  const prevStationStateIsLoading = usePrevious(staticStateIsLoading);
  const prevOverdueOrderIsLoading = usePrevious(overdueOrderIsLoading);
  const prevTodayStateIsLoading = usePrevious(todayStateIsLoading);

  async function getDashboardListByDateData() {
    const currentData = moment(new Date()).format('YYYY-MM-DD');

    try {
      await getListDashboardByDate(currentData, available_state);
    } finally {
      setTodayStateIsLoading(false);
      setPageIsLoading(false);
    }
  }

  async function getSummaryDashboardOrderListData() {
    try {
      await getListSummaryDashboardOrder(available_state);
    } finally {
      setStaticStateIsLoading(false);
      setPageIsLoading(false);
    }
  }

  async function getSummaryDashboardOrderRataPlusData() {
    try {
      await getListSummaryDashboardOrderRataPlus(available_state);
    } finally {
      setStaticStateIsLoading(false);
      setPageIsLoading(false);
    }
  }

  async function getOverdueOrderListData() {
    try {
      await getOverdueOrderNumber(available_state);
    } finally {
      setStaticStateIsLoading(false);
      setOverdueOrderIsLoading(false);
      setPageIsLoading(false);
    }
  }

  async function getStationOrderListData() {
    try {
      await getListDashboardStationOrder(available_state);
    } finally {
      setActiveOrderIsLoading(false);
      setStaticOrderIsLoading(false);
      setPageIsLoading(false);
    }
  }

  useEffect(() => {
    if (staticOrderIsLoading) {
      if (!isEqual(prevStaticOrderLoading, staticOrderIsLoading)) {
        getStationOrderListData();
      }
    }
  }, [staticOrderIsLoading]);

  useEffect(() => {
    if (activeOrderIsLoading && !staticOrderIsLoading) {
      if (!isEqual(prevActiveOrderIsLoading, activeOrderIsLoading)) {
        getStationOrderListData();
      }
    }
  }, [activeOrderIsLoading]);

  useEffect(() => {
    if (overdueOrderIsLoading) {
      if (!isEqual(prevOverdueOrderIsLoading, overdueOrderIsLoading)) {
        getOverdueOrderListData();
      }
    }
  }, [overdueOrderIsLoading]);

  useEffect(() => {
    if (todayStateIsLoading) {
      if (!isEqual(prevTodayStateIsLoading, todayStateIsLoading)) {
        getDashboardListByDateData();
      }
    }
  }, [todayStateIsLoading]);

  useEffect(() => {
    if (staticStateIsLoading) {
      if (!isEqual(prevStationStateIsLoading, staticStateIsLoading)) {
        getSummaryDashboardOrderListData();
      }
    }
  }, [staticStateIsLoading]);

  useEffect(() => {
    if (staticStateIsLoading) {
      if (!isEqual(prevStationStateIsLoading, staticStateIsLoading)) {
        getSummaryDashboardOrderRataPlusData();
      }
    }
  }, [staticStateIsLoading]);

  useEffect(() => {
    setStationOrderList(setToArray(listDashboardStationOrder) || []);
    setStationOrderNumber(
      !isEmpty(listDashboardStationOrder.data) &&
        listDashboardStationOrder.data.totalData
    );
  }, [listDashboardStationOrder]);

  const handleShowSearch = () => {
    setShowInput(true);
    setFilterShow(false);
  };

  const handleHideSearch = () => {
    setShowInput(false);
    setValue('');
    setActiveOrderData(stationOrderList);
  };

  const handleReloadStaticOrder = () => {
    setStaticOrderIsLoading(true);
  };

  const handleReloadOverdueOrder = () => {
    setOverdueOrderIsLoading(true);
  };

  const handleReloadStaticState = () => {
    setStaticStateIsLoading(true);
  };

  const handleReloadTodayState = () => {
    setTodayStateIsLoading(true);
  };

  const handleReloadActiveOder = () => {
    setActiveOrderIsLoading(true);
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  const onOrderTypeChange = (checkedValues) => {
    const handleOrderType = checkedValues.map((value) => value.split(' ')[0]);
    const handleOrderDesc = checkedValues.map((value) =>
      value.split('-').pop().replace(' ', '')
    );
    setActiveFilter({
      ...activeFilter,
      orderType: handleOrderType,
      orderDesc: handleOrderDesc,
    });

    setOrderTypeFilterValue({
      orderOpt: checkedValues,
    });
  };

  const onCurrentStateChange = (checkedValues) => {
    const handleStateId = checkedValues.map((value) => value.split(' ')[0]);
    const handleStateName = checkedValues.map((value) =>
      value.substring(value.indexOf('-') + 1).replace(' ', '')
    );

    setActiveFilter({
      ...activeFilter,
      stateId: handleStateId,
      stateName: handleStateName,
    });

    setCurrentStateFilterValue({
      currentOpt: checkedValues,
    });
  };

  const onOverdueChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      deadlineStatus: checkedValues,
    });
  };

  const onFlaggedChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      orderStatusDesc: checkedValues,
    });
  };

  const onTestFitChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      testFitStatus: checkedValues,
    });
  };

  const onPrintTypeChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      printTypeString: checkedValues,
    });
  };

  const handleFilterShow = () => {
    setFilterShow(!filterShow);
    setShowInput(false);
  };

  const resetFilter = () => {
    setActiveFilter({});
    setCurrentStateFilterValue({});
    setOrderTypeFilterValue({});
  };

  useEffect(() => {
    setActiveOrderData(stationOrderList);

    const orderTypeOpts = stationOrderList.map((value) => ({
      label: `${value.orderType} - ${value.orderDesc}`,
      value: `${value.orderType} - ${value.orderDesc}`,
    }));

    const handleDuplicateOrderTypeOpts = orderTypeOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    const currentStateOpts = stationOrderList.map((value) => ({
      label: `${value.stateId} - ${value.stateName}`,
      value: `${value.stateId} - ${value.stateName}`,
    }));

    const handleDuplicateCurrentStateOpts = currentStateOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    const testFitOpts = stationOrderList.map((value) => ({
      label: value.testFitStatus,
      value: value.testFitStatus,
    }));

    const handleDuplicateTestFitOpts = testFitOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    setCurrentStateOptions(handleDuplicateCurrentStateOpts);
    setOrderTypeOptions(handleDuplicateOrderTypeOpts);
    setTestFitOptions(handleDuplicateTestFitOpts);
  }, [stationOrderList]);

  useOuterClickNotifier(handleFilterShow, innerRef);

  useEffect(() => {
    if (!isEmpty(listDashboardByDate)) {
      const concatSummaryWithType = listDashboardByDate.map((value) => ({
        ...value,
        type: 'Count Order',
      }));
      setConcatListByDateWithType(concatSummaryWithType);
    }
  }, [listDashboardByDate]);

  useEffect(() => {
    if (!isEmpty(listSummaryDashboardOrder)) {
      const concatSummaryWithType = listSummaryDashboardOrder.map((value) => ({
        ...value,
        type: 'Count Order',
      }));
      setConcatStationStateWithType(concatSummaryWithType);
    }
  }, [listSummaryDashboardOrder]);

  useEffect(() => {
    if (!isEmpty(listSummaryDashboardOrderRataPlus)) {
      const summaryRplus = listSummaryDashboardOrderRataPlus.map((value) => ({
        ...value,
        type: 'Count Order',
      }));
      setConcatRplusStateWithType(summaryRplus);
    }
  }, [listSummaryDashboardOrderRataPlus]);

  // Filter;
  useEffect(() => {
    const allFilterResult = nestedFilter(stationOrderList, activeFilter);
    if (!isEqual(prevActiveFilter, activeFilter)) {
      setActiveOrderData(allFilterResult);
    }
  }, [activeFilter, stationOrderList, prevActiveFilter]);

  // Search
  const onInputChange = (e) => {
    const convertValue = e.target.value;
    setValue(convertValue);

    const filteredData = stationOrderList.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          item[key] &&
          item[key]
            .toString()
            .toLowerCase()
            .search(convertValue.toLowerCase()) !== -1
      );
    });

    setActiveOrderData(filteredData);
  };

  if (pageIsLoading) {
    return <PageSpinner />;
  }

  return (
    <Content>
      <Row gutter={20} type="flex">
        <Col xs={12}>
          <div className="panel dashboard__station-order">
            {staticOrderIsLoading ? (
              <PageSpinner className="pd-section--dashboard-number" />
            ) : (
              <>
                <Row type="flex" className="mb-30">
                  <Col xs={12}>
                    <div className="mb-15 text-base">Station Orders</div>
                    <Text strong className="text-md">
                      {stationOrderNumber}
                    </Text>
                  </Col>
                  <Col xs={12} type="flex" align="end">
                    <Icon
                      type="inbox"
                      style={{ fontSize: '50px', color: '#c5c5c5' }}
                    />
                  </Col>
                </Row>
                <Tooltip title="Reload">
                  <Button icon="reload" onClick={handleReloadStaticOrder} />
                </Tooltip>
              </>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <div className="panel">
            {overdueOrderIsLoading ? (
              <PageSpinner className="pd-section--dashboard-number" />
            ) : (
              <>
                <Row type="flex" className="mb-30">
                  <Col xs={12}>
                    <div className="mb-15 text-base">Overdue Orders</div>
                    <Text strong className="text-md">
                      {overdueNumber}
                    </Text>
                  </Col>
                  <Col xs={12} type="flex" align="end">
                    <Icon
                      type="inbox"
                      style={{ fontSize: '50px', color: '#c5c5c5' }}
                    />
                  </Col>
                </Row>
                <Tooltip title="Reload">
                  <Button icon="reload" onClick={handleReloadOverdueOrder} />
                </Tooltip>
              </>
            )}
          </div>
        </Col>
      </Row>

      <Row gutter={20} type="flex">
        <Col xs={12}>
          <div className="panel">
            {staticStateIsLoading ? (
              <PageSpinner className="pd-section--dashboard-bar" />
            ) : (
              <>
                <Row type="flex" className="mb-30">
                  <Col xs={16}>
                    <div className="mb-15">
                      <strong className="text-md">
                        On Station Orders by State
                      </strong>
                    </div>
                  </Col>
                  <Col xs={8} type="flex" align="end">
                    <Tooltip title="Reload">
                      <Button icon="reload" onClick={handleReloadStaticState} />
                    </Tooltip>
                  </Col>
                </Row>
                <Bar
                  data={concatStationStateWithType}
                  yField="stateName"
                  xField="countOrder"
                  color="#1890ff"
                  height={1200}
                  appendPadding={[0, 20, 0, 0]}
                  label={{
                    position: 'right',
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <div className="panel">
            {staticStateIsLoading ? (
              <PageSpinner className="pd-section--dashboard-bar" />
            ) : (
              <>
                <Row type="flex" className="mb-30">
                  <Col xs={16}>
                    <div className="mb-15">
                      <strong className="text-md">Rata Plus by State</strong>
                    </div>
                  </Col>
                  <Col xs={8} type="flex" align="end">
                    <Tooltip title="Reload">
                      <Button icon="reload" onClick={handleReloadStaticState} />
                    </Tooltip>
                  </Col>
                </Row>
                <Bar
                  data={concatRplusStateWithType}
                  yField="stateName"
                  xField="countOrder"
                  color="#1890ff"
                  height={1200}
                  appendPadding={[0, 20, 0, 0]}
                  label={{
                    position: 'right',
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>

      <div className="panel">
        <Row gutter={20} type="flex" className="table-global__action-item">
          <Col xs={12}>
            <div className="mb-15">
              {showInput ? (
                <div className="table-global__search">
                  <Input
                    className="table-global__search-input"
                    placeholder="Search Active Order Here..."
                    onChange={onInputChange}
                    value={value}
                  />
                  <Button
                    onClick={handleHideSearch}
                    icon="close"
                    className="position-absolute table-global__search-close"
                  />
                </div>
              ) : (
                <strong className="text-md table-global__title">
                  Active Orders
                </strong>
              )}
            </div>
          </Col>
          <Col xs={12} type="flex" align="end">
            <Tooltip title="Search">
              <Button
                onClick={handleShowSearch}
                icon="search"
                className="mr-10"
              />
            </Tooltip>

            <Tooltip title="Filter">
              <Button
                icon="filter"
                className="mr-10"
                onClick={handleFilterShow}
              />
            </Tooltip>

            {filterShow && (
              <FilterDashboard
                onOrderTypeChange={onOrderTypeChange}
                onCurrentStateChange={onCurrentStateChange}
                onOverdueChange={onOverdueChange}
                onFlaggedChange={onFlaggedChange}
                onTestFitChange={onTestFitChange}
                onPrintTypeChange={onPrintTypeChange}
                orderTypeOptions={orderTypeOptions}
                testFitOptions={testFitOptions}
                currentStateOptions={currentStateOptions}
                resetFilter={resetFilter}
                orderTypeSelectValue={orderTypeFilterValue.orderOpt}
                currentStateSelectValue={currentStateFilterValue.currentOpt}
                overdueSelectValue={activeFilter.deadlineStatus}
                flaggedSelectValue={activeFilter.orderStatusDesc}
                testFitSelectValue={activeFilter.testFitStatus}
                printTypeSelectValue={activeFilter.printTypeString}
                innerRef={innerRef}
              />
            )}

            <Tooltip title="Download CSV">
              <CSVLink
                filename={'active-order-list-export.csv'}
                headers={headerActiveExportCsv}
                data={stationOrderList}>
                <Icon
                  className="mr-10"
                  type="cloud-download"
                  style={{ fontSize: '20px' }}
                />
              </CSVLink>
            </Tooltip>

            <Tooltip title="Reload">
              <Button icon="reload" onClick={handleReloadActiveOder} />
            </Tooltip>
          </Col>
        </Row>

        <ActiveFilter
          activeFilterData={activeFilter}
          setActiveFilter={setActiveFilter}
          setFilterShow={setFilterShow}
          setOrderTypeFilterValue={setOrderTypeFilterValue}
          setCurrentStateFilterValue={setCurrentStateFilterValue}
          orderTypeFilterValue={orderTypeFilterValue}
          currentStateFilterValue={currentStateFilterValue}
        />

        {activeOrderIsLoading ? (
          <PageSpinner className="pd-section--dashboard-table" />
        ) : (
          <Table
            dataSource={activeOrderData}
            scroll={{ x: 1500, y: 500 }}
            onChange={onTableChange}
            rowClassName={(record) =>
              ORDER_TYPE_RATA_PLUS.includes(record.orderType) ||
              record.baseAlignerPlus
                ? 'rataplus-row ant-table-row--global'
                : 'ant-table-row--global'
            }
            pagination={pagination}
            columns={tableColumns}
            rowKey={(record) => record.orderId}
            onRow={(record) => ({
              onClick: () =>
                history.push({
                  pathname: `/cms/transaction/order-detail/edit?id=${record.orderId}`,
                  data: { orderDetail: { ...record } },
                  progress: false,
                }),
            })}
            className="table-global__table-item"
          />
        )}
      </div>
      <div className="panel">
        {todayStateIsLoading ? (
          <PageSpinner className="pd-section--dashboard-bar" />
        ) : (
          <>
            <Row type="flex" className="mb-30">
              <Col xs={16}>
                <div strong className="mb-15">
                  <strong className="text-md">Today&apos;s State Output</strong>
                </div>
              </Col>
              <Col xs={8} type="flex" align="end">
                <Tooltip title="Reload">
                  <Button icon="reload" onClick={handleReloadTodayState} />
                </Tooltip>
              </Col>
            </Row>

            <Bar
              data={concatListByDateWithType}
              yField="stateName"
              xField="countOrder"
              color="grey"
              appendPadding={[0, 20, 0, 0]}
              height={1200}
              label={{
                position: 'right',
              }}
            />
          </>
        )}
      </div>

      <StationState />

      <FloatingButton />
    </Content>
  );
}

const mapStateToProps = ({
  listSummaryDashboardOrder,
  listSummaryDashboardOrderRataPlus,
  listOverdueOrder,
  listDashboardByDate,
  listDashboardStationOrder,
  overdueNumber,
}) => ({
  listSummaryDashboardOrder,
  listSummaryDashboardOrderRataPlus,
  listOverdueOrder,
  listDashboardByDate,
  listDashboardStationOrder,
  overdueNumber,
});

export default withRouter(
  connect(mapStateToProps, {
    getListSummaryDashboardOrder,
    getListSummaryDashboardOrderRataPlus,
    getListOverdueOrder,
    getListDashboardByDate,
    getListDashboardStationOrder,
    getOverdueOrderNumber,
  })(DashboardList)
);
