import React from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import ClinicApi from 'api/clinic';

import { setCreateEditMessage } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getClinicListData,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await ClinicApi.addClinic(
            values.clinicCode,
            values.clinicName,
            values.clinicAddress,
            values.clinicContactNumber
          );

          history.push({
            pathname: '/cms/system/clinic',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Clinic Data',
            'Error Inserting Action Data!'
          );
          getClinicListData();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  return (
    <Modal
      title="Add New Clinic Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Clinic Data.</div>
      <Form>
        <Form.Item label="Code">
          {getFieldDecorator(
            'clinicCode',
            {}
          )(<Input size="large" placeholder="Clinic Code*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator(
            'clinicName',
            {}
          )(<Input size="large" placeholder="Clinic Name*" />)}
        </Form.Item>
        <Form.Item label="Address">
          {getFieldDecorator(
            'clinicAddress',
            {}
          )(<Input size="large" placeholder="Clinic Address*" />)}
        </Form.Item>
        <Form.Item label="Contact Number">
          {getFieldDecorator(
            'clinicContactNumber',
            {}
          )(<Input size="large" placeholder="Clinic Contact Number" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
