/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Input,
  Form,
  message,
  Tooltip,
  Button,
  Row,
  Col,
  DatePicker,
} from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';

import StateApi from 'api/state';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import SelectDropdown from 'components/SelectDropdown';
import { setToArray, convertOptions } from 'utils';
import ClinicNoteApi from 'api/clinic-note';
import {
  getClinicNoteDetailById,
  getClinicNoteSubStatusById,
} from 'store/action/ClinicNoteAction';

export const ClinicNotesEditComponent = ({
  history,
  form,
  location,
  clinicNoteSubStatusByIdData,
  getClinicNoteSubStatusById,
}) => {
  const { getFieldDecorator } = form;
  const { TextArea } = Input;
  // const [loadingPage, setLoadingPage] = useState(true);
  const [clinicStatus, setClinicStatusStates] = useState([]);
  const [states, setStates] = useState([]);
  const [clinicNoteDetail, setClinicNoteDetail] = useState([]);
  const [clinicNoteSubStatus, setClinicNoteSubStatus] = useState([]);

  const { pathname } = location;
  const orderId = pathname.split('/').pop();
  const orderIdOnly = orderId.split('?')[0];
  const urlParams = new URLSearchParams(window.location.search);
  const noteId = urlParams.get('id');

  let noteInitialValue = [];
  clinicNoteDetail.forEach((value) => noteInitialValue.push(value.note));
  let stateIdInitialValue = [];
  clinicNoteDetail.forEach((value) => stateIdInitialValue.push(value.stateId));
  let fuValue = [];
  clinicNoteDetail.forEach((value) => fuValue.push(value.fuTimestamp));

  // Calling API for getting data clinic note detail
  useEffect(() => {
    async function getOrderTypeList() {
      const response = await ClinicNoteApi.getClinicNoteDetailById(noteId);
      let clinicNoteDetail = setToArray(response.data);

      setClinicNoteDetail(clinicNoteDetail);
    }

    getOrderTypeList();
  }, []);

  function setCreateEditMessage(response, successMsg, failedMsg) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      message.success(successMsg);
      history.goBack();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, value) => {
      if (!err) {
        try {
          let theResponse;

          const getSpecificSubStatusClinicNoteData = clinicNoteSubStatus.find(
            (subStatusValue) =>
              subStatusValue.noteSubStatusDesc === value.clinicNoteSubStatusName
          );

          const getSpecificStatusClinicNoteData = clinicStatus.find(
            (statusValue) =>
              statusValue.noteStatusDesc === value.clinicNoteStatusName
          );

          theResponse = await ClinicNoteApi.updateClinicNote(
            noteId,
            orderIdOnly,
            value.stateId[0],
            !isEmpty(getSpecificStatusClinicNoteData)
              ? getSpecificStatusClinicNoteData.noteStatusId
              : '',
            !isEmpty(getSpecificSubStatusClinicNoteData)
              ? getSpecificSubStatusClinicNoteData.noteSubStatusId
              : '',
            value.note,
            moment(value.fuTimestamp).format('YYYY-MM-DD HH:mm:ss'),
            localStorage.getItem('username')
          );

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Clinic Note',
            'Error Updating Clinic Note Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          // setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  useEffect(() => {
    async function getStatusList(id) {
      const response = await ClinicNoteApi.getClinicNoteStatus(id);
      let ClinicNoteStatus = setToArray(response.data);

      setClinicStatusStates(ClinicNoteStatus);
    }

    if (!isEmpty(stateIdInitialValue[0])) {
      getStatusList(stateIdInitialValue[0]);
    }
  }, [stateIdInitialValue[0]]);

  useEffect(() => {
    async function getClinicNoteSubStatusList() {
      const response = await ClinicNoteApi.getClinicNoteSubStatus();
      let clinicNoteSubStatusList = setToArray(response.data);

      setClinicNoteSubStatus(clinicNoteSubStatusList);
    }

    getClinicNoteSubStatusList();
  }, []);

  async function getClinicNoteSubStatusByIdData(id) {
    await getClinicNoteSubStatusById(id);
  }

  useEffect(() => {
    if (!isEmpty(clinicNoteDetail) && clinicNoteDetail[0].noteStatusId) {
      getClinicNoteSubStatusByIdData(clinicNoteDetail[0].noteStatusId);
    }
  }, [clinicNoteDetail]);

  const handleStatusOnChange = (value) => {
    if (!isEmpty(clinicStatus)) {
      const getSpecificStatusClinicNoteData = clinicStatus.find(
        (statusValue) => statusValue.noteStatusDesc === value
      );

      getClinicNoteSubStatusByIdData(
        getSpecificStatusClinicNoteData.noteStatusId
      );
    }

    form.setFieldsValue({
      clinicNoteSubStatusName: undefined,
    });
  };

  return (
    <>
      <DetailLayout
        detailTitle={'Clinic Note'}
        className={'edit-form'}
        detailComponent={
          <Form onSubmit={(event) => handleSubmit(event)}>
            <Form.Item className="edit-form__submit">
              <Tooltip title="Submit">
                <Button size="large" htmlType="submit" icon="check-circle" />
              </Tooltip>
            </Form.Item>
            <Row gutter={20}>
              <Col xs={8}>
                <Form.Item label="State">
                  {getFieldDecorator('stateId', {
                    initialValue: clinicNoteDetail.map(
                      (value) => value.stateId
                    ),
                  })(
                    <SelectDropdown
                      options={stateOptions}
                      placeHolder={'Select State'}
                      disabled={true}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label="Status">
                  {getFieldDecorator('clinicNoteStatusName', {
                    rules: [
                      { required: true, message: 'Please select status!' },
                    ],
                    initialValue:
                      !isEmpty(clinicNoteDetail) &&
                      clinicNoteDetail[0].noteStatusDesc,
                  })(
                    <SelectDropdown
                      options={
                        !isEmpty(clinicStatus)
                          ? convertOptions(clinicStatus, 'noteStatusDesc')
                          : []
                      }
                      onChange={handleStatusOnChange}
                      placeHolder={'Select Status'}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label="Sub Status">
                  {getFieldDecorator('clinicNoteSubStatusName', {
                    rules: [
                      { required: true, message: 'Please select sub status!' },
                    ],
                    initialValue:
                      !isEmpty(clinicNoteDetail) &&
                      clinicNoteDetail[0].noteSubStatusDesc,
                  })(
                    <SelectDropdown
                      options={
                        !isEmpty(clinicNoteSubStatusByIdData)
                          ? convertOptions(
                              clinicNoteSubStatusByIdData,
                              'noteSubStatusDesc'
                            )
                          : []
                      }
                      placeHolder={'Select Sub Status'}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label="FU Date">
                  {getFieldDecorator('fuTimestamp', {
                    rules: [{ required: true, message: 'Please select date!' }],
                    initialValue: moment(fuValue[0], 'DD/MM/YYYY - hh:mm:ss'),
                  })(
                    <DatePicker
                      size="large"
                      showTime
                      placeholder="Select Updated Date"
                      format="DD/MM/YYYY - HH:mm:ss"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Notes">
              {getFieldDecorator('note', {
                initialValue: noteInitialValue[0],
              })(
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="Insert Notes"
                />
              )}
            </Form.Item>
          </Form>
        }
      />
    </>
  );
};

export const ClinicNotesEditComponentForm = Form.create({
  name: 'validate_edit_clinic_note_form',
})(ClinicNotesEditComponent);

const mapStateToProps = ({
  clinicNoteDetailByIdData,
  clinicNoteSubStatusByIdData,
}) => ({
  clinicNoteDetailByIdData,
  clinicNoteSubStatusByIdData,
});

export default withRouter(
  connect(mapStateToProps, {
    getClinicNoteDetailById,
    getClinicNoteSubStatusById,
  })(ClinicNotesEditComponentForm)
);
