export const headerCsv = [
  { label: 'Name', key: 'errorTimestamp' },
  { label: 'Request Input', key: 'requestInput' },
  { label: 'Error Message', key: 'errorDesc' },
];

export const tableColumns = [
  {
    title: 'Name',
    key: 'errorTimestamp',
    dataIndex: 'errorTimestamp',
    sorter: (a, b) => a.errorTimestamp.localeCompare(b.errorTimestamp),
  },
  {
    title: 'Request Input',
    key: 'requestInput',
    dataIndex: 'requestInput',
    sorter: (a, b) => a.requestInput.localeCompare(b.requestInput),
  },
  {
    title: 'Error Message',
    key: 'errorDesc',
    dataIndex: 'errorDesc',
    sorter: (a, b) => a.errorDesc.localeCompare(b.errorDesc),
  },
];
