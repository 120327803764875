/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  message,
  Button,
  Form,
  Layout,
  Typography,
  Tabs,
  Tooltip,
  Input,
  DatePicker,
  Icon,
} from 'antd';
import moment from 'moment';
import { isEqual, isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ActionApi from 'api/action';
import OrderApi from 'api/order';
import FlowApi from 'api/flow';
import StateApi from 'api/state';
import BoxApi from 'api/box';
import SimulationApi from 'api/simulation-design';
import { setToArray, isObjectDate } from 'utils';
import { usePrevious } from 'utils/hooks';
import { ShippingContext } from 'contexts/Shipping';
import { OrderContext } from 'contexts/Order';
import { AuthenticationContext } from 'contexts/Authentication';
import { getResetHistoryList } from 'store/action/OrderAction/';
import { getListClinic } from 'store/action/clinic-action/GetClinicAction';
import { getPaymentDetailById } from 'store/action/PaymentDetailAction';
import ModalSingleForm from 'components/ModalSingleForm';
import { PageSpinner } from 'components/PageSpinner';

import ActiveOrders from './components/ActiveOrders';
import Details from './components/Details';
import Tracking from './components/Tracking';
import ModalAddAddress from './components/modal/ModalAddAddress';
import ModalCetakUlang from './components/modal/ModalCetakUlang';
import ModalTroubleshoot from './components/modal/ModalTroubleshoot';
import ModalFlag from './components/modal/ModalFlag';
import ModalResolve from './components/modal/ModalResolve';
import ModalRefinement from './components/modal/ModalCreateRefinement';
import ModalArchived from './components/modal/ModalArchived';
import ModalProductionFlow from './components/modal/modal-progress/ModalFlow';
import ModalReplaceBox from './components/modal/ModalReplaceBox';
import ModalTestFit from './components/modal/modal-progress/ModalTestFit';
import ModalSingleWithoutSubmit from './components/modal/modal-progress/ModalSingleWithoutSubmit';
import ModalFullscreenWithoutSubmit from './components/modal/modal-progress/ModalFullScreenWithoutSubmit';
import ModalFullscreenWithSubmit from './components/modal/modal-progress/ModalFullScreenWithSubmit';
import ModalImpression from './components/modal/modal-progress/ModalImpression';
import ModalRefinementTroubleshoot from './components/modal/ModalRefinementTroubleshoot/ModalRefinementTroubleshoot';
import { ModalConfirmCetakUlang } from './components/ActiveOrders/components/ConfirmCetakUlang';
import './style.scss';
import {
  reasonList1022to1011,
  reasonList1022to1022,
  reasonList1031to1011,
  reasonList1040to1011,
  reasonList1040to1022,
  reasonList1040to1063,
  reasonList1040to1064,
  reasonList1040to1066,
  reasonList1044to1011,
  reasonList1044to1022,
  reasonList1044to1064,
  reasonList1044to1066,
  reasonList1063to1011,
} from './helper';
import TagLabel from 'components/TagLabel';

const { Content } = Layout;
const { Title } = Typography;

export function OrderDetailCreateEdit({
  form,
  history,
  location,
  getResetHistoryList,
  getListClinic,
  resetHistoryList,
  getPaymentDetailById,
}) {
  const [testStatus, setTestStatus] = useState(true);
  const [values, setValues] = useState({});
  const [orderStatuss, setOrderStatuss] = useState([]);
  const [orderDetailList, setOrderDetailList] = useState({});
  const [flows, setFlows] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [nextStates, setNextStates] = useState([]);
  const [boxs, setBoxs] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showModalCetakUlang, setShowModalCetakUlang] = useState(false);
  const [showModalTroubleshoot, setShowModalTroubleshoot] = useState(false);
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [showModalResolve, setShowModalResolve] = useState(false);
  const [showModalRefinement, setShowModalRefinement] = useState(false);
  const [showModalRefinementTroubleshoot, setshowModalRefinementTroubleshoot] =
    useState(false);
  const [showModalConfirmCetakUlang, setShowModalConfirmCetakUlang] =
    useState(false);
  const [showModalSLADeadline, setShowModalSLADeadline] = useState(false);
  const [showModalArchived, setShowModalArchived] = useState(false);
  const [showModalAppointment, setShowModalAppointment] = useState(false);
  const [showModalAppointmentStart, setShowModalAppointmentStart] =
    useState(false);
  const [showModalOC, setShowModalOC] = useState(false);
  const [showModalAppointmentSV2, setShowModalAppointmentSV2] = useState(false);
  const [showModalProgressFlow, setShowModalProgressFlow] = useState(false);
  const [showModalConsultation, setShowModalConsultation] = useState(false);
  const [showModalReplaceBox, setShowModalReplaceBox] = useState(false);
  const [isRedo, setIsRedo] = useState(false);
  const [showModalCourier, setShowModalCourier] = useState(false);
  const [showModalClinicList, setShowModalClinicList] = useState(false);
  const [showModalDIYDate, setShowModalDIYDate] = useState(false);
  const [isRescheduleAppointment, setIsRescheduleAppointment] = useState(false);
  const [
    isRescheduleAppointmentWithClinic,
    setIsRescheduleAppointmentWithClinic,
  ] = useState(false);
  const [isReplaceBox, setIsReplaceBox] = useState(false);
  const [showModalTestFit, setShowModalTestFit] = useState(false);
  const [showModalAttachment, setShowModalAttachment] = useState(false);
  const [showModalSlicing, setShowModalSlicing] = useState(false);
  const [showModalRedoProgress, setShowModalRedoProgress] = useState(false);
  const [showModalVideoSentDateProgress, setShowModalVideoSentDateProgress] =
    useState(false);
  const [showModalDeliveryAddress, setShowModalDeliveryAddress] =
    useState(false);
  const [showModalDelivUp, setShowModalDelivUp] = useState(false);
  const [showModalDelivDown, setShowModalDelivDown] = useState(false);
  const [showModalPrintUp, setShowModalPrintUp] = useState(false);
  const [showModalPrintDown, setShowModalPrintDown] = useState(false);
  const [showModalPrintType, setShowModalPrintType] = useState(false);
  const [showModalRedoAppointmentStart, setShowModalRedoAppointmentStart] =
    useState(false);
  const [showModalClinicNote, setShowModalClinicNote] = useState(false);
  const [showModalImpression, setShowModalImpression] = useState(false);
  const [showModalConfirmCU, setShowModalConfirmCU] = useState(false);
  const [isProgressAppointmentEndSubmit, setIsProgressAppointmentEndSubmit] =
    useState(false);
  const [isConsultationSubmit, setIsConsultationSubmit] = useState(false);
  const [isVideoSentDateSubmit, setIsVideoSentDateSubmit] = useState(false);
  const [anotherButtonState, setAnotherButtonState] = useState(0);
  const [printType, setPrintType] = useState('');
  const [testFitValue, setTestFitValue] = useState(null);
  const [flowId, setFlowId] = useState(''); // flowid
  const [stateId, setStateId] = useState(''); // currentState id
  const [stateIdFlow, setStateIdFlow] = useState(''); //nextstate id
  const [progress1Data, setProgress1Data] = useState({
    nextStateId: null,
    appointmentTime: null,
    diyDeliveryTime: null,
    clinicId: null,
    shippingId: null,
    shippingAddress: null,
    boxNumber: '-',
    testFit: null,
    attachment: null,
    slicing: null,
    // customerNote: null,
    customerPhone: null,
    systemNote: null,
    printDown1: null,
    printUp1: null,
    printDown2: null,
    printUp2: null,
    delivDown1: null,
    delivUp1: null,
    delivDown2: null,
    delivUp2: null,
    printType: null,
    ocChecklist: null,
    videoSentTime: null,
    raRbRemarks: null,
    raImpression: null,
    rbImpression: null,
    biteKananImpression: null,
    biteKiriImpression: null,
    biteImpression: null,
  });
  const [buttonState, setButtonState] = useState(0);
  const [orderShippings, setOrderShippings] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [whenFlag, setWhenFlag] = useState(false);
  const [whenResolve, setWhenResolve] = useState(false);
  const [buttonProgressClicked, setButtonProgressClicked] = useState(false);
  // const prevButtonState = usePrevious(setButtonState, buttonState);
  const [activeFilterOrderLog, setActiveFilterOrderLog] = useState({
    activeOrder: [],
    flowStatus: [],
    shippingCourier: [],
    shippingAddress: [],
  });
  const [activeFilterRefinement, setActiveFilterRefinement] = useState({
    activeOrder: [],
    totalUp: [],
    totalDown: [],
    clinicId: [],
    attachment: [],
  });
  const [ctdFlag, setCtdFlag] = useState('');

  //Context
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { getOrderHistoryList } = useContext(OrderContext || {});
  const { getOrderShippingById, orderShippingById } =
    useContext(ShippingContext);
  const { opSubmitOrder } = permissionDataList;

  const formRef = useRef(null);
  const prevValuesForm = usePrevious(values);

  let triggerButtonStateInRow = 0;
  let triggerStateFlowIdInRow = '';
  let triggerPrintTypeInRow = null;

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const { getFieldDecorator } = form;

  const username = localStorage.getItem('username');
  const checkFlowType = values.flowType === '3' ? 3 : 7;

  // Centralize submit even
  function handleSubmit(e) {
    e.preventDefault();

    if (anotherButtonState === 1) {
      form.validateFields(async (err, val) => {
        if (!err) {
          try {
            if (
              progress1Data.printType === 1 &&
              val.stateId === '1022' &&
              (progress1Data.nextStateId === '1025' ||
                progress1Data.nextStateId === '1026')
            ) {
              const payload = {
                stateId: values.stateId,
              };
              const impressionResponse = await SimulationApi.addImpression3D(
                values.customerName,
                id,
                val.orderReceiptNumber,
                val.orderType,
                localStorage.getItem('username'),
                progress1Data.printType,
                progress1Data.raImpression,
                progress1Data.rbImpression,
                progress1Data.biteKananImpression,
                progress1Data.biteKiriImpression,
                payload
              );

              setCreateEditMessage(
                impressionResponse.data,
                'Success Adding Impression Data with Order ID ' + id,
                'Error Adding Impression Data!'
              );

              if (impressionResponse.status !== 'FAILED') {
                handleHideModalImpression();
              }
            }

            if (
              progress1Data.printType === 0 &&
              (progress1Data.nextStateId === '1040' ||
                progress1Data.nextStateId === '1061')
            ) {
              const payload = {
                stateId: values.stateId,
              };
              const impressionResponse = await SimulationApi.addImpressionPutty(
                values.customerName,
                id,
                val.orderReceiptNumber,
                val.orderType,
                localStorage.getItem('username'),
                progress1Data.printType,
                progress1Data.raImpression,
                progress1Data.rbImpression,
                progress1Data.biteImpression,
                payload
              );

              setCreateEditMessage(
                impressionResponse.data,
                'Success Adding Impression Data with Order ID ' + id,
                'Error Adding Impression Data!'
              );
              if (impressionResponse.data.status !== 'FAILED') {
                handleHideModalImpression();
              }
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    }
    // buttonState === 1, trigger submit for updating order detail (button checked in top of page)
    if (buttonState === 1) {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;
            theResponse = await OrderApi.updateOrderDetail(
              id,
              values.orderReceiptNumber,
              values.customerId,
              values.customerName,
              values.stateId,
              values.flowId,
              values.orderType,
              values.orderStatusId,
              progress1Data.boxNumber !== '-'
                ? progress1Data.boxNumber
                : values.boxNumber,
              username,
              values.orderShippingId,
              progress1Data.clinicId ? progress1Data.clinicId : values.clinicId,
              isObjectDate(
                values['SLA Deadline'].format('DD-MM-YYYY HH:mm:ss')
              ) === 'd da-al-In'
                ? null
                : isObjectDate(
                    values['SLA Deadline'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  ),
              // values.customerNote,
              values.customerPhone,
              values.systemNote,
              values.userNote,
              values.removalNote,
              values.totalUp,
              values.totalDown,
              values.ocUp,
              values.ocDown,
              values.agUp,
              values.agDown,
              values.printCount,
              values.delivCount,
              values.attachment,
              values.slicing,
              values.printType === true ? 1 : 0,
              values.printDownRemaining,
              values.printUpRemaining,
              values.delivDownRemaining,
              values.delivUpRemaining,
              values.round,
              !isEmpty(progress1Data.appointmentTime)
                ? isObjectDate(progress1Data.appointmentTime, 'timestamp')
                : isObjectDate(
                    values['appointmentTime'].format('DD-MM-YYYY HH:mm:ss')
                  ) === 'd da-al-In'
                ? null
                : isObjectDate(
                    values['appointmentTime'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  ),
              values.testFit,
              values.flagged,
              values.removalRequested,
              orderDetailList.stateFromClinic,
              values.flagNote,
              isObjectDate(
                values['appointmentSv2Timestamp'].format('DD-MM-YYYY HH:mm:ss')
              ) === 'd da-al-In'
                ? null
                : isObjectDate(
                    values['appointmentSv2Timestamp'].format(
                      'DD-MM-YYYY HH:mm:ss'
                    ),
                    'timestamp'
                  ),
              values.ocChecklist,
              isObjectDate(
                values['videoSentTime'].format('DD-MM-YYYY HH:mm:ss')
              ) === 'd da-al-In'
                ? null
                : isObjectDate(
                    values['videoSentTime'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  ),
              values.raRbRemarks
            );

            setCreateEditMessage(
              theResponse.data,
              'Success Updating Data With Order ID ' + id,
              'Error Updating Data'
            );

            if (theResponse.data.status !== 'FAILED') {
              getOrderDetailList(id);
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 2) {
      // buttonState === 2, trigger submit for flagging order, button flag in order detail
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            if (values.flagNoteDialog === '') {
              message.error('Please input flagged note!');
            } else {
              theResponse = await OrderApi.updateOrderDetailStatus(
                id,
                values.flagNoteDialog,
                values.userNote
              );
              setWhenFlag(true);
              setCreateEditMessage(
                theResponse.data,
                'Success Flagging Order With Order ID ' + id,
                'Error Flagging Order Data!'
              );
              handleHideModalFlag();
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 3) {
      // buttonState === 3, trigger submit for resolving order, button resolved in order detail
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            if (values.userNoteResolveDialog === '') {
              message.error('Please input resolve note!');
            } else {
              theResponse = await OrderApi.updateOrderDetailStatusResolve(
                id,
                values.userNoteResolveDialog //userNote
              );
              setWhenResolve(true);

              setCreateEditMessage(
                theResponse.data,
                'Succes Resolving Data With Order ID ' + id,
                'Error Resolving Data!'
              );
              handleHideModalResolve();
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 4) {
      // buttonState === 4, trigger submit for creating refinement, button create refinement in order detail
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            if (values.systemNoteRefinementDialog === '') {
              message.error('Please input refinement order!');
            } else {
              theResponse = await OrderApi.createRefinement(id, username);

              setCreateEditMessage(
                theResponse.data,
                'Success Create Refinement With Order ID ' + id,
                'Error Create Refinement!'
              );

              handleHideModalRefinement();
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 5) {
      // buttonState === 5, trigger submit for archiving order, button archived in order detail
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            if (values.userNoteArchivedDialog === '') {
              message.error('Please input archived note!');
            } else {
              theResponse = await OrderApi.updateOrderDetailStatusArchived(
                id,
                values.userNoteArchivedDialog //userNote
              );

              setCreateEditMessage(
                theResponse.data,
                'Success Archiving Order DATA with order ID ' + id,
                'Error Archiving Order Data!'
              );

              handleHideModalArchived();
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (buttonState === 6) {
      // buttonState === 6, trigger submit for reactiving order, button reactived in order detail
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            theResponse = await OrderApi.updateOrderDetailStatusReactive(
              id,
              'reactivate by API' //userNote
            );

            setCreateEditMessage(
              theResponse.data,
              'Success Reactived Order ID ' + id,
              'Error Reactived Order!'
            );
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    } else if (triggerButtonStateInRow === 7 || buttonState === 7) {
      // buttonState === 7, trigger submit for progressing order, this code can be triggered from button progress, modal production flow, or last step of production flow.
      form.validateFields(async (err, values) => {
        // const printTypeValue = values.printType === true ? 1 : 0;

        if (!err) {
          let theResponse;

          try {
            setLoadingButton(true);
            theResponse = await OrderApi.progressOrder(
              id,
              values.stateId,
              triggerButtonStateInRow === 0
                ? progress1Data.nextStateId
                  ? progress1Data.nextStateId
                  : nextStates[0].nextStateId
                : triggerStateFlowIdInRow,
              values.flowId,
              values.orderType,
              username,
              isConsultationSubmit
                ? isObjectDate(
                    values['consultationTime'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  )
                : null,
              isProgressAppointmentEndSubmit
                ? isObjectDate(
                    values['appointmentTime'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  )
                : !isEmpty(progress1Data.appointmentTime)
                ? isObjectDate(progress1Data.appointmentTime, 'timestamp')
                : null,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.totalUp,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.totalDown,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.ocUp,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.ocDown,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.agUp,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : values.agDown,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : progress1Data.attachment,
              isRedo || progress1Data.nextStateId !== '1042o'
                ? null
                : progress1Data.slicing,
              progress1Data.clinicId,
              progress1Data.shippingAddress,
              progress1Data.shippingId,
              // progress1Data.customerNote,
              values.customerPhone,
              progress1Data.systemNote
                ? progress1Data.systemNote
                : isRedo
                ? `${values.reason} at ${values.appointmentSv2Timestamp}: ${values.systemNoteRedoDialog}`
                : null,
              !isEmpty(progress1Data.diyDeliveryTime)
                ? isObjectDate(progress1Data.diyDeliveryTime, 'timestamp')
                : null,
              // values.stateId === '1025' || values.stateId === '1031' ? 1 : 0,
              //values.stateId === '1025' || values.stateId === '1031' ? values.testFit ? 1 : 0 : null,
              (values.stateId === '1025' && progress1Data.testFit) ||
                (values.stateId === '1031' && values.testFit)
                ? 1
                : 0,
              //   values.stateId == '1025'
              //     ? progress1Data.testFit
              //       ? 1
              //       : 0
              //     : values.stateId == '1031'
              //     ? values.testFit
              //       ? 1
              //       : 0
              //     : null,
              progress1Data.boxNumber,
              values.stateId === '1022'
                ? true
                : values.stateId === '1023'
                ? false
                : null,
              progress1Data.delivUp1,
              progress1Data.delivUp2,
              progress1Data.delivDown1,
              progress1Data.delivDown2,
              progress1Data.printUp1,
              progress1Data.printUp2,
              (!isEmpty(nextStates) &&
                nextStates.length < 2 &&
                nextStates[0].nextStateId === '1073') ||
                (!isEmpty(stateIdFlow) && stateIdFlow === '1073')
                ? values.printDown1 || values.printDown1 === 0
                  ? values.printDown1
                  : null
                : null,
              values.stateId === '1092' ? values.printDown2 : null,
              progress1Data.printType || progress1Data.printType === 0
                ? progress1Data.printType
                : // : values.stateId === '1022' &&
                //   nextStates[2].nextStateId === '1025' &&
                //   values.flowId !== 'F1' &&
                //   values.flowId !== 'F2'
                // ? printTypeValue
                values.stateId === '1025' &&
                  nextStates[0].nextStateId === '1031' &&
                  values.flowId !== 'H1A' &&
                  values.flowId !== 'H2A'
                ? 0
                : triggerPrintTypeInRow,
              values['appointmentSv2Timestamp']
                ? isObjectDate(
                    values['appointmentSv2Timestamp'].format(
                      'DD-MM-YYYY HH:mm:ss'
                    ),
                    'timestamp'
                  )
                : null,
              progress1Data.ocChecklist,
              isVideoSentDateSubmit
                ? isObjectDate(
                    values['videoSentTime'].format('DD-MM-YYYY HH:mm:ss'),
                    'timestamp'
                  )
                : null,
              values.raRbRemarks
            );

            setCreateEditMessage(
              theResponse.data,
              'Success Progressing Order DATA with Order ID ' + id,
              'Error Progressing Order Data!'
            );

            if (values.printDown1 || values.printDown2) {
              handleHideModalPrintDown();
            }
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        } else {
          message.error(
            'There is required field that must be filled, check again!'
          );
          setLoadingButton(false);
        }
      });
    } else if (buttonState === 8) {
      form.validateFields(async (err, values) => {
        if (!err) {
          try {
            setLoadingButton(true);
            let theResponse;

            theResponse = await OrderApi.progressRevert(
              id,
              values.rahang === 'RARB' && values.stateId === '1090'
                ? '15111'
                : values.rahang === 'RA' && values.stateId === '1090'
                ? '15121'
                : values.rahang === 'RB' && values.stateId === '1090'
                ? '15131'
                : values.rahang === 'RARB' && values.stateId === '1087'
                ? '16111'
                : values.rahang === 'RA' && values.stateId === '1087'
                ? '16121'
                : values.rahang === 'RB' && values.stateId === '1087'
                ? '16131'
                : null,
              localStorage.getItem('username')
            );

            setCreateEditMessage(
              theResponse.data,
              'Success Updating Data With Order ID ' + id,
              'Error Updating Data'
            );

            if (theResponse.data.status !== 'FAILED') {
              getOrderDetailList(id);
            }

            handleHideModalCetakUlang();
            handleHideModalTroubleshoot();

            // refactor this code using change state
            window.location.reload();
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          } finally {
            setLoadingButton(false);
          }
        }
      });
    }
  }

  // Calling redux for getting list clinic
  useEffect(() => {
    async function getClinicListData() {
      await getListClinic();
    }

    getClinicListData();
  }, []);

  // Calling API for getting data order detail by id
  async function getOrderDetailList() {
    const response = await OrderApi.listOrderDetailById(id);
    let orderDetailList = response.data.data.list[0];
    setValues(orderDetailList);
    setOrderDetailList(orderDetailList);
  }

  useEffect(() => {
    getOrderDetailList();
  }, []);

  useEffect(() => {
    if (!isEmpty(values)) {
      setStateId(values.stateId);
      setFlowId(values.flowId);
      setTestFitValue(
        values.testFit === true
          ? true
          : values.testFit === false || values.testFit === null
          ? false
          : '-'
      );
      setPrintType(
        values.printType === null
          ? 0
          : values.stateId === '1025' || values.stateId === '1031'
          ? values.printType
          : '-'
      );
      setCtdFlag(values.ctdFlag);
    }
  }, [values]);

  // Calling API for getting data list Order status
  useEffect(() => {
    async function getOrderStatusList() {
      const response = await OrderApi.listOrderStatus();
      let orderStatusList = setToArray(response.data);

      setOrderStatuss(orderStatusList);
    }

    getOrderStatusList();
  }, []);

  // Calling Redux for getting data payment detail by id
  useEffect(() => {
    async function getPaymentDetailByIdData() {
      await getPaymentDetailById(id);
    }

    getPaymentDetailByIdData();
  }, []);

  // Calling API for getting data state list
  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  // Calling API for getting data flow list
  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);
      setFlows(flowList);
    }

    getFlowList();
  }, []);

  // Calling API for getting data order type list
  useEffect(() => {
    async function getOrderTypeList() {
      const response = await OrderApi.listOrderType();
      let orderTypeList = setToArray(response.data);

      setOrderTypes(orderTypeList);
    }

    getOrderTypeList();
  }, []);

  // Calling API for getting data next state (percabangan)
  useEffect(() => {
    async function getNextStateList() {
      if (id) {
        if (flowId && stateId) {
          const response = await ActionApi.listActionByFlowAndState(
            flowId,
            stateId,
            testFitValue === true
              ? true
              : testFitValue === false || testFitValue === null
              ? false
              : '-',
            printType,
            id,
            ctdFlag
          );
          let nextStatesList = setToArray(response.data);

          setNextStates(nextStatesList);
        }
      }
    }

    getNextStateList();
  }, [flowId, stateId, id, testFitValue]);

  // Calling API for getting box list
  useEffect(() => {
    async function getBoxList() {
      const response = await BoxApi.listBox();
      let boxList = setToArray(response.data);

      setBoxs(boxList);
    }

    getBoxList();
  }, []);

  // Calling API for getting box list
  //   useEffect(() => {
  //     async function getOrderShippingList() {
  //       const response = await OrderApi.listOrderShipping();
  //       let orderShippingList = setToArray(response.data);

  //       setOrderShippings(orderShippingList);
  //     }

  //     getOrderShippingList();
  //   }, []);

  // Calling REDUX for getting history list by id
  useEffect(() => {
    async function getHistoryById() {
      await getOrderHistoryList(id);

      // await getRefinementHistoryList(id);
    }

    getHistoryById();
  }, []);

  // Calling REDUX for getting reset history list by id
  useEffect(() => {
    async function getResetHistoryById() {
      await getResetHistoryList(id);
    }

    getResetHistoryById();
  }, []);

  // Calling REDUX for getting order shipping list
  useEffect(() => {
    async function getShippingListById() {
      if (!isEmpty(values) && !isEqual(prevValuesForm, values)) {
        let shippingId = values.orderShippingId;
        await getOrderShippingById(shippingId);
      }
    }

    getShippingListById();
  }, [values]);

  function setCreateEditMessage(response, successMsg, failedMsg) {
    if (response.status === 'FAILED') {
      if (response.resultCode === 'BR') {
        message.error(failedMsg);
      } else {
        message.error(response.desc);
      }
    } else {
      message.success(successMsg);
      if (
        isRescheduleAppointmentWithClinic ||
        isRescheduleAppointment ||
        !successMsg.includes('Updating Data')
      ) {
        history.push({ pathname: '/cms/order/active' });
      }
    }
  }

  const detailTitle = !isEmpty(values)
    ? `${
        values.createdAt
          ? `${moment(values.createdAt).format(moment.HTML5_FMT.DATE)} ${moment(
              values.createdAt
            ).format(moment.HTML5_FMT.TIME_SECONDS)} -`
          : ''
      } ${values.boxNumber !== '-' ? `${values.boxNumber} -` : ''} ${
        values.stateId
      }${values.stateName ? ` - ${values.stateName}` : ''}`
    : '';

  const orderTypeOptions = orderTypes.map((value) => ({
    label: `${value.orderType} - ${value.orderDesc}`,
    value: value.orderType,
  }));

  const nextStateOptions = nextStates.map((value) => ({
    label: value.actionName,
    value: value.nextStateId,
  }));

  const redoStateList = nextStates.filter(function (el) {
    return el.progressType !== 'Approve';
  });

  const nextStateList = nextStates.filter(function (el) {
    return el.progressType === 'Approve';
  });

  //need to refactor
  // === Handle submit auto progress, in modal "select production flow" ===
  const handleSubmitFlowAuto = (e, record, allRecord) => {
    triggerButtonStateInRow = 7;
    triggerStateFlowIdInRow = record;
    // triggerAllRecord = allRecord;

    if (allRecord.name === 'Putty' && record === '1025')
      triggerPrintTypeInRow = 0;
    if (allRecord.name === 'Putty' && record === '1026')
      triggerPrintTypeInRow = 0;
    if (allRecord.name === '3D Scan' && record === '1025')
      triggerPrintTypeInRow = 1;

    handleSubmit(e);
  };

  // Handle submit updating order, button checked in top of page
  const handleButtonSubmit = () => {
    setButtonState(1);
  };

  // Handle submit reactived order, button reactived in order detail
  const handleButtonReactive = () => {
    setButtonState(6);
  };

  // === Handle add address modal ===
  // show modal
  const handleShowAddAddress = () => {
    setShowAddAddress(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideAddAddress = () => {
    setShowAddAddress(false);
  };

  // === Handle add cetak ulang modal ===
  // show modal
  const handleShowModalCetakUlang = () => {
    setShowModalCetakUlang(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalCetakUlang = () => {
    setShowModalCetakUlang(false);
  };

  // === Handle add troubleshoot modal ===
  // show modal
  const handleShowModalTroubleshoot = () => {
    setShowModalTroubleshoot(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalTroubleshoot = () => {
    setShowModalTroubleshoot(false);
  };

  // === Handle flag modal ===
  // show modal
  const handleShowModalFlag = () => {
    setShowModalFlag(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalFlag = () => {
    setShowModalFlag(false);
  };
  // submit modal
  const handleButtonFlagged = () => {
    setButtonState(2);
  };

  // === Handle resolved modal ===
  // show modal
  const handleShowModalResolve = () => {
    setShowModalResolve(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalResolve = () => {
    setShowModalResolve(false);
  };

  // === Handle create refinement modal ===
  // show modal
  const handleShowModalRefinement = () => {
    setShowModalRefinement(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalRefinement = () => {
    setShowModalRefinement(false);
  };

  const handleModalRefinementTroubleshoot = () => {
    setshowModalRefinementTroubleshoot((show) => !show);
  };

  const handleModalConfirmCetakUlang = () => {
    setShowModalConfirmCetakUlang((show) => !show);
  };

  const handleSubmitModalTroubleshoot = () => {
    setButtonState(9);
    // form.validateFields(async (err, values) => {
    // })
  };

  // === Handle adjust sla deadline modal ===
  // show modal
  const handleShowSlaDeadline = () => {
    setShowModalSLADeadline(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideSlaDeadline = () => {
    setShowModalSLADeadline(false);
  };

  // === Handle archived modal ===
  // show modal
  const handleHideModalArchived = () => {
    setShowModalArchived(false);
    setTestStatus(false);
  };
  // hide modal
  const handleShowModalArchived = () => {
    setShowModalArchived(true);
  };
  // submit modal
  const handleSubmitModalSLADeadline = () => {
    setButtonState(1);
    handleHideSlaDeadline();
  };

  // === Handle AppointmentSV2 modal ===
  // show modal when click reschedule appointment in state 1087
  const handleShowModalAppointmentSV2 = () => {
    setShowModalAppointmentSV2(true);
    setIsRescheduleAppointment(true);
    setTestStatus(false);
  };
  // show modal, in progress flow
  const handleShowModalProgressAppointmentSV2 = () => {
    setShowModalAppointmentSV2(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalAppointmentSV2 = () => {
    setShowModalAppointmentSV2(false);
  };
  // submit modal
  const handleSubmitModalAppointmentSV2 = () => {
    if (isRescheduleAppointment) {
      //Set from button reschedule appointment
      setButtonState(1);
    } else {
      setButtonState(7);
    }

    handleHideModalAppointmentSV2();
  };

  // === Handle appointment modal ===, this modal show when you click button reschedule appointment
  //show modal
  const handleShowModalAppointment = () => {
    setShowModalAppointment(true);
    setIsRescheduleAppointment(true);
  };

  // === Handle appointment modal ===, this modal show when you click button reschedule appointment, but in state 1022
  const handleShowModalRescheduleAppointmentWithClinic = () => {
    setShowModalAppointmentStart(true);
    setIsRescheduleAppointmentWithClinic(true);
  };

  // === Handle hide all appointment modal (both from progress flow/ button reschedule appointment) ===
  //hide modal
  const handleHideModalAppointment = () => {
    setShowModalAppointment(false);
  };

  // === Handle submit all kind of appointment modals (both from progress flow/ button reschedule appointment) ===
  //submit
  const handleSubmitModalAppointment = () => {
    if (isRescheduleAppointment) {
      //Set from button reschedule appointment
      setButtonState(1);
    } else {
      setIsProgressAppointmentEndSubmit(true);
      setButtonState(7);
    }
    handleHideModalAppointment();
  };

  // === Handle appointment modal ===, this modal show in progress flow dan biasanya urutannya berada pada awal flow
  // show modal
  const handleShowModalProgressAppointment = () => {
    setShowModalAppointmentStart(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalProgressAppointment = () => {
    setShowModalAppointmentStart(false);
  };

  // === Handle appointment modal ===, this modal show in progress flow dan biasanya urutannya berada pada akhir flow
  // show modal
  const handleShowModalProgressAppointmentEnd = () => {
    setIsRescheduleAppointment(false);
    setShowModalAppointment(true);
    setTestStatus(false);
  };

  // === Handle redo appointment modal ===, this modal show in progress flow dan biasanya urutannya berada pada awal flow
  // show modal
  const handleShowModalRedoAppointmentStart = () => {
    setShowModalRedoAppointmentStart(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalRedoAppointmentStart = () => {
    setShowModalRedoAppointmentStart(false);
  };

  // === Handle "Select production flow" modal ===, this modal show in progress flow
  // show modal
  const handleShowModalProductionFlow = () => {
    setShowModalProgressFlow(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalProductionFlow = () => {
    setShowModalProgressFlow(false);
  };

  // === Handle consultaion modal ===, this modal show in progress flow
  // show modal
  const handleShowModalConsultation = () => {
    setShowModalConsultation(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalConsultation = () => {
    setShowModalConsultation(false);
  };
  // submit modal
  const handleSubmitModalConsultation = () => {
    setIsConsultationSubmit(true);
    setButtonState(7);
    handleHideModalConsultation();
  };

  // === Handle replace box modal ===, this modal show when you click button replace box
  // show modal
  const handleShowModalReplaceBox = () => {
    setShowModalReplaceBox(true);
    setIsReplaceBox(true);
    setTestStatus(false);
  };

  // === Handle replace box modal ===, this modal show in progress flow
  // show modal
  const handleShowModalProgressBox = () => {
    setShowModalReplaceBox(true);
    setTestStatus(false);
  };
  // hide modal for modal in button replace box and progress flow replace box
  const handleHideModalReplaceBox = () => {
    setShowModalReplaceBox(false);
    setIsReplaceBox(false);
  };

  // === Handle clinic list modal ===, this modal show in progress flow
  // show modal
  const handleShowModalClinic = () => {
    setShowModalClinicList(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalClinic = () => {
    setShowModalClinicList(false);
  };

  // === Handle DIY Date modal ===, this modal show in progress flow
  // show modal
  const handleShowModalDIYDate = () => {
    setShowModalDIYDate(true);
  };
  // hide modal
  const handleHideModalDIYDate = () => {
    setShowModalDIYDate(false);
  };

  // === Handle Delivery Address modal ===, this modal show in progress flow
  // show modal
  const handleShowModalDeliveryAddress = () => {
    setShowModalDeliveryAddress(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalDeliveryAddress = () => {
    setShowModalDeliveryAddress(false);
  };

  // === Handle Test fit modal ===, this modal show in progress flow
  // show modal
  const handleShowModalTestFit = () => {
    setShowModalTestFit(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalTestFit = () => {
    setShowModalTestFit(false);
  };

  // === Handle Treat Attachment modal ===, this modal show in progress flow
  // show modal
  const handleShowModalAttachment = () => {
    setShowModalAttachment(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalAttachment = () => {
    setShowModalAttachment(false);
  };

  // === Handle Treat Slicing modal ===, this modal show in progress flow
  // show modal
  const handleShowModalSlicing = () => {
    setShowModalSlicing(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalSlicing = () => {
    setShowModalSlicing(false);
  };

  // === Handle OC modal ===, this modal show in progress flow
  // show modal
  const handleShowModalOC = () => {
    setShowModalOC(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalOC = () => {
    setShowModalOC(false);
  };

  // === Handle Redo modal ===, this modal show in progress flow
  // show modal
  const handleShowModalRedo = () => {
    setShowModalRedoProgress(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalRedo = () => {
    setShowModalRedoProgress(false);
  };
  // submit modal
  const handleSubmitModalRedo = () => {
    setProgress1Data({
      ...progress1Data,
      nextStateId: !isEmpty(stateIdFlow) && stateIdFlow,
    });
    setIsRedo(true);
    handleHideModalRedo();
    setButtonState(7);
  };

  // === Handle Video Sent Date modal ===, this modal show in progress flow
  // show modal
  const handleShowModalVideoSentDate = () => {
    setShowModalVideoSentDateProgress(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalVideoSentDate = () => {
    setShowModalVideoSentDateProgress(false);
  };
  // submit modal
  const handleSubmitModalVideoSentDate = () => {
    setProgress1Data({
      ...progress1Data,
      nextStateId: !isEmpty(stateIdFlow) && stateIdFlow,
    });
    setIsVideoSentDateSubmit(true);
    setButtonState(7);
    handleHideModalVideoSentDate();
  };

  // === Handle courier delivery modal ===, this modal show in progress flow
  // show modal
  const handleShowModalCourier = () => {
    setShowModalCourier(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalCourier = () => {
    setShowModalCourier(false);
  };

  // === Handle clinic note modal ===, this modal show in progress flow
  // show modal
  const handleShowModalClinicNote = () => {
    setShowModalClinicNote(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalClinicNote = () => {
    setShowModalClinicNote(false);
  };

  // === Handle print type modal (3D/putty select) ===, this modal show in progress flow
  // hide modal
  const handleHideModalPrintType = () => {
    setShowModalPrintType(false);
  };
  // show modal
  const handleShowModalPrintType = () => {
    setShowModalPrintType(true);
    setTestStatus(false);
  };

  // === Handle print up modal ===, this modal show in progress flow
  // show modal
  const handleShowModalPrintUp = () => {
    setShowModalPrintUp(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalPrintUp = () => {
    setShowModalPrintUp(false);
  };

  // === Handle print down modal ===, this modal show in progress flow
  // show modal
  const handleShowModalPrintDown = () => {
    setShowModalPrintDown(true);
    setButtonProgressClicked(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalPrintDown = () => {
    setShowModalPrintDown(false);
    setButtonProgressClicked(false);
  };
  // submit modal
  const handleSubmitModalPrintDown = () => {
    setButtonState(7);
  };

  // === Handle delivery up modal ===, this modal show in progress flow
  // show modal
  const handleShowModalDelivUp = () => {
    setShowModalDelivUp(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalDelivUp = () => {
    setShowModalDelivUp(false);
  };

  // === Handle delivery down modal ===, this modal show in progress flow
  // show modal
  const handleShowModalDelivDown = () => {
    setShowModalDelivDown(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalDelivDown = () => {
    setShowModalDelivDown(false);
  };

  //  === Handle impression modal ===, this modal show in progress flow
  // show modal
  const handleShowModalImpression = () => {
    setShowModalImpression(true);
  };
  // hide modal
  const handleHideModalImpression = () => {
    setShowModalImpression(false);
  };

  //  === Handle confirm CU modal ===, this modal show when button CU is clicked
  // show modal
  const handleShowModalConfirmCU = () => {
    setShowModalConfirmCU(true);
    setTestStatus(false);
  };
  // hide modal
  const handleHideModalConfirmCU = () => {
    setShowModalConfirmCU(false);
  };

  return (
    <>
      {!isEmpty(values) ? (
        <Layout className="edit-form edit-form--order-detail">
          <Content>
            <Title className="edit-form__title">{detailTitle}</Title>
            {orderDetailList.flagged && (
              <div style={{ marginBottom: '1em' }}>
                <TagLabel
                  name={
                    <>
                      <Icon type="warning" />
                      {` `}
                      <span style={{ fontWeight: 'bold' }}>Flagged.</span>{' '}
                      Please read{' '}
                      <span style={{ fontWeight: 'bold' }}>System Note!</span>
                    </>
                  }
                  color={'red'}
                />
              </div>
            )}
            <Form
              onSubmit={(event) => handleSubmit(event)}
              id="form-detail-order">
              {opSubmitOrder && (
                <Form.Item className="edit-form__submit">
                  {orderDetailList.flagged === false && (
                    <Tooltip title="Submit">
                      <Button
                        size="large"
                        htmlType="submit"
                        icon="check-circle"
                        loading={loadingButton}
                        onClick={handleButtonSubmit}
                      />
                    </Tooltip>
                  )}
                </Form.Item>
              )}

              <Tabs type="card" size="large" className="edit-form__tab">
                <TabPane
                  key={1}
                  tab={`${
                    values.orderStatusId === 'A' ? 'Archived' : 'Active'
                  } Order`}
                  forceRender={true}>
                  <ActiveOrders
                    id={id}
                    location={location}
                    boxs={boxs}
                    orderTypes={orderTypes}
                    states={states}
                    values={values}
                    loadingButton={loadingButton}
                    handleShowModalImpression={handleShowModalImpression}
                    handleShowModalPrintType={handleShowModalPrintType}
                    handleShowAddAddress={handleShowAddAddress}
                    handleShowModalCetakUlang={handleShowModalCetakUlang}
                    handleShowModalTroubleshoot={handleShowModalTroubleshoot}
                    handleShowModalFlag={handleShowModalFlag}
                    handleShowModalResolve={handleShowModalResolve}
                    handleShowModalRefinement={handleShowModalRefinement}
                    handleModalRefinementTroubleshoot={
                      handleModalRefinementTroubleshoot
                    }
                    handleShowSlaDeadline={handleShowSlaDeadline}
                    handleShowModalAppointment={handleShowModalAppointment}
                    handleShowModalPrintUp={handleShowModalPrintUp}
                    handleShowModalDelivUp={handleShowModalDelivUp}
                    handleShowModalAttachment={handleShowModalAttachment}
                    handleShowModalProgressBox={handleShowModalProgressBox}
                    handleShowModalRescheduleAppointmentWithClinic={
                      handleShowModalRescheduleAppointmentWithClinic
                    }
                    handleShowModalConfirmCU={handleShowModalConfirmCU}
                    handleShowModalProgressAppointmentSV2={
                      handleShowModalProgressAppointmentSV2
                    }
                    handleShowModalVideoSentDate={handleShowModalVideoSentDate}
                    handleShowModalAppointmentSV2={
                      handleShowModalAppointmentSV2
                    }
                    handleShowModalDeliveryAddress={
                      handleShowModalDeliveryAddress
                    }
                    handleShowModalProductionFlow={
                      handleShowModalProductionFlow
                    }
                    handleModalConfirmCetakUlang={handleModalConfirmCetakUlang}
                    detailTitle={detailTitle}
                    progress1Data={progress1Data}
                    setProgress1Data={setProgress1Data}
                    setButtonState={setButtonState}
                    prodFlowList={nextStates}
                    handleShowModalReplaceBox={handleShowModalReplaceBox}
                    getFieldDecorator={getFieldDecorator}
                    form={form}
                    handleButtonFlagged={handleButtonFlagged}
                    orderTypeOptions={orderTypeOptions}
                    whenFlag={whenFlag}
                    whenResolve={whenResolve}
                    handleShowModalArchived={handleShowModalArchived}
                    handleButtonReactive={handleButtonReactive}
                    orderShippingById={orderShippingById}
                  />
                </TabPane>
                <TabPane tab="Tracking" forceRender={true} key={2}>
                  <Tracking
                    id={id}
                    location={location}
                    values={values}
                    flows={flows}
                    orderStatuss={orderStatuss}
                    orderShippings={orderShippings}
                    nextStates={nextStates}
                    nextStateOptions={nextStateOptions}
                    getFieldDecorator={getFieldDecorator}
                    activeFilterOrderLog={activeFilterOrderLog}
                    activeFilterRefinement={activeFilterRefinement}
                    setActiveFilterRefinement={setActiveFilterRefinement}
                    setActiveFilterOrderLog={setActiveFilterOrderLog}
                    // refinementHistory={
                    //   !isEmpty(refinementHistoryList)
                    //     ? refinementHistoryList
                    //     : []
                    // }
                    resetHistory={
                      !isEmpty(resetHistoryList) ? resetHistoryList : []
                    }
                  />
                </TabPane>
                <TabPane tab="Details" forceRender={true} key={3}>
                  <Details currentState={values.stateId} values={values} />
                </TabPane>
              </Tabs>

              {testStatus === false && (
                <>
                  {/* Modal Add address, this modal show when you click button add address, need refactor to use global single form */}
                  <ModalAddAddress
                    orderShippings={orderShippings}
                    showAddAddress={showAddAddress}
                    handleHideAddAddress={handleHideAddAddress}
                    shippingId={values && values.orderShippingId}
                    setShowAddAddress={setShowAddAddress}
                  />

                  {/* Modal Cetak Ulang, this modal show when you click button cetak ulang, need refactor to use global single form */}
                  <ModalCetakUlang
                    showModal={showModalCetakUlang}
                    handleHideModalCetakUlang={handleHideModalCetakUlang}
                    setButtonState={setButtonState}
                    form={form}
                  />

                  {/* Modal Troubleshoot, this modal show when you click button Troubleshoot, need refactor to use global single form */}
                  <ModalTroubleshoot
                    showModal={showModalTroubleshoot}
                    handleHideModalTroubleshoot={handleHideModalTroubleshoot}
                    setButtonState={setButtonState}
                    form={form}
                  />

                  {/* Modal flag, this modal show when you click button flag, need refactor to use global single form */}
                  <ModalFlag
                    showModal={showModalFlag}
                    setButtonState={setButtonState}
                    form={form}
                    // values={values}
                    handleHideModalFlag={handleHideModalFlag}
                  />

                  {/* Modal resolved, this modal show when you click button resolved, need refactor to use global single form */}
                  <ModalResolve
                    showModal={showModalResolve}
                    setButtonState={setButtonState}
                    form={form}
                    handleHideModalResolve={handleHideModalResolve}
                  />

                  {/* Modal create refinement, this modal show when you click button create refinement, need refactor to use global single form */}
                  <ModalRefinement
                    showModal={showModalRefinement}
                    setButtonState={setButtonState}
                    form={form}
                    handleHideModal={handleHideModalRefinement}
                    loadingButton={loadingButton}
                  />

                  {/* Modal create refinement New Concept */}
                  {(values.stateId === '1090' || values.stateId === '2011') && (
                    <ModalRefinementTroubleshoot
                      showModal={showModalRefinementTroubleshoot}
                      setButtonState={setButtonState}
                      form={form}
                      handleHideModal={handleModalRefinementTroubleshoot}
                      handleSubmitModalTroubleshoot={
                        handleSubmitModalTroubleshoot
                      }
                    />
                  )}

                  {/* Modal archived, this modal show when you click button archived, need refactor to use global single form  */}
                  <ModalArchived
                    showModal={showModalArchived}
                    setButtonState={setButtonState}
                    form={form}
                    handleHideModal={handleHideModalArchived}
                  />

                  {/* Modal SLA Deadline, this modal show when you click button adjust sla deadline*/}
                  <ModalSingleForm
                    showModal={showModalSLADeadline}
                    form={form}
                    handleSubmitModal={handleSubmitModalSLADeadline}
                    handleHideModal={handleHideSlaDeadline}
                    values={values}
                    idContainer={'form-detail-order'}
                    modalTitle={'Adjust SLA Deadline'}
                    initValue={
                      values.orderDeadline
                        ? moment(values.orderDeadline, 'DD-MM-YYYY HH:mm:ss')
                        : moment()
                    }
                    fieldDecoratorName={'SLA Deadline'}
                    modalDesc={'Input Date & Time to adjust SLA Deadline'}
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Click to select a date"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Modal Reschedule Appointment, this modal show when you click button reschedule appointment or appointment in progress with submit (modal appointment that placed in the end of the flow)*/}
                  <ModalSingleForm
                    showModal={showModalAppointment}
                    form={form}
                    handleSubmitModal={handleSubmitModalAppointment}
                    handleHideModal={handleHideModalAppointment}
                    values={values}
                    idContainer={'form-detail-order'}
                    modalTitle={'Appointment'}
                    initValue={moment()}
                    fieldDecoratorName={'appointmentTime'}
                    modalDesc={
                      'Input Appointment Date & Time to reschedule appointment'
                    }
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Appointment Date & Time*"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Modal Reschedule Appointment SV2, this modal in progress flow or when you click reschedule appointment button in state 1087*/}
                  <ModalSingleForm
                    showModal={showModalAppointmentSV2}
                    form={form}
                    handleSubmitModal={handleSubmitModalAppointmentSV2}
                    handleHideModal={handleHideModalAppointmentSV2}
                    values={values}
                    idContainer={'form-detail-order'}
                    modalTitle={'Appointment'}
                    initValue={moment()}
                    fieldDecoratorName={'appointmentSv2Timestamp'}
                    modalDesc={
                      'Input Appointment Date & Time to reschedule appointment'
                    }
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Appointment Date & Time*"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Consultation Modal, this modal show when you click next state in progress flow that need to fill consultation time*/}
                  <ModalSingleForm
                    showModal={showModalConsultation}
                    form={form}
                    handleSubmitModal={handleSubmitModalConsultation}
                    handleHideModal={handleHideModalConsultation}
                    values={values}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    modalName={'consultation-modal'}
                    idContainer={'form-detail-order'}
                    modalTitle={'Consultation'}
                    initValue={moment()}
                    fieldDecoratorName={'consultationTime'}
                    modalDesc={
                      'Input Consultation Date & Time to create consultation and progress order'
                    }
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Consultation Date & Time*"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Production Flow Modal, this modal show when you click progress button and need to choose multiple next state*/}
                  <ModalProductionFlow
                    showModal={showModalProgressFlow}
                    handleHideModal={handleHideModalProductionFlow}
                    handleShowModalConsultation={handleShowModalConsultation}
                    handleShowModalProgressAppointment={
                      handleShowModalProgressAppointment
                    }
                    handleShowModalAppointmentEnd={
                      handleShowModalProgressAppointmentEnd
                    }
                    handleShowModalRedoAppointmentStart={
                      handleShowModalRedoAppointmentStart
                    }
                    handleShowModalPrintType={handleShowModalPrintType}
                    handleShowModalDIYDate={handleShowModalDIYDate}
                    handleShowModalPrintUp={handleShowModalPrintUp}
                    handleShowModalDelivUp={handleShowModalDelivUp}
                    handleShowModalAttachment={handleShowModalAttachment}
                    handleShowModalProgressBox={handleShowModalProgressBox}
                    handleShowModalDeliveryAddress={
                      handleShowModalDeliveryAddress
                    }
                    handleShowModalOC={handleShowModalOC}
                    handleShowModalRedo={handleShowModalRedo}
                    handleShowModalVideoSentDate={handleShowModalVideoSentDate}
                    handleShowModalClinicNote={handleShowModalClinicNote}
                    values={values}
                    prodFlowList={nextStates}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setStateIdFlow={setStateIdFlow}
                    setButtonState={setButtonState}
                    form={form}
                    formRef={formRef}
                    handleSubmitFlowAuto={handleSubmitFlowAuto}
                    redoStateList={redoStateList}
                    nextStateList={nextStateList}
                  />

                  {/* Replace box modal, this modal show when you click replace box button (if replacebox true) / in progress flow (if replace box false)*/}
                  <ModalReplaceBox
                    showModal={showModalReplaceBox}
                    handleHideModal={handleHideModalReplaceBox}
                    isReplaceBox={isReplaceBox}
                    setButtonState={setButtonState}
                    handleShowModalTestFit={handleShowModalTestFit}
                    form={form}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    values={values}
                  />

                  {/* Test fit modal, this modal show in progress flow*/}
                  <ModalTestFit
                    handleHideModal={handleHideModalTestFit}
                    showModal={showModalTestFit}
                    // stateIdFlow={stateIdFlow}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setButtonState={setButtonState}
                    form={form}
                    values={values}
                    idContainer={''}
                    // prodFlowList={nextStates}
                    showOtherModal={handleShowModalProductionFlow}
                  />

                  {/* Redo modal, this modal show in progress flow*/}
                  <ModalSingleForm
                    showModal={showModalRedoProgress}
                    form={form}
                    handleSubmitModal={handleSubmitModalRedo}
                    handleHideModal={handleHideModalRedo}
                    values={values}
                    idContainer={'form-detail-order'}
                    modalTitle={`Redo ${
                      stateIdFlow === '1061' || values.stateId === '1063'
                        ? 'QC'
                        : values.stateId === '1031' || values.stateId === '1025'
                        ? 'Appointment'
                        : values.stateId === '1022' ||
                          values.stateId === '1040' ||
                          values.stateId === '1044'
                        ? 'State'
                        : 'Design'
                    }`}
                    initValue={''}
                    fieldDecoratorName={'systemNoteRedoDialog'}
                    modalDesc={`Input Redo ${
                      stateIdFlow === '1061' || values.stateId === '1063'
                        ? 'QC'
                        : values.stateId === '1031' || values.stateId === '1025'
                        ? 'Appointment'
                        : values.stateId === '1022' ||
                          values.stateId === '1040' ||
                          values.stateId === '1044'
                        ? 'State'
                        : 'Design'
                    } Note to progress order.`}
                    options={
                      values.stateId === '1022' && stateIdFlow === '1011'
                        ? reasonList1022to1011
                        : values.stateId === '1022' && stateIdFlow === '1022'
                        ? reasonList1022to1022
                        : values.stateId === '1031' && stateIdFlow === '1011'
                        ? reasonList1031to1011
                        : values.stateId === '1040' && stateIdFlow === '1011'
                        ? reasonList1040to1011
                        : (values.stateId === '1040' &&
                            stateIdFlow === '1022') ||
                          (values.stateId === '1025' && stateIdFlow === '1022')
                        ? reasonList1040to1022
                        : values.stateId === '1040' && stateIdFlow === '1063'
                        ? reasonList1040to1063
                        : values.stateId === '1040' && stateIdFlow === '1064'
                        ? reasonList1040to1064
                        : values.stateId === '1040' && stateIdFlow === '1066'
                        ? reasonList1040to1066
                        : values.stateId === '1044' && stateIdFlow === '1011'
                        ? reasonList1044to1011
                        : values.stateId === '1044' && stateIdFlow === '1022'
                        ? reasonList1044to1022
                        : values.stateId === '1044' && stateIdFlow === '1064'
                        ? reasonList1044to1064
                        : values.stateId === '1044' && stateIdFlow === '1066'
                        ? reasonList1044to1066
                        : values.stateId === '1063' && stateIdFlow === '1011'
                        ? reasonList1063to1011
                        : reasonList1044to1066
                    }
                    inputComponent={
                      <TextArea
                        placeholder={`Input Redo ${
                          stateIdFlow === '1061' || values.stateId === '1063'
                            ? 'QC'
                            : values.stateId === '1031' ||
                              values.stateId === '1025'
                            ? 'Appointment'
                            : values.stateId === '1022' ||
                              values.stateId === '1040' ||
                              values.stateId === '1044'
                            ? 'State'
                            : 'Design'
                        } Notes`}
                        row={4}
                      />
                    }
                  />

                  {/* Video sent date modal, this modal show in progress flow*/}
                  <ModalSingleForm
                    showModal={showModalVideoSentDateProgress}
                    form={form}
                    handleSubmitModal={handleSubmitModalVideoSentDate}
                    handleHideModal={handleHideModalVideoSentDate}
                    values={values}
                    idContainer={'form-detail-order'}
                    modalTitle={'Video Sent Date'}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    initValue={
                      values.orderDeadline
                        ? moment(values.orderDeadline, 'DD-MM-YYYY HH:mm:ss')
                        : moment()
                    }
                    fieldDecoratorName={'videoSentTime'}
                    modalDesc={'Video Send Date & Time to Patient'}
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Select Video Send Date & Time to Patient"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* delivery atas/ delivery up modal, this modal show in progress flow*/}
                  <ModalSingleWithoutSubmit
                    showModal={showModalDelivUp}
                    handleHideModal={handleHideModalDelivUp}
                    modalName={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      (!isEmpty(nextStates) &&
                        nextStates[0].nextStateId === '2011') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '2011')
                        ? 'deliv-up-2-modal'
                        : 'deliv-up-1-modal'
                    }
                    showOtherModal={handleShowModalDelivDown}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    modalTitle={'Delivery Validation'}
                    detailValue={values}
                    initValue={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivUpRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? 3
                        : values.printUp1
                    }
                    maxNumberValid2={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivUpRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalUp >= 3
                          ? 3
                          : values.totalUp
                        : 10
                    }
                    minNumberValid2={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivUpRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalUp >= 3
                          ? 3
                          : values.totalUp
                        : 0
                    }
                    fieldDecoratorName={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      (!isEmpty(nextStates) &&
                        nextStates[0].nextStateId === '2011') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '2011')
                        ? 'delivUp2'
                        : 'delivUp1'
                    }
                    modalDesc={'Input Jumlah Delivery Atas to progress order'}
                    inputComponent={
                      <Input
                        placeholder="Jumlah Delivery Atas *"
                        size="large"
                      />
                    }
                  />

                  {/* delivery down/ delivery bawah modal, this modal show in progress flow*/}
                  <ModalSingleWithoutSubmit
                    showModal={showModalDelivDown}
                    handleHideModal={handleHideModalDelivDown}
                    modalName={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      (!isEmpty(nextStates) &&
                        nextStates[0].nextStateId === '2011') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '2011')
                        ? 'deliv-down-2-modal'
                        : 'deliv-down-1-modal'
                    }
                    showOtherModal={handleShowModalDeliveryAddress}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    modalTitle={'Delivery Validation'}
                    detailValue={values}
                    initValue={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivDownRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? 3
                        : values.printDown1
                    }
                    fieldDecoratorName={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      (!isEmpty(nextStates) &&
                        nextStates[0].nextStateId === '2011') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '2011')
                        ? 'delivDown2'
                        : 'delivDown1'
                    }
                    maxNumberValid2={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivDownRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalDown >= 3
                          ? 3
                          : values.totalDown
                        : 10
                    }
                    minNumberValid2={
                      values.stateId === '1094i' ||
                      values.stateId === '1094o' ||
                      values.flowId.includes('A') ||
                      values.flowId.includes('E') ||
                      values.flowId.includes('F') ||
                      values.flowId.includes('Y')
                        ? values.delivDownRemaining
                        : (values.stateId === '1074i' ||
                            values.stateId === '1074o') &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalDown >= 3
                          ? 3
                          : values.totalDown
                        : 0
                    }
                    modalDesc={'Input Jumlah Delivery Bawah to progress order'}
                    inputComponent={
                      <Input
                        placeholder="Jumlah Delivery Bawah *"
                        size="large"
                      />
                    }
                  />

                  {/* print up/ print atas modal, this modal show in progress flow*/}
                  <ModalSingleWithoutSubmit
                    showModal={showModalPrintUp}
                    handleHideModal={handleHideModalPrintUp}
                    modalName={
                      (!isEmpty(nextStates) &&
                        nextStates.length < 2 &&
                        nextStates[0].nextStateId === '1073') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '1073')
                        ? 'print-up-1-modal'
                        : 'print-up-2-modal'
                    }
                    showOtherModal={handleShowModalPrintDown}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    modalTitle={'Print Validation'}
                    detailValue={values}
                    initValue={
                      values.stateId === '1092'
                        ? values.printUpRemaining
                        : values.stateId === '1072' &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? ''
                        : ''
                    }
                    fieldDecoratorName={
                      (!isEmpty(nextStates) &&
                        nextStates.length < 2 &&
                        nextStates[0].nextStateId === '1073') ||
                      (!isEmpty(stateIdFlow) && stateIdFlow === '1073')
                        ? 'printUp1'
                        : 'printUp2'
                    }
                    modalDesc={'Input Jumlah Print Atas to progress order'}
                    inputComponent={
                      <Input placeholder="Jumlah Print Atas *" size="large" />
                    }
                    maxNumberValid2={
                      values.stateId === '1092'
                        ? values.printUpRemaining
                        : values.stateId === '1072' &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalUp >= 3
                          ? 3
                          : values.totalUp
                        : 10
                    }
                    minNumberValid2={
                      values.stateId === '1092'
                        ? values.printUpRemaining
                        : values.stateId === '1072' &&
                          (values.flowId.includes('B') ||
                            values.flowId.includes('D'))
                        ? values.totalUp >= 3
                          ? 3
                          : values.totalUp
                        : 0
                    }
                  />

                  {/* print down/ print bawah modal, this modal show in progress flow */}
                  {!values.stateId.includes('1074') && (
                    <ModalSingleForm
                      showModal={showModalPrintDown}
                      form={form}
                      handleSubmitModal={handleSubmitModalPrintDown}
                      handleHideModal={handleHideModalPrintDown}
                      values={values}
                      idContainer={'form-detail-order'}
                      modalTitle={'Print Validation'}
                      buttonProgressClicked={buttonProgressClicked}
                      progress1Data={progress1Data}
                      initValue={
                        values.stateId === '1092'
                          ? values.printDownRemaining
                          : values.stateId === '1072' &&
                            (values.flowId.includes('B') ||
                              values.flowId.includes('D') ||
                              values.flowId.includes('Y'))
                          ? ''
                          : 10 - progress1Data.printUp1
                      }
                      fieldDecoratorName={
                        (!isEmpty(nextStates) &&
                          nextStates.length < 2 &&
                          nextStates[0].nextStateId === '1073') ||
                        (!isEmpty(stateIdFlow) && stateIdFlow === '1073')
                          ? 'printDown1'
                          : 'printDown2'
                      }
                      modalDesc={'Input Jumlah Print Bawah to progress order'}
                      inputComponent={
                        <Input
                          placeholder="Jumlah Print Bawah *"
                          size="large"
                        />
                      }
                      maxNumberValid2={
                        values.stateId === '1092'
                          ? values.printDownRemaining
                          : values.stateId === '1072' &&
                            (values.flowId.includes('B') ||
                              values.flowId.includes('D'))
                          ? values.totalDown >= 3
                            ? 3
                            : values.totalDown
                          : 10
                      }
                      minNumberValid2={
                        values.stateId === '1092'
                          ? values.printDownRemaining
                          : values.stateId === '1072' &&
                            (values.flowId.includes('B') ||
                              values.flowId.includes('D'))
                          ? values.totalDown >= 3
                            ? 3
                            : values.totalDown
                          : 0
                      }
                    />
                  )}

                  {/* Set systemNote in progress without submit */}
                  {/* Redo appointment without submit & clinic note without submit */}
                  <ModalSingleWithoutSubmit
                    showModal={
                      stateIdFlow === '1021'
                        ? showModalRedoAppointmentStart
                        : showModalClinicNote
                    }
                    handleHideModal={
                      stateIdFlow === '1021'
                        ? handleHideModalRedoAppointmentStart
                        : handleHideModalClinicNote
                    }
                    detailValue={values}
                    modalName={
                      stateIdFlow === '1021'
                        ? 'redo-appointment-modal'
                        : 'clinic-note-modal'
                    }
                    showOtherModal={
                      stateIdFlow === '1021'
                        ? handleShowModalDIYDate
                        : handleShowModalProgressAppointment
                    }
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    idContainer={''}
                    modalTitle={
                      stateIdFlow === '1021' || stateIdFlow === '1011'
                        ? 'Redo Appointment'
                        : 'Clinic Note'
                    }
                    initValue={''}
                    fieldDecoratorName={'systemNoteRedoDialog'}
                    modalDesc={
                      stateIdFlow === '1021' || stateIdFlow === '1011'
                        ? 'Input Redo Appointment To Progress Order'
                        : 'Input Clinic Note To Progress Order'
                    }
                    inputComponent={
                      <TextArea
                        placeholder={
                          stateIdFlow === '1021' || stateIdFlow === '1011'
                            ? 'Input Redo Appointment Note'
                            : 'Input Clinic Note'
                        }
                        row={4}
                      />
                    }
                    setIsRedo={setIsRedo}
                  />

                  {/* Set appointment in progress */}
                  <ModalSingleWithoutSubmit
                    showModal={showModalAppointmentStart}
                    handleHideModal={handleHideModalProgressAppointment}
                    detailValue={values}
                    modalName={'appointment-modal'}
                    showOtherModal={handleShowModalClinic}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    idContainer={
                      isRescheduleAppointment ? 'form-detail-order' : ''
                    }
                    modalTitle={'Appointment'}
                    initValue={
                      isRescheduleAppointment
                        ? values &&
                          moment(values.appointmentTime, 'DD-MM-YYYY HH:mm:ss')
                        : moment()
                    }
                    fieldDecoratorName={'appointmentTime'}
                    modalDesc={
                      'Input Appointment Date & Time to reschedule appointment'
                    }
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="Appointment Date & Time*"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Set diy in progress */}
                  <ModalSingleWithoutSubmit
                    showModal={showModalDIYDate}
                    handleHideModal={handleHideModalDIYDate}
                    modalName={'diy-modal'}
                    showOtherModal={handleShowModalDeliveryAddress}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    modalTitle={'DIY Date'}
                    detailValue={values}
                    initValue={moment()}
                    fieldDecoratorName={'diyDeliveryTime'}
                    modalDesc={
                      'Input DIY Date & Time to create consultation and progress order'
                    }
                    inputComponent={
                      <DatePicker
                        size="large"
                        showTime
                        placeholder="DIY Date & Time*"
                        format="DD-MM-YYYY HH:mm:ss"
                      />
                    }
                  />

                  {/* Print Type */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalPrintType}
                    showModal={showModalPrintType}
                    stateIdFlow={stateIdFlow}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setButtonState={setButtonState}
                    form={form}
                    stateName={'printType'}
                    tableName={'Print Type'}
                    prodFlowList={nextStates}
                  />

                  {/* Confirm CU */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalConfirmCU}
                    showModal={showModalConfirmCU}
                    stateIdFlow={stateIdFlow}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setButtonState={setButtonState}
                    form={form}
                    stateName={'confirmCU'}
                    tableName={'Konfirmasi Cetak Ulang'}
                    prodFlowList={nextStates}
                  />

                  {/* List clinic */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalClinic}
                    showModal={showModalClinicList}
                    stateIdFlow={stateIdFlow}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setButtonState={setButtonState}
                    isRescheduleAppointmentWithClinic={
                      isRescheduleAppointmentWithClinic
                    }
                    form={form}
                    stateName={'clinicId'}
                    tableName={'Clinic'}
                    prodFlowList={nextStates}
                  />

                  {/* List Delivery Address */}
                  <ModalFullscreenWithoutSubmit
                    handleHideModal={handleHideModalDeliveryAddress}
                    showModal={showModalDeliveryAddress}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    handleShowOtherModal={handleShowModalCourier}
                    stateName={'shippingAddress'}
                    tableName={'Delivery Address'}
                  />

                  {/* Courier */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalCourier}
                    showModal={showModalCourier}
                    stateIdFlow={stateIdFlow}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    setButtonState={setButtonState}
                    form={form}
                    stateName={'shippingId'}
                    tableName={'Courier'}
                    prodFlowList={nextStates}
                  />

                  {/* Treat Attachment */}
                  <ModalFullscreenWithoutSubmit
                    handleHideModal={handleHideModalAttachment}
                    showModal={showModalAttachment}
                    handleShowOtherModal={handleShowModalSlicing}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    stateName={'attachment'}
                    tableName={'Treat Attachment'}
                  />

                  {/* Treat Slicing */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalSlicing}
                    showModal={showModalSlicing}
                    form={form}
                    setButtonState={setButtonState}
                    setProgress1Data={setProgress1Data}
                    stateName={'slicing'}
                    tableName={'Treat Slicing'}
                    stateIdFlow={stateIdFlow}
                    progress1Data={progress1Data}
                    prodFlowList={nextStates}
                  />

                  {/* OC Options */}
                  <ModalFullscreenWithSubmit
                    handleHideModal={handleHideModalOC}
                    showModal={showModalOC}
                    form={form}
                    setButtonState={setButtonState}
                    setProgress1Data={setProgress1Data}
                    stateName={'ocChecklist'}
                    tableName={'OC Options'}
                    stateIdFlow={stateIdFlow}
                    progress1Data={progress1Data}
                    prodFlowList={nextStates}
                  />

                  {/* Modal PrintType to Show Impression*/}
                  <ModalFullscreenWithoutSubmit
                    handleHideModal={handleHideModalPrintType}
                    showModal={showModalPrintType}
                    handleShowOtherModal={handleShowModalImpression}
                    setProgress1Data={setProgress1Data}
                    progress1Data={progress1Data}
                    stateName={'printType'}
                    tableName={'Jenis Cetakan'}
                    handleSubmitFlowAuto={handleSubmitFlowAuto}
                  />
                  {/* Modal Impression */}
                  {(progress1Data.printType === 1 ||
                    values.stateId === '1032') && (
                    <ModalImpression
                      handleHideModal={handleHideModalImpression}
                      showModal={showModalImpression}
                      setAnotherButtonState={setAnotherButtonState}
                      form={form}
                      progress1Data={progress1Data}
                      setButtonState={setButtonState}
                      setProgress1Data={setProgress1Data}
                      values={values}
                      loadingButton={loadingButton}
                    />
                  )}
                  {values.confirmClinic === true && (
                    <ModalConfirmCetakUlang
                      showModal={showModalConfirmCetakUlang}
                      form={form}
                      values={values}
                      handleHideModal={handleModalConfirmCetakUlang}
                      handleModalProgress={handleShowModalProductionFlow}
                    />
                  )}
                </>
              )}
            </Form>
          </Content>
        </Layout>
      ) : (
        <PageSpinner />
      )}
    </>
  );
}

const mapStateToProps = ({
  resetHistoryList,
  paymentDetailByIdData,
  eKanbanForm,
}) => ({
  resetHistoryList,
  paymentDetailByIdData,
  eKanbanForm,
});

export const OrderDetailForm = Form.create({
  name: 'validate_create_order-detail',
  onValuesChange(props, values, allFieldsValues) {
    if (allFieldsValues.stateId === '1041o') {
      if (values.totalUp || values.totalDown) {
        if (allFieldsValues.totalDown && allFieldsValues.totalUp) {
          const totalValidation =
            Number(allFieldsValues.totalDown) + Number(allFieldsValues.totalUp);

          if (totalValidation <= 15) {
            message.error(
              'Total Atas + Total Bawah cannot less then or same as 15!'
            );
          }

          if (totalValidation > 40) {
            message.warning('Total Atas + Total Bawah is more than 40!');
          }
        }
      }
    }
  },
})(OrderDetailCreateEdit);

export default withRouter(
  connect(mapStateToProps, {
    getResetHistoryList,
    getListClinic,
    getPaymentDetailById,
  })(OrderDetailForm)
);
