import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import BoxApi from 'api/box';
import { setToArray } from 'utils';
import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';

import { tableBoxStatusColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function BoxStatusList() {
  const [boxStatus, setBoxStatus] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });

  const [rowSelected, setRowSelected] = useState([]);
  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  async function getBoxStatusList() {
    try {
      const response = await BoxApi.listBoxStatus();
      let boxStatusList = setToArray(response.data);

      setBoxStatus(boxStatusList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getBoxStatusList();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getBoxStatusId = rowSelected.map((value) => value.boxStatusId);
    const getBoxStatusIdToString = getBoxStatusId.join(',');

    confirm({
      title: `Are you sure delete this box status data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then box status data with id ${getBoxStatusIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingBoxStatus() {
          try {
            await BoxApi.deleteMultipleBoxStatus(getBoxStatusIdToString);
            setRowSelected([]);
            getBoxStatusList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingBoxStatus();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return <></>;
  };

  const filterComponent = () => {
    return <></>;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/master/box-status/edit?id=${record.boxStatusId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Box Status'}
          inputPlaceholder={'Search Box Status Here...'}
          pageSize={8}
          originalData={boxStatus}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableBoxStatusColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          recordKey={(record) => record.boxStatusId}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getBoxStatusList={getBoxStatusList}
      />
    </Layout>
  );
}

export default { BoxStatusList };
