import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { message, Button } from 'antd';
// import { isEmpty } from 'lodash';

import TableGlobal from 'components/Table';
import { getRefinementHistoryList } from 'store/action/OrderAction/';
import ModalRefinementDetail from './component/ModalRefinementHistoryDetail';

export const RefinementHistory = ({
  getRefinementHistoryList,
  refinementHistoryList,
  orderDetailData,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [
    showRefinementHistoryDetail,
    setShowRefinementHistoryDetail,
  ] = useState(false);
  const [refinementData, setRefinementData] = useState({});

  const handleModalRefinement = (param, record) => (e) => {
    if (param === 'show') {
      setShowRefinementHistoryDetail(true);
      setRefinementData(record);
    } else {
      setShowRefinementHistoryDetail(false);
    }
  };

  const refinementColums = [
    {
      title: 'ID',
      key: 'refinementHistoryId',
      dataIndex: 'refinementHistoryId',
      sorter: (a, b) =>
        a.refinementHistoryId.localeCompare(b.refinementHistoryId),
    },
    {
      title: 'Date',
      key: 'dueDate',
      dataIndex: 'dueDate',
      sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
    },
    {
      title: 'State',
      key: 'state',
      dataIndex: 'state',
      sorter: (a, b) => a.state - b.state,
    },
    {
      title: 'Type',
      key: 'refinementType',
      dataIndex: 'refinementType',
      sorter: (a, b) => a.refinementType.localeCompare(b.refinementType),
    },

    {
      title: 'RA',
      key: 'ra',
      dataIndex: 'ra',
      sorter: (a, b) => a.ra - b.ra,
    },
    {
      title: 'RB',
      key: 'rb',
      dataIndex: 'rb',
      sorter: (a, b) => a.rb - b.rb,
    },
    {
      title: 'Action',
      key: 'totalDown',
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Button
          type="primary"
          icon="bars"
          size={'large'}
          onClick={handleModalRefinement('show', record)}
        />
      ),
    },
  ];

  const handleOnRowPathname = () => {
    // return `${pathname}${search}`;
  };

  useEffect(() => {
    async function getRefinementHistoryById() {
      const convertSoNumber = orderDetailData.orderReceiptNumber.split('/')[0];
      try {
        await getRefinementHistoryList(convertSoNumber, 0);
      } catch (err) {
        if (err.response) {
          const errMessage = err.response.data.message;
          message.error(errMessage);
        } else {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        }
      } finally {
        // setLoadingPage(false);
      }
    }

    getRefinementHistoryById();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TableGlobal
        tableName={'Refinement History'}
        classCustom="order-detail__table panel--table-history mb-35"
        inputPlaceholder={'Search Refinement History Here...'}
        pageSize={8}
        originalData={refinementHistoryList || []}
        activeFilter={[{}]}
        setActiveFilter={{}}
        tableColumns={refinementColums}
        exportCsvIsExist={false}
        editableTable={false}
        activeFilterComponent={<></>}
        isFilter={false}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        innerRef={innerRef}
        onRowPathname={handleOnRowPathname}
      />
      <ModalRefinementDetail
        handleModalRefinement={handleModalRefinement()}
        showModal={showRefinementHistoryDetail}
        refinementHistoryData={refinementData}
      />
    </>
  );
};

const mapStateToProps = ({ refinementHistoryList }) => ({
  refinementHistoryList,
});

export default withRouter(
  connect(mapStateToProps, {
    getRefinementHistoryList,
  })(RefinementHistory)
);
