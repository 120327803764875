import api from './index';

function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + data[d]);
  return ret.join("&");
}

export default {
  getOrderListApi(payload) {
    let param = {
      ...(payload.filterBy.orderType.length > 0 && {
        listOrderType: payload.filterBy.orderType,
      }),
      ...(payload.filterBy.isOverdue.length > 0 && {
        isOverdue: payload.filterBy.isOverdue,
      }),
      ...(payload.filterBy.isTestFit.length > 0 && {
        isTestFit: payload.filterBy.isTestFit,
      }),
      offset: payload.offset,
      limit: payload.limit,
      ...(payload.searchBy && {searchBy: payload.searchBy}),
      ...((payload.sortBy && payload.sort) && {sortBy: payload.sortBy}),
      ...(payload.sort === 'ascend' && {sort: 'ASC'}),
      ...(payload.sort === 'descend' && {sort: 'DESC'})
    }
    const querystring = encodeQueryData(param);
    // return api.get(`/order/bucket?isRataPlus=yes`)
    return api.get(`/order/bucket?isRataPlus=yes&${querystring}`)
  }
}
