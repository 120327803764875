/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';

import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';
import { getBeErrorLogList } from 'store/action/be-error-action/GetBeErrorListAction';

import { headerCsv, tableColumns } from './helper';
import ActiveFilter from './components/ActiveFilter';
import Filter from './components/Filter';

const { Content } = Layout;

export function BeErrorLog({ getBeErrorLogList, listBeErrorLog }) {
  const [loadingPage, setLoadingPage] = useState(true);
  const [errorLogList, setErrorLogList] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState({
    orderType: [], //Order type
  });

  async function getBeErrorLogListData() {
    await getBeErrorLogList();
  }

  useEffect(() => {
    getBeErrorLogListData();
  }, []);

  useEffect(() => {
    async function getErrorLogList() {
      try {
        setErrorLogList(listBeErrorLog);
      } finally {
        setLoadingPage(false);
      }
    }

    getErrorLogList();
  });

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  if (loadingPage) {
    return <PageSpinner />;
  }

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
      />
    );
  };

  const handleOnRowPathname = (record) => {
    return `/cms/be-error-log/${record.logId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'BE Error Log'}
          inputPlaceholder={'Search BE Error Log Here ...'}
          pageSize={8}
          headerCsv={headerCsv}
          originalData={errorLogList}
          csvName={'be-error-log-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          recordKey={(record) => record.logId}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
        />
      </Content>
    </Layout>
  );
}

const mapStateToProps = ({ listBeErrorLog }) => ({
  listBeErrorLog,
});

export default connect(mapStateToProps, { getBeErrorLogList })(BeErrorLog);
