import React from 'react';
import { Row, Col, Button } from 'antd';
// import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';

export const Filter = ({
  resetFilter,
  innerRef,
  activeFilter,
  originalData,
  onBoxNumberChange,
}) => {
  const boxNumberOpts = originalData.map((value) => ({
    label: value.boxNumber,
    value: value.boxNumber,
  }));

  const handleDuplicateBoxNumberOpts = boxNumberOpts.filter(
    (value, index, array) =>
      array.findIndex((t) => t.label === value.label) === index
  );

  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Box Number'}
            onChange={onBoxNumberChange}
            options={handleDuplicateBoxNumberOpts}
            selectValue={activeFilter.boxNumber}
          />
        </Col>
      </Row>
    </div>
  );
};

Filter.propTypes = {
  // onOrderTypeChange: PropTypes.func,
};

export default Filter;
