import EkanbanApi from 'api/e-kanban';
import { setToArray } from 'utils';
import { isEmpty } from 'lodash';

import {
  GET_LIST_STATION_STATE,
  GET_LIST_STATION_HISTORY,
  GET_EKANBAN_PRINT_LABEL_DATA,
  GET_EKANBAN_DATA,
  SET_EKANBAN_FORM,
} from '../../type';

export const getListStationState =
  (orderType, startDate, endDate) => async (dispatch) => {
    const { data } = await EkanbanApi.getStationState(
      orderType,
      startDate,
      endDate
    );

    dispatch({
      type: GET_LIST_STATION_STATE,
      payload: data.data,
    });
  };

export const getListStationHistory = (orderId) => async (dispatch) => {
  const { data } = await EkanbanApi.getStationHistory(orderId);

  dispatch({
    type: GET_LIST_STATION_HISTORY,
    payload: data.data,
  });
};

export const getEkanbanPrintLabel = (orderId) => async (dispatch) => {
  const { data } = await EkanbanApi.getEkanbanPrintLabel(orderId);

  dispatch({
    type: GET_EKANBAN_PRINT_LABEL_DATA,
    payload: data.data,
  });
};

export const setEkanbanForm = (data) => async (dispatch) => {
  dispatch({
    type: SET_EKANBAN_FORM,
    payload: data,
  });
};

export const getEkanbanData = (kanbanId) => async (dispatch) => {
  const { data } = await EkanbanApi.getEkanbanData(kanbanId);
  const eKanbanData = setToArray(data);

  dispatch({
    type: GET_EKANBAN_DATA,
    payload: eKanbanData,
  });

  dispatch({
    type: SET_EKANBAN_FORM,
    payload: !isEmpty(eKanbanData) ? eKanbanData[0] : {},
  });
};
