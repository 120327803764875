export const simulationDesignColumns = [
  {
    title: 'Upload Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
  {
    title: 'Revision',
    key: 'revision',
    dataIndex: 'revision',
    render: val => val ? "Yes" : "No"
  },
  {
    title: 'Designer Name',
    key: 'designerName',
    dataIndex: 'designerName',
    sorter: (a, b) =>
      a.designerName === null || b.designerName === null
        ? (a.designerName === null) - (b.designerName === null)
        : a.designerName.localeCompare(b.designerName),
  },
  {
    title: 'Patient Name',
    key: 'patientName',
    dataIndex: 'patientName',
    sorter: (a, b) =>
      a.patientName === null || b.patientName === null
        ? (a.patientName === null) - (b.patientName === null)
        : a.patientName.localeCompare(b.patientName),
  },
  {
    title: 'SO Number',
    dataIndex: 'orderReceiptNumber',
    key: 'orderReceiptNumber',
    sorter: (a, b) =>
      a.orderReceiptNumber === null || b.orderReceiptNumber === null
        ? (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null)
        : a.orderReceiptNumber.localeCompare(b.orderReceiptNumber),
  },
  {
    title: 'Order Type',
    dataIndex: 'orderDesc',
    key: 'orderDesc',
    sorter: (a, b) =>
      a.orderDesc === null || b.orderDesc === null
        ? (a.orderDesc === null) - (b.orderDesc === null)
        : a.orderDesc.localeCompare(b.orderDesc),
  },
  {
    title: 'Video Version',
    key: 'videoVersionName',
    dataIndex: 'videoVersionName',
    sorter: (a, b) =>
      a.videoVersionName === null || b.videoVersionName === null
        ? (a.videoVersionName === null) - (b.videoVersionName === null)
        : a.videoVersionName.localeCompare(b.videoVersionName),
  },
  {
    title: 'Total Video',
    key: 'videoCount',
    dataIndex: 'videoCount',
    sorter: (a, b) => (a.videoCount === null) - (b.videoCount === null),
  },
  {
    title: 'RA',
    dataIndex: 'ra',
    key: 'ra',
    sorter: (a, b) =>
      a.ra === null || b.ra === null
        ? (a.ra === null) - (b.ra === null)
        : a.ra - b.ra,
  },
  {
    title: 'RB',
    dataIndex: 'rb',
    key: 'rb',
    sorter: (a, b) =>
      a.rb === null || b.rb === null
        ? (a.rb === null) - (b.rb === null)
        : a.rb - b.rb,
  },
  {
    title: 'Assigned Doctor',
    key: 'doctorName',
    dataIndex: 'doctorName',
    sorter: (a, b) =>
      a.doctorName === null || b.doctorName === null
        ? (a.doctorName === null) - (b.doctorName === null)
        : a.doctorName.localeCompare(b.doctorName),
  },
];

export const simulationDesignLastUpdate = [
  ...simulationDesignColumns,
  {
    title: 'Tanggal Approval/Rejected',
    sorter: (a, b) => (a.approvedAt === null) - (b.approvedAt === null),
    render: (row) =>
      `${
        row['approvedAt']
          ? row['approvedAt']
          : row['rejectedAt']
          ? row['rejectedAt']
          : ''
      }`,
  },
];

export const headerSimulationExportCsv = [
  { label: 'Upload Date', key: 'createdAt' },
  { label: 'Patient Name', key: 'patientName' },
  { label: 'SO Number', key: 'orderReceiptNumber' },
  { label: 'Designer Name', key: 'designerName'},
  { label: 'Status', key: 'simulationStatusName' },
  { label: 'Order Type', key: 'orderDesc' },
  { label: 'Video Version', key: 'videoVersionName' },
  { label: 'Number of Set', key: 'totalAligner' },
  { label: 'Assigned Doctor', key: 'doctorName' },
];

export const attemptOptions = [
  { label: '1st', value: '1st' },
  { label: '2nd', value: '2nd' },
  { label: '3rd', value: '3rd' },
  { label: '4th', value: '4th' },
  { label: '5th', value: '5th' },
];

export const revisionVideoHistoryColumn = [
  {
    title: 'Date',
    key: 'uploadedAt',
    dataIndex: 'uploadedAt',
    sorter: (a, b) =>
      a.uploadedAt === null || b.uploadedAt === null
        ? (a.uploadedAt === null) - (b.uploadedAt === null)
        : a.uploadedAt.localeCompare(b.uploadedAt),
  },
  {
    title: 'Simulation Design ID',
    key: 'simulationId',
    dataIndex: 'simulationId',
    sorter: (a, b) =>
      a.simulationId === null || b.simulationId === null
        ? (a.simulationId === null) - (b.simulationId === null)
        : a.simulationId.localeCompare(b.simulationId),
  },
  {
    title: 'Video Recording',
    key: 'videoName',
    dataIndex: 'videoName',
    sorter: (a, b) =>
      a.videoName === null || b.videoName === null
        ? (a.videoName === null) - (b.videoName === null)
        : a.videoName.localeCompare(b.videoName),
  },
  {
    title: 'Video Version',
    key: 'videoVersionId',
    dataIndex: 'videoVersionId',
    sorter: (a, b) =>
      a.videoVersionId === null || b.videoVersionId === null
        ? (a.videoVersionId === null) - (b.videoVersionId === null)
        : a.videoVersionId.localeCompare(b.videoVersionId),
  },
  {
    title: 'Slicing Chart',
    key: 'slicingName',
    dataIndex: 'slicingName',
    sorter: (a, b) =>
      a.slicingName === null || b.slicingName === null
        ? (a.slicingName === null) - (b.slicingName === null)
        : a.slicingName.localeCompare(b.slicingName),
  },
  {
    title: 'Revision Note from Doctor',
    key: 'doctorApprovalNote',
    dataIndex: 'doctorApprovalNote',
    sorter: (a, b) =>
      a.doctorApprovalNote === null || b.doctorApprovalNote === null
        ? (a.doctorApprovalNote === null) - (b.doctorApprovalNote === null)
        : a.doctorApprovalNote.localeCompare(b.doctorApprovalNote),
  },
  {
    title: 'Revision Reason from Patient',
    key: 'patientApprovalNote',
    dataIndex: 'patientApprovalNote',
    sorter: (a, b) =>
      a.patientApprovalNote === null || b.patientApprovalNote === null
        ? (a.patientApprovalNote === null) - (b.patientApprovalNote === null)
        : a.patientApprovalNote.localeCompare(b.patientApprovalNote),
  },
];
