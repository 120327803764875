import { Form, Button, Icon, Row } from 'antd';
import React, { useContext } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import '../../../styles/index.scss';
import VideoRecordingItem from './components/VideoRecordingItem';
import { AuthenticationContext } from 'contexts/Authentication';

function VideoRecordingContainer({
  form,
  simulationDesignByIdData,
  setIsErrorBeforeUpload,
  setIsErrorUpload,
  isErrorUpload,
  isErrorBeforeUpload,
  listVideo,
  getSimulationDesignByIdData,
  buttonState,
  setIsEditEnabled,
  isEditEnabled,
}) {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const {
    permissionDataList: { opEditSetVideo },
  } = useContext(AuthenticationContext);

  getFieldDecorator('videokeys', {
    initialValue: simulationDesignByIdData.listVideo
      ? Array.from(
          { length: simulationDesignByIdData.listVideo.length },
          (v, i) => i
        )
      : [0],
  });

  const videokeys = getFieldValue('videokeys');
  const username = localStorage.getItem('username');

  const removeVideoRecording = (indexVideoRecording) => {
    setFieldsValue({
      videokeys: videokeys.filter(
        (video, index) => index !== indexVideoRecording
      ),
    });
  };

  const addVideoRecording = () => {
    const videokeys = getFieldValue('videokeys');
    setFieldsValue({ videokeys: [...videokeys, videokeys.length] });
  };
  return (
    <div className="simulation-design__video-recording position-relative mb-35">
      <Row type="flex" justify='space-between'>
        <div className="color-text-primary text-md fw-bold mb-15">
          Video Recording
        </div>
        {listVideo && opEditSetVideo && (
          <Button
            type="link"
            {... !isEditEnabled && { icon: 'edit'}}
            {... isEditEnabled && { style: { color: 'black'}}}
            onClick={() => setIsEditEnabled(!isEditEnabled)}
            >
            {isEditEnabled? 'Cancel' : 'Edit'}
          </Button>
        )}
      </Row>
      {videokeys.map((videoRecValue, index) => (
        <VideoRecordingItem
          simulationDesignByIdData={simulationDesignByIdData}
          username={username}
          getSimulationDesignByIdData={getSimulationDesignByIdData}
          index={index}
          listVideo={listVideo}
          setIsErrorBeforeUpload={setIsErrorBeforeUpload}
          isErrorBeforeUpload={isErrorBeforeUpload}
          setIsErrorUpload={setIsErrorUpload}
          isErrorUpload={isErrorUpload}
          form={form}
          removeVideoRecording={removeVideoRecording}
          buttonState={buttonState}
          opEditSetVideo={opEditSetVideo}
          isEditEnabled={isEditEnabled}
          setIsEditEnabled={setIsEditEnabled}
        />
      ))}

      {(simulationDesignByIdData.simulationStatusId === 'RDO' ||
        simulationDesignByIdData.simulationStatusId === 'SPD') && (
        <Button
          type="link"
          className="mt-5"
          onClick={addVideoRecording}
          // disabled={arrayVideo.length < 1 ? true : false}
        >
          <Icon type="plus" /> Add Video Recording
        </Button>
      )}
    </div>
  );
}

export default withRouter(connect()(Form.create()(VideoRecordingContainer)));
