import React from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';

import BoxApi from 'api/box';
import { setCreateEditMessage } from 'utils';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getBoxStatusList,
  setShowModalAddData,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          theResponse = await BoxApi.createBoxStatus(
            values.boxStatusId,
            values.boxStatusDesc
          );

          history.push({
            pathname: '/cms/master/box-status',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Box Status Data',
            'Error Inserting Box Status Data!'
          );

          getBoxStatusList();
          setShowModalAddData(false);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  return (
    <Modal
      title="Add New Box Status Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Box Status Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator(
            'boxStatusId',
            {}
          )(<Input size="large" placeholder="Box Status ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator(
            'boxStatusDesc',
            {}
          )(<Input size="large" placeholder="Box Status Name*" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create()(withRouter(ModalAddData));
