import React from 'react';
import { Modal, Row, Col } from 'antd';
import { isEmpty } from 'lodash';

export const ModalRefinementDetail = ({
  handleModalRefinement,
  showModal,
  refinementHistoryData,
}) => {
  return (
    <Modal
      centered
      visible={showModal}
      getContainer={document.body}
      onCancel={() => handleModalRefinement('hide')}
      footer={[]}>
      {!isEmpty(refinementHistoryData) && (
        <>
          {' '}
          <div className="color-text-primary text-lg fw-bold mb-30">
            Refinement History Detail
          </div>
          <Row>
            <Col span={12} className="mb-10">
              ID
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.refinementHistoryId || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Date
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.dueDate || '-'}
            </Col>
            <Col span={12} className="mb-15">
              State
            </Col>
            <Col span={12} className="mb-15">
              {refinementHistoryData.refinementStatus || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Type
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.refinementType || '-'}
            </Col>
            <Col span={12} className="mb-10">
              RA
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.ra || '-'}
            </Col>
            <Col span={12} className="mb-10">
              RB
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.rb || '-'}
            </Col>
            <Col span={12} className="mb-10">
              AG Atas
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.agUp || '-'}
            </Col>
            <Col span={12} className="mb-10">
              AG Bawah
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.agDown || '-'}
            </Col>
            <Col span={12} className="mb-10">
              OC Atas
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.ocUp || '-'}
            </Col>
            <Col span={12} className="mb-10">
              OC Bawah
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.ocDown || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Slicing
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.slicing || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Attachment
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.attachment || '-'}
            </Col>
            <Col span={12} className="mb-10">
              Test Fit
            </Col>
            <Col span={12} className="mb-10">
              {refinementHistoryData.testFit || '-'}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

// ModalFullScreen.defaultProps = {
//   idContainer: '',
// };

export default ModalRefinementDetail;
