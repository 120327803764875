import orderV2 from "api/orderV2"
import { ORDER_CONSTANTS } from "store/type/v2/OrderConstants"

export const getList = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: ORDER_CONSTANTS.LIST_REQUEST,
      payload: {
        isLoading: true,
        orderList: {
          allData: 0,
          page: 1,
          data: [],
          totalData: 0,
          totalPage: 0,
          limit: 10,
          filters: {},
          searchBy: '',
          offset: 0,
          sortBy: "created_at",
          sort: "descend"
        }
      }
    })

    const {data} = await orderV2.getOrderListApi(payload);

    if (data.status === 'SUCCESS') {
      return dispatch({
        type: ORDER_CONSTANTS.LIST_SUCCESS,
        payload: {
          isLoading: false,
          isError: false,
          orderList: {
            allData: data.data.allData,
            data: data.data.list,
            limit: payload.limit,
            offset: 0,
            sortBy: "created_at",
            sort: "descend",
            page: data.data.page,
            totalData: data.data.totalData,
            totalPage: data.data.totalPage,
          },
        }
      })
    }

    if (data.status === 'FAILED') {
      dispatch({
        type: ORDER_CONSTANTS.LIST_FAILED,
        payload: {
          isLoading: false,
          isError: true,
          // orderList: ,
        }
      })
    }

  }
}