import React from 'react';
import { Modal, Input, Form, Button } from 'antd';

export const ModalRefinement = ({
  showModal,
  handleHideModal,
  setButtonState,
  form,
  loadingButton
}) => {
  const { TextArea } = Input;
  const { getFieldDecorator } = form;

  const handleSubmitModal = () => {
    setButtonState(4);
  };

  return (
    <Modal
      title="Create Refinement Order"
      centered
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmitModal()}
      onCancel={() => handleHideModal()}
      footer={[
        <Button key="back" onClick={handleHideModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loadingButton}
          onClick={handleSubmitModal}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">
        Input Refinement Note to create refinement order
      </div>
      <Form>
        <Form.Item>
          {getFieldDecorator('systemNoteRefinementDialog', {
            initialValue: '',
          })(<TextArea size="large" placeholder="Refinement Note*" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalRefinement;
