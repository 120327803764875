/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Input, Upload, message, Button } from 'antd';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';

import '../styles/index.scss';
export const InputWithUpload = ({
  placeholder,
  customClass,
  isDownload,
  purpose,
  setUploadedIdFile,
  uploadedFileName,
  uploadedFileUrl,
  setIsErrorUpload,
  isErrorUpload,
//   withMaxSizeFile,
  setIsErrorBeforeUpload,
  isErrorBeforeUpload,
  uploadedFileId,
  actionAPI,
  withoutInitValue,
  disabled,
  acceptExtention,
  indexform,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isRemove, setIsRemove] = useState('');
  const [fileList, setFileList] = useState([]);


  useEffect(
    () => {
      if (withoutInitValue) {
        setFileList([]);
      } else {
        let fileListDataInit;
        if (purpose) {
          fileListDataInit = uploadedFileName[purpose]
            ? [
                {
                  uid: uploadedFileId[purpose] || `${purpose}-0`,
                  name: uploadedFileName[purpose],
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        } else {
          fileListDataInit = uploadedFileName
            ? [
                {
                  uid: uploadedFileId,
                  name: uploadedFileName,
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        }

        setFileList(fileListDataInit);
      }
    },
    withoutInitValue ? [] : [uploadedFileName[purpose]]
  );

  const props = {
    name: 'file',
    action: actionAPI,

    onDownload: (file) => {
      if (!isEmpty(file.response)) {
        saveAs(file.response.data[0].fileUrl, file.response.data[0].fileName);
      } else {
        saveAs(uploadedFileUrl, uploadedFileName[purpose]);
      }
    },

    openFileDialogOnClick: isErrorUpload[purpose] ? false : true,

    onRemove: (file) => {
      setIsRemove(file);
    },

    showUploadList: {
      showDownloadIcon: isDownload,
    },

    beforeUpload: (file) => {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        setIsErrorUpload({ ...isErrorUpload, [purpose]: true });
        setIsErrorBeforeUpload({ ...isErrorBeforeUpload, [purpose]: true });
        setFileList([file]);
        message.error('File must smaller than 10MB!');
      }
      return isLt50M;
    },

    accept: acceptExtention,

    ...(withoutInitValue ? '' : { fileList: fileList }),

    onChange: ({ fileList, file }) => {
      if (file.status === 'uploading') {
        setIsUploading(true);
        setIsErrorUpload({ ...isErrorUpload, [purpose]: false });
        setIsRemove('');
        setFileList(fileList);
      }

      if (file.status === 'removed') {
        setIsUploading(false);
        setIsErrorUpload({ ...isErrorUpload, [purpose]: false });
        setIsErrorBeforeUpload({ ...isErrorBeforeUpload, [purpose]: false });
        if (purpose === 'threeDScan') {
          const threeDScanList = uploadedFileId.threeDScan;
          for (let i = 0; i < threeDScanList.length; i++) {
            if (threeDScanList[i] === file.response.data[0].fileId) {
              threeDScanList.splice(i, 1);
            }
          }
          setUploadedIdFile({ ...uploadedFileId, [purpose]: threeDScanList });
        } else if (purpose === 'video' || purpose === 'slicing' || purpose === 'imageBite' || purpose === 'archform' || purpose === 'videoBite') {
          const videoList = uploadedFileId.video;
          for (let i = 0; i < videoList.length; i++) {
            if (file.response.data) {
              if (videoList[i] === file.response.data[0].fileId) {
                videoList[i] = 'NON';
              }
            }
          }
          setUploadedIdFile({ ...uploadedFileId, [purpose]: videoList });
        } else {
          setUploadedIdFile({ ...uploadedFileId, [purpose]: null });
        }
        setFileList([]);
      }

      if (file.status === 'done' && file.response.status === 'SUCCESS') {
        message.success(
          `Success uploading ${file.name} file in ${purpose} field`
        );
        if (!isEmpty(file.response.data) && file.response.data[0].fileId) {
          if (purpose === 'threeDScan') {
            const threeDScanList = uploadedFileId.threeDScan;
            threeDScanList.push(file.response.data[0].fileId);
            setUploadedIdFile({
              ...uploadedFileId,
              [purpose]: threeDScanList,
            });
          } else if (purpose === 'video' || purpose === 'slicing' || purpose === 'imageBite' || purpose === 'archform' || purpose === 'videoBite') {
            const videoList = uploadedFileId[purpose];
            videoList[indexform] = file.response.data[0].fileId
            setUploadedIdFile({
              ...uploadedFileId,
              [purpose]: videoList,
            });
          } else if (purpose === 'videoV2' || purpose === 'slicingV2' || purpose === 'imageBiteV2' || purpose === 'archformV2' || purpose === 'videoBiteV2') {
            setUploadedIdFile(file.response.data[0].fileId)
          } else {
            setUploadedIdFile({
              ...uploadedFileId,
              [purpose]: file.response.data[0].fileId,
            });
          }
        }
        setIsUploading(false);
        setIsErrorUpload({ ...isErrorUpload, [purpose]: false });
        setFileList(fileList);
      } else if (
        file.status === 'error' ||
        (file.response &&
          file.response.status === 'FAILED' &&
          file.status !== 'removed')
      ) {
        message.error(`Error uploading ${file.name} file in ${purpose} field`);

        setIsErrorUpload({
          ...isErrorUpload,
          [purpose]: true,
        });
        setIsUploading(false);
        setFileList(fileList);

        if (file.response && file.response.status === 'FAILED') {
          const errMessage = file.response.desc;
          message.error(errMessage);
        } else {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        }
      }
    },
  };



  return (
    <Upload
      {...props}
      className={`input-with-upload ${customClass}   ${
        customClass === 'input-with-upload--edit-detail' && isUploading
          ? 'input-with-upload--edit-detail-uploading'
          : ''
      }`}>
      <div className="d-flex input-with-upload__item align-items-center position-relative">
        <Input
          className="input-with-upload__input"
          placeholder={placeholder}
          size="large"
          disabled={disabled}
        />

        {isErrorUpload[purpose] &&
          isRemove === '' &&
          !isErrorBeforeUpload[purpose] && (
            <div className="d-flex align-items-center input-with-upload__wrap-text-failed">
              <div className="input-with-upload__text-failed">Failed. </div>

            </div>
          )}

        {isErrorBeforeUpload[purpose] && (
          <div className="d-flex align-items-center input-with-upload__wrap-text-failed">
            <div className="input-with-upload__text-failed">Failed. </div>
          </div>
        )}

        {isUploading && (
          <div className="input-with-upload__loading-text">Uploading File</div>
        )}

        <Button
          style={{ right: '20px' }}
          className="input-with-upload__btn"
          icon="paper-clip"
        />
      </div>
    </Upload>
  );
};

InputWithUpload.defaultProps = {
  isDownload: false,
  customClass: '',
  withoutInitValue: false,
  disabled: false,
  acceptExtention: 'video/*,application/pdf,image/*,.zip',
  withMaxSizeFile: true,
};

export default React.memo(InputWithUpload);
