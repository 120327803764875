/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip, Checkbox, Spin } from 'antd';
import { isEmpty } from 'lodash';

import ClinicNoteApi from 'api/clinic-note';
import StateApi from 'api/state';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import './style.scss';

export function ClinicStatusCreateEdit({ form, history }) {
  const [clinicStatusById, setClinicStatusById] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [states, setStates] = useState([]);
  const [statesValues, setStateValues] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  useEffect(() => {
    async function getClinicStatusById() {
      const response = await ClinicNoteApi.getClinicStatusById(id);
      let clinicStatusData = setToArray(response.data);

      setClinicStatusById(clinicStatusData[0]);
      setDefaultValue(clinicStatusData[0].stateId.split(','));
    }

    getClinicStatusById();
  }, []);

  useEffect(() => {
    async function getStateList() {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    }

    getStateList();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await ClinicNoteApi.updateClinicNoteStatus(
            clinicStatusById.noteStatusId,
            values.clinicStatusName,
            isEmpty(statesValues) ? defaultValue.join() : statesValues.join()
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/clinic-status',
            });
          }
          setCreateEditMessage(
            theResponse.data,
            'Success Updating Clinic Status Data with ID ' + id,
            'Error Updating Clinic Status Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  const onStateChange = (checkedValues) => {
    setStateValues(checkedValues);
  };

  const stateOptions = states.map((value) => ({
    label: `${value.stateId} - ${value.stateName}`,
    value: value.stateId,
  }));

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [show]);

  return (
    <DetailLayout
      detailTime={`${id}`}
      detailTitle={'Edit Clinic Status Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="ID">
            {getFieldDecorator('clinicStatusId', {
              rules: [
                {
                  required: true,
                  message: 'Please input clinic status ID!',
                },
              ],
              initialValue: clinicStatusById && clinicStatusById.noteStatusId,
            })(
              <Input
                placeholder="Clinic Status ID"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('clinicStatusName', {
              rules: [{ required: true, message: 'Please input status name!' }],
              initialValue: clinicStatusById && clinicStatusById.noteStatusDesc,
            })(
              <Input
                placeholder="Status Name"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>
          {!show ? (
            <div className="loadingSpin">
              <Spin />
            </div>
          ) : (
            <>
              <h4>Status</h4>
              <Checkbox.Group
                style={{ display: 'grid' }}
                options={stateOptions.slice(1)}
                onChange={onStateChange}
                defaultValue={defaultValue}
              />
            </>
          )}
        </Form>
      }
    />
  );
}

export const ClinicStatusForm = Form.create({
  name: 'validate_create_clinic_status',
})(ClinicStatusCreateEdit);

export default { ClinicStatusCreateEdit };
