import React, { useState, useRef } from 'react';

import TableGlobal from 'components/Table';
import { withRouter } from 'react-router';

import { resetHistoryColums } from '../../../../helper';

export const ResetHistory = ({ resetHistory, location }) => {
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const { pathname, search } = location;

  const handleOnRowPathname = () => {
    return `${pathname}${search}`;
  };

  return (
    <TableGlobal
      tableName={'Reset History'}
      classCustom="order-detail__table panel--table-history"
      inputPlaceholder={'Search Reset History Here...'}
      pageSize={8}
      originalData={resetHistory || []}
      activeFilter={[{}]}
      setActiveFilter={{}}
      tableColumns={resetHistoryColums}
      exportCsvIsExist={false}
      editableTable={false}
      activeFilterComponent={<></>}
      isFilter={false}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      innerRef={innerRef}
      onRowPathname={handleOnRowPathname}
    />
  );
};

export default withRouter(ResetHistory);
