import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { message } from 'antd';
import { isEmpty } from 'lodash';

import TableGlobal from 'components/Table';
import { getListVideoRevisionHistory } from 'store/action/SimulationDesignAction';

import { revisionVideoHistoryColumn } from '../../../helper';

export const RevisionVideoHistory = ({
  listVideoRevisionHistoryData,
  getListVideoRevisionHistory,
  simulationDesignByIdData,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const simulationId = urlParams.get('id');

  const handleOnRowPathname = () => {
    // return `${pathname}${search}`;
  };

  async function getListVideoRevisionHistoryData() {
    try {
      if (!isEmpty(simulationDesignByIdData)) {
        const convertSoNumber = simulationDesignByIdData[0].orderReceiptNumber.split(
          '/'
        )[0];
        await getListVideoRevisionHistory(convertSoNumber, simulationId);
      }
    } catch (err) {
      if (err.response) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      // setLoadingPage(false);
    }
  }

  useEffect(() => {
    getListVideoRevisionHistoryData();
    // eslint-disable-next-line
  }, []);

  return (
    <TableGlobal
      tableName={'Revision Video History'}
      classCustom="order-detail__table panel--table-history mb-35"
      inputPlaceholder={'Search Revision Video History Here...'}
      pageSize={8}
      originalData={listVideoRevisionHistoryData || []}
      activeFilter={[{}]}
      setActiveFilter={{}}
      tableColumns={revisionVideoHistoryColumn}
      exportCsvIsExist={false}
      editableTable={false}
      activeFilterComponent={<></>}
      isFilter={false}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      innerRef={innerRef}
      onRowPathname={handleOnRowPathname}
    />
  );
};

const mapStateToProps = ({ listVideoRevisionHistoryData }) => ({
  listVideoRevisionHistoryData,
});

export default withRouter(
  connect(mapStateToProps, {
    getListVideoRevisionHistory,
  })(RevisionVideoHistory)
);
