import { GET_LIST_SUMMARY_DASHBOARD_ORDER, GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS } from '../../../type';

export const listSummaryDashboardOrder = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_SUMMARY_DASHBOARD_ORDER:
      return action.payload;
    default:
      return state;
  }
};

export const listSummaryDashboardOrderRataPlus = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_SUMMARY_DASHBOARD_ORDER_RPLUS:
        return action.payload;
    default:
      return state;
  }
}