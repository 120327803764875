import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import OrderApi from 'api/order';
import { setToArray } from 'utils';

export const OrderContext = createContext();

function OrderContextProvider({ children }) {
  const [activeOrderIsLoading, setActiveOrderIsLoading] = useState(true);
  const [orderHistory, setOrderHistory] = useState([]);
  const [activeOrderByIdList, setActiveOrderByIdList] = useState([]);

  const [orderTypeList, setOrderTypeList] = useState([]);

  useEffect(() => {
    async function getOrderTypeList() {
      const response = await OrderApi.listOrderType();
      let orderTypeList = setToArray(response.data);
      setOrderTypeList(orderTypeList);
    }

    getOrderTypeList();
  }, []);

  async function getActiveOrderByIdList(id) {
    const response = await OrderApi.getActiveOrderListById(id);
    let activeOrderList = setToArray(response.data);
    setActiveOrderByIdList(activeOrderList);
  }

  async function getOrderHistoryList(id) {
    const response = await OrderApi.listOrderHistoryById(id);
    let orderHistoryList = response.data.data.list;
    setOrderHistory(orderHistoryList);
  }


  return (
    <OrderContext.Provider
      value={{
        activeOrderIsLoading,
        setActiveOrderIsLoading,
        orderHistory,
        getOrderHistoryList,
        // refundRequestList,
        orderTypeList,
        getActiveOrderByIdList,
        activeOrderByIdList,
      }}>
      {children}
    </OrderContext.Provider>
  );
}

OrderContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(OrderContextProvider);
