/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import { getListClinic } from 'store/action/clinic-action/GetClinicAction';
import TableGlobal from 'components/Table';
import { AuthenticationContext } from 'contexts/Authentication';

import { headerClinicCsv, tableClinicColumns } from '../helper';
import ActiveFilter from './components/ActiveFilter';
import Filter from './components/Filter';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;

export function ClinicListComponent({ listClinic, getListClinic }) {
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);

  const innerRef = useRef(null);
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opClinic } = permissionDataList;

  const [activeFilter, setActiveFilter] = useState({
    clinicName: [], //clinicName
  });

  async function getClinicListData() {
    await getListClinic();
  }

  useEffect(() => {
    getClinicListData();
  }, []);

  const onClinicNameChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      clinicName: checkedValues,
    });
  };

  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
        onClinicNameChange={onClinicNameChange}
        originalData={!isEmpty(listClinic) ? listClinic : []}
      />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return opClinic ? `/cms/system/clinic/${record.id}` : `/cms/system/clinic`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Clinic List'}
          inputPlaceholder={'Search Clinic Here...'}
          pageSize={8}
          headerCsv={headerClinicCsv}
          originalData={!isEmpty(listClinic) ? listClinic : []}
          csvName={'clinic-list.csv'}
          addDataTable={true}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableClinicColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          handleShowAddData={handleShowModalAddData}
          innerRef={innerRef}
          recordKey={(record) => record.id}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getClinicListData={getClinicListData}
      />
    </Layout>
  );
}

const mapStateToProps = ({ listClinic }) => ({
  listClinic,
});

export default withRouter(
  connect(mapStateToProps, { getListClinic })(ClinicListComponent)
);
