import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import BoxApi from 'api/box';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import { setToArray, setCreateEditMessage } from 'utils';

export function BoxStatusCreateEdit({ form, history }) {
  const [boxStatusData, setBoxStatusData] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await BoxApi.updateBoxStatus(id, values.boxStatusDesc);

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/box-status',
            });
          }
          setCreateEditMessage(
            theResponse.data,
            'Berhasil mengubah data box status dengan box status ID ' + id,
            'ERROR UPDATING DATA'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getBoxStatusById() {
      const response = await BoxApi.boxStatusById(id);
      let boxStatusData = setToArray(response.data);

      setBoxStatusData(boxStatusData[0]);
    }

    getBoxStatusById();
    // eslint-disable-next-line
  }, []);

  return (
    <DetailLayout
      detailTime={`${id} - ${boxStatusData ? boxStatusData.boxStatusDesc : ''}`}
      detailTitle={'Edit Box Status Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>

          <Form.Item label="ID">
            {getFieldDecorator('boxStatusId', {
              rules: [
                { required: true, message: 'Please input box status ID!' },
              ],
              initialValue: boxStatusData && boxStatusData.boxStatusId,
            })(
              <Input
                placeholder="Box Status ID"
                autoComplete="false"
                disabled
                size="large"
              />
            )}
          </Form.Item>

          <Form.Item label="Name">
            {getFieldDecorator('boxStatusDesc', {
              rules: [
                { required: true, message: 'Please input box status name!' },
              ],
              initialValue: boxStatusData && boxStatusData.boxStatusDesc,
            })(
              <Input
                placeholder="Box Status Description"
                autoComplete="false"
                size="large"
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const BoxStatusForm = Form.create({
  name: 'validate_create_box-status',
})(BoxStatusCreateEdit);

export default { BoxStatusCreateEdit };
