/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash';

import '../styles/index.scss';

export const LinkUploadReadOnly = ({
  //   placeholder,
  //   customClass,
  isPreviewIcon,
  isDownload,
  purpose,
  uploadedFileName,
  uploadedFileUrl,
  uploadedFileId,
  withoutInitValue,
  //   disabled,
  acceptExtention,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(
    () => {
      if (withoutInitValue) {
        setFileList([]);
      } else {
        let fileListDataInit;
        if (purpose) {
          fileListDataInit = uploadedFileName[purpose]
            ? [
                {
                  uid: uploadedFileId[purpose] || `${purpose}-0`,
                  name: uploadedFileName[purpose],
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        } else {
          fileListDataInit = uploadedFileName
            ? [
                {
                  uid: uploadedFileId,
                  name: uploadedFileName,
                  status: 'done',
                  url: uploadedFileUrl,
                },
              ]
            : [];
        }

        setFileList(fileListDataInit);
      }
    },
    withoutInitValue ? [] : [uploadedFileName[purpose]]
  );

  const props = {
    name: 'file',

    onDownload: (file) => {
      if (!isEmpty(file.response)) {
        saveAs(file.response.data[0].fileUrl, file.response.data[0].fileName);
      } else {
        saveAs(uploadedFileUrl, uploadedFileName[purpose]);
      }
    },

    openFileDialogOnClick: true,

    showUploadList: {
      showDownloadIcon: isDownload,
      showRemoveIcon: false,
      showPreviewIcon: isPreviewIcon,
    },

    accept: acceptExtention,

    ...(withoutInitValue ? '' : { fileList: fileList }),
  };

  return <Upload className={'input-with-upload-read-only'} {...props} />;
};

LinkUploadReadOnly.defaultProps = {
  isDownload: false,
  customClass: '',
  withoutInitValue: false,
  disabled: false,
  acceptExtention: 'video/*,application/pdf,image/*,.zip',
  isPreviewIcon: true,
};

export default React.memo(LinkUploadReadOnly);
