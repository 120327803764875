//Pluggin
import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import 'rc-color-picker/assets/index.css';

// Pages
import { LoginPageForm as LoginPage } from 'pages/Login';
import DashboardList from 'pages/Dashboard';
import BeErrorLog from 'pages/BeErrorLog';
import BeErrorLogDetailForm from 'pages/BeErrorLog/ViewDetail';
import { AuditBatch } from 'pages/AuditBatch';
import { AuditBatchDetailForm } from 'pages/AuditBatch/ViewDetail';
import RefundRequestList from 'pages/RefundRequest';
import SimulationDesignEditComponentForm from 'pages/SimulationDesign/EditDetail';
import { ArchivedOrderList } from 'pages/order/Archived';
import {CompletedOrderList} from 'pages/order/Completed';
import { ActiveOrderList } from 'pages/order/Active';
import RataPlusPage from 'pages/order/ActiveV2';
import { FlaggedOrderList } from 'pages/order/Flagged';
import OrderDetailForm from 'pages/order/EditOrderDetail';
import { AddressEditComponentForm } from 'pages/order/EditOrderDetail/components/EditAddress/';
import ClinicNotesEditComponentForm from 'pages/order/EditOrderDetail/components/EditClinicNotes/';
import { UserRoleEditComponentForm } from 'pages/administration/User/EditDetail';
import UserRoleList from 'pages/administration/User';
import { ActionList } from 'pages/master/Action';
import { ActionForm } from 'pages/master/Action/EditDetail';
import { BoxStatusList } from 'pages/master/BoxStatus';
import { BoxStatusForm } from 'pages/master/BoxStatus/EditDetail';
import { FlowList } from 'pages/master/Flow';
import { FlowForm } from 'pages/master/Flow/EditDetail';
import { OrderStatusList } from 'pages/master/OrderStatus';
import { OrderStatusForm } from 'pages/master/OrderStatus/EditDetail';
import { OrderTypeList } from 'pages/master/OrderType';
import { OrderTypeForm } from 'pages/master/OrderType/EditDetail';
import { ShippingList } from 'pages/master/Shipping';
import { ShippingForm } from 'pages/master/Shipping/EditDetail';
import { StateList } from 'pages/master/State';
import { ClinicStatusList } from 'pages/master/ClinicStatus';
import { ClinicStatusForm } from 'pages/master/ClinicStatus/EditDetail';
import { ClinicSubStatusList } from 'pages/master/ClinicSubStatus';
import ClinicSubStatusCreateEditForm from 'pages/master/ClinicSubStatus/EditDetail';
import { StateForm } from 'pages/master/State/EditDetail';
import { BoxList } from 'pages/transaction/Box';
import { BoxForm } from 'pages/transaction/Box/EditDetail';
import { OrderShippingList } from 'pages/transaction/OrderShipping';
import { OrderShippingForm } from 'pages/transaction/OrderShipping/EditDetail';
import { OrderHistoryList } from 'pages/transaction/OrderHistory';
import { OrderHistoryForm } from 'pages/transaction/OrderHistory/EditDetail';
import { BoxListComponent } from 'pages/system/BoxSystem';
import { BoxViewDetail } from 'pages/system/BoxSystem/ViewDetail';
import ClinicListComponent from 'pages/system/Clinic/';
import { ClinicEditComponentForm } from 'pages/system/Clinic/EditDetail';
import SimDesListPage from 'pages/SimulationDesign/SimDesListPage';

//Component
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import AuthenticatedGuardRoute from 'components/guard/Authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/NotAuthenticated';

//Context
import OrderContext from 'contexts/Order';
import AuthenticationContext from 'contexts/Authentication';
import ShippingContext from 'contexts/Shipping';
import AuditBatchContext from 'contexts/AuditBatch';

//Styles
import 'antd/dist/antd.css';
import './App.scss';

function ContentManagement() {
  return (
    <Fragment>
      <Switch>
        <NotAuthenticatedGuardRoute exact path="/" component={LoginPage} />;
        <Layout className="pd-cms">
          <Sidebar />
          <Layout>
            <Header />
            <AuthenticatedGuardRoute exact path="/cms/" />
            <AuthenticatedGuardRoute
              exact
              path="/cms/dashboard"
              component={DashboardList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/order/archived"
              component={ArchivedOrderList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/order/completed"
              component={CompletedOrderList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/order/active"
              component={ActiveOrderList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/order/rataplus"
              component={RataPlusPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/order/flagged"
              component={FlaggedOrderList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/action"
              component={ActionList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/action/:id"
              component={ActionForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/box-status"
              component={BoxStatusList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/box-status/:id"
              component={BoxStatusForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/flow"
              component={FlowList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/flow/:id"
              component={FlowForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/order-status"
              component={OrderStatusList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/order-status/:id"
              component={OrderStatusForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/be-error-log"
              component={BeErrorLog}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/be-error-log/:id"
              component={BeErrorLogDetailForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/order-type"
              component={OrderTypeList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/order-type/:id"
              component={OrderTypeForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/shipping"
              component={ShippingList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/shipping/:id"
              component={ShippingForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/state"
              component={StateList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/state/:id"
              component={StateForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/clinic-status"
              component={ClinicStatusList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/clinic-status/:id"
              component={ClinicStatusForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/clinic-sub-status"
              component={ClinicSubStatusList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/master/clinic-sub-status/:id"
              component={ClinicSubStatusCreateEditForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/system/box"
              component={BoxListComponent}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/system/box/:id"
              component={BoxViewDetail}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/system/clinic"
              component={ClinicListComponent}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/system/clinic/:id"
              component={ClinicEditComponentForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/box"
              component={BoxList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/box/:id"
              component={BoxForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-shipping"
              component={OrderShippingList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-shipping/:id"
              component={OrderShippingForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-detail/:id"
              component={OrderDetailForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-history"
              component={OrderHistoryList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-history/:id"
              component={OrderHistoryForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/audit-batch/"
              component={AuditBatch}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/audit-batch/:id"
              component={AuditBatchDetailForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/administration/user"
              component={UserRoleList}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/administration/user/:id"
              component={UserRoleEditComponentForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/cms/refund-request"
              component={RefundRequestList}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-detail/edit-address/:id"
              component={AddressEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/transaction/order-detail/edit-clinic-notes/:id"
              component={ClinicNotesEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/pengecekan-sebelum-design"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/pengecekan-sebelum-design/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-proses-design"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-proses-design/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-review-dokter"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-review-dokter/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/approved-doctor"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/approved-doctor/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/reject-butuh-treatment"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/reject-butuh-treatment/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/reject-design-only"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/reject-design-only/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-review-pasien"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sedang-review-pasien/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/approved-pasien"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/approved-pasien/:id"
              component={SimulationDesignEditComponentForm}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sudah-export"
              component={SimDesListPage}
            />

            <AuthenticatedGuardRoute
              exact
              path="/cms/simulation-design/sudah-export/:id"
              component={SimulationDesignEditComponentForm}
            />
          </Layout>
        </Layout>
      </Switch>
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <AuthenticationContext>
        <ShippingContext>
          <OrderContext>
            <AuditBatchContext>
              <ContentManagement />
            </AuditBatchContext>
          </OrderContext>
        </ShippingContext>
      </AuthenticationContext>
    </Router>
  );
}

export default App;
