/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Table, Button, Tooltip, Input } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual, isEmpty } from 'lodash';

import { OrderContext } from 'contexts/Order';
import { AuthenticationContext } from 'contexts/Authentication';
import SelectDropdown from 'components/SelectDropdown';
import { nestedFilter } from 'utils';
import { useOuterClickNotifier, usePrevious } from 'utils/hooks';

import { orderLogsColums } from '../../helper';
import ActiveFilterOrderLogs from '../active-filter/ActiveFilterLogs';
import FilterOrderLogs from '../filter/FilterOrderLogs';
import TableResetHistory from '../Details/components/ResetHistory';

export const OrderDetailTracking = ({
  flows,
  orderStatuss,
  orderShippings,
  activeFilterOrderLog,
  nextStateOptions,
  values,
  getFieldDecorator,
  setActiveFilterOrderLog,
  resetHistory,
}) => {
  const prevActiveFilter = usePrevious(
    activeFilterOrderLog,
    setActiveFilterOrderLog
  );
  const [filterOrderLogShow, setFilterOrderLogShow] = useState(false);
  const [showInputOrderLog, setShowInputOrderLog] = useState(false);
  const [filteredOrderLog, setFilteredOrderLog] = useState([]);
  const [valueOrderLog, setValueOrderLog] = useState('');
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opSupervisorFeature } = permissionDataList;
  const { orderHistory } = useContext(OrderContext);
  const innerOrderLogRef = useRef(null);
  // const innerRefinementRef = useRef(null);
  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  const [paginationOrderLog, setPaginationOrderLog] = useState({
    current: 1,
    pageSize: 5,
  });

  const onOrderLogTableChange = (pagination) => {
    setPaginationOrderLog(pagination);
  };

  const orderStatussOptions = orderStatuss.map((value) => ({
    label: `${value.orderStatusId} - ${value.orderStatusDesc}`,
    value: value.orderStatusId,
  }));

  const orderShippingOptions = orderShippings.map((value) => ({
    label: value.orderShippingId,
    value: value.orderShippingId,
  }));

  const handleFilterOrderLogShow = () => {
    setFilterOrderLogShow(!filterOrderLogShow);
    setShowInputOrderLog(false);
  };

  const handleHideSearchOrderLog = () => {
    setShowInputOrderLog(false);
    setValueOrderLog('');
    setFilteredOrderLog(orderHistory);
  };

  const handleShowSearchOrderLog = () => {
    setShowInputOrderLog(true);
    setFilterOrderLogShow(false);
  };

  const resetFilterOrderLog = () => {
    setActiveFilterOrderLog({});
  };

  const onActiveOrderChange = (checkedValues) => {
    setActiveFilterOrderLog({
      ...activeFilterOrderLog,
      activeOrder: checkedValues,
    });
  };

  const onFlowStatusChange = (checkedValues) => {
    setActiveFilterOrderLog({
      ...activeFilterOrderLog,
      flowStatus: checkedValues,
    });
  };

  const onShippingAddressChange = (checkedValues) => {
    setActiveFilterOrderLog({
      ...activeFilterOrderLog,
      shippingAddress: checkedValues,
    });
  };

  const onShippingCourierChange = (checkedValues) => {
    setActiveFilterOrderLog({
      ...activeFilterOrderLog,
      shippingCourier: checkedValues,
    });
  };

  useOuterClickNotifier(handleFilterOrderLogShow, innerOrderLogRef);

  useEffect(() => {
    setFilteredOrderLog(orderHistory);
  }, [orderHistory]);

  //filter
  useEffect(() => {
    const allFilterResult = nestedFilter(orderHistory, activeFilterOrderLog);

    if (!isEqual(prevActiveFilter, activeFilterOrderLog)) {
      setFilteredOrderLog(allFilterResult);
    }
  }, [activeFilterOrderLog, orderHistory, prevActiveFilter]);

  //search
  const onInputOrderLogChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    setValueOrderLog(convertValue);

    const filteredData = orderHistory.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key]
            .toString()
            .toLowerCase()
            .search(e.target.value) !== -1
      );
    });

    setFilteredOrderLog(filteredData);
  };

  return (
    <div className="order-detail-tracking">
      <Row gutter={15}>
        <Col xs={6}>
          <Form.Item label="SLA Deadline">
            {getFieldDecorator('orderDeadline', {
              initialValue: values && values.orderDeadline,
            })(
              <Input
                placeholder="SLA Deadline"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item label="Flow">
            {getFieldDecorator('flowId', {
              rules: [{ required: true, message: 'Please input flow!' }],
              initialValue: values && values.flowId,
            })(
              <SelectDropdown
                options={flowOptions}
                placeHolder={'Select Flow'}
                disabled={true}
              />
            )}
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item label="Order Status">
            {getFieldDecorator('orderStatusId', {
              rules: [
                {
                  required: true,
                  message: 'Please input order status!',
                },
              ],
              initialValue: values && values.orderStatusId,
            })(
              <SelectDropdown
                options={orderStatussOptions}
                placeHolder={'Select Order status'}
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="On Station Timestamp">
            <Input
              placeholder="On Station Timestamp"
              autoComplete="false"
              size="large"
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Creation Timestamp">
            {getFieldDecorator('createdAt', {
              initialValue:
                values &&
                `${moment(values.createdAt).format(
                  moment.HTML5_FMT.DATE
                )} ${moment(values.createdAt).format(
                  moment.HTML5_FMT.TIME_SECONDS
                )}`,
            })(
              <Input
                placeholder="Creation Timestamp"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="DIY Delivery Timestamp">
            {getFieldDecorator('diyDeliveryTimestamp', {
              initialValue: values && values.diyDeliveryTime,
            })(
              <Input
                placeholder="DIY Delivery Timestamp"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Consultation Timestamp">
            {getFieldDecorator('consultationTime', {
              initialValue: values && values.consultationTime,
            })(
              <Input
                placeholder="Consultation Timestamp"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Appointment SV 2 Timestamp">
            {getFieldDecorator('appointmentSv2Timestamp', {
              initialValue: values && values.appointmentSv2Timestamp,
            })(
              <Input
                placeholder="Appointment SV 2 Timestamp"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Appointment Timestamp">
            {getFieldDecorator('appointmentTime', {
              initialValue: values && values.appointmentTime,
            })(
              <Input
                placeholder="Appointment Timestamp"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Box Timestamp">
            {getFieldDecorator('boxTimestamp', {
              initialValue: values && values.boxTimestamp,
            })(
              <Input
                placeholder="Box Timestamp"
                autoComplete="false"
                disabled
                size="large"
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Round 2 Timestamp">
            {getFieldDecorator('round2Timestamp', {
              initialValue: values && values.round2Timestamp,
            })(
              <Input
                placeholder="Round 2 Timestamp"
                autoComplete="false"
                disabled
                size="large"
              />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Round">
            {getFieldDecorator('round', {
              initialValue: values && values.stateRound,
            })(
              <Input
                placeholder="Round"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item label="Shipping ID">
            {getFieldDecorator('orderShippingId', {
              rules: [
                {
                  required: true,
                  message: 'Please input order shipping!',
                },
              ],
              initialValue: values && values.orderShippingId,
            })(
              <SelectDropdown
                options={orderShippingOptions}
                placeHolder={'Select Order Shipping'}
                disabled
              />
            )}
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item label="Set Next State">
            {getFieldDecorator(
              'nextStateId',
              {}
            )(
              <SelectDropdown
                options={nextStateOptions}
                placeHolder={'Select Next State'}
                disabled
              />
            )}
          </Form.Item>
        </Col>

        { 
          <Col xs={6}>
            <Form.Item label="Flagged By">
              <Input 
                size="large"
                value={values && values.flaggedBy}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }

{ 
          <Col xs={6}>
            <Form.Item label="Flagged At">
              <Input 
                size="large"
                value={values && values.flaggedAt}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }

        {
          <Col xs={6}>
            <Form.Item label="Archived By">
              <Input 
                size="large"
                value={values && values.archivedBy}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }

        {
          <Col xs={6}>
            <Form.Item label="Archived At">
              <Input 
                size="large"
                value={values && values.archivedAt}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }

        {
          <Col xs={6}>
            <Form.Item label="Resolved By">
              <Input 
                size="large"
                value={values && values.resolvedBy}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }

        {
          <Col xs={6}>
            <Form.Item label="Resolved At">
              <Input 
                size="large"
                value={values && values.resolvedAt}
                placeholder=" - "
                disabled
              />
            </Form.Item>
          </Col>
        }
      </Row>

      {opSupervisorFeature && (
        <>
          <div className="order-detail__logs-table order-detail__table mb-35">
            <Row gutter={20} type="flex" className="order-detail__table-row">
              <Col xs={12}>
                {showInputOrderLog ? (
                  <div className="order-detail__search">
                    <Input
                      className="order-detail__search-input"
                      placeholder="Search order log here..."
                      onChange={onInputOrderLogChange}
                      value={valueOrderLog}
                    />
                    <Button
                      onClick={handleHideSearchOrderLog}
                      icon="close"
                      className="position-absolute"
                    />
                  </div>
                ) : (
                  <strong className="text-md">Order Logs</strong>
                )}
              </Col>

              <Col xs={12} type="flex" align="end">
                <Tooltip title="Search">
                  <Button
                    icon="search"
                    className="mr-10"
                    onClick={handleShowSearchOrderLog}
                  />
                </Tooltip>
                <Tooltip title="Filter">
                  <Button icon="filter" onClick={handleFilterOrderLogShow} />
                </Tooltip>
                {filterOrderLogShow && (
                  <FilterOrderLogs
                    onShippingCourierChange={onShippingCourierChange}
                    onShippingAddressChange={onShippingAddressChange}
                    onActiveOrderChange={onActiveOrderChange}
                    onFlowStatusChange={onFlowStatusChange}
                    innerRef={innerOrderLogRef}
                    resetFilter={resetFilterOrderLog}
                    activeFilterData={activeFilterOrderLog}
                    orderHistory={orderHistory}
                  />
                )}
              </Col>
            </Row>
            <ActiveFilterOrderLogs
              activeFilterData={activeFilterOrderLog}
              setFilterShow={setFilterOrderLogShow}
              setActiveFilter={setActiveFilterOrderLog}
            />
            <Table
              columns={orderLogsColums}
              dataSource={filteredOrderLog}
              className="order-detail__table-item"
              scroll={{ x: 1000 }}
              onChange={onOrderLogTableChange}
              pagination={paginationOrderLog}
            />
          </div>

          <TableResetHistory resetHistory={resetHistory} />
        </>
      )}
    </div>
  );
};

OrderDetailTracking.propTypes = {
  flows: PropTypes.array,
  orderStatuss: PropTypes.array,
  orderShippings: PropTypes.array,
};

export default OrderDetailTracking;
