import React, { createContext, useEffect, useState } from 'react';

import ShippingApi from 'api/shipping';
import OrderApi from 'api/order';
// import PropTypes from 'prop-types';
import { setToArray } from 'utils';

export const ShippingContext = createContext();

const Shipping = ({ children }) => {
  const [orderShippingById, setOrderShippingById] = useState([]);
  const [shippingList, setShippingList] = useState([]);

  async function getOrderShippingById(id) {
    const response = await OrderApi.listOrderShippingById(id);
    const listOrderShipping = response.data.data.list;
    setOrderShippingById(listOrderShipping);
  }

  async function addOrderShipping(payload) {
    await OrderApi.addOrderShipping(payload);
  }

  useEffect(() => {
    async function getShippingList() {
      const response = await ShippingApi.listShipping();
      let shippingList = setToArray(response.data);

      setShippingList(shippingList);
    }

    getShippingList();
  }, []);

  return (
    <ShippingContext.Provider
      value={{
        getOrderShippingById,
        orderShippingById,
        addOrderShipping,
        shippingList,
      }}>
      {children}
    </ShippingContext.Provider>
  );
};

// Shipping.propTypes = {};

export default Shipping;
