import React from 'react';
import { Row, Col, Button } from 'antd';
import { testFitOptions } from '../../helper/';
// import PropTypes from 'prop-types';

import MultiselectDropdown from 'components/MultiselectDropdown';

export const Filter = ({
  resetFilter,
  innerRef,
  activeFilter,
  onTestFitChange,
}) => {
  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <MultiselectDropdown
            placeHolder={'Select Test Fit'}
            onChange={onTestFitChange}
            options={testFitOptions}
            selectValue={activeFilter.testFit}
          />
        </Col>
      </Row>
    </div>
  );
};

Filter.propTypes = {
  // onOrderTypeChange: PropTypes.func,
};

export default Filter;
