import React from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import './styles/index.scss';

export const ActiveFilter = ({
  activeFilterData,
  setActiveFilter,
  setFilterShow,
  setCurrentStateFilterValue,
  setOrderTypeFilterValue,
  orderTypeFilterValue,
  currentStateFilterValue,
}) => {
  const removeEachFilter = (param, value) => (e) => {
    e.preventDefault();

    switch (param) {
      case 'overdue':
        setActiveFilter({
          ...activeFilterData,
          deadlineStatus: activeFilterData.deadlineStatus.filter(
            (overdue) => overdue !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'test-fit':
        setActiveFilter({
          ...activeFilterData,
          testFitStatus: activeFilterData.testFitStatus.filter(
            (testFit) => testFit !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'flagged':
        setActiveFilter({
          ...activeFilterData,
          flagged: activeFilterData.orderStatusDesc.filter(
            (flagged) => flagged !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'order-type':
        setActiveFilter({
          ...activeFilterData,
          orderType: activeFilterData.orderType.filter(
            (orderType) => orderType !== value.split(' ')[0]
          ),
          orderDesc: activeFilterData.orderDesc.filter(
            (orderDesc) => orderDesc !== value.split('-').pop().replace(' ', '')
          ),
        });
        setOrderTypeFilterValue({
          orderOpt: orderTypeFilterValue.orderOpt.filter(
            (orderOpt) => orderOpt !== value
          ),
        });
        setFilterShow(false);
        break;
      case 'current-state':
        setActiveFilter({
          ...activeFilterData,
          stateId: activeFilterData.stateId.filter(
            (currentState) => currentState !== value.split(' ')[0]
          ),
          stateName: activeFilterData.stateName.filter(
            (currentState) =>
              currentState !== value.split('-').pop().replace(' ', '')
          ),
        });
        setCurrentStateFilterValue({
          currentOpt: currentStateFilterValue.currentOpt.filter(
            (currentState) => currentState !== value
          ),
        });
        setFilterShow(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="active-filter d-flex">
      {!isEmpty(activeFilterData.deadlineStatus) &&
        activeFilterData.deadlineStatus.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">
              {value === 'Overdue' ? 'Overdue' : '-'}
            </div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('overdue', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.testFitStatus) &&
        activeFilterData.testFitStatus.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('test-fit', value)}
            />
          </div>
        ))}
      {!isEmpty(activeFilterData.orderStatusDesc) &&
        activeFilterData.orderStatusDesc.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">
              {value === 'Flagged' ? 'Flagged' : '-'}
            </div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('flagged', value)}
            />
          </div>
        ))}
      {!isEmpty(currentStateFilterValue.currentOpt) &&
        currentStateFilterValue.currentOpt.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('current-state', value)}
            />
          </div>
        ))}
      {!isEmpty(orderTypeFilterValue.orderOpt) &&
        orderTypeFilterValue.orderOpt.map((value, index) => (
          <div className="d-flex active-filter__item mr-10" key={index}>
            <div className="mr-10 text-sm">{value}</div>
            <Button
              className="active-filter__btn-close"
              icon="close"
              size="small"
              onClick={removeEachFilter('order-type', value)}
            />
          </div>
        ))}
    </div>
  );
};

ActiveFilter.propTypes = {
  activeFilterData: PropTypes.object,
  setActiveFilter: PropTypes.func,
  setFilterShow: PropTypes.func,
};

export default ActiveFilter;
