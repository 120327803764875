import React from 'react';
import { Tag } from 'antd';

import './styles/index.scss';

export const TagLabel = ({ name, color }) => {
  return (
    <div className="tag-label">
      <Tag className={`tag-label__item tag-label__item--${color}`}>{name}</Tag>
    </div>
  );
};

export default TagLabel;
