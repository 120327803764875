/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext } from 'react';
import { isEmpty } from 'lodash';
import { Button, Form } from 'antd';
import { connect } from 'react-redux';

import { TableGlobal } from 'components/Table';
import ModalFullScreen from 'components/ModalFullScreen';
import { ShippingContext } from 'contexts/Shipping';

import {
  treatSlicingList,
  printTypeList,
  ocList,
  confirmCUList,
} from '../../../../helper';

//This modal is used if you want to set fullscreen modal in end of progress flow, for submiting progress
export const ModalFullscreenWithSubmit = ({
  showModal,
  handleHideModal,
  setButtonState,
  form,
  stateIdFlow,
  setProgress1Data,
  progress1Data,
  stateName,
  tableName,
  prodFlowList,
  listClinic,
  isRescheduleAppointmentWithClinic,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    orderStatusId: [],
  });
  const { getFieldDecorator } = form;

  const innerRef = useRef(null);
  const { shippingList } = useContext(ShippingContext);

  const handleOnRowPathname = (record) => {
    handleHideModal();
    setProgress1Data({
      ...progress1Data,
      nextStateId: !isEmpty(stateIdFlow)
        ? stateIdFlow
        : prodFlowList[0].nextStateId,
      [stateName]:
        stateName === 'shippingId'
          ? record.shippingId
          : stateName === 'clinicId'
          ? `${record.id} ${record.clinicName}`
          : record.value,
    });
    if (isRescheduleAppointmentWithClinic) {
      setButtonState(1);
    } else {
      setButtonState(7);
    }
  };

  const handleRow = (row) => {
    return (
      <Form.Item className="mb-0">
        {getFieldDecorator(
          stateName,
          {}
        )(
          <Button className="replace-box__btn-submit" htmlType="submit">
            {stateName === 'shippingId'
              ? row.shippingId
              : stateName === 'clinicId'
              ? `${row.id} ${row.clinicName}`
              : row.name}
          </Button>
        )}
      </Form.Item>
    );
  };

  const treatSlicingColumns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => handleRow(record),
    },
  ];

  const courierColumns = [
    {
      title: 'Name',
      key: 'shippingId',
      render: (text, record) => handleRow(record),
    },
  ];

  const ocColumns = [
    {
      title: 'OC Options',
      key: 'oc',
      render: (text, record) => handleRow(record),
    },
  ];

  const clinicColumns = [
    {
      title: 'Clinic Id',
      key: 'id',
      render: (text, record) => handleRow(record),
    },
  ];

  const printTypeColumns = [
    {
      title: 'Name',
      key: 'printType',
      render: (text, record) => handleRow(record),
    },
  ];

  const confirmCUColumns = [
    {
      title: 'Apakah order butuh Cetak Ulang?',
      key: 'name',
      render: (text, record) => handleRow(record),
    },
  ];

  const handleOriginalData = () => {
    if (stateName === 'slicing') {
      return treatSlicingList;
    } else if (stateName === 'clinicId') {
      return listClinic; //clinic list
    } else if (stateName === 'printType') {
      return printTypeList; //3D / Putty
    } else if (stateName === 'ocChecklist') {
      return ocList;
    } else if (stateName === 'confirmCU') {
      return confirmCUList;
    } else {
      return shippingList; //courier
    }
  };

  return (
    <>
      <ModalFullScreen
        showModal={showModal}
        handleHideModal={handleHideModal}
        idContainer={'form-detail-order'}
        modalComponent={
          <TableGlobal
            tableName={`Select ${tableName}`}
            inputPlaceholder={`Search ${tableName} Here ...`}
            pageSize={8}
            originalData={handleOriginalData()}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            editableTable={false}
            exportCsvIsExist={false}
            tableColumns={
              stateName === 'slicing'
                ? treatSlicingColumns
                : stateName === 'clinicId'
                ? clinicColumns
                : stateName === 'printType'
                ? printTypeColumns
                : stateName === 'ocChecklist'
                ? ocColumns
                : stateName === 'confirmCU'
                ? confirmCUColumns
                : courierColumns
            }
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            innerRef={innerRef}
            clickNewHistory={false}
            onRowPathname={handleOnRowPathname}
          />
        }
      />
    </>
  );
};

const mapStateToProps = ({ listClinic }) => ({
  listClinic,
});

export default connect(mapStateToProps, {})(ModalFullscreenWithSubmit);
