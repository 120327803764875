export const headerCsv = [
  { label: 'ID', key: 'userId' },
  { label: 'Name', key: 'userName' },
  { label: 'Email', key: 'userEmail' },
  { label: 'Role', key: 'roleName' },
  { label: 'Station', key: 'stationName' },
];

export const tableColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'userId',
    sorter: (a, b) => a.userId - b.userId,
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'userName',
    sorter: (a, b) => a.userName.localeCompare(b.userName),
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'userEmail',
    sorter: (a, b) => a.userEmail.localeCompare(b.userEmail),
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'roleName',
    sorter: (a, b) => a.roleName.localeCompare(b.roleName),
  },
  {
    title: 'Station',
    key: 'station',
    dataIndex: 'stationName',
    sorter: (a, b) => a.stationName.localeCompare(b.stationName),
  },
];
