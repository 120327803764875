import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import TableDataContainer from './containers/TableDataContainer';

import { getListSimulationDesignV2 } from 'store/action/SimulationDesignAction';

const { Content } = Layout;

function SimDesListPage({
  getListSimulationDesignV2,
  listSimulationDesignData,
}) {
  const history = useHistory();
  const { pathname } = useLocation();
  const [simulationStatus, setSimulationStatus] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    if (pathname.includes('sudah-export'))
      setSimulationStatus({ label: 'sudah-export', value: 'SE' });
    if (pathname.includes('approved-pasien'))
      setSimulationStatus({ label: 'approved-pasien', value: 'AP' });
    if (pathname.includes('sedang-review-pasien'))
      setSimulationStatus({ label: 'sedang-review-pasien', value: 'WP' });
    if (pathname.includes('reject-design-only'))
      setSimulationStatus({ label: 'reject-design-only', value: 'RDO' });
    if (pathname.includes('reject-butuh-treatment'))
      setSimulationStatus({ label: 'reject-butuh-treatment', value: 'RBT' });
    if (pathname.includes('approved-doctor'))
      setSimulationStatus({ label: 'approved-doctor', value: 'A' });
    if (pathname.includes('sedang-review-dokter'))
      setSimulationStatus({ label: 'sedang-review-dokter', value: 'W' });
    if (pathname.includes('sedang-proses-design'))
      setSimulationStatus({ label: 'sedang-proses-design', value: 'SPD' });
      if (pathname.includes('pengecekan-sebelum-design'))
      setSimulationStatus({ label: 'pengecekan-sebelum-design', value: 'MD' });
  }, [pathname]);

  return (
    <Layout className="order-list-page">
      <Content>
        {listSimulationDesignData && (
          <TableDataContainer
            dataSource={listSimulationDesignData.list}
            listActionDispatcher={(val) => getListSimulationDesignV2(val)}
            total={listSimulationDesignData.allData}
            simulationStatusId={simulationStatus.value}
            handleRowOnClick={(record, rowIndex) => {
              history.push({
                pathname: `/cms/simulation-design/${simulationStatus.label}/edit?id=${record.simulationId}`,
                data: record,
              });
            }}
            tableTitle={`Simulation Design | ${pathname.split('/')[3].split('-').map(word =>  word[0].toUpperCase() + word.substring(1)).join(" ")}`}
            rowKey="simulationId"
          />
        )}
      </Content>
    </Layout>
  );
}
const mapStateToProps = ({ listSimulationDesignData }) => ({
  listSimulationDesignData,
});
export default connect(mapStateToProps, { getListSimulationDesignV2 })(
  SimDesListPage
);
