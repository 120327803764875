/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';

import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';
import { getListRefundRequest } from 'store/action/RefundRequestAction';

import { headerCsv, tableColumns } from './helper';
import ActiveFilter from './component/ActiveFilter';
import Filter from './component/Filter';

const { Content } = Layout;

export function RefundRequestList({
  getListRefundRequest,
  listRefundRequestData,
}) {
  const [loadingPage, setLoadingPage] = useState(true);
  const [editTable, setEditTable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const innerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState({
    testFit: [],
  });

  async function getRefundList() {
    try {
      await getListRefundRequest();
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getRefundList();
  }, []);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const resetFilter = () => {
    setActiveFilter({});
  };

  if (loadingPage) {
    return <PageSpinner />;
  }

  const onTestFitChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      testFit: checkedValues,
    });
  };

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        setShowFilter={setShowFilter}
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        activeFilter={activeFilter}
        innerRef={innerRef}
        onTestFitChange={onTestFitChange}
      />
    );
  };

  const handleOnRowPathname = (record) => {
    return `/cms/transaction/order-detail/edit?id=${record.orderId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Refund Request'}
          inputPlaceholder={'Search Refund Request Here...'}
          pageSize={8}
          headerCsv={headerCsv}
          originalData={listRefundRequestData || []}
          csvName={'refund-request-list.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={false}
          recordKey={(record) => record.orderId}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
        />
      </Content>
    </Layout>
  );
}

const mapStateToProps = ({ listRefundRequestData }) => ({
  listRefundRequestData,
});

export default connect(mapStateToProps, { getListRefundRequest })(
  RefundRequestList
);
