import React from 'react';
import { Modal, Input, Form, Button } from 'antd';

export const ModalFlag = ({
  showModal,
  handleHideModalFlag,
  setButtonState,
  form,
}) => {
  const { TextArea } = Input;
  const { getFieldDecorator } = form;

  const handleSubmitModalFlag = () => {
    setButtonState(2);
  };

  return (
    <Modal
      title="Flag Note"
      centered
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmitModalFlag()}
      onCancel={() => handleHideModalFlag()}
      footer={[
        <Form.Item key="wrap">
          <Button key="back" onClick={handleHideModalFlag}>
            Cancel
          </Button>
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            onClick={handleSubmitModalFlag}>
            Ok
          </Button>
        </Form.Item>,
      ]}>
      <div className="mb-25">Enter the flag note of the order</div>

      <Form.Item>
        {getFieldDecorator('flagNoteDialog', {
          initialValue: '',
        })(<TextArea size="large" placeholder="Flag Note*" />)}
      </Form.Item>
    </Modal>
  );
};

export default ModalFlag;
