import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import SimulationDesignApi from 'api/simulation-design';

import { Col, Form, Row, Collapse, Icon, Upload, Button } from 'antd';
import { AuthenticationContext } from 'contexts/Authentication';


const { Dragger } = Upload;

export const FinalSTL = ({
  form,

  simulationDesignByIdData,
}) => {
  const { getFieldDecorator } = form;
  const { Panel } = Collapse;
  const username = localStorage.getItem('username');

  const { permissionDataList: {opEditSetAlignerDetail} } = useContext(AuthenticationContext)
  const [fileListRa, setFileListRa] = useState([]);
  const [fileListRb, setFileListRb] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleDownloadFinalSTL = async () => {
    let response = await SimulationDesignApi.downloadFinalStlFile({ simulationId: simulationDesignByIdData[0].simulationId})
    const link = document.createElement("a");
    link.target = '_blank';
    link.href = response.data;
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    if (
      !isEmpty(simulationDesignByIdData) &&
      !isEmpty(simulationDesignByIdData[0].listFinalStlRb)
    ) {
      let fileListDataInit = simulationDesignByIdData[0].listFinalStlRb.map(
        (value) => ({
          uid: value.fileId,
          name: value.fileName,
          status: 'done',
          url: value.fileUrl,
        })
      );

      form.setFieldsValue({
        finalStlRb: fileListDataInit,
      });
      setFileListRb(fileListDataInit || []);
    }

    if (
      !isEmpty(simulationDesignByIdData) &&
      !isEmpty(simulationDesignByIdData[0].listFinalStlRa)
    ) {
      let fileListDataInit = simulationDesignByIdData[0].listFinalStlRa.map(
        (value) => ({
          uid: value.fileId,
          name: value.fileName,
          status: 'done',
          url: value.fileUrl,
        })
      );

      form.setFieldsValue({
        finalStlRa: fileListDataInit,
      });

      setFileListRa(fileListDataInit || []);
    }
    //eslint-disable-next-line
  }, [simulationDesignByIdData]);

  const handleFinalStlRaChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (opEditSetAlignerDetail && file.response) {
        file.uid =file.response.data[0].fileId
        file.name = file.response.data[0].fileName
        file.status = 'done'
        file.url = file.response.data[0].fileUrl
      } else if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    form.setFieldsValue({
      finalStlRa: fileList,
    });

    setFileListRa(fileList);
  };

  const handleFinalStlRbChange = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (opEditSetAlignerDetail && file.response) {
        file.uid =file.response.data[0].fileId
        file.name = file.response.data[0].fileName
        file.status = 'done'
        file.url = file.response.data[0].fileUrl
      } else if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    form.setFieldsValue({
      finalStlRb: fileList,
    });

    setFileListRb(fileList);
  };

  return (
    <div className="mb-35 simulation-design__final-stl">
      <div className="color-text-primary text-md fw-bold mb-15">Final STL</div>

      <Collapse
        accordion
        className="simulation-design__collapse-form-item mt-20">
        <Panel header="Version 1" key="1">
          {' '}
          {simulationDesignByIdData[0].simulationStatusId === 'SE' && (
            <Row type="flex" justify="end">
              <Button
                onClick={() => handleDownloadFinalSTL()}
                icon="cloud-download" />
            </Row>
          )}
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="RA">
                {getFieldDecorator('finalStlRa', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                })(
                  <Dragger
                    name="file"
                    accept=".stl"
                    multiple={true}
                    openFileDialogOnClick={
                      simulationDesignByIdData[0].simulationStatusId === 'AP' || opEditSetAlignerDetail ||
                      false
                    }
                    onChange={handleFinalStlRaChange}
                    className="dnd-multiple-upload"
                    action={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/final-stl-ra/${username}`}>
                    {isEmpty(fileListRa) && (
                      <div className="dnd-multiple-upload__wrap-item">
                        <div>
                          <p className="ant-upload-drag-icon">
                            <Icon type="file-add" />
                          </p>
                          <div className="ant-upload-text text-base">
                            Drop files
                          </div>
                          <div className="ant-upload-text text-base">
                            Final STL RA
                          </div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="RB">
                {getFieldDecorator('finalStlRb', {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                })(
                  <Dragger
                    name="file"
                    accept=".stl"
                    multiple={true}
                    openFileDialogOnClick={
                      simulationDesignByIdData[0].simulationStatusId === 'AP' || opEditSetAlignerDetail ||
                      false
                    }
                    onChange={handleFinalStlRbChange}
                    className="dnd-multiple-upload"
                    action={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/final-stl-rb/${username}`}>
                    {isEmpty(fileListRb) && (
                      <div className="dnd-multiple-upload__wrap-item">
                        <div>
                          <p className="ant-upload-drag-icon">
                            <Icon type="file-add" />
                          </p>
                          <div className="ant-upload-text text-base">
                            Drop files
                          </div>
                          <div className="ant-upload-text text-base">
                            Final STL RB
                          </div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};



export default connect()(Form.create()(FinalSTL));
