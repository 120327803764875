import { GET_LIST_STATE, GET_LIST_PRINT_GROUP } from '../../type';

export const listState = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_STATE:
      return action.payload;
    default:
      return state;
  }
};

export const listPrintGroup = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_PRINT_GROUP:
      return action.payload;
    default:
      return state;
  }
};
