import {
  Collapse,
  Input,
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  message,
  Upload,
  Modal,
} from 'antd';
import React, { useState } from 'react';

// components
import InputUpload from 'components/upload/InputWithUpload';
import InputUploadReadOnly from 'components/upload/InputWithUploadReadOnly';
import SelectDropdown from 'components/SelectDropdown';
import ModalPatientRevision from '../../VideoRecording/ModalPatientRevision';
import TagLabel from 'components/TagLabel';

// service api
import SimulationDesignApi from 'api/simulation-design';

// utils
import { setCreateEditMessage } from 'utils';
import '../../../../styles/index.scss';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';

const { Panel } = Collapse;
const { TextArea } = Input;

function VideoRecordingItem({
  simulationDesignByIdData,
  username,
  getSimulationDesignByIdData,
  index,
  listVideo,
  removeVideoRecording,
  form,
  setIsErrorBeforeUpload,
  isErrorBeforeUpload,
  setIsErrorUpload,
  isErrorUpload,
  isEditEnabled,
  buttonState,
}) {
  const { getFieldDecorator, getFieldValue } = form;
  const [modalPatientRevision, setModalPatientRevision] = useState(false);
  const [modalPatientRevisionIndex, setModalPatientRevisionIndex] =
    useState('');

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewName, setPreviewName] = useState('');

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleApproval = async (e, simulationData, param, approvalType) => {
    if (simulationData) {
      try {
        let response;
        let payload = {
          simulationData: simulationData,
          simulationStatus: simulationDesignByIdData.simulationStatusId,
          username: username,
          approvalType: approvalType,
        };
        response = await SimulationDesignApi.approvalVideo(payload);

        setCreateEditMessage(
          response.data,
          `Success to ${param} video`,
          `Failed to ${param} video`
        );

        getSimulationDesignByIdData();
      } catch (err) {
        if (err.response.data.message) {
          const errMessage = err.response.data.message;
          message.error(errMessage);
        } else {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        }
      }
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewName(file.name);
  };
  function remapFileList(attachmentList) {
    let temp = attachmentList.map((val) => {
      return {
        uid: val.fileId,
        status: val.active,
        name: val.fileName,
        url: val.fileUrl,
      };
    });
    return temp;
  }
  let remappedFileList =
    listVideo &&
    listVideo[index] &&
    listVideo[index].attachmentList &&
    remapFileList(listVideo[index].attachmentList);
  return (
    <Collapse
      accordion
      className="mt-20 simulation-design__collapse-form-item"
      key={index}>
      <Panel
        header={
          <div className="d-flex align-items-center">
            <div className="mr-10">{`Video Recording ${index + 1}`}</div>
            {listVideo &&
            listVideo[index] !== undefined &&
            listVideo[index].latestApprovalStatus === 'Approved' ? (
              <TagLabel name={'Approved by Doctor'} color={'green'} />
            ) : listVideo &&
              listVideo[index] !== undefined &&
              listVideo[index].latestApprovalStatus === 'Rejected' ? (
              <TagLabel name={'Rejected by Doctor'} color={'red'} />
            ) : listVideo &&
              listVideo[index] !== undefined &&
              listVideo[index].latestApprovalStatus === 'Revised' ? (
              <TagLabel name={'Revised by Doctor'} color={'orange'} />
            ) : (
              ''
            )}

            {listVideo &&
            listVideo[index] &&
            listVideo[index].latestPatientApprovalStatus === 'Approved' ? (
              <TagLabel name={'Approved by Patient'} color={'green'} />
            ) : listVideo &&
              listVideo[index] &&
              listVideo[index].latestPatientApprovalStatus === 'Rejected' ? (
              <TagLabel name={'Rejected by Patient'} color={'red'} />
            ) : listVideo &&
              listVideo[index] &&
              listVideo[index].latestPatientApprovalStatus === 'Revised' ? (
              <TagLabel name={'Revised by Patient'} color={'orange'} />
            ) : (
              ''
            )}
            {index > 0 && isEmpty(listVideo) && (
              <Button
                icon="close"
                onClick={() => removeVideoRecording(index)}
                className="simulation-design__video-recording-close-btn"
              />
            )}
            {index > 0 && listVideo && !listVideo[index] && (
              <Button
                icon="close"
                onClick={() => removeVideoRecording(index)}
                className="simulation-design__video-recording-close-btn"
              />
            )}
          </div>
        }
        key={index}>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label="Number Set of RA">
              {getFieldDecorator(`videoRecordings.${index}.ra`, {
                validateTrigger: ['onChange', 'onBlur'],
                ...buttonState !== 1 && { rules: [
                  {
                    required: true,
                    message: 'Please input Number Set of RA!',
                  },
                ]},
                initialValue:
                  listVideo && listVideo[index] && String(listVideo[index].ra),
              })(
                <Input
                  placeholder="Insert Number Set of RA"
                  autoComplete="false"
                  size="large"
                  {...(listVideo && listVideo[index] &&
                    (simulationDesignByIdData.simulationStatusId !== 'SPD' ||
                      simulationDesignByIdData.simulationStatusId !==
                        'RDO') && !isEditEnabled && {
                      disabled: true,
                    })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Number Set of RB"
              {...(form.getFieldError('videoRecordings')[index]['rb'] && {
                help: form.getFieldError('videoRecordings')[index]['rb'],
                validateStatus: 'error',
              })}>
              {getFieldDecorator(`videoRecordings.${index}.rb`, {
                validateTrigger: ['onChange', 'onBlur'],
                ...buttonState !== 1 && {rules: [
                  {
                    required: true,
                    message: 'Please input Number Set of RB!',
                  },
                ]},
                initialValue:
                  listVideo && listVideo[index] && String(listVideo[index].rb),
              })(
                <Input
                  placeholder="Insert Number Set of RB"
                  autoComplete="false"
                  size="large"
                  {...(listVideo && listVideo[index] &&
                    (simulationDesignByIdData.simulationStatusId !== 'SPD' ||
                      simulationDesignByIdData.simulationStatusId !==
                        'RDO') && !isEditEnabled && {
                      disabled: true,
                    })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Number Set of OCUpper">
              {getFieldDecorator(`videoRecordings.${index}.ocUp`, {
                initialValue:
                  listVideo && listVideo[index] && String(listVideo[index].ocUp),
              })(
                <Input
                  placeholder="Insert OC Upper"
                  autoComplete="false"
                  size="large"
                  {...(listVideo && listVideo[index] &&
                    (simulationDesignByIdData.simulationStatusId !== 'SPD' ||
                      simulationDesignByIdData.simulationStatusId !==
                        'RDO') && !isEditEnabled && {
                      disabled: true,
                    })}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Number Set of OCLower">
              {getFieldDecorator(`videoRecordings.${index}.ocDown`, {
                initialValue:
                  listVideo && listVideo[index] && String(listVideo[index].ocDown),
              })(
                <Input
                  placeholder="Insert OC OCLower"
                  autoComplete="false"
                  size="large"
                  {...(listVideo && listVideo[index] &&
                    (simulationDesignByIdData.simulationStatusId !== 'SPD' ||
                      simulationDesignByIdData.simulationStatusId !==
                        'RDO') && !isEditEnabled && {
                      disabled: true,
                    })}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label="Video Recording">
              {listVideo && listVideo[index]
                ? getFieldDecorator(`videoRecordings.${index}.videoId`, {
                    initialValue: listVideo[index].fileId,
                  })(
                    <InputUploadReadOnly
                      placeholder="Upload Video Recording"
                      style={{ width: '80%', marginRight: 8 }}
                      purpose=""
                      uploadedFileId={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].fileId) ||
                        ''
                      }
                      uploadedFileName={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].fileName) ||
                        ''
                      }
                      uploadedFileUrl={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].fileUrl) ||
                        ''
                      }
                      acceptExtention={'video/*'}
                      isDownload={true}
                      disabled={true}
                    />
                  )
                : getFieldDecorator(`videoRecordings.${index}.videoId`, {
                    ...(simulationDesignByIdData.simulationStatusId ===
                      'SPD' && {
                      ...buttonState !== 1 && {rules: [
                        {
                          required: true,
                          message: 'Please input Video Recording!',
                        },
                      ]},
                    }),
                  })(
                    <InputUpload
                      placeholder="Upload Video Recording"
                      style={{ width: '80%', marginRight: 8 }}
                      setIsErrorUpload={setIsErrorUpload}
                      isErrorUpload={isErrorUpload}
                      setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                      isErrorBeforeUpload={isErrorBeforeUpload}
                      purpose="videoV2"
                      indexform={index}
                      setUploadedIdFile={(val) =>
                        form.setFieldsValue({
                          videoRecordings: {
                            [index]: {
                              videoId: val,
                            },
                          },
                        })
                      }
                      withoutInitValue={true}
                      acceptExtention={'video/*'}
                      disabled={
                        simulationDesignByIdData.simulationStatusId === 'SPD' ||
                        simulationDesignByIdData.simulationStatusId === 'RDO'
                          ? false
                          : true
                      }
                      actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/video/${username}`}
                    />
                  )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Video Version">
              {getFieldDecorator(`videoRecordings.${index}.videoVersionId`, {
                initialValue:
                  listVideo && listVideo[index]
                    ? listVideo[index].videoVersionId
                    : 'NS',
              })(
                <SelectDropdown
                  options={[
                    { label: 'Slicing', value: 'S' },
                    { label: 'No Slicing', value: 'NS' },
                  ]}
                  {...(listVideo && listVideo[index] &&
                    (simulationDesignByIdData.simulationStatusId !== 'SPD' ||
                      simulationDesignByIdData.simulationStatusId !==
                        'RDO') && {
                      disabled: true,
                    })}
                  placeHolder={'Select Video Version'}
                  onChange={(e) =>
                    form.setFieldsValue({
                      videoRecordings: {
                        [index]: {
                          videoVersionId: e,
                        },
                      },
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Slicing Chart">
              {listVideo && listVideo[index] ? getFieldDecorator(`videoRecordings.${index}.slicingId`, { initialValue: listVideo[index].slicingId })(
                <InputUploadReadOnly
                  placeholder="Slicing Chart"
                  style={{ width: '80%', marginRight: 8 }}
                  purpose=""
                  uploadedFileId={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingId) ||
                    ''
                  }
                  uploadedFileName={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingName) ||
                    ''
                  }
                  uploadedFileUrl={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingUrl) ||
                    ''
                  }
                  acceptExtention={'video/*'}
                  isDownload={true}
                  disabled={true}
                />
              ) 
              : getFieldValue(`videoRecordings.${index}.videoVersionId`) ===
              'S' ? (
                getFieldDecorator(`videoRecordings.${index}.slicingId`)(
                  <InputUpload
                    placeholder="Upload Slicing Chart"
                    style={{ width: '80%', marginRight: 8 }}
                    setIsErrorUpload={setIsErrorUpload}
                    isErrorUpload={isErrorUpload}
                    setUploadedIdFile={(val) =>
                      form.setFieldsValue({
                        videoRecordings: {
                          [index]: {
                            slicingId: val,
                          },
                        },
                      })
                    }
                    setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                    isErrorBeforeUpload={isErrorBeforeUpload}
                    purpose="slicingV2"
                    uploadedFileId={() =>
                      getFieldValue(`videoRecordings.${index}.slicingId`)
                    }
                    withoutInitValue={true}
                    indexform={index}
                    acceptExtention={'image/*'}
                    disabled={
                      simulationDesignByIdData.simulationStatusId === 'SPD' ||
                      simulationDesignByIdData.simulationStatusId === 'RDO'
                        ? false
                        : true
                    }
                    actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/slicing/${username}`}
                  />
                )
              ) : getFieldDecorator(`videoRecordings.${index}.slicingId`)(
                <InputUploadReadOnly
                  placeholder="Slicing Chart"
                  style={{ width: '80%', marginRight: 8 }}
                  purpose=""
                  uploadedFileId={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingId) ||
                    ''
                  }
                  uploadedFileName={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingName) ||
                    ''
                  }
                  uploadedFileUrl={
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].slicingUrl) ||
                    ''
                  }
                  acceptExtention={'video/*'}
                  isDownload={true}
                  disabled={true}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Video Sent Date">
              {getFieldDecorator(`videoRecordings.${index}.videoSentDate`, {
                initialValue:
                  listVideo && listVideo[index] && listVideo.videoSentTimestamp
                    ? moment(
                        listVideo[index].videoSentTimestamp,
                        'DD-MM-YYYY HH:mm:ss'
                      )
                    : '',
              })(
                <DatePicker
                  size="large"
                  showTime
                  placeholder="Video Sent Date*"
                  format="DD-MM-YYYY HH:mm:ss"
                  disabled
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label="Bite Video">
              {listVideo && listVideo[index]
                ? getFieldDecorator(`videoRecordings.${index}.videoBiteId`, {
                    initialValue: listVideo[index].videoBiteId,
                  })(
                    <InputUploadReadOnly
                      placeholder="Upload Video Video"
                      style={{ width: '80%', marginRight: 8 }}
                      purpose=""
                      uploadedFileId={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].videoBiteId) ||
                        ''
                      }
                      uploadedFileName={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].videoBiteName) ||
                        ''
                      }
                      uploadedFileUrl={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].videoBiteUrl) ||
                        ''
                      }
                      acceptExtention={'video/*'}
                      isDownload={true}
                      disabled={true}
                    />
                  )
                : getFieldDecorator(`videoRecordings.${index}.videoBiteId`, {
                    initialValue: null
                  })(
                    <InputUpload
                      placeholder="Upload Bite Video"
                      style={{ width: '80%', marginRight: 8 }}
                      setIsErrorUpload={setIsErrorUpload}
                      isErrorUpload={isErrorUpload}
                      setUploadedIdFile={(val) =>
                        form.setFieldsValue({
                          videoRecordings: {
                            [index]: {
                              videoBiteId: val,
                            },
                          },
                        })
                      }
                      setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                      isErrorBeforeUpload={isErrorBeforeUpload}
                      purpose="videoBiteV2"
                      indexform={index}
                      uploadedFileId={() =>
                        getFieldValue(`videoRecordings.${index}.videoBiteId`)
                      }
                      // uploadedFileName={uploadedFileName}
                      // uploadedFileUrl={simulationDesignByIdData[0].videoUrl}
                      withoutInitValue={true}
                      acceptExtention={'video/*'}
                      disabled={
                        simulationDesignByIdData.simulationStatusId === 'SPD' ||
                        simulationDesignByIdData.simulationStatusId === 'RDO'
                          ? false
                          : true
                      }
                      actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/video-bite/${username}`}
                    />
                  )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Bite Image">
              {listVideo && listVideo[index]
                ? getFieldDecorator(`videoRecordings.${index}.imageBiteId`, {
                    initialValue: listVideo[index].imageBiteId,
                  })(
                    <InputUploadReadOnly
                      placeholder="Upload Bite Image"
                      style={{ width: '80%', marginRight: 8 }}
                      purpose=""
                      uploadedFileId={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].imageBiteId) ||
                        ''
                      }
                      uploadedFileName={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].imageBiteName) ||
                        ''
                      }
                      uploadedFileUrl={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].imageBiteUrl) ||
                        ''
                      }
                      acceptExtention={'image/*'}
                      isDownload={true}
                      disabled={true}
                    />
                  )
                : getFieldDecorator(`videoRecordings.${index}.imageBiteId`, {
                    ...(simulationDesignByIdData.simulationStatusId ===
                      'SPD' && {
                        initialValue: null
                    }),
                  })(
                    <InputUpload
                      placeholder="Upload Bite Image"
                      style={{ width: '80%', marginRight: 8 }}
                      setIsErrorUpload={setIsErrorUpload}
                      isErrorUpload={isErrorUpload}
                      setUploadedIdFile={(val) =>
                        form.setFieldsValue({
                          videoRecordings: {
                            [index]: {
                              imageBiteId: val,
                            },
                          },
                        })
                      }
                      setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                      isErrorBeforeUpload={isErrorBeforeUpload}
                      purpose="imageBiteV2"
                      indexform={index}
                      uploadedFileId={() =>
                        getFieldValue(`videoRecordings.${index}.imageBiteId`)
                      }
                      // uploadedFileName={uploadedFileName}
                      // uploadedFileUrl={simulationDesignByIdData[0].videoUrl}
                      withoutInitValue={true}
                      acceptExtention={'image/*'}
                      disabled={
                        simulationDesignByIdData.simulationStatusId === 'SPD' ||
                        simulationDesignByIdData.simulationStatusId === 'RDO'
                          ? false
                          : true
                      }
                      actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/image-bite/${username}`}
                    />
                  )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Archform File">
              {listVideo && listVideo[index]
                ? getFieldDecorator(`videoRecordings.${index}.archformId`, {
                    initialValue: listVideo[index].archformId,
                  })(
                    <InputUploadReadOnly
                      placeholder="Upload Video Recording"
                      style={{ width: '80%', marginRight: 8 }}
                      purpose=""
                      uploadedFileId={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].archformId) ||
                        ''
                      }
                      uploadedFileName={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].archformName) ||
                        ''
                      }
                      uploadedFileUrl={
                        (listVideo &&
                          listVideo[index] &&
                          listVideo[index].archformUrl) ||
                        ''
                      }
                      acceptExtention={'video/*'}
                      isDownload={true}
                      disabled={true}
                    />
                  )
                : getFieldDecorator(`videoRecordings.${index}.archformId`)(
                    <InputUpload
                      placeholder="Upload Archform File"
                      style={{ width: '100%', marginRight: 8 }}
                      setIsErrorUpload={setIsErrorUpload}
                      isErrorUpload={isErrorUpload}
                      setUploadedIdFile={(val) =>
                        form.setFieldsValue({
                          videoRecordings: {
                            [index]: {
                              archformId: val,
                            },
                          },
                        })
                      }
                      setIsErrorBeforeUpload={setIsErrorBeforeUpload}
                      isErrorBeforeUpload={isErrorBeforeUpload}
                      purpose="archformV2"
                      uploadedFileId={() =>
                        getFieldValue(`videoRecordings.${index}.archformId`)
                      }
                      indexform={index}
                      withoutInitValue={true}
                      disabled={
                        simulationDesignByIdData.simulationStatusId === 'SPD' ||
                        simulationDesignByIdData.simulationStatusId === 'RDO'
                          ? false
                          : true
                      }
                      acceptExtention=".archform"
                      actionAPI={`${process.env.REACT_APP_API_URL}/api/simulation/files/upload/archform/${username}`}
                    />
                  )}
            </Form.Item>
          </Col>
        </Row>
        {listVideo && listVideo[index] && listVideo[index].doctorApprovalNote && (
          <Form.Item label="Revision Notes from Doctor">
            {getFieldDecorator(`videoRecordings.${index}.doctorApprovalNote`, {
              initialValue:
                (listVideo &&
                  listVideo[index] &&
                  listVideo[index].doctorApprovalNote) ||
                '',
            })(
              <TextArea
                placeholder="Revision Notes from Doctor"
                size="large"
                rows={4}
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        )}

        {simulationDesignByIdData.simulationStatusId !== 'SPD' && (
            <Form.Item label="Revision Notes from Designer">
              {getFieldDecorator(
                `videoRecordings.${index}.designerApprovalNote`,
                {
                  initialValue:
                    (listVideo &&
                      listVideo[index] &&
                      listVideo[index].designerApprovalNote) ||
                    '',
                }
              )(
                <TextArea
                  placeholder="Revision Notes from Designer"
                  size="large"
                  rows={4}
                  autoComplete="false"
                  disabled={ (simulationDesignByIdData.simulationStatusId !== 'RDO' ||  listVideo) && listVideo[index] ? true : false}
                  // {...listVideo && listVideo[index] && {disabled : true}}
                />
              )}
            </Form.Item>
          )}

        {listVideo && listVideo[index] && listVideo[index].patientApprovalNote && (
          <Form.Item label="Revision Notes from Patient">
            {getFieldDecorator(`videoRecordings.${index}.patientApprovalNote`, {
              initialValue:
                (listVideo &&
                  listVideo[index] &&
                  listVideo[index].patientApprovalNote) ||
                '',
            })(
              <TextArea
                placeholder="Revision Notes from Patient"
                size="large"
                rows={4}
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        )}

        <ModalPatientRevision
          isModalPatientOpen={modalPatientRevision}
          handleOnCancel={() => setModalPatientRevision((val) => !val)}
          handleOnOk={(e) => {
            listVideo[index].patientApprovalNote = getFieldValue(
              'patientApprovalNoteVal'
            );
            handleApproval(e, listVideo[index], 'revised', 'revisedBy');
            setModalPatientRevision((val) => !val);
          }}
          valRevisionPatient={getFieldValue(
            `patientApprovalNote[${modalPatientRevisionIndex}]`
          )}
          form={form}
        />

        {simulationDesignByIdData.simulationStatusId === 'WP' &&
          listVideo[index] &&
          listVideo[index].latestApprovalStatus === 'Approved' && (
            <Row
              className="simulation-design__video-recording-approval-btn mb-15"
              gutter={15}>
              <Col span={8}>
                <Button
                  icon="check-circle"
                  onClick={(e) => {
                    handleApproval(
                      e,
                      listVideo[index],
                      'approve',
                      'approvedBy'
                    );
                  }}
                  className={
                    listVideo[index].latestPatientApprovalStatus === 'Approved'
                      ? 'button-success--filled'
                      : 'button-success--outline'
                  }>
                  Approve by Patient
                </Button>
              </Col>

              <Col span={8}>
                <Button
                  icon="close-circle"
                  onClick={(e) => {
                    handleApproval(e, listVideo[index], 'reject', 'rejectedBy');
                  }}
                  className={
                    listVideo[index].latestPatientApprovalStatus === 'Rejected'
                      ? 'button-danger--filled'
                      : 'button-danger--outline'
                  }>
                  Reject by Patient
                </Button>
              </Col>

              <Col span={8}>
                <Button
                  icon="exclamation-circle"
                  onClick={() => {
                    setModalPatientRevision((val) => !val);
                    setModalPatientRevisionIndex(index);
                  }}
                  className={
                    listVideo[index].latestPatientApprovalStatus === 'Revised'
                      ? 'button-warning--filled'
                      : 'button-warning--outline'
                  }>
                  Revise by Patient
                </Button>
              </Col>
            </Row>
          )}
        {listVideo && (
          <Row>
            <div>Attachments</div>
            <Col span={12}>
              <Upload
                listType="picture-card"
                defaultFileList={remappedFileList}
                onPreview={handlePreview}
                showUploadList={{
                  showPreviewIcon: true,
                  showDownloadIcon: true,
                  showRemoveIcon: false,
                }}></Upload>
            </Col>
            <Modal
              title={previewName}
              visible={previewVisible}
              okText="Download"
              onOk={() => saveAs(previewImage)}
              onCancel={(val) => setPreviewVisible(!val)}>
              <img
                alt={`attachment-${index + 1}`}
                style={{ width: '100%' }}
                src={previewImage}
              />
            </Modal>
          </Row>
        )}
      </Panel>
    </Collapse>
  );
}

export default VideoRecordingItem;
