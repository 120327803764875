import api from './index';

export default {
  /*
  createAction(payload) {
    return api.post(`/action`, { admin: payload });
  },
  updateAction(payload) {
    return api.put(`/action`, { admin: payload });
  },
  */
  listAction() {
    return api.get(`/action/false`);
  },

  listActionById(id) {
    return api.get(`/action/${id}/false`);
  },

  listActionByFlowAndState(flowId, stateId, testFitValue, printType, orderId, ctdFlag) {
    if (ctdFlag) {
      return api.get(`/action/${flowId}/${stateId}/${testFitValue}/${printType}/${orderId}/${ctdFlag}`)
    } else {
      return api.get(`/action/${flowId}/${stateId}/${testFitValue}/${printType}/${orderId}`)
    }
  },

  createAction(id, name, stateId, nextStateId, flowId) {
    return api.post(`/action`, {
      actionId: id,
      actionName: name,
      stateId: stateId,
      nextStateId: nextStateId,
      flowId: flowId,
    });
  },

  updateAction(id, name, stateId, nextStateId, flowId) {
    return api.put(`/action`, {
      actionId: id,
      actionName: name,
      stateId: stateId,
      nextStateId: nextStateId,
      flowId: flowId,
    });
  },

  deleteAction(id) {
    return api.delete(`/action/${id}`);
  },

  deleteMultipleAction(id) {
    return api.delete(`action/bulk?actionId=${id}`);
  },
};
