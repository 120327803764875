import React from 'react';
import { Row, Col, Button, DatePicker, Select } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

export const Filter = ({
  resetFilter,
  innerRef,
  activeFilter,
  onOrderTypeChange,
  orderTypeOptions,
  onStartDateChange,
  onEndDateChange,
}) => {
  const { Option } = Select;

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  return (
    <div className="filter" ref={innerRef}>
      <Row type="flex" gutter={20} className="mb-15">
        <Col xs={12} type="flex" align="start">
          <div className="text-base">
            <strong>Filters</strong>
          </div>
        </Col>
        <Col xs={12} type="flex" align="end">
          <Button onClick={resetFilter} type="link">
            Reset
          </Button>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12} className="mb-20">
          <DatePicker
            disabledDate={disabledDate}
            defaultValue={
              activeFilter.startDate === '-'
                ? moment()
                : moment(activeFilter.startDate)
            }
            size="large"
            showTime
            placeholder="Start Date"
            format="YYYY-MM-DD"
            onChange={onStartDateChange}
            selectValue={activeFilter.startDate}
            allowClear={false}
          />
        </Col>
        <Col xs={12} className="mb-20">
          <DatePicker
            disabledDate={disabledDate}
            defaultValue={
              activeFilter.endDate === '-'
                ? moment()
                : moment(activeFilter.endDate)
            }
            size="large"
            showTime
            placeholder="End Date"
            format="YYYY-MM-DD"
            onChange={onEndDateChange}
            selectValue={activeFilter.endDate}
            allowClear={false}
          />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={12}>
          <Select
            defaultValue={activeFilter.orderType}
            size="large"
            style={{ width: 260 }}
            onChange={onOrderTypeChange}
            selectValue={
              activeFilter.orderType === '-' ? [] : activeFilter.orderType
            }>
            {orderTypeOptions.map((value, index) => (
              <Option value={value.value} key={index}>
                {value.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

Filter.propTypes = {
  onOrderTypeChange: PropTypes.func,
};

export default Filter;
