import api from './index';

export default {
  getEkanbanPrintLabel(orderId) {
    return api.get(`/e-kanban/print-label/${orderId}`);
  },

  getEkanbanBulkPrintLabel(orderId) {
    return api.get(`/e-kanban/print-label/bulk?orderId=${orderId}`);
  },

  getStationHistory(orderId) {
    return api.get(`/e-kanban/history/${orderId}`);
  },

  getStationState(orderType, startDate, endDate) {
    return api.get(`/e-kanban/state/${orderType}/${startDate}/${endDate}`);
  },

  updateEkanbanData(
    kanbanId,
    boxNumber,
    medicalRecord,
    clinic,
    doctor,
    printType,
    productionRound,
    upperCount,
    lowerCount,
    notes,
    testFit,
    trimLower,
    trimUpper,
    printNote,
    priorityNote,
    upperData,
    lowerData
  ) {
    return api.put(`/e-kanban/data`, {
      kanbanId: kanbanId,
      boxNumber: boxNumber,
      medicalRecord: medicalRecord,
      clinic: clinic,
      doctor: doctor,
      printType: printType,
      productionRound: productionRound,
      upperCount: upperCount,
      lowerCount: lowerCount,
      notes: notes,
      trimUpper: trimUpper,
      trimLower: trimLower,
      printNote: printNote,
      priorityNote: priorityNote,
      testFit: testFit,
      upperData: upperData,
      lowerData: lowerData,
    });
  },

  addEkanbanData(
    orderId,
    boxNumber,
    medicalRecord,
    clinic,
    doctor,
    printType,
    productionRound,
    upperCount,
    lowerCount,
    notes,
    testFit,
    trimLower,
    trimUpper,
    printNote,
    priorityNote,
    upperData,
    lowerData
  ) {
    return api.post(`/e-kanban/data`, {
      orderId: orderId,
      boxNumber: boxNumber,
      medicalRecord: medicalRecord,
      clinic: clinic,
      doctor: doctor,
      printType: printType,
      productionRound: productionRound,
      upperCount: upperCount,
      lowerCount: lowerCount,
      notes: notes,
      trimUpper: trimUpper,
      trimLower: trimLower,
      printNote: printNote,
      priorityNote: priorityNote,
      testFit: testFit,
      upperData: upperData,
      lowerData: lowerData,
    });
  },

  getEkanbanData(kanbanId) {
    return api.get(`/e-kanban/data/${kanbanId}`);
  },
  getEkanbanReport() {
    return api.get(`/e-kanban/report`);
  },
};
