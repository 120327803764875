import SimulationDesignApi from 'api/simulation-design';
import { setToArray } from 'utils';
import { isEmpty } from 'lodash';

import {
  GET_LIST_SIMULATION_DESIGN,
  GET_SIMULATION_DESIGN_BY_ID,
  GET_LIST_PATIENT_NAME_SIMULATION_DESIGN,
  GET_LIST_STATUS_SIMULATION_DESIGN,
  GET_LIST_SO_NUMBER_SIMULATION_DESIGN,
  GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN,
  GET_ORDER_TYPE_SIMULATION_DESIGN,
  GET_LIST_DOCTOR_SIMULATION_DESIGN,
  GET_LIST_DESIGNER_SIMULATION_DESIGN,
  GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN,
  GET_LIST_VIDEO_REVISION_HISTORY,
  GET_LIST_ARCHIVED_SIMULATION_HISTORY,
  GET_LIST_IMPRESSION_SIMULATION_HISTORY,
} from '../../type';

export const getListSimulationDesign = (simulationStatusById, dateFilter) => async (
  dispatch
) => {
  const { data } = await SimulationDesignApi.getSimulationListByStatus(
    simulationStatusById, dateFilter
  );

  dispatch({
    type: GET_LIST_SIMULATION_DESIGN,
    payload: !isEmpty(data.data) ? setToArray(data) : [],
  });
};

export const getListSimulationDesignV2 = (payload) => async (
  dispatch
) => {
  const { data } = await SimulationDesignApi.getSimulationListByStatusWithParam(payload);

  dispatch({
    type: GET_LIST_SIMULATION_DESIGN,
    payload: !isEmpty(data.data) ? data.data : {},
  });
}

export const getSimulationDesignById = (id) => async (dispatch) => {
  const response = await SimulationDesignApi.getSimulationDesignById(id);

  dispatch({
    type: GET_SIMULATION_DESIGN_BY_ID,
    payload: setToArray(response.data),
  });
};

export const getListStatusSimulation = () => async (dispatch) => {
  const response = await SimulationDesignApi.getStatusSimulationList();

  dispatch({
    type: GET_LIST_STATUS_SIMULATION_DESIGN,
    payload: setToArray(response.data),
  });
};

export const getListPatientNameSimulation = () => async (dispatch) => {
  const response = await SimulationDesignApi.getPatientNameSimulationList();

  dispatch({
    type: GET_LIST_PATIENT_NAME_SIMULATION_DESIGN,
    payload: setToArray(response.data),
  });
};

export const getListSoNumberSimulation = (patientName) => async (dispatch) => {
  const response = await SimulationDesignApi.getSoNumberSimulationList(
    patientName
  );

  dispatch({
    type: GET_LIST_SO_NUMBER_SIMULATION_DESIGN,
    payload: setToArray(response.data),
  });
};

export const getListVideoVersionSimulation = () => async (dispatch) => {
  const response = await SimulationDesignApi.getVideoVersionSimulationList();

  dispatch({
    type: GET_LIST_VIDEO_VERSION_SIMULATION_DESIGN,
    payload: setToArray(response.data),
  });
};

export const getOrderTypeSimulation = (soNumber) => async (dispatch) => {
  const { data } = await SimulationDesignApi.getOrderTypeSimulation(soNumber);

  dispatch({
    type: GET_ORDER_TYPE_SIMULATION_DESIGN,
    payload: data.data,
  });
};

export const getListDoctorSimulation = () => async (dispatch) => {
  const { data } = await SimulationDesignApi.getDoctorSimulationList();

  dispatch({
    type: GET_LIST_DOCTOR_SIMULATION_DESIGN,
    payload: data.data,
  });
};

export const getListDesignerSimulation = (stationId) => async (dispatch) => {
  const { data } = await SimulationDesignApi.getDesignerSimulationList(
    stationId
  );

  dispatch({
    type: GET_LIST_DESIGNER_SIMULATION_DESIGN,
    payload: data.data,
  });
};

export const getListAdminDesignerSimulation = (stationId) => async (dispatch) => {
  const { data } = await SimulationDesignApi.getDesignerSimulationList(
    stationId
  );

  dispatch({
    type: GET_LIST_ADMIN_DESIGNER_SIMULATION_DESIGN,
    payload: data.data,
  });
};

export const getListVideoRevisionHistory = (soNumber, simulationId) => async (
  dispatch
) => {
  const { data } = await SimulationDesignApi.getVideoRevisionHistory(
    soNumber,
    simulationId
  );

  dispatch({
    type: GET_LIST_VIDEO_REVISION_HISTORY,
    payload: data.data,
  });
};

export const getListArchivedSimulationHistory = (
  soNumber,
  simulationId
) => async (dispatch) => {
  const { data } = await SimulationDesignApi.getArchivedSimulationHistory(
    soNumber,
    simulationId
  );

  dispatch({
    type: GET_LIST_ARCHIVED_SIMULATION_HISTORY,
    payload: data.data,
  });
};

export const getListImpressionSimulationHistory = (soNumber) => async (
  dispatch
) => {
  const { data } = await SimulationDesignApi.getImpressionSimulationHistory(
    soNumber
  );

  dispatch({
    type: GET_LIST_IMPRESSION_SIMULATION_HISTORY,
    payload: data.data,
  });
};
