import OrderApi from 'api/order';
import { setToArray } from 'utils';

import { GET_LIST_DASHBOARD_BY_DATE } from '../../../type';

export const getListDashboardByDate =
  (currentDate, availableState) => async (dispatch) => {
    const response = await OrderApi.getSummaryDashboardByDate(
      currentDate,
      availableState
    );

    dispatch({
      type: GET_LIST_DASHBOARD_BY_DATE,
      payload: setToArray(response.data),
    });
  };
