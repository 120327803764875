import { isEmpty } from 'lodash';

export const headerCsv = [
  { label: 'ID', key: 'orderId' },
  { label: 'Name', key: 'orderName' },
  { label: 'Customer Name', key: 'customerName' },
  { label: 'Primary Order Number', key: 'orderReceiptNumber' },
  { label: 'Test Fit', key: 'testFitStatus' },
  { label: 'Removal Request Note', key: 'removalNote' },
];

export const tableColumns = [
  {
    title: 'ID',
    key: 'orderId',
    dataIndex: 'orderId',
    sorter: (a, b) => a.orderId.localeCompare(b.orderId),
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: (a, b) => (a.customerName === null) - (b.customerName === null),
  },
  {
    title: 'Primary Order Number',
    key: 'orderReceiptNumber',
    dataIndex: 'orderReceiptNumber',
    sorter: (a, b) =>
      (a.orderReceiptNumber === null) - (b.orderReceiptNumber === null),
  },
  {
    title: 'Test Fit',
    key: 'testFit',
    render: (row) => `${!isEmpty(row['testFit']) ? 'v' : '-'}`,
    sorter: (a, b) => a.testFit.localeCompare(b.testFit),
  },
  {
    title: 'Removal Request Note',
    key: 'removalNote',
    dataIndex: 'removalNote',
    sorter: (a, b) => a.removalNote.localeCompare(b.removalNote),
  },
];

export const testFitOptions = [
  { label: '-', value: null },
  { label: 'Test Fit', value: 'true' },
];
