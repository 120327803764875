/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Input, Form, Tooltip, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { capitalize } from 'lodash';

import SelectDropdown from 'components/SelectDropdown';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import AuthenticationApi from 'api/authentication';
import StateApi from 'api/state';
import { setToArray, setCreateEditMessage } from 'utils';
import { AuthenticationContext } from 'contexts/Authentication';

export function UserRoleEditComponent({ form, location, history }) {
  const { getFieldDecorator } = form;
  const { pathname } = location;
  const email = pathname.split('/').pop();
  const [userRole, setUserRole] = useState([]);
  const [userStation, setUserStation] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const { getUserByEmailData, userByEmail } = useContext(
    AuthenticationContext || {}
  );

  useEffect(() => {
    getUserByEmailData(email);
  }, []);

  useEffect(() => {
    async function getUserRoleData() {
      const { data } = await AuthenticationApi.getUserRole();
      setUserRole(setToArray(data));
    }

    getUserRoleData();
  }, []);

  useEffect(() => {
    async function getUserStationData() {
      const { data } = await StateApi.listStation();
      setUserStation(setToArray(data));
    }

    getUserStationData();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const getRoleId = userRole.find(
          (obj) => obj.roleName === values.roleName
        );

        const getStationId = userStation.find(
          (obj) => obj.stationName === values.stationName
        );

        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await AuthenticationApi.updateUser(
            values.userId,
            values.userName,
            values.userEmail,
            getRoleId.roleId,
            getStationId.stationId,
            values.roleName,
            values.stationName
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/administration/user',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating User Data with Email ' + email,
            'Error Updating User Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  const roleOptions = userRole.map((value) => ({
    label: value.roleName,
    value: value.roleName,
  }));

  const stationOptions = userStation.map((value) => ({
    label: `${value.stationId} - ${value.stationName}`,
    value: value.stationName,
  }));

  return (
    <>
      {!isEmpty(userByEmail) && (
        <>
          <DetailLayout
            detailTime={userByEmail ? capitalize(userByEmail[0].userName) : ''}
            detailTitle={'User'}
            className={'edit-form'}
            detailComponent={
              <Form onSubmit={(event) => handleSubmit(event)}>
                <Form.Item className="edit-form__submit">
                  <Tooltip title="Submit">
                    <Button
                      size="large"
                      htmlType="submit"
                      loading={loadingButton}
                      icon="check-circle"
                    />
                  </Tooltip>
                </Form.Item>
                <Form.Item label="ID">
                  {getFieldDecorator('userId', {
                    initialValue: userByEmail && userByEmail[0].userId,
                  })(
                    <Input
                      placeholder="ID"
                      size="large"
                      autoComplete="false"
                      disabled
                    />
                  )}
                </Form.Item>
                <Form.Item label="Name">
                  {getFieldDecorator('userName', {
                    initialValue: userByEmail && userByEmail[0].userName,
                  })(
                    <Input
                      placeholder="Name*"
                      size="large"
                      autoComplete="false"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Email">
                  {getFieldDecorator('userEmail', {
                    initialValue: userByEmail && userByEmail[0].userEmail,
                  })(
                    <Input
                      placeholder="Email*"
                      size="large"
                      autoComplete="false"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Role">
                  {getFieldDecorator('roleName', {
                    initialValue: userByEmail && userByEmail[0].roleName,
                  })(
                    <SelectDropdown
                      options={roleOptions}
                      placeHolder={'Select Role Name'}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Station">
                  {getFieldDecorator('stationName', {
                    initialValue: userByEmail && userByEmail[0].stationName,
                  })(
                    <SelectDropdown
                      options={stationOptions}
                      placeHolder={'Select Station Name'}
                    />
                  )}
                </Form.Item>
              </Form>
            }
          />
        </>
      )}
    </>
  );
}

export const UserRoleEditComponentForm = Form.create({
  name: 'validate_edit_user_role',
})(UserRoleEditComponent);

export default withRouter(UserRoleEditComponent);
