export const tableBoxColumns = [
  {
    title: 'Box Number',
    key: 'Box Number',
    dataIndex: 'boxNumber',
    sorter: (a, b) => a.boxNumber.localeCompare(b.boxNumber),
    sortDirections: ['descend', 'ascend'],
    width: 150,
  },
  {
    title: 'Box Status',
    key: 'Box Status',
    dataIndex: 'boxStatusId',
    sorter: (a, b) => a.boxStatusId.localeCompare(b.boxStatusId),
    sortDirections: ['descend', 'ascend'],
  },
];

export const tableOrderHistoryColumns = [
  {
    title: 'Order History Id',
    key: 'orderHistoryId',
    dataIndex: 'orderHistoryId',
    sorter: (a, b) => a.orderHistoryId.localeCompare(b.orderHistoryId),
    sortDirections: ['descend', 'ascend'],
    width: 250,
  },
  {
    title: 'Order Id',
    key: 'orderId',
    dataIndex: 'orderId',
    sorter: (a, b) => a.orderId.localeCompare(b.orderId),
    sortDirections: ['descend', 'ascend'],
  },
];

export const tableOrderShippingColumns = [
  {
    title: 'ID',
    key: 'ID',
    dataIndex: 'orderShippingId',
    sorter: (a, b) => a.orderShippingId.localeCompare(b.orderShippingId),
    sortDirections: ['descend', 'ascend'],
    width: 250,
  },
  {
    title: 'Name',
    key: 'shippingId1',
    dataIndex: 'shippingId1',
    sorter: (a, b) => a.shippingId1.localeCompare(b.shippingId1),
    sortDirections: ['descend', 'ascend'],
  },
];
