import { GET_LIST_DASHBOARD_BY_DATE } from '../../../type';

export const listDashboardByDate = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_DASHBOARD_BY_DATE:
      return action.payload;
    default:
      return state;
  }
};
