import React from 'react';
import { Modal, Form, Button, Input } from 'antd';
import SelectDropdown from 'components/SelectDropdown';

export const ModalCetakUlang = ({
  showModal,
  handleHideModalCetakUlang,
  setButtonState,
  form,
  // getOrderDetailList,
  // id
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitModalCetakUlang = () => {
    setButtonState(8);
  };

  const statesOptions = [
    { label: 'Rahang Atas', value: 'RA' },
    { label: 'Rahang Bawah', value: 'RB' },
    { label: 'Rahang Atas and Rahang Bawah', value: 'RARB' },
  ];

  return (
    <Modal
      title="Cetak Ulang"
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmitModalCetakUlang()}
      onCancel={() => handleHideModalCetakUlang()}
      footer={[
        <Button key="back" onClick={handleHideModalCetakUlang}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModalCetakUlang}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Cetak Ulang</div>
      <Form>
        <Form.Item>
          {getFieldDecorator('rahang', {
            initialValue: 'Select Cetak Ulang',
          })(
            <SelectDropdown
              options={statesOptions}
              placeHolder={'Select Cetak Ulang'}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('setBermasalah', {
            // initialValue: 'Insert RA: #Set Bermasalah / RB: #Set Bermasalah',
          })(
            <Input
              placeholder={'Insert RA: #Set Bermasalah / RB: #Set Bermasalah'}
              size="large"
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCetakUlang;
