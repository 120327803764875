import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Col, Input, Form, Row, Checkbox } from 'antd';

import { getPaymentDetailById } from 'store/action/PaymentDetailAction';

import './aligner-detail.scss';

export const AlignerDetail = ({ form, orderDetailData }) => {
  const { getFieldDecorator } = form;

  return (
    <div className="simulation-design__aligner-detail">
      <div className="color-text-primary text-md fw-bold mb-20">
        Aligner Detail
      </div>
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item label="Set Bermasalah RA">
            {getFieldDecorator('setBermasalahRa', {
              initialValue: !isEmpty(orderDetailData)
                ? orderDetailData.problemSetRa
                : '',
            })(
              <Input
                placeholder="Set Bermasalah RA"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Set Bermasalah RB">
            {getFieldDecorator('setBermasalahRb', {
              initialValue: !isEmpty(orderDetailData)
                ? orderDetailData.problemSetRb
                : '',
            })(
              <Input
                placeholder="Set Bermasalah RB"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={15}>
        {/* RA / RB Section */}
        <Col span={12}>
          <Form.Item
            label={
              <>
                RA <span className="dot-primary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('alignerRa', {
              initialValue: !isEmpty(orderDetailData) ? orderDetailData.ra : '',
              rules: [
                {
                  required:
                    !isEmpty(orderDetailData) &&
                    orderDetailData.simulationStatusId === 'SE',
                  message: 'Please input RA!',
                },
              ],
            })(
              <Input
                placeholder="Insert RA"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('raDetails', {
              initialValue:
                orderDetailData.raDetails &&
                orderDetailData.raDetails.split(','),
              ...(orderDetailData.simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(orderDetailData) &&
                      orderDetailData.simulationStatusId === 'SE',
                    message: 'Please input RA!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(101)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i}
                    className="mr-20 aligner-checkbox-detail"
                    index={i}
                    value={String(i)}
                    disabled>
                    {String(i)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('agUpper', {
              initialValue: !isEmpty(orderDetailData)
                ? orderDetailData.agUpper
                : null,
            })(
              <Checkbox
                size={'large'}
                className="aligner-checkbox-detail"
                checked={form.getFieldValue('agUpper') === true ? true : false}
                disabled>
                AG Upper
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                RB <span className="dot-primary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('alignerRb', {
              initialValue: !isEmpty(orderDetailData) ? orderDetailData.rb : '',
              rules: [
                {
                  required:
                    !isEmpty(orderDetailData) &&
                    orderDetailData.simulationStatusId === 'SE',
                  message: 'Please input RB!',
                },
              ],
            })(
              <Input
                placeholder="Insert RB"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('rbDetails', {
              initialValue:
                orderDetailData.rbDetails &&
                orderDetailData.rbDetails.split(','),
              ...(orderDetailData.simulationStatusId === 'AP' && {
                rules: [
                  {
                    required:
                      !isEmpty(orderDetailData) &&
                      orderDetailData.simulationStatusId === 'SE',
                    message: 'Please input RB!',
                  },
                ],
              }),
            })(
              <Checkbox.Group
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(101)].map((x, i) => (
                  <Checkbox
                    size={'large'}
                    key={i}
                    className="mr-20 aligner-checkbox-detail"
                    index={i}
                    disabled
                    value={String(i)}>
                    {String(i)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('agLower', {
              initialValue: !isEmpty(orderDetailData)
                ? orderDetailData.agLower
                : null,
            })(
              <Checkbox
                size={'large'}
                className="mr-20 aligner-checkbox-detail-OC"
                checked={form.getFieldValue('agLower') === true ? true : false}
                disabled>
                AG Lower
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item label="Total Aligner">
            {getFieldDecorator('totalAligner', {
              initialValue: orderDetailData.ra + orderDetailData.rb,
            })(
              <Input
                placeholder="Insert Total Aligner"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        </Col>

        {/* OC Section */}
        <Col span={12}>
          <Form.Item
            label={
              <>
                OC UPPER <span className="dot-secondary"></span>
              </>
            }
            className="mb-20">
            {getFieldDecorator('ocUp', {
              initialValue: orderDetailData.simDesOcUp,
            })(
              <Input
                placeholder="Insert OC Upper"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item className="mb-10">
            {getFieldDecorator('ocUpDetails', {
              initialValue:
                orderDetailData.ocUpDetails &&
                orderDetailData.ocUpDetails.split(','),
            })(
              <Checkbox.Group
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(100)].map((x, i) => (
                  <Checkbox
                    disabled
                    size={'large'}
                    key={i + 1}
                    className="mr-10 aligner-checkbox-detail-OC"
                    index={i + 1}
                    value={String(i + 1)}>
                    {String(i + 1)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                OC LOWER <span className="dot-secondary"></span>
              </>
            }
            style={{ marginTop: '6.1em' }}
            className="mb-20">
            {getFieldDecorator('ocDown', {
              initialValue: orderDetailData.simDesOcDown,
            })(
              <Input
                placeholder="Insert OC Lower"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('ocDownDetails', {
              initialValue:
                orderDetailData.ocDownDetails &&
                orderDetailData.ocDownDetails.split(','),
            })(
              <Checkbox.Group
                style={{
                  height: '3em',
                  width: '35vw',
                  overflowX: 'scroll',
                  whiteSpace: 'nowrap',
                }}>
                {[...Array(100)].map((x, i) => (
                  <Checkbox
                    disabled
                    size={'large'}
                    key={i + 1}
                    className="mr-10 aligner-checkbox-detail-OC"
                    index={i + 1}
                    value={String(i + 1)}>
                    {String(i + 1)}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
          <Form.Item label="Total OC" style={{ marginTop: '6.1em' }}>
            {getFieldDecorator('totalOC', {
              initialValue:
                orderDetailData.simDesOcUp + orderDetailData.simDesOcDown,
            })(
              <Input
                placeholder="Insert Total OC"
                size="large"
                autoComplete="false"
                disabled
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ paymentDetailByIdData }) => ({
  paymentDetailByIdData,
});

export default connect(mapStateToProps, {
  getPaymentDetailById,
})(Form.create()(AlignerDetail));
