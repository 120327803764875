import { GET_LIST_CLINIC } from '../../../type';

export const listClinic = (state = [], action) => {
  switch (action.type) {
    case GET_LIST_CLINIC:
      return action.payload;
    default:
      return state;
  }
};
