/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip, DatePicker } from 'antd';
import moment from 'moment';

import DetailLayout from 'components/detail-layout/DetailReadOnly';
import { setCreateEditMessage, setToArray } from 'utils';
import OrderApi from 'api/order';
import ShippingApi from 'api/shipping';
import SelectDropdown from 'components/SelectDropdown';

export function OrderShippingCreateEdit({ form, history }) {
  const [shippings, setShippings] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [shippingById, setShippingById] = useState({});

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.updateOrderShipping(
            shippingById.osIncId,
            id,
            values.shippingReceiptNumber1,
            values.shippingId1,
            values.shippingAddress1,
            values.shippingReceiver1,
            values.shippingAt1
              ? isObjectDate(values['shippingAt1'].format('DD-MM-YYYY'))
              : null
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/transaction/order-shipping',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Berhasil mengubah data order shipping dengan order shipping ID ' +
              id,
            'ERROR UPDATING DATA'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getShippingList() {
      const response = await ShippingApi.listShipping();
      let shippingList = setToArray(response.data);

      setShippings(shippingList);
    }

    getShippingList();
  }, []);

  useEffect(() => {
    async function getShippingById() {
      const response = await OrderApi.listOrderShippingById(id);
      let shippingData = setToArray(response.data);

      setShippingById(shippingData[0]);
    }

    getShippingById();
  }, []);

  const shippingOptions = shippings.map((value) => ({
    label: value.shippingId,
    value: value.shippingId,
  }));

  function isObjectDate(date) {
    let theDate;
    if (typeof date !== 'undefined' && date !== null) {
      date instanceof Date
        ? (theDate =
            date.getFullYear() +
            '-' +
            parseInt(date.getMonth() + 1) +
            '-' +
            date.getDate())
        : (theDate =
            date.substring(6, 10) +
            '-' +
            date.substring(3, 5) +
            '-' +
            date.substring(0, 2));
    } else {
      theDate = undefined;
    }
    return theDate;
  }

  return (
    <DetailLayout
      detailTime={`${id}`}
      detailTitle={'Edit Order Shipping Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="Order Shipping ID">
            {getFieldDecorator('orderShippingId', {
              rules: [
                {
                  required: true,
                  message: 'Please input Order Shipping ID!',
                },
              ],
              initialValue: shippingById && shippingById.orderShippingId,
            })(
              <Input
                placeholder="Order Shipping ID"
                autoComplete="false"
                size="large"
                disabled
              />
            )}
          </Form.Item>
          <Form.Item label="Receipt Number 1">
            {getFieldDecorator('shippingReceiptNumber1', {
              initialValue: shippingById && shippingById.shippingReceiptNumber1,
            })(
              <Input
                placeholder="Receipt Number 1"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>
          <Form.Item label="Shipping Courier 1">
            {getFieldDecorator('shippingId1', {
              rules: [
                {
                  required: true,
                  message: 'Please input shipping courier 1!',
                },
              ],
              initialValue: shippingById && shippingById.shippingId1,
            })(
              <SelectDropdown
                options={shippingOptions}
                placeHolder={'Select Shipping Courier'}
              />
            )}
          </Form.Item>

          <Form.Item label="Address 1">
            {getFieldDecorator('shippingAddress1', {
              initialValue: shippingById && shippingById.shippingAddress1,
            })(
              <Input
                placeholder="Address 1"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="Receiver 1">
            {getFieldDecorator('shippingReceiver1', {
              initialValue: shippingById && shippingById.shippingReceiver1,
            })(
              <Input
                placeholder="Receiver 1"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>
          <Form.Item label="Shipping At 1">
            {getFieldDecorator('shippingAt1', {
              ...(shippingById.shippingAt1
                ? {
                    initialValue: moment(
                      shippingById.shippingAt1,
                      'DD-MM-YYYY'
                    ),
                  }
                : {}),
            })(
              <DatePicker
                size="large"
                showTime
                placeholder="Click to select a date"
                format="DD-MM-YYYY"
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const OrderShippingForm = Form.create({
  name: 'validate_create_order-shipping',
})(OrderShippingCreateEdit);

export default { OrderShippingCreateEdit };
