export const ORDER_RATAPLUS_COLUMNS = [
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    width: 200,
  },
  {
    title: 'Primary Order Number',
    dataIndex: 'orderReceiptNumber',
    key: 'orderReceiptNumber',
    sorter: true,
    width: 200,
  },
  {
    title: 'Patient Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sorter: true,
    width: 150,
  },
  {
    title: 'Order Type',
    render: (row) => `${row['orderType']} - ${row['orderDesc']} ${row['orderFull']}`,
    key: 'orderType',
    sorter: true,
    width: 150,
  },
  {
    title: 'Current State',
    render: (row) => `${row['stateId']} - ${row['stateName']}`,
    key: 'currentState',
    sorter: true,
    width: 150,
  },
  {
    title: 'Test Fit',
    dataIndex: 'testFitStatus',
    key: 'testFitStatus',
    sorter: true,
  },
  {
    title: 'Overdue',
    key: 'deadlineStatus',
    sorter: true,
    render: (row) => (row['deadlineStatus'] === 'Overdue' ? 'Overdue' : '-'),
  },
  {
    title: 'Over Correct',
    key: 'ocChecklist',
    sorter: true,
    render: (row) => (row['ocChecklist'] ? 'Yes' : 'No'),
  },
];