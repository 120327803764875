import {
  GET_LIST_OVERDUE_ORDER,
  GET_OVERDUE_ORDER_NUMBER,
} from '../../../type';

export const listOverdueOrder = (state = {}, action) => {
  switch (action.type) {
    case GET_LIST_OVERDUE_ORDER:
      return action.payload;
    default:
      return state;
  }
};

export const overdueNumber = (state = {}, action) => {
  switch (action.type) {
    case GET_OVERDUE_ORDER_NUMBER:
      return action.payload;
    default:
      return state;
  }
};
