import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { Col, Modal, Row, Upload } from 'antd';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function DoctorAttachmentList({ simulationDesignByIdData }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewName, setPreviewName] = useState('');

  return (
    <Row>
      <div>Attachments</div>
      <Col span={12}>
        <Upload
          listType="picture-card"
          defaultFileList={
            simulationDesignByIdData &&
            simulationDesignByIdData[0].attachmentList &&
            simulationDesignByIdData[0].attachmentList.map((val) => ({
              uid: val.fileId,
              status: val.active,
              name: val.fileName,
              url: val.fileUrl,
            }))
          }
          onPreview={async (file) => {
            if (!file.url && !file.preview) {
              file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage(file.url);
            setPreviewVisible(true);
            setPreviewName(file.name);
          }}
          showUploadList={{
            showPreviewIcon: true,
            showDownloadIcon: true,
            showRemoveIcon: false,
          }}></Upload>
      </Col>
      <Modal
        title={previewName}
        visible={previewVisible}
        okText="Download"
        onOk={() => saveAs(previewImage)}
        onCancel={(val) => setPreviewVisible(!val)}>
        <img alt={`attachment`} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Row>
  );
}

export default DoctorAttachmentList;
