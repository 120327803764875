/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import SelectDropdown from 'components/SelectDropdown';
import ClinicNoteApi from 'api/clinic-note';
import { setCreateEditMessage, convertOptions } from 'utils';
import { getListClinicNote } from 'store/action/ClinicNoteAction';

export const ModalAddData = ({
  form,
  history,
  showModalAddData,
  handleHideModalAddData,
  getClinicSubStatusList,
  setShowModalAddData,
  clinicNoteListData,
  getListClinicNote,
}) => {
  const { getFieldDecorator } = form;

  useEffect(() => {
    async function getClinicNoteList() {
      await getListClinicNote();
    }

    getClinicNoteList();
  }, []);

  const handleSubmitAddData = () => {
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          //   setLoadingButton(true);
          let theResponse;

          const getSpecificStatusClinicNoteData = clinicNoteListData.find(
            (statusValue) =>
              statusValue.noteStatusDesc === values.clinicParentName
          );

          theResponse = await ClinicNoteApi.createClinicSubStatus(
            values.clinicSubStatusId,
            values.clinicSubStatusName,
            getSpecificStatusClinicNoteData.noteStatusId
          );

          history.push({
            pathname: '/cms/master/clinic-sub-status',
          });

          setCreateEditMessage(
            theResponse.data,
            'Success Inserting Clinic Sub Status Data',
            'Error Inserting Clinic Sub Status Data!'
          );
          getClinicSubStatusList();

          setShowModalAddData(false);
          form.resetFields();
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          //   setLoadingButton(false);
        }
      }
    });
  };

  return (
    <Modal
      title="Add New Clinic Sub Status Data"
      centered
      visible={showModalAddData}
      onOk={() => handleSubmitAddData()}
      onCancel={() => handleHideModalAddData()}
      footer={[
        <Button key="back" onClick={handleHideModalAddData}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitAddData}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Input New Clinic Sub Status Data.</div>
      <Form>
        <Form.Item label="ID">
          {getFieldDecorator('clinicSubStatusId', {
            rules: [
              {
                required: true,
                max: 5,
                message: 'Please input Clinic Sub Status ID!',
              },
            ],
          })(<Input size="large" placeholder="Clinic Sub Status ID*" />)}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('clinicSubStatusName', {
            rules: [
              {
                required: true,
                message: 'Please input Clinic Sub Status name!',
              },
            ],
          })(<Input size="large" placeholder="Clinic Sub Status Name*" />)}
        </Form.Item>
        <Form.Item label="Parent ID">
          {getFieldDecorator('clinicParentName', {
            rules: [
              {
                required: true,
                message: 'Please input Parent ID!',
              },
            ],
          })(
            <SelectDropdown
              options={
                !isEmpty(clinicNoteListData)
                  ? convertOptions(clinicNoteListData, 'noteStatusDesc')
                  : []
              }
              placeHolder={'Select Status'}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ clinicNoteListData }) => ({ clinicNoteListData });

export default withRouter(
  connect(mapStateToProps, {
    getListClinicNote,
  })(Form.create()(ModalAddData))
);
