import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { SudahExportTableColumn } from './helper/SudahExportTableColumn';

import { TableDataTitle } from './components';

import './TableDataContainer.scss';

function TableDataContainer({
  listActionDispatcher,
  dataSource,
  total,
  simulationStatusId,
  handleRowOnClick,
  tableTitle,
  rowKey
}) {
  // lmit default to 10
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('created_at');
  const [sort, setSort] = useState('descend');
  // searchBy waiting for API
  const [searchBy, setSearchBy] = useState();
  // filterBy waiting for API, NOT IMPLEMENTED YET
  // eslint-disable-next-line
  const [filterBy, setFilterBy] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function actionDispatch() {
      setIsLoading(true);
      if (
        typeof listActionDispatcher == 'function' &&
        simulationStatusId !== ''
      )
        await listActionDispatcher({
          limit,
          offset,
          filterBy,
          sortBy,
          sort,
          searchBy,
          page,
          simulationStatusId,
        });
      setIsLoading(false);
    }

    actionDispatch();
    // eslint-disable-next-line
  }, [
    limit,
    offset,
    filterBy,
    sortBy,
    sort,
    searchBy,
    page,
    simulationStatusId,
  ]);

  const handleSearch = (val) => {
    setSearchBy(val);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setOffset((pagination.current - 1) * 10);
    setSortBy(sorter.columnKey);
    setSort(sorter.order);
  };

  return (
    <div className={`panel`}>
      <TableDataTitle
        tableName={tableTitle}
        handleSearch={(val) => handleSearch(val)}
      />
      <Table
        currentPage={page}
        columns={SudahExportTableColumn}
        dataSource={dataSource}
        scroll={{ x: 1650, y: 300 }}
        className="table-global__table-item"
        onChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter)
        }
        pagination={{ total: total }}
        loading={isLoading}
        onRow={(record, rowIndex) => ({
          onClick: () => handleRowOnClick(record, rowIndex),
        })}
        rowKey={rowKey}
      />
    </div>
  );
}

TableDataContainer.propTypes = {
  listActionDispatcher: PropTypes.func,
  dataSource: PropTypes.array,
  total: PropTypes.number,
  simulationStatusId: PropTypes.string,
  handleRowOnClick: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  rowKey: PropTypes.string.isRequired
};

export default TableDataContainer;
