/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Layout, message, Modal } from 'antd';
import { withRouter } from 'react-router-dom';

import TableGlobal from 'components/Table';
import { PageSpinner } from 'components/PageSpinner';
import OrderApi from 'api/order';
import { AuthenticationContext } from 'contexts/Authentication';
import { setToArray } from 'utils';

import Filter from './components/Filter';
import ActiveFilter from './components/ActiveFilter';
import { activeOrderColumns, headerActiveExportCsv } from '../helper';
import '../styles/index.scss';

const { Content } = Layout;
const { confirm } = Modal;

const ORDER_TYPE_RATA_PLUS = [
  '16071',
  '16072',
  '16051',
  '16052',
  '16041',
  '16052',
];

export function ActiveOrderList() {
  const [activeOrder, setActiveOrders] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const [activeFilter, setActiveFilter] = useState({
    orderType: [], //Order type
    stateId: [], //Current State
    deadlineStatus: [], //Overdue
    orderStatusDesc: [], //Flagged
    printTypeString: [], //Print Type
    stateName: [],
    orderDesc: [],
    testFitStatus: [],
  });
  const [filterShow, setFilterShow] = useState(false);
  const innerRef = useRef(null);

  const [editTable, setEditTable] = useState(false);
  const [rowSelected, setRowSelected] = useState([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [currentStateOptions, setCurrentStateOptions] = useState([]);
  const [testFitOptions, setTestFitOptions] = useState([]);
  const [printTypeOptions, setPrintTypeOptions] = useState([]);
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState({
    orderOpt: [],
  });
  const [currentStateFilterValue, setCurrentStateFilterValue] = useState({
    currentStateOpt: [],
  });
  const { permissionDataList } = useContext(AuthenticationContext || {});
  const { opEditData } = permissionDataList;

  const available_state = localStorage.getItem('available_state');

  async function getActiveOrderList() {
    try {
      const response = await OrderApi.getActiveOrder(available_state);
      let activeOrderList = setToArray(response.data);

      setActiveOrders(activeOrderList);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getActiveOrderList();
  }, []);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const onOrderTypeChange = (checkedValues) => {
    const handleOrderType = checkedValues.map((value) => value.split(' ')[0]);
    const handleOrderDesc = checkedValues.map((value) =>
      value.split('-').pop().replace(' ', '')
    );
    setActiveFilter({
      ...activeFilter,
      orderType: handleOrderType,
      orderDesc: handleOrderDesc,
    });

    setOrderTypeFilterValue({
      orderOpt: checkedValues,
    });
  };

  const onCurrentStateChange = (checkedValues) => {
    const handleStateId = checkedValues.map((value) => value.split(' ')[0]);
    const handleStateName = checkedValues.map((value) =>
      value.substring(value.indexOf('-') + 1).replace(' ', '')
    );

    setActiveFilter({
      ...activeFilter,
      stateId: handleStateId,
      stateName: handleStateName,
    });

    setCurrentStateFilterValue({
      currentOpt: checkedValues,
    });
  };

  const onOverdueChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      deadlineStatus: checkedValues,
    });
  };

  const onFlaggedChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      orderStatusDesc: checkedValues,
    });
  };

  const onTestFitChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      testFitStatus: checkedValues,
    });
  };

  const onPrintTypeChange = (checkedValues) => {
    setActiveFilter({
      ...activeFilter,
      printTypeString: checkedValues,
    });
  };

  const resetFilter = () => {
    setActiveFilter({});
    setCurrentStateFilterValue({});
    setOrderTypeFilterValue({});
  };

  useEffect(() => {
    const orderTypeOpts = activeOrder.map((value) => ({
      label: `${value.orderType} - ${value.orderDesc}`,
      value: `${value.orderType} - ${value.orderDesc}`,
    }));

    const handleDuplicateOrderTypeOpts = orderTypeOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    const currentStateOpts = activeOrder.map((value) => ({
      label: `${value.stateId} - ${value.stateName}`,
      value: `${value.stateId} - ${value.stateName}`,
    }));

    const handleDuplicateCurrentStateOpts = currentStateOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    const testFitOpts = activeOrder.map((value) => ({
      label: value.testFitStatus,
      value: value.testFitStatus,
    }));

    const handleDuplicateTestFitOpts = testFitOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    const printTypeOpts = activeOrder.map((value) => ({
      label:
        value.printType === 0
          ? 'Putty'
          : value.printType === 1
          ? '3D Scan'
          : '-',
      value: value.printType === 0 ? 0 : value.printType === 1 ? 1 : null,
    }));

    const handleDuplicatPrintTypeOpts = printTypeOpts.filter(
      (value, index, array) =>
        array.findIndex((t) => t.label === value.label) === index
    );

    setCurrentStateOptions(handleDuplicateCurrentStateOpts);
    setOrderTypeOptions(handleDuplicateOrderTypeOpts);
    setTestFitOptions(handleDuplicateTestFitOpts);
    setPrintTypeOptions(handleDuplicatPrintTypeOpts);
  }, [activeOrder]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const activeFilterComponent = () => {
    return (
      <ActiveFilter
        activeFilterData={activeFilter}
        setActiveFilter={setActiveFilter}
        setFilterShow={setFilterShow}
        setCurrentStateFilterValue={setCurrentStateFilterValue}
        setOrderTypeFilterValue={setOrderTypeFilterValue}
        orderTypeFilterValue={orderTypeFilterValue}
        currentStateFilterValue={currentStateFilterValue}
      />
    );
  };

  const filterComponent = () => {
    return (
      <Filter
        resetFilter={resetFilter}
        onOrderTypeChange={onOrderTypeChange}
        onCurrentStateChange={onCurrentStateChange}
        onOverdueChange={onOverdueChange}
        onFlaggedChange={onFlaggedChange}
        onTestFitChange={onTestFitChange}
        innerRef={innerRef}
        orderTypeOptions={orderTypeOptions}
        currentStateOptions={currentStateOptions}
        testFitOptions={testFitOptions}
        activeFilter={activeFilter}
        currentStateFilterValue={currentStateFilterValue}
        orderTypeFilterValue={orderTypeFilterValue}
        onPrintTypeChange={onPrintTypeChange}
        printTypeOptions={printTypeOptions}
      />
    );
  };

  const handleDeleteRow = async () => {
    const getOrderId = rowSelected.map((value) => value.orderId);
    const getPrimaryOrderNumber = rowSelected.map(
      (value) => value.orderReceiptNumber
    );

    const getOrderIdToString = getOrderId.join(',');
    const getPrimaryOrderNumberToString = getPrimaryOrderNumber.join(',');

    confirm({
      title: `Are you sure delete this active order data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then active order data with primary order number ${getPrimaryOrderNumberToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingActiveOrder() {
          try {
            await OrderApi.deleteOrderBulk(getOrderIdToString);
            setRowSelected([]);
            getActiveOrderList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingActiveOrder();
      },
      onCancel() {},
    });
  };

  const handleOnRowPathname = (record) => {
    return `/cms/transaction/order-detail/edit?id=${record.orderId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Active Orders'}
          inputPlaceholder={'Search Active Order Here...'}
          pageSize={8}
          headerCsv={headerActiveExportCsv}
          originalData={activeOrder}
          csvName={'active-order-list-export.csv'}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={activeOrderColumns}
          editTable={editTable}
          handleEditTable={handleEditTable}
          editableTable={opEditData ? true : false}
          activeFilterComponent={activeFilterComponent()}
          showFilter={filterShow}
          setShowFilter={setFilterShow}
          innerRef={innerRef}
          scrollX={2000}
          filterComponent={filterComponent()}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          recordKey={(record) => record.orderId}
          handleDeleteRow={handleDeleteRow}
          rowClassName={(record) =>
            ORDER_TYPE_RATA_PLUS.includes(record.orderType) ||
            record.baseAlignerPlus
              ? 'rataplus-row ant-table-row--global'
              : 'ant-table-row--global'
          }
        />
      </Content>
    </Layout>
  );
}

export default withRouter(ActiveOrderList);
