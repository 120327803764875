/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { withRouter } from 'react-router';

import { setToArray } from 'utils';
import AuthenticationApi from 'api/authentication';

export const AuthenticationContext = createContext();

function AuthenticationContextProvider({ children, location }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [permissionDataList, setPermissionDataList] = useState({});
  const [userByEmail, setUserByEmail] = useState({});
  const [loginData, setLoginData] = useState({});
  const { pathname } = location;

  useEffect(() => {
    localStorage.getItem('cms_token') && setAuthenticated(true);

    return () => {
      setAuthenticated(false);
    };
  }, []);

  async function login(payload) {
    const { data } = await AuthenticationApi.login(payload);
    const accessToken = get(data.data, 'access_token', null);
    const roleId = get(data.data, 'roleId', '');
    const userName = get(data.data, 'userName', '');
    const availableState = get(data.data, 'availableState', []);
    const userEmail = get(data.data, 'userEmail', '');
    const stationId = get(data.data, 'stationId', '');
    setLoginData(data.data);

    if (accessToken && roleId) {
      localStorage.setItem('cms_token', accessToken);
      localStorage.setItem('role_id', roleId);
      localStorage.setItem('username', userName);
      localStorage.setItem('available_state', availableState);
      localStorage.setItem('user_email', userEmail);
      localStorage.setItem('stationId', stationId);

      setAuthenticated(true);
    }
  }

  async function permissionList(roleId) {
    const { data } = await AuthenticationApi.permissions(roleId);
    setPermissionDataList(setToArray(data)[0]);
  }

  async function getUserByEmailData(email) {
    const { data } = await AuthenticationApi.getUserByEmail(email);
    setUserByEmail(setToArray(data));
  }

  useEffect(() => {
    if (!isEmpty(loginData) || localStorage.getItem('role_id')) {
      permissionList(loginData.roleId || localStorage.getItem('role_id'));
    }

    if (
      (!isEmpty(loginData) || localStorage.getItem('user_email')) &&
      !pathname.includes('/administration/user')
    ) {
      getUserByEmailData(
        loginData.userEmail || localStorage.getItem('user_email')
      );
    }
  }, [loginData]);

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        login,
        permissionDataList,
        loginData,
        userByEmail,
        getUserByEmailData,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default withRouter(AuthenticationContextProvider);
