import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import ActionApi from 'api/action';
import { setToArray } from 'utils';
import TableGlobal from 'components/Table';
import { PageSpinner } from 'components/PageSpinner';

import { tableActionColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function ActionList() {
  const [actions, setActions] = useState([]);

  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });

  const [rowSelected, setRowSelected] = useState([]);
  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  useEffect(() => {
    getActionList();
  }, []);

  async function getActionList() {
    try {
      const response = await ActionApi.listAction();
      let actionList = setToArray(response.data);

      setActions(actionList);
    } finally {
      setLoadingPage(false);
    }
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getActionId = rowSelected.map((value) => value.actionId);
    const getActionIdToString = getActionId.join(',');

    confirm({
      title: `Are you sure delete this action data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then action data with id ${getActionIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingAction() {
          try {
            await ActionApi.deleteMultipleAction(getActionIdToString);
            setRowSelected([]);
            getActionList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingAction();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return (
      <></>
      // <ActiveFilter
      //   setShowFilter={setShowFilter}
      //   activeFilterData={activeFilter}
      //   setActiveFilter={setActiveFilter}
      // />
    );
  };

  const filterComponent = () => {
    return (
      <></>
      // <Filter
      //   resetFilter={resetFilter}
      //   activeFilter={activeFilter}
      //   innerRef={innerRef}
      // />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/master/action/edit?id=${record.actionId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : Action'}
          inputPlaceholder={'Search Action Here...'}
          pageSize={8}
          originalData={actions}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableActionColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          recordKey={(record) => record.actionId}
          rowSelected={rowSelected}
          handleDeleteRow={handleDeleteRow}
          isFilter={false}
          setRowSelected={setRowSelected}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getActionList={getActionList}
      />
    </Layout>
  );
}

export default { ActionList };
