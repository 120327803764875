import React from 'react';
import { Form, Checkbox, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

export const SimulationDetail = ({ form, orderDetailData }) => {
  const { getFieldDecorator } = form;

  return (
    <div className="order-detail__simulation">
      <div className="color-text-primary text-md fw-bold mb-20">
        Simulation Design Detail
      </div>

      <Link
        size="large"
        className="order-detail__simulation-btn text-base"
        to={`${
          orderDetailData.stateId === '1040'
            ? '/cms/simulation-design/pengecekan-sebelum-design'
            : orderDetailData.stateId === '1041n'
            ? '/cms/simulation-design/sedang-proses-design'
            : orderDetailData.stateId === '1042n'
            ? '/cms/simulation-design/sedang-review-dokter'
            : orderDetailData.stateId === '1043'
            ? '/cms/simulation-design/approved-doctor'
            : orderDetailData.stateId === '1044'
            ? '/cms/simulation-design/sedang-review-pasien'
            : orderDetailData.stateId === '1045'
            ? '/cms/simulation-design/approved-pasien'
            : '/cms/simulation-design/sudah-export'
        }/edit?id=${orderDetailData.simulationId}`}>
        Go to Simulation Design
      </Link>

      <Row gutter={10} className="mt-35">
        <Col span={8}>
          <Form.Item className="mb-20">
            {getFieldDecorator('threeDScanFileMinimumFormat', {
              initialValue:
                orderDetailData && orderDetailData.threeDScanFileMinimumFormat,
              valuePropName: 'checked',
              rules: [
                {
                  required: false,
                  transform: (value) => value || undefined,
                  type: 'boolean',
                  message: 'Please fill this checkbox!',
                },
              ],
            })(
              <Checkbox size={'large'} value={true} disabled>
                3D Scan file meets minimum format requirements
              </Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={9}>
          {' '}
          <Form.Item className="mb-20">
            {getFieldDecorator('threeDScanFileMinimumStandard', {
              initialValue:
                orderDetailData &&
                orderDetailData.threeDScanFileMinimumStandard,
              valuePropName: 'checked',
              rules: [
                {
                  required: false,
                  transform: (value) => value || undefined,
                  type: 'boolean',
                  message: 'Please fill this checkbox!',
                },
              ],
            })(
              <Checkbox size={'large'} value={true} disabled>
                3D scan file meets minimum standard requirements
              </Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={7} className="mb-20">
          <Form.Item>
            {getFieldDecorator('puttyScanFile', {
              initialValue: orderDetailData && orderDetailData.puttyScanFile,
              valuePropName: 'checked',
              rules: [
                {
                  required: false,
                  transform: (value) => value || undefined,
                  type: 'boolean',
                  message: 'Please fill this checkbox!',
                },
              ],
            })(
              <Checkbox size={'large'} value={true} disabled>
                Putty scan file
              </Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create()(SimulationDetail);
