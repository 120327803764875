import React from 'react';
import { Modal, Form, Button } from 'antd';
import SelectDropdown from 'components/SelectDropdown';

export const ModalTroubleshoot = ({
  showModal,
  handleHideModalTroubleshoot,
  setButtonState,
  form,
}) => {
  const { getFieldDecorator } = form;

  const handleSubmitModalTroubleshoot = () => {
    setButtonState(8);
  };

  const statesOptions = [
    { label: 'Rahang Atas', value: 'RA' },
    { label: 'Rahang Bawah', value: 'RB' },
    { label: 'Rahang Atas and Rahang Bawah', value: 'RARB' },
  ];

  return (
    <Modal
      title="Troubleshoot"
      visible={showModal}
      getContainer={() => document.getElementById('form-detail-order')}
      onOk={() => handleSubmitModalTroubleshoot()}
      onCancel={() => handleHideModalTroubleshoot()}
      footer={[
        <Button key="back" onClick={handleHideModalTroubleshoot}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleSubmitModalTroubleshoot}>
          Ok
        </Button>,
      ]}>
      <div className="mb-25">Troubleshoot</div>
      <Form>
        <Form.Item>
          {getFieldDecorator('rahang', {
            initialValue: 'Select Type',
          })(
            <SelectDropdown
              options={statesOptions}
              placeHolder={'Select Type'}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalTroubleshoot;
