import React from 'react';
// antd ---------
import { List, message, Typography } from 'antd';
import ModalFullScreen from 'components/ModalFullScreen';
import OrderApi from 'api/order';
import { useHistory } from 'react-router-dom';

const data = ['Ya', 'Tidak'];

function ModalConfirmCetakUlang({
  showModal,
  handleHideModal,
  values,
  handleModalProgress,
}) {
  const history= useHistory();
  const handleClick = async (e) => {
    if (e === 'Tidak') {
      let resp = await OrderApi.confirmCetakUlang(values);

      if (resp.data.status === "FAILED") {
        message.error(resp.data.desc)
      } else {
        message.success("Confirm Cetak Ulang Berhasil")
        history.push('/cms/order/active')
      }
      handleHideModal();
    }
    if (e === 'Ya') {
      handleHideModal();
      handleModalProgress();
    }
  };
  return (
    <ModalFullScreen
      showModal={showModal}
      handleHideModal={handleHideModal}
      modalComponent={
        <>
          <List
            size="large"
            className="refine-header"
            header={
              <Typography.Text style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Apakah order butuh cetak ulang?
              </Typography.Text>
            }
            footer={<div style={{ padding: '1em' }}></div>}
            bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item onClick={() => handleClick(item)} value={item}>
                {item}
              </List.Item>
            )}
          />
        </>
      }
    />
  );
}

export default ModalConfirmCetakUlang;
