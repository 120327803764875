import React, { useEffect, useState } from 'react';
import { message, Button, Form, Input, Tooltip } from 'antd';

import OrderApi from 'api/order';
import FlowApi from 'api/flow';
import { setToArray, setCreateEditMessage } from 'utils';
import DetailLayout from 'components/detail-layout/DetailReadOnly';
import SelectDropdown from 'components/SelectDropdown';

import './style.scss';

export function OrderTypeCreateEdit({ form, history }) {
  const [orderTypeById, setOrderTypeById] = useState({});
  const [flows, setFlows] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);
          let theResponse;

          theResponse = await OrderApi.updateOrderType(
            id,
            values.orderDesc,
            values.flowId
          );

          if (theResponse.data.status !== 'FAILED') {
            history.push({
              pathname: '/cms/master/order-type',
            });
          }

          setCreateEditMessage(
            theResponse.data,
            'Success Updating Order Type Data with ID ' + id,
            'Error Updating Order Type Data!'
          );
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  useEffect(() => {
    async function getOrderTypeById() {
      const response = await OrderApi.getOrderTypeById(id);
      let orderTypeData = setToArray(response.data);

      setOrderTypeById(orderTypeData[0]);
    }

    getOrderTypeById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getFlowList() {
      const response = await FlowApi.listFlow();
      let flowList = setToArray(response.data);

      setFlows(flowList);
    }

    getFlowList();
  }, []);

  const flowOptions = flows.map((value) => ({
    label: `${value.flowId} - ${value.flowName}`,
    value: value.flowId,
  }));

  return (
    <DetailLayout
      detailTime={`${id} - ${orderTypeById ? orderTypeById.orderDesc : ''}`}
      detailTitle={'Edit Order Type Data'}
      className={'edit-form'}
      detailComponent={
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item className="edit-form__submit">
            <Tooltip title="Submit">
              <Button
                size="large"
                htmlType="submit"
                loading={loadingButton}
                icon="check-circle"
              />
            </Tooltip>
          </Form.Item>
          <Form.Item label="Order Type">
            {getFieldDecorator('orderType', {
              rules: [{ required: true, message: 'Please input order type!' }],
              initialValue: orderTypeById && orderTypeById.orderType,
            })(
              <Input
                placeholder="Order Type"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="Order Description">
            {getFieldDecorator('orderDesc', {
              rules: [
                { required: true, message: 'Please input order description!' },
              ],
              initialValue: orderTypeById && orderTypeById.orderDesc,
            })(
              <Input
                placeholder="Order Description"
                size="large"
                autoComplete="false"
              />
            )}
          </Form.Item>

          <Form.Item label="Flow ID">
            {getFieldDecorator('flowId', {
              rules: [{ required: true, message: 'Please input flow!' }],
              initialValue: orderTypeById && orderTypeById.flowId,
            })(
              <SelectDropdown
                options={flowOptions}
                placeHolder={'Select Flow'}
              />
            )}
          </Form.Item>
        </Form>
      }
    />
  );
}

export const OrderTypeForm = Form.create({
  name: 'validate_create_order-type',
})(OrderTypeCreateEdit);

export default { OrderTypeCreateEdit };
