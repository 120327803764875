import React, { useEffect, useState, useRef } from 'react';
import { Layout, message, Modal } from 'antd';

import { setToArray } from 'utils';
import StateApi from 'api/state';
import { PageSpinner } from 'components/PageSpinner';
import TableGlobal from 'components/Table';

import { tableStateColumns } from '../helper';
import ModalAdd from './components/ModalAdd';

const { Content } = Layout;
const { confirm } = Modal;

export function StateList() {
  const [states, setStates] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalAddData, setShowModalAddData] = useState(false);
  const innerRef = useRef(null);
  const [editTable, setEditTable] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    // auditState: [], //auditState
  });
  const [rowSelected, setRowSelected] = useState([]);

  const handleEditTable = () => {
    setEditTable(!editTable);
  };

  const handleHideModalAddData = () => {
    setShowModalAddData(false);
  };

  const handleShowModalAddData = () => {
    setShowModalAddData(true);
  };

  useEffect(() => {
    getStateList();
  }, []);

  async function getStateList() {
    try {
      const response = await StateApi.listState();
      let stateList = setToArray(response.data);

      setStates(stateList);
    } finally {
      setLoadingPage(false);
    }
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  const handleDeleteRow = async () => {
    const getStateId = rowSelected.map((value) => value.stateId);
    const getStateIdToString = getStateId.join(',');

    confirm({
      title: `Are you sure delete this state data?`,
      okText: 'Yes',
      okType: 'danger',
      content: `If you click "Yes", then state data with id ${getStateIdToString} will be deleted`,
      cancelText: 'No',
      onOk() {
        async function deletingState() {
          try {
            await StateApi.deleteMultipleState(getStateIdToString);
            setRowSelected([]);
            getStateList();
          } catch (err) {
            if (err.response) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingState();
      },
      onCancel() {},
    });
  };

  const activeFilterComponent = () => {
    return (
      <></>
      // <ActiveFilter
      //   setShowFilter={setShowFilter}
      //   activeFilterData={activeFilter}
      //   setActiveFilter={setActiveFilter}
      // />
    );
  };

  const filterComponent = () => {
    return (
      <></>
      // <Filter
      //   resetFilter={resetFilter}
      //   activeFilter={activeFilter}
      //   innerRef={innerRef}
      // />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelected(selectedRows);
    },
  };

  const handleOnRowPathname = (record) => {
    return `/cms/master/state/edit?id=${record.stateId}`;
  };

  return (
    <Layout className="order-list-page">
      <Content>
        <TableGlobal
          tableName={'Master : State'}
          inputPlaceholder={'Search State Here...'}
          pageSize={8}
          originalData={states}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          tableColumns={tableStateColumns}
          addDataTable={true}
          editTable={editTable}
          handleEditTable={handleEditTable}
          exportCsvIsExist={false}
          editableTable={true}
          activeFilterComponent={activeFilterComponent()}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          innerRef={innerRef}
          filterComponent={filterComponent()}
          handleShowAddData={handleShowModalAddData}
          onRowPathname={handleOnRowPathname}
          rowSelection={rowSelection}
          rowSelected={rowSelected}
          recordKey={(record) => record.stateId}
          isFilter={false}
          handleDeleteRow={handleDeleteRow}
        />
      </Content>
      <ModalAdd
        showModalAddData={showModalAddData}
        handleHideModalAddData={handleHideModalAddData}
        setShowModalAddData={setShowModalAddData}
        getStateList={getStateList}
      />
    </Layout>
  );
}

export default { StateList };
